import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { dismissInstructions } from '../../../../actions/billingActions';

const BillingInstructionModal = (props) => {
  const confirm = () => {
    props._dismissInstructions();
  };

  let billingSubtitle;
  let billingTitle;
  if (props.platform === 'Shopify') {
    billingSubtitle = 'Clyde bills your store separately from Shopify';
  } else {
    billingSubtitle = '';
    billingTitle = 'Clyde Billing';
  }

  return (
    <div className='modal-container__backdrop modal-container__backdrop--billing'>
      <div className='billing-instructions'>
        <div className='billing-instructions__subtitle'>
          { billingSubtitle }
        </div>
        { billingTitle
          && <div className='billing-instructions__title'>
            { billingTitle }
          </div>
        }
        <div className='billing-instructions__section'>
          In order to launch your product protection program with Clyde,
          we'll need to collect your billing information.
        </div>
        <div className='billing-instructions__section'>
          <div>When a customer purchases a Clyde Protection Plan on your site,
            you collect the full cost of the plan up front. In order to work with our
            insurance partners, Clyde will invoice you each month for the premiums and
            fees from the contracts you sold. You keep 100% of your risk-free profit.
          </div>
          <br/>
        </div>
        <div className='billing-instructions__button-container'>
          <div onClick={ confirm } className='billing-instructions-confirm'>
            I get it
          </div>
          <div className='billing-instructions-more-questions'>
            <a
              href='https://docs.joinclyde.com/docs/billing-payments-refunds'
              target='_blank'
              rel='noopener noreferrer'
            >
              I have more questions
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  platform: state.auth.platform,
  invoice: state.billing.invoice,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({ _dismissInstructions: dismissInstructions }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(BillingInstructionModal);
