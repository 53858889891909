import React, { Component } from 'react';
import validator from 'validator';
import { ClydeTextInput } from '@joinclyde/clyde-react-components';

class CreateUser extends Component {
  state = {
    accountType: 'basic',
    email: '',
    errors: [],
  }

  componentWillUnmount() {
    this.props._clearFeedback();
  }

  handleCreateUser = (e) => {
    e.preventDefault();
    if (!this.props.isProcessing) {
      const errors = [];
      if (!validator.isEmail(this.state.email)) {
        errors.push('Email is not valid.');
      }

      if (errors.length > 0) {
        this.props._clearSuccess();
        this.setState({ errors });
      } else {
        this.setState({ errors: [] });
        this.props._createUser({
          shopId: this.props.shopId,
          accountType: this.state.accountType,
          email: this.state.email,
        });
      }
    }
  }

  handleChange = (e) => {
    e.preventDefault();
    const { value } = e.target;
    const { name } = e.target;
    this.setState({ [name]: value });
  }

  buildFeedback = () => {
    if (this.props.isSuccess) {
      return (
        <span className='create-user__feedback-success'>Successfully created new user!</span>
      );
    }
    return (
        <ul className='create-user__feedback-errors-list'>
          { this.state.errors.map((err, idx) => <li key={ idx } >{ err }</li>) }
        </ul>
    );
  }

  render() {
    const feedBackClassName = 'col-12 col-md-8 col-lg-9 d-flex align-items-center justify-content-center justify-content-md-start';
    return (
      <div className='info-tile create-user__tile'>
        <form
          className='create-user__form'
          onSubmit={ this.handleCreateUser }>
          <div className='create-user__input row'>
            <div className='col-12 col-md-8 col-lg-9'>
              <label htmlFor='create-user-email-input'>Email:</label>
              <ClydeTextInput
                id='create-user-email-input'
                name='email'
                onChange={ this.handleChange }
                value={ this.state.email }
              />
            </div>
            <div className='col-12 col-md-4 col-lg-3'>
              <label htmlFor='create-user-account-type'>Account Type:</label>
              <select
                id='create-user-account-type'
                type='text'
                name='accountType'
                onChange={ this.handleChange }
                value={ this.state.accountType }>
                <option value='admin' >Admin</option>
                <option value='basic'>User</option>
              </select>
            </div>
          </div>
          <div className='row create-user__feedback'>
            <div className='col-12 col-md-4 col-lg-3 d-flex justify-content-start'>
              <button
                className='button'
                type='submit'>Create User</button>
            </div>
            {
              this.state.errors.length > 0 || this.props.isSuccess
                ? <div className={ feedBackClassName }>{ this.buildFeedback() }</div>
                : null
            }
          </div>
        </form>
      </div>
    );
  }
}

export default CreateUser;
