import validator from 'validator';
import { getCountryCallingCode, isPossiblePhoneNumber } from 'libphonenumber-js';

import countriesAndProvincesList from './countriesAndProvinces';

const SUPPORTED_COUNTRIES = new Set(Object.keys(countriesAndProvincesList));

const customerInfoValidator = (customerInfo, { optional }) => {
  const formErrors = [];
  for (const field of Object.keys(customerInfo)) {
    const value = customerInfo[field];

    if (!value && optional.has(field)) {
      continue;
    }

    switch (field) {
      case 'firstName':
      case 'lastName':
      case 'address1':
      case 'city':
        if (validator.isEmpty(value)) {
          formErrors.push(field);
        }
        break;
      case 'email':
        if (!validator.isEmail(value)) {
          formErrors.push('email');
        }
        break;
      case 'phone':
        if (!isPossiblePhoneNumber(value, customerInfo.country)) {
          if (value.replace('+', '') === getCountryCallingCode(customerInfo.country)) {
            // TODO: make sure phone is optional?
            customerInfo.phone = '';
          } else {
            formErrors.push('phone');
          }
        }
        break;
      case 'province':
        if (provinceError(value.toUpperCase(), customerInfo.country)) {
          formErrors.push('province');
        }
        break;
      case 'country':
        if (!SUPPORTED_COUNTRIES.has(value)) {
          formErrors.push('country');
        }
        break;
      case 'zip':
        if (validator.isEmpty(value) || !validator.isPostalCode(value, customerInfo.country)) {
          formErrors.push('zip');
        }
        break;
      default:
        break;
    }
  }
  return formErrors;
};

const provinceError = (province, country) => {
  const provinces = countriesAndProvincesList[country];

  if (provinces === false || !provinces.length) {
    // Don't validate province if it isn't required for the country
    return false;
  } if (!provinces.find((state) => state.value === province)) {
    return true;
  } if (validator.isEmpty(country)) {
    return true;
  } if (validator.isEmpty(province)) {
    return true;
  }
  return false;
};

export default customerInfoValidator;
