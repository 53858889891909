import React from 'react';
import ContractSale from './ContractSale';

const SaleList = (props) => (
    <div className='sales-list-container'>
      <div className='sales-list-container__header row'>
        <div className='col-6'>Customer Email</div>
        <div className='col-2'>Purchase Date</div>
        <div className='col-2'>Billed</div>
        <div className='col-2'>Refunded</div>
      </div>
      { props.sales.map((sale, index) => (
          <ContractSale key={ `sale-${index}` } sale={ sale } currency={ props.currency } showConfirmResendEmail={ props.showConfirmResendEmail }/>
      )) }
    </div>
);

export default SaleList;
