import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router';

import {
  launchProducts,
  openUploadModal,
  openNewProductModal,
  dismissLaunchInstr,
  getRateSheetExport,
  toggleDownloadFalse,
  getLaunchingStatus,
  setPage,
} from '../../actions/productsActions';
import { getShopSettings } from '../../actions/autopilotActions';
import { getBillingInfo } from '../../actions/billingActions';
import { getLicensingInfo } from '../../actions/licenseActions';
import { getCTAInfo, toggleOnlyIncludeRegistrableProducts } from '../../actions/customizeActions';
import { toggleViewRegistrableProducts } from '../../actions/productRegistrationsActions';

import Products from './Products';

const mapStateToProps = (state) => ({
  products: state.products,
  billingAccounts: state.billing.accounts,
  platform: state.auth.platform,
  billingInvoice: state.billing.invoice,
  contractSaleModalOpen: state.contractSale.modalOpen,
  onlyIncludeRegistrableProducts: state.customize.onlyIncludeRegistrableProducts,
  showRegistrationPage: state.customize.showRegistrationPage,
  viewRegistrableProducts: state.productRegistrations.viewRegistrableProducts,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  _launchProducts: launchProducts,
  _getBillingInfo: getBillingInfo,
  _getShopSettings: getShopSettings,
  _openUploadModal: openUploadModal,
  _openNewProductModal: openNewProductModal,
  _dismissLaunchInstr: dismissLaunchInstr,
  _getCTAInfo: getCTAInfo,
  _getRateSheetExport: getRateSheetExport,
  _toggleDownloadFalse: toggleDownloadFalse,
  _setPage: setPage,
  _getLaunchingStatus: getLaunchingStatus,
  _toggleOnlyIncludeRegistrableProducts: toggleOnlyIncludeRegistrableProducts,
  _toggleViewRegistrableProducts: toggleViewRegistrableProducts,
  _getLicensingInfo: getLicensingInfo,
}, dispatch);

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Products));
