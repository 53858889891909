import React from 'react';
import { LoadingDots, ClydeTooltip } from '@joinclyde/clyde-react-components';

const DataTile = (props) => {
  const {
    handleSelect,
    id,
    isLoading,
    mainValue,
    secondaryInfo,
    selected,
    title,
    tooltipLabel,
  } = props;
  let className = 'button-nostyle performance-data-tile';
  if (!handleSelect) {
    // no onClick function passed down; tile is un-selectable
    className += ' performance-data-tile--no-select';
  }
  if (selected) {
    // tile is selected
    className += ' performance-data-tile--selected';
  }

  return (
    <button
      id={ id }
      className={ className }
      disabled={ !handleSelect }
      onClick={ handleSelect }>
      <div className='performance-data-tile__title'>
        <span>{ title }</span>
        {
          tooltipLabel
          && <ClydeTooltip
          position='bottom'
          label={ tooltipLabel }
          hideIndicator>
            <img
              className='performance-data-tile__title__info'
              data-tip
              tabIndex='0'
              alt='info'
              src='/images/info-icon.svg' />
          </ClydeTooltip>

        }
      </div>
      <div className='performance-data-tile__content'>
        {
          isLoading
            ? <LoadingDots dotColor='clydeBlack' dotSize='medium' />
            : <React.Fragment>
                <ul className='performance-data-tile__list'>
                  <li className='performance-data-tile__list__main'>{ mainValue }</li>
                  {
                    secondaryInfo.map((info, idx) => (
                      <li key={ `dt-${idx}` }>
                      <span className='performance-data-tile__list__title'>{ info.title }</span>
                      <span className='performance-data-tile__list__value'>{ info.value }</span>
                      </li>
                    ))
                  }
                </ul>
              </React.Fragment>
        }
      </div>
    </button>
  );
};

export default DataTile;
