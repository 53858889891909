import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { bindActionCreators } from 'redux';
import Cookies from 'universal-cookie';
import NavBar from './NavBar';
import { getOnboardingInfo } from '../actions/onboardingActions';
import { returnedToLogin } from '../actions/authActions';
import { getClaimsForResolution } from '../actions/claims/claimsListActions';
import LocaleProvider from '../providers/LocaleProvider';
import ApiProvider from '../providers/ApiProvider';

const cookies = new Cookies();

class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      shopId: cookies.get('clydeShopId'),
    };
  }

  componentDidMount() {
    if (!cookies.get('clydeShopId')) {
      this.props.history.push('/login');
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.returnToLogin === true) {
      this.props.history.push('/login');
      this.props._returnedToLogin();
    }
    if (!cookies.get('clydeShopId')) {
      this.props.history.push('/login');
      return;
    }
    if (nextProps.authed && this.props.initialState) {
      this.props._getOnboardingInfo();
      this.props._getClaimsForResolution(this.shopId);
    }
    if (nextProps.isOnboarding && !nextProps.initialState) {
      this.props.history.push('/welcome');
      return;
    }
    if (this.props.history.location.pathname === '/') {
      this.props.history.push('/products');
    }
  }

  render() {
    const { loading, authed, children } = this.props;
    const { shopId } = this.state;
    return (
      <div className='page-wrapper'>
        <NavBar />
        { shopId && authed && !loading
          ? (
            <ApiProvider shopId={ shopId } token={ cookies.get('clydeToken') }>
              <LocaleProvider shopId={ shopId }>
                { children }
              </LocaleProvider>
            </ApiProvider>
          )
          : null
        }
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  ...state.onboarding,
  authed: state.auth.authed,
  loading: state.auth.loading,
  returnToLogin: state.auth.returnToLogin,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  _getOnboardingInfo: getOnboardingInfo,
  _returnedToLogin: returnedToLogin,
  _getClaimsForResolution: getClaimsForResolution,
}, dispatch);

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Home));
