import React, { Component } from 'react';
import {
  ClydeButton,
  ClydeCheckbox,
  SuccessAnimation,
} from '@joinclyde/clyde-react-components';

import ContactForm from './ContactForm';

class ChangeOrderCustomerModal extends Component {
  state = {
    customerInfo: {
      email: '',
      phoneNumber: '',
      firstName: '',
      lastName: '',
      address1: '',
      address2: '',
      city: '',
      country: 'US',
      province: '',
      zip: '',
    },
    hasError: null,
    validRequest: false,
    validTransfer: false,
  }

  submitChange = () => {
    const {
      orderId,
      userId,
      _submitChange,
      selectedOrder: {
        address: {
          country: originalUserCountry,
        },
      },
      shopId,
    } = this.props;
    const { customerInfo, validRequest, validTransfer } = this.state;

    let hasError = null;

    if (originalUserCountry !== customerInfo.country) {
      hasError = `Transfers are only available to customers in ${originalUserCountry}`;
    }

    if (!validRequest || !validTransfer) {
      hasError = 'Please verify this transfer is valid';
    }

    for (const key of Object.keys(customerInfo)) {
      if (customerInfo[key] || key === 'address2') {
        continue;
      }
      hasError = 'All fields required (except address 2)';
      break;
    }

    this.setState({ hasError });

    if (!hasError) {
      _submitChange(customerInfo, orderId, userId, shopId, 'dashboard');
    }
  }

  handleChange = (e) => {
    const customerInfo = { ...this.state.customerInfo };
    const { name, value } = e.target;
    customerInfo[name] = value;
    this.setState({
      customerInfo,
      hasError: null,
    });
  }

  toggleCheck = (e) => {
    const { name, checked } = e.target;
    this.setState({ [name]: checked });
  }

  render() {
    const {
      processing,
      finished,
      _closeModal,
    } = this.props;
    const {
      customerInfo,
      validTransfer,
      validRequest,
      hasError,
    } = this.state;

    return (
      <div className='cop-modal-background cop-change-order-customer' onClick={ _closeModal }>
        <div className='cop-modal-foreground' onClick={ (e) => e.stopPropagation() }>
          {
            finished
              ? <div className='cop-modal-foreground__action-complete'>
                  <SuccessAnimation text='Order updated!' onSuccess={ _closeModal } />
                </div>
              : <React.Fragment>
                  <div className='cop-modal-header'>
                    <button className='button-no-style cop-modal-header__cancel' onClick={ _closeModal }>
                      &#x2715;
                    </button>
                    <p className='cop-modal-header__explanation'>
                      Change customer information on this specific order
                    </p>
                    <h1 className='cop-modal-header__header'>Update Order Info</h1>
                  </div>

                  <div className='cop-modal-content'>
                    <ContactForm
                      contactInfo={ customerInfo }
                      _handleTextChange={ this.handleChange }
                    />
                  </div>

                  <ul className='cop-change-order-customer__verifications'>
                    <li>
                      <ClydeCheckbox
                        backgroundColor='clydeBlack'
                        id='validRequest'
                        name='validRequest'
                        onChange={ this.toggleCheck }
                        value={ validRequest } />
                      <label className='checkbox-label cop-change-order-customer__verifications__label' htmlFor='validRequest'>
                        I have verified that the individual requesting this update
                        is the same individual that made this order.
                      </label>
                    </li>

                    <li>
                      <ClydeCheckbox
                        backgroundColor='clydeBlack'
                        id='validTransfer'
                        name='validTransfer'
                        onChange={ this.toggleCheck }
                        value={ validTransfer } />
                      <label className='checkbox-label cop-change-order-customer__verifications__label' htmlFor='validTransfer'>
                        If I am transferring this information to a different individual,
                        I have communicated this transfer to the appropriate insurance partner.
                      </label>
                    </li>
                  </ul>

                  {
                    hasError
                      && <p className='cop-change-order-customer__error'>{ hasError }</p>
                  }

                  <div className='cop-modal-actions'>
                    <ClydeButton
                      className='cop-modal-actions__confirm'
                      id='confirm-change-order-customer-button'
                      onClick={ this.submitChange }
                      processing={ processing }
                      successText='Updated!'
                    >
                      Update Order Info
                    </ClydeButton>
                  </div>
                </React.Fragment>
          }
        </div>
      </div>
    );
  }
}

export default ChangeOrderCustomerModal;
