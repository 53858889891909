import { wsSend } from './websocketActions';

export const selectColor = (colorCode) => {
  const hexRegex = /^#[0-9A-F]{6}$/;
  if (!hexRegex.test(colorCode)) {
    return {
      type: 'SELECT_INVALID_COLOR',
      payload: {
        colorCode,
      },
    };
  }

  return {
    type: 'SELECT_COLOR',
    payload: {
      colorCode,
    },
  };
};

export const setDisplayName = (name) => ({
  type: 'SET_DISPLAY_NAME',
  payload: name,
});

export const setCustomLocation = (location) => ({
  type: 'SET_CUSTOM_LOCATION',
  payload: location,
});

export const togglePrompt = (promptName) => ({
  type: 'TOGGLE_PROMPT',
  payload: promptName,
});

export const togglePostPurchase = () => ({
  type: 'TOGGLE_POST_PURCHASE',
});

export const setProductPageLocation = (optionNum) => ({
  type: 'SET_PRODUCT_PAGE_LOCATION',
  payload: optionNum,
});

export const selectType = (type) => ({
  type: 'SELECT_TYPE',
  payload: type,
});

export const selectCartType = (type) => ({
  type: 'SELECT_CART_TYPE',
  payload: type,
});

export const displayCTASaveButton = (bool) => ({
  type: 'DISPLAY_CTA_SAVE_BUTTON',
  payload: bool,
});

export const getCTAInfo = () => (dispatch) => {
  dispatch({
    type: 'GET_CTA_INFO_PROCESSING',
    payload: {},
  });
  dispatch(wsSend({
    type: 'GET_CTA_INFO_REQ',
    payload: {},
  }));
};

export const setShopImageData = (data) => ({
  type: 'SET_SHOP_IMAGE_DATA',
  payload: data,
});

export const updateCTAInfo = (ctaState) => {
  const sendState = {
    displayName: ctaState.displayName,
    color: ctaState.color,
    validColor: ctaState.validColor,
    modalPrompt: ctaState.modalPrompt,
    cartPrompt: ctaState.cartPrompt,
    productPagePrompt: ctaState.productPagePrompt,
    catalogPrompt: ctaState.catalogPrompt,
    type: ctaState.type,
    cartType: ctaState.cartType,
    productPageLocation: ctaState.productPageLocation,
    customLocation: ctaState.customLocation,
    shopImageData: ctaState.imageData,
    newImage: ctaState.newImage,
    postPurchaseEmailCampaign: ctaState.postPurchaseEmailCampaign,
  };

  return (dispatch) => {
    dispatch({
      type: 'CTA_SAVE_PROCESSING',
      payload: {},
    });
    dispatch(wsSend({
      type: 'UPDATE_CTA_INFO_REQ',
      payload: sendState,
    }));
  };
};

export const toggleOnlyIncludeRegistrableProducts = (bool) => (dispatch) => {
  dispatch(wsSend({
    type: 'TOGGLE_ONLY_INCLUDE_REGISTRABLE_PRODUCTS',
    payload: {
      onlyIncludeRegistrableProducts: bool,
    },
  }));
};

export const completeCustomizeStep = (ctaState) => {
  const sendState = {
    displayName: ctaState.displayName,
    color: ctaState.color,
    validColor: ctaState.validColor,
    modalPrompt: ctaState.modalPrompt,
    cartPrompt: ctaState.cartPrompt,
    productPagePrompt: ctaState.productPagePrompt,
    catalogPrompt: ctaState.catalogPrompt,
    type: ctaState.type,
    productPageLocation: ctaState.productPageLocation,
    customLocation: ctaState.customLocation,
    shopImageData: ctaState.imageData,
    newImage: ctaState.newImage,
  };
  return wsSend({
    type: 'COMPLETE_ONBOARDING_CUSTOMIZE_REQ',
    payload: sendState,
  });
};

export const updateAffirmIntegration = ({ affirmEnabled, affirmPublicKey }) => {
  const sendState = {
    affirmEnabled,
    affirmPublicKey,
  };
  return wsSend({
    type: 'UPDATE_AFFIRM_INTEGRATION_REQ',
    payload: sendState,
  });
};

export default {};
