import React from 'react';
import { ClydeButton } from '@joinclyde/clyde-react-components';

const WarningModal = (props) => (
  <div className='modal-container'>
    <div className='modal-container__backdrop' />
    <div className='clyde-modal-content'>
      <div className='onboarding-warning-modal onboarding-warning-modal' data-test='warningModal'>
        <h3>Thanks again for downloading Clyde!</h3>
        <br />
        <p>
          { `A Merchant Onboarding Specialist is currently reviewing your store to ensure it's fully
          eligible for a protection program and will let you know within 48 hours whether there's a fit.
          In the meantime, feel free to learn more about what types of merchants perform best with Clyde ` }
          <a href='https://docs.joinclyde.com/docs/clydes-ideal-merchant-profile'>here</a>.
          You may continue the onboarding process now, but you'll need to wait for approval
          from our team to launch your program.
        </p>
        <ClydeButton
          className='onboarding-warning-modal__button'
          onClick={ props._nextStep }
          data-test='gotIt'
        >
          Got it!
        </ClydeButton>
        <p className='onboarding-warning-modal__small-print'>
          If you're already working with Clyde's Implementations Team,
          disregard the above, and continue with onboarding!
        </p>
      </div>
    </div>
  </div>
);

export default WarningModal;
