import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { bindActionCreators } from 'redux';

import { getCTAInfo } from '../../actions/customizeActions';
import Settings from './Settings';

const mapStateToProps = (state) => ({
  accountType: state.auth.accountType,
  platform: state.auth.platform,
  waw: {
    // usesCheckoutWidget: state.customize.usesCheckoutWidget,
    showRegistrationPage: state.customize.showRegistrationPage,
  },
});
const mapDispatchToProps = (dispatch) => bindActionCreators({
  _getCTAInfo: getCTAInfo,
}, dispatch);

export default withRouter(connect(
  mapStateToProps, mapDispatchToProps,
)(Settings));
