import { wsSend } from './websocketActions';

export const startRecordSale = (product) => ({
  type: 'START_SALE',
  payload: { product },
});

export const selectVariant = (variant) => ({
  type: 'SELECT_VARIANT',
  payload: { variant },
});

export const selectContract = (contract) => ({
  type: 'SELECT_CONTRACT',
  payload: { contract },
});

export const submitSale = (
  productId,
  contractId,
  productPrice,
  contractBackendFee,
  contractPlatformFee,
  contractBaseCost,
  customerInfo,
  customerCost,
  quantity,
) => (dispatch) => {
  dispatch(wsSend({
    type: 'SUBMIT_SALE_REQ',
    payload: {
      productId,
      contractId,
      productPrice: parseFloat(productPrice),
      contractBackendFee,
      contractPlatformFee,
      contractBaseCost,
      customerInfo,
      customerCost,
      quantity,
    },
  }));
  dispatch({
    type: 'SUBMIT_SALE_PROCESSING',
    payload: {},
  });
};

export const exitSale = () => ({
  type: 'EXIT_SALE',
  payload: {},
});

export default {};
