import React, { Component } from 'react';
import {
  ClydeTextInput,
  ClydeSelect,
  ClydeButton,
} from '@joinclyde/clyde-react-components';

import CustomerTable from './CustomerTable';

class CustomerSearch extends Component {
  state = {
    search: '',
    searchKey: new Date(), // to re-render search input on type change
    searchTypeName: '',
    searchSubmitted: false,
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    const {
      customers: { searchType },
    } = this.props;
    this.setSearchTypeName(searchType);
  }

  handleValueChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  }

  handleSearchTypeChange = (e) => {
    this.setSearchTypeName(e.target.value);
    this.setState({ searchKey: new Date() });
    this.props._setSearchType(e.target.value);
  }

  setSearchTypeName = (key) => {
    const switchKey = key || 'email';
    let searchTypeName;
    switch (switchKey) {
      case 'email':
        searchTypeName = 'email address';
        break;
      case 'phone':
        searchTypeName = 'phone number';
        break;
      case 'externalId':
        searchTypeName = 'unique ID';
        break;
      case 'merchantOrderId':
        searchTypeName = 'your order ID';
        break;
      case 'lineItemSerialNumber':
        searchTypeName = 'a line item\'s serial number';
        break;
      case 'orderId':
        searchTypeName = 'an order ID';
        break;
      default:
        searchTypeName = 'email';
        break;
    }
    this.setState({ searchTypeName });
  }

  runSearch = (e) => {
    e.preventDefault();
    if (!this.state.search) {
      return;
    }

    const { search } = this.state;
    const {
      customers: { searchType },
      shopId,
      _searchCustomers,
    } = this.props;
    _searchCustomers(shopId, search, searchType);
    this.setState({ searchSubmitted: true });
  }

  handleSelectCustomerClick = (e, customer) => {
    this.props._selectCustomer(customer);
  }

  handleSelectCustomerKey = (e, customer) => {
    if (e.key === ' ' || e.key === 'Enter') {
      e.preventDefault();
      this.props._selectCustomer(customer);
    }
  }

  render() {
    const {
      customers,
      platform,
      handleRegistrationCsvExport,
      _showNewOrderModal,
    } = this.props;

    const searchTypes = [
      {
        value: 'email',
        text: 'Email Address',
      },
      {
        value: 'phone',
        text: 'Phone Number',
      },
      {
        value: 'externalId',
        text: 'Unique Identifier',
      },
      {
        value: 'merchantOrderId',
        text: 'Merchant Order ID',
        applicable: platform === 'Shopify',
      },
      {
        value: 'lineItemSerialNumber',
        text: 'Serial Number',
        applicable: customers.canSerialNumberSearch,
      },
      {
        value: 'orderId',
        text: 'Order ID',
      },
    ].filter((option) => option.applicable === undefined || option.applicable);

    return (
      <div className='customers'>
        <div className='customers__header__section'>
          <div className='customers__header'>
            <h1>Customers</h1>
            <p>View all customers who’ve purchased a contract or registered their product(s).</p>
          </div>

          <div className='customers__header__buttons'>
            <ClydeButton
              id='customers__header__button-registrations'
              className='customers__header__button-registrations'
              onClick={ () => { handleRegistrationCsvExport(); } }
            >
              Export Registrations
            </ClydeButton>
            <ClydeButton
              className='customers__header__button-create-order'
              onClick={ () => { _showNewOrderModal(); } }
            >
              New order
            </ClydeButton>
          </div>

        </div>

        <div className='customers__input'>
          <h2>Search by:</h2>
          <form
            className='customers__input__form'
            onSubmit={ this.runSearch } >
            <ClydeSelect
              options={ searchTypes }
              defaultValue={ customers.searchType }
              name='searchType'
              className='customers__input__form__search-type'
              defaultLabel='Search By...'
              onChange={ this.handleSearchTypeChange } />
            <ClydeTextInput
              type='text'
              search
              id='customers__input__form__search'
              className='customers__input__form__search'
              name='search'
              key={ this.state.searchKey }
              onChange={ this.handleValueChange }
              placeholder={ `Search by ${this.state.searchTypeName}` }
            />
          </form>
        </div>
        <CustomerTable
          selectCustomerClick={ this.handleSelectCustomerClick }
          selectCustomerKey={ this.handleSelectCustomerKey }
          customers={ customers.customers }
          isProcessing={ customers.isProcessing }
          platform={ platform }
          search={ this.state.search }
          searchType={ customers.searchType }
          searchSubmitted={ this.state.searchSubmitted }/>
      </div>
    );
  }
}

export default CustomerSearch;
