import React from 'react';
import classnames from 'classnames';
import Transition from 'react-transition-group/Transition';

const UserTypeSwitch = (props) => {
  const duration = 200;
  const defaultStyle = {
    transition: 'left 200ms ease-in-out',
  };
  const transitionStyles = {
    exiting: { left: '64px' },
    exited: { left: '64px' },
    entering: { left: '4px' },
    entered: { left: '4px' },
  };
  const switchClassname = classnames('user-type-switch', {
    'user-type-switch--true': props.truthValue,
    'user-type-switch--false': !props.truthValue,
    'user-type-switch--disabled': props.disabled,
  });

  return (
    <Transition in={ props.truthValue } timeout={ duration }>
      {
        (state) => (
          <div
            className={ switchClassname }
            onClick={ () => props.toggleFunc(props.truthValue) } >
              <div className='user-type-switch__dot' style={ { ...defaultStyle, ...transitionStyles[state] } } >
              <span className='user-type-switch__dot__text user-type-switch__dot__text--true'>{ props.trueLabel }</span>
              <span className='user-type-switch__dot__text user-type-switch__dot__text--false'>{ props.falseLabel }</span>
              </div>
          </div>
        )
      }
    </Transition>
  );
};

export default UserTypeSwitch;
