import React, { Component } from 'react';
import classnames from 'classnames';
import { Link } from 'react-router-dom';
import { UnmountClosed } from 'react-collapse';
import { Helmet } from 'react-helmet';
import { ClydeCheckbox } from '@joinclyde/clyde-react-components';

import SettingSwitch from './SettingSwitch';
import ConfirmLaunch from '../Shared/ConfirmLaunch';
import SaveButton from '../../Utils/SaveButton';
import { Currency } from '../../Utils/Currency';

class ContractSettings extends Component {
  state = {
    marginChanged: false,
    showLaunchModal: false,
  }

  UNSAFE_componentWillMount() {
    this.props._getShopSettings(true);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const {
      platform,
      autopilot,
      billingAccounts,
      billingInvoice,
    } = this.props;
    if (
      autopilot.saveProcessing
      && !nextProps.autopilot.saveProcessing
      && (platform === 'Shopify' || platform === 'BigCommerce')
    ) {
      const defaultBillingAcct = billingAccounts.find((acct) => acct.default);
      const billingReady = billingInvoice
        || (defaultBillingAcct && defaultBillingAcct.verified);
      if (this.state.marginChanged && billingReady) {
        this.setState({ marginChanged: false });
        this.toggleLaunchModal();
      }
    }
  }

  handleLaunch() {
    this.toggleLaunchModal();
    this.props._launchProducts();
  }

  handleSave() {
    this.props._updateShopSettings(this.props.autopilot, this.state.marginChanged);
  }

  toggleLaunchModal() {
    this.setState({ showLaunchModal: !this.state.showLaunchModal });
  }

  marginChange(event) {
    if (event.target.value > 400) {
      this.props._setCustomMargin(400);
    } else {
      this.props._setCustomMargin(event.target.value.slice(0, 6));
    }
    this.setState({ marginChanged: true });
  }

  emptyMargin(event) {
    this.setState({ marginChanged: true });
    if (!event.target.value) {
      this.props._setCustomMargin(25);
    }
  }

  handleRoundTotal(event) {
    this.setState({ marginChanged: true });
    this.props._setRoundTotal(event.target.checked);
  }

  handleToggleAutoMargin() {
    this.setState({ marginChanged: true });
    this.props._toggleAutoMargin();
  }

  render() {
    const {
      platform,
      launchProcessing,
      autopilot: {
        autoMargin,
        markupPercentage,
        roundTotal,
        saveProcessing,
        shopReviewed,
        showSaveButton,
      },
    } = this.props;

    const marginDollar = roundTotal
      ? ((Math.round((5.00 * markupPercentage) / 100) * 2) / 2 - 0.01).toFixed(2)
      : (Math.round(((5.00 * markupPercentage) / 100) * 100) / 100).toFixed(2);
    const totalCost = (parseFloat(marginDollar) + 5.00).toFixed(2);

    return (
      <div>
        <div>
          <div className='page-container'>
            <Helmet>
              <title>Clyde | Contracts</title>
            </Helmet>

            <div className='page-layout'>
              { shopReviewed === false
                && <p className='onboarding-instructions'>Customize your autopilot settings to determine how we should manage your protection program.</p>
              }
              <div className='title-wrapper'>
                <h1>Contract Settings</h1><br/>
              </div>
              <div className='page-layout__content'>
                <div className='autopilot-option'>
                  <div className='autopilot-option__title '>Autopilot Connect
                  </div>
                  <div className='autopilot-switch--always-on'>ON</div>
                  <div className='autopilot-option__subtitle'>Clyde automatically connects all your products with contracts, even when you add a new product. This is always on.</div>
                </div>
                <div className='autopilot-option'>
                  <div className={ classnames('autopilot-option__title',
                    { 'autopilot-option__title--inactive': !autoMargin }) }>
                    Autopilot Markup
                  </div>
                  <div className='autopilot-switch-container'>
                    <SettingSwitch
                      on={ autoMargin }
                      toggle={ () => { this.handleToggleAutoMargin(); } }/></div>
                  <div className={ classnames('autopilot-option__subtitle',
                    { 'autopilot-option__subtitle--inactive': !autoMargin }) }>
                    Clyde automatically optimizes your markup on each contract
                    to drive the most passive revenue to your top line.
                    { platform !== 'Shopify'
                      && <span>
                          We use this setting to provide you with a
                          recommended retail price for your contracts.
                        </span>
                    }
                  </div>
                </div>
                <UnmountClosed isOpened={ autoMargin === false }>
                  <div>
                    <div className='autopilot-option'>
                      <div className='autopilot-option__title '>Set custom markup percentage</div>
                      <div className='autopilot-option__subtitle'>
                        Set a markup percentage that applies to every contract.
                        The % amount is based on the price of the contract.
                      </div>
                    </div>
                    <div className='autopilot-option'>
                      <div className='margin-options'>
                        {
                          markupPercentage > 400
                            && <span>
                              Note: Your current markup percentage was set by Clyde to be above the allowed maximum.
                              </span>
                        }
                        <input
                          value={ markupPercentage }
                          onBlur={ (e) => { this.emptyMargin(e); } }
                          onChange={ (e) => { this.marginChange(e); } }
                          disabled={ launchProcessing }
                          type='number'
                          min='0'
                          max='400'
                          className='margin-options__amount-input'
                        />
                        <span className='margin-options__percent'>%</span>
                        <div className='margin-options__round-total'>
                          <ClydeCheckbox
                            backgroundColor='clydeBlack'
                            id='roundTotal'
                            onChange={ (e) => { this.handleRoundTotal(e); } }
                            checked={ roundTotal }
                            disabled={ launchProcessing }
                            label={
                              <>
                                Round total cost to the nearest&nbsp;
                                <Currency amount={ 0.99 } />
                              </>
                            }
                          />
                        </div>
                      </div>
                      <div className='margin-explanation'>
                        If a contract costs <em>
                          <Currency amount={ 5 } />
                        </em> to underwrite,
                        you are adding a markup of <em>
                          <Currency amount={ marginDollar } />
                        </em> <span className='margin-explanation__highlight'>({ markupPercentage }%)</span>.
                        Total cost to the consumer is <em>
                          <Currency amount={ totalCost } />
                        </em>
                        and you make <em>
                          <Currency amount={ marginDollar } />
                        </em> in profit.
                      </div>
                    </div>
                  </div>
                </UnmountClosed>
              </div>
            </div>
          </div>
        </div>

        <div className='process-div'>
          <div className='fixed-save-bar'>
            <SaveButton
              id={ 'contract-settings-save-button' }
              tooltipId={ 'contract-settings-tooltip' }
              className='autopilot-save-button'
              enableTooltip={ saveProcessing || launchProcessing }
              tooltipText={ 'Your contracts are currently updating. Please wait for this process to complete before changing your settings.' }
              disabled={ false }
              visible={ showSaveButton }
              saveFunc={ () => { this.handleSave(); } }
              processing={ saveProcessing || launchProcessing } />
            <Link to='/products' className='products-page-link'>Manage products</Link>
          </div>
        </div>

        { this.state.showLaunchModal
          && <ConfirmLaunch
            platform={ platform }
            updateText={ `Ready to launch your markup changes to ${platform}?` }
            cancelText={ 'Not yet' }
            handleLaunch={ () => { this.handleLaunch(); } }
            closeLaunchModal={ () => { this.toggleLaunchModal(); } }
            launched
            launchProcessing={ launchProcessing }
            billingReady
          />
        }
      </div>
    );
  }
}

export default ContractSettings;
