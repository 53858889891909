import React, { Component } from 'react';
import { SuccessAnimation } from '@joinclyde/clyde-react-components';
import moment from 'moment';

import FileClaim from './FileClaim';
import FileClaimResolution from '../../../Claims/ClaimResolution';
import AdjudicationDecision from './AdjudicationDecision';
import LimitedNotification from './LimitedNotification';
import { isApprovedClaim } from '../../../Utils/claimHelper';

class FileClaimModal extends Component {
  state = { divertToHiCylde: false }

  componentDidMount() {
    const {
      fileClaim: { sale, claimType, lineItem },
      shopId,
      issueTypes,
      _getSaleInfoForClaim,
      _getIssueTypes,
    } = this.props;

    const dataForDetailsQuery = claimType === 'extendedWarranty' ? sale.saleId : lineItem;

    _getSaleInfoForClaim(dataForDetailsQuery, claimType, shopId);
    if (!issueTypes?.length) {
      _getIssueTypes();
    }
  }

  handleSuccessfulFiling = () => {
    const {
      claimId,
      shopClaimSettings: { retailerResolutions },
      _cancelFileClaim,
      _showResolutionForm,
      _getClaimsForResolution,
      shopId,
    } = this.props;

    if (retailerResolutions) {
      _showResolutionForm(claimId);
      // Update claims that need resolution count when claim is filed and set to approved
      _getClaimsForResolution(shopId);
      return;
    }

    _cancelFileClaim();
  }

  checkAndFile = (formData, supportingInfo, shopClaimSettings, contractSaleInfo) => {
    const {
      fileClaim: {
        claimType,
        sale: { contractADH },
        lineItem,
      },
    } = this.props;

    const hasAccident = formData.claimIssueTypes.some((issue) => (
      issue.name === 'droppedOrDamaged'
    ));

    if (hasAccident && claimType === 'limitedWarranty' && (contractADH || lineItem.contractADH)) {
      this.setState({ divertToHiCylde: true });
      return;
    }

    this.props._confirmFileClaim(formData, supportingInfo, shopClaimSettings, contractSaleInfo);
  }

  stepSwitcher = () => {
    const {
      customerName,
      fileClaim,
      shopClaimSettings,
      orderId,
      issueTypes,
      _updateFileClaimInput,
      _cancelFileClaim,
    } = this.props;

    switch (fileClaim.step) {
      case 'resolution':
        return (
          <FileClaimResolution
            claimId={ fileClaim.claimId }
            productParts={ fileClaim.productParts }
            remainingLimitOfLiability={ fileClaim.remainingLimitOfLiability }
            shopClaimSettings={ shopClaimSettings }
            isLimitedClaim={ fileClaim.claimType === 'limitedWarranty' }
            _cancelFileClaim={ _cancelFileClaim }
          />
        );
      case 'claim':
      default:
        return (
          <FileClaim
            customerName={ customerName }
            fileClaim={ fileClaim }
            shopClaimSettings={ shopClaimSettings }
            orderId={ orderId }
            issueTypes={ issueTypes }
            _confirmFileClaim={ this.checkAndFile }
            _updateFileClaimInput={ _updateFileClaimInput }
          />
        );
    }
  }

  // Used to re-fetch claim data after a successful resolution
  refreshCop = () => {
    const {
      orderId,
      _getOrderDetails,
      _cancelFileClaim,
      _getClaimsForResolution,
      shopId,
    } = this.props;
    _cancelFileClaim();
    _getOrderDetails(orderId);
    // Update claims that need resolution count when claim is resolved
    _getClaimsForResolution(shopId);
  }

  goBackToCustomerSearch = () => {
    const { _backToCustomerSearch, _cancelFileClaim } = this.props;
    _cancelFileClaim();
    _backToCustomerSearch();
  }

  render() {
    const {
      fileClaim: {
        actionComplete,
        savedClaim,
        sale: {
          oemWarrantyLength: saleOemLength,
          productName,
          purchaseDate,
        },
        step,
        claimType,
        lineItem,
        referralEmailProcessing,
      },
      shopClaimSettings: { retailerResolutions },
      shopName,
      _cancelFileClaim,
      _sendHiClydeReferral,
    } = this.props;

    if (
      (
        savedClaim?.claimState
        && (!isApprovedClaim(savedClaim.claimState) || retailerResolutions)
      )
      || this.state.divertToHiCylde
    ) {
      return (
        <AdjudicationDecision
          savedClaimState={ savedClaim.claimState }
          isReferral={ this.state.divertToHiCylde }
          referralEmailProcessing={ referralEmailProcessing }
          denialReason={ savedClaim.denialReason }
          contractSaleId={ lineItem?.saleId }
          _closeModal={ _cancelFileClaim }
          _toCustomerSearch={ this.goBackToCustomerSearch }
          _sendHiClydeReferral={ _sendHiClydeReferral }
          _refreshCop={ this.refreshCop }
        />
      );
    }

    const successText = isApprovedClaim(savedClaim.claimState) && retailerResolutions ? 'Approved!' : 'Filed!';
    const isLimitedClaim = claimType === 'limitedWarranty';
    const oemWarrantyLength = saleOemLength || lineItem?.oemWarrantyLength || 0;
    const oemWarrantyExpiry = moment(purchaseDate).utc().add(oemWarrantyLength, 'months');
    const oemWarrantyActive = oemWarrantyExpiry.isAfter(moment());
    const modalHeaderText = step === 'claim' ? 'Submit a Limited Warranty Claim' : `File a ${step.charAt(0).toUpperCase() + step.slice(1)}`;

    return (
      <div className='cop-modal-background' onClick={ _cancelFileClaim }>
        <div
          className='cop-modal-foreground cop-modal-foreground--cop-file-claim'
          onClick={ (e) => { e.stopPropagation(); } }>
          {
            actionComplete
              ? <div className='cop-modal-foreground__action-complete'>
                  <SuccessAnimation
                    color={ 'clydeGreen' }
                    onSuccess={ (e) => { this.handleSuccessfulFiling(e); } }
                    text={ successText } />
                </div>
              : <React.Fragment>
                  <LimitedNotification
                    isLimitedClaim={ isLimitedClaim }
                    shopName={ shopName }
                    step={ step }
                    clydeCovered={ Boolean(lineItem?.saleId) }
                    isDop={ Boolean(lineItem?.coverageStartType === 'dop') }
                    limitedWarrantyExpired={ !oemWarrantyActive }
                  />
                  <div className='cop-modal-header'>
                    <button
                      className='button-nostyle cop-modal-header__cancel'
                      onClick={ _cancelFileClaim }
                    >
                      &#x2715;
                    </button>
                    <p className='cop-modal-header__explanation cop-modal-header__explanation--product-name'>
                      { productName || lineItem?.productName || lineItem?.name }
                    </p>
                    <h1 className='cop-modal-header__header'>{ modalHeaderText }</h1>
                  </div>
                  { this.stepSwitcher() }
                </React.Fragment>
          }
        </div>
      </div>
    );
  }
}

export default FileClaimModal;
