import { wsSend } from './websocketActions';

// Change Customers flow step
export const backToCustomerSearch = () => ({
  type: 'BACK_TO_CUSTOMER_SEARCH',
  payload: {},
});

export const backToOrderSearch = () => ({
  type: 'BACK_TO_ORDER_SEARCH',
  payload: {},
});

// Customer Search actions
export const searchCustomers = (shopId, searchString, searchType) => (dispatch) => {
  dispatch({
    type: 'CUSTOMERS_PROCESSING',
    payload: {},
  });
  dispatch(
    wsSend({
      type: 'SEARCH_CUSTOMERS_REQ',
      payload: {
        shopId,
        searchString,
        searchType,
      },
    }),
  );
};

export const setSearchType = (searchType) => ({
  type: 'SET_SEARCH_TYPE',
  payload: searchType,
});

export const selectCustomer = (customer) => ({
  type: 'SELECT_CUSTOMER',
  payload: customer,
});

// Order Search actions
export const getCustomerOrders = ({
  shopId, contact, contactType, customerId,
}) => (dispatch) => {
  dispatch({
    type: 'CUSTOMERS_PROCESSING',
    payload: {},
  });
  dispatch(
    wsSend({
      type: 'GET_CUSTOMER_ORDERS_REQ',
      payload: {
        shopId,
        contact,
        contactType,
        customerId,
      },
    }),
  );
};

export const selectOrder = (order) => ({
  type: 'SELECT_ORDER',
  payload: order,
});

export const selectRegistration = (registration) => ({
  type: 'SELECT_REGISTRATION',
  payload: registration,
});

// Products View actions
export const getOrderDetails = (orderId, shopId) => (dispatch) => {
  dispatch({
    type: 'CUSTOMERS_PROCESSING',
    payload: {},
  });
  dispatch(
    wsSend({
      type: 'GET_ORDER_DETAILS_REQ',
      payload: {
        orderId,
        shopId,
      },
    }),
  );
};

export const saveLineItemSerialNumber = (lineItemId, serialNumber) => (dispatch) => {
  dispatch(
    wsSend({
      type: 'SAVE_LINE_ITEM_SERIAL_NUMBER_REQ',
      payload: {
        lineItemId,
        serialNumber,
      },
    }),
  );
};

// Contract Sale Serial Number
export const saveNewContractSaleSerialNumber = (
  contractSaleId,
  serialNumber,
) => (dispatch) => {
  dispatch({
    type: 'SAVE_CONTRACT_SALE_SERIAL_NUMBER',
    payload: {},
  });
  dispatch(
    wsSend({
      type: 'SAVE_CONTRACT_SALE_SERIAL_NUMBER_REQ',
      payload: {
        contractSaleId,
        serialNumber,
      },
    }),
  );
};

// Actions
export const updateDeliveryDate = (orderId, lineItemId, newDate, quantity) => (dispatch) => {
  dispatch({
    type: 'PRODUCT_DELIVERY_DATE_PROCESSING',
    payload: {},
  });
  dispatch(
    wsSend({
      type: 'UPDATE_PRODUCT_DELIVERY_DATE_REQ',
      payload: {
        orderId,
        lineItemId,
        newDate,
        quantity,
      },
    }),
  );
};

// Resend sale email actions
export const showResendSaleEmail = () => ({
  type: 'SHOW_RESEND_SALE_EMAIL',
  payload: {},
});

export const cancelResendSaleEmail = () => ({
  type: 'CANCEL_RESEND_SALE_EMAIL',
  payload: {},
});

export const updateContactResendSaleEmail = (contact, isValid, contactType) => ({
  type: 'UPDATE_CONTACT_RESEND_SALE_EMAIL',
  payload: { contact, isValid, contactType },
});

export const confirmResendSaleEmail = (
  shopId,
  saleId,
  contact,
  contactType,
) => (dispatch) => {
  dispatch({
    type: 'RESEND_SALE_EMAIL_SUBMIT_PROCESSING',
    payload: {},
  });
  dispatch(
    wsSend({
      type: 'RESEND_SALE_EMAIL_SUBMIT_REQ',
      payload: {
        shopId, saleId, contact, contactType,
      },
    }),
  );
};

// Send pp email actions
export const showResendPpEmail = () => ({
  type: 'SHOW_RESEND_PP_EMAIL',
  payload: {},
});

export const cancelResendPpEmail = () => ({
  type: 'CANCEL_RESEND_PP_EMAIL',
  payload: {},
});

export const confirmResendPpEmail = (orderId, lineItemIds, contact) => (dispatch) => {
  dispatch({
    type: 'RESEND_PP_EMAIL_SUBMIT_PROCESSING',
    payload: {},
  });
  dispatch(
    wsSend({
      type: 'RESEND_PP_EMAIL_SUBMIT_REQ',
      payload: { orderId, lineItemIds, contact },
    }),
  );
};

// Update claim status actions
export const showDisplayClaimHistory = (warrantyType, data) => ({
  type: 'SHOW_DISPLAY_CLAIM_HISTORY',
  payload: {
    warrantyType,
    data,
  },
});

export const cancelDisplayClaimHistory = () => ({
  type: 'CANCEL_DISPLAY_CLAIM_HISTORY',
  payload: {},
});

export const getAllClaimsOnSale = (data, warrantyType) => (dispatch) => {
  dispatch({
    type: 'GET_ALL_CLAIMS_ON_SALE_PROCESSING',
    payload: {},
  });
  dispatch(
    wsSend({
      type: 'GET_ALL_CLAIMS_ON_SALE_REQ',
      payload: { data, warrantyType },
    }),
  );
};

// File claim actions
export const showFileClaim = (type, info) => ({
  type: 'SHOW_FILE_COP_CLAIM',
  payload: {
    info,
    type,
  },
});

export const cancelFileClaim = () => ({
  type: 'CANCEL_FILE_COP_CLAIM',
  payload: {},
});

export const getSaleInfoForClaim = (dataForDetailsQuery, claimType, shopId) => (dispatch) => {
  dispatch({
    type: 'GET_COP_SALE_INFO_FOR_CLAIM_PROCESSING',
    payload: {},
  });
  dispatch(
    wsSend({
      type: 'GET_COP_SALE_INFO_FOR_CLAIM_REQ',
      payload: {
        dataForDetailsQuery,
        claimType,
        shopId,
      },
    }),
  );
};

export const updateFileClaimInput = (name, value) => ({
  type: 'UPDATE_FILE_COP_CLAIM_INPUT',
  payload: { name, value },
});

export const showFileClaimResolution = (claimId) => ({
  type: 'SHOW_FILE_COP_CLAIM_RESOLUTION_FORM',
  payload: { claimId },
});

export const confirmFileClaim = (
  claimData,
  supportingInfo,
  shopClaimSettings,
  contractSaleInfo,
) => (dispatch) => {
  dispatch({
    type: 'FILE_COP_CLAIM_SUBMIT_PROCESSING',
    payload: {},
  });
  dispatch(
    wsSend({
      type: 'FILE_COP_CLAIM_SUBMIT_REQ',
      payload: {
        claimData,
        shopClaimSettings,
        supportingInfo,
        contractSaleInfo,
      },
    }),
  );
};

// Make contract sale actions
export const showBulkContractSale = () => ({
  type: 'SHOW_BULK_COP_CONTRACT_SALE',
  payload: {},
});

export const cancelBulkContractSale = () => ({
  type: 'CANCEL_BULK_COP_CONTRACT_SALE',
  payload: {},
});

export const getContractsForBulkSale = (clydeOrderId, lineItemIds) => (dispatch) => {
  dispatch({
    type: 'BULK_COP_CONTRACT_SALE_PROCESSING',
    payload: {},
  });
  dispatch(
    wsSend({
      type: 'GET_BULK_COP_CONTRACT_SALE_REQ',
      payload: { clydeOrderId, lineItemIds },
    }),
  );
};

export const selectBulkCopSaleContract = (
  lineItemId,
  idx,
  selectedContract,
) => ({
  type: 'SELECT_BULK_COP_CONTRACT_SALE_CONTRACT',
  payload: {
    lineItemId,
    idx,
    selectedContract,
  },
});

export const selectAllBulkCopSaleContracts = (newCart) => ({
  type: 'SELECT_ALL_BULK_COP_CONTRACT_SALE_CONTRACTS',
  payload: newCart,
});

export const recordBulkContractSale = (clydeOrderId, sales) => (dispatch) => {
  dispatch({
    type: 'BULK_COP_CONTRACT_SALE_SUBMIT_PROCESSING',
    payload: {},
  });
  dispatch(
    wsSend({
      type: 'BULK_COP_CONTRACT_SALE_SUBMIT_REQ',
      payload: {
        clydeOrderId,
        sales,
      },
    }),
  );
};

// Cancel contract actions
export const showCancelContract = (saleId, purchaseSource) => ({
  type: 'SHOW_CANCEL_CONTRACT',
  payload: { saleId, purchaseSource },
});

export const cancelCancelContract = () => ({
  type: 'CANCEL_CANCEL_CONTRACT',
  payload: {},
});

export const confirmCancelContract = (saleId, orderId, shopId, issueShopifyRefund) => (dispatch) => {
  dispatch({
    type: 'CANCEL_CONTRACT_SUBMIT_PROCESSING',
    payload: {},
  });
  dispatch(
    wsSend({
      type: 'CANCEL_CONTRACT_SUBMIT_REQ',
      payload: {
        saleId,
        orderId,
        shopId,
        issueShopifyRefund,
      },
    }),
  );
};

export const confirmCancelPpContract = (saleId, orderId, shopId, issueShopifyRefund) => (dispatch) => {
  dispatch({
    type: 'CANCEL_CONTRACT_SUBMIT_PROCESSING',
    payload: {},
  });
  dispatch(
    wsSend({
      type: 'CANCEL_PP_CONTRACT_SUBMIT_REQ',
      payload: {
        saleId,
        orderId,
        shopId,
        issueShopifyRefund,
      },
    }),
  );
};

// NewOrder actions
export const populateCustomersStateWithNewOrderInfo = (
  orderId,
  customerInfo,
) => ({
  type: 'POPULATE_CUSTOMERS_STATE_WITH_NEW_ORDER_INFO',
  payload: { orderId, customerInfo },
});

// Change order customer actions (TransferOrder)
export const showChangeOrderCustomer = () => ({
  type: 'SHOW_CHANGE_ORDER_CUSTOMER',
  payload: {},
});

export const hideChangeOrderCustomer = () => ({
  type: 'HIDE_CHANGE_ORDER_CUSTOMER',
  payload: {},
});

export const submitChangeOrderCustomer = (customerInfo, orderId, userId, shopId, source) => (dispatch) => {
  dispatch({
    type: 'SUBMIT_ORDER_CUSTOMER_PROCESSING',
    payload: {},
  });
  dispatch(
    wsSend({
      type: 'UPDATE_ORDER_CUSTOMER_REQ',
      payload: {
        customer: customerInfo,
        cartId: orderId,
        userId,
        shopId,
        source,
      },
    }),
  );
};

// update oem actions
export const showUpdateOEM = (saleId, oemWarrantyLength) => ({
  type: `SHOW_UPDATE_OEM`,
  payload: { saleId, oemWarrantyLength },
});

export const updateContractSaleOEMLength = (newLength, saleId) => (dispatch) => {
  dispatch({
    type: 'UPDATE_CONTRACT_SALE_OEM_PROCESSING',
    payload: {},
  });
  dispatch(
    wsSend({
      type: 'UPDATE_CONTRACT_SALE_OEM_REQ',
      payload: { newLength, saleId },
    }),
  );
};

export const cancelOEMUpdate = () => ({
  type: `CANCEL_OEM_UPDATE`,
  payload: {},
});

export const sendHiClydeReferral = (saleId) => (dispatch) => {
  dispatch({
    type: 'SEND_HI_CLYDE_REFERRAL_PROCESSING',
    payload: {},
  });
  dispatch(
    wsSend({
      type: 'SEND_HI_CLYDE_REFERRAL_REQ',
      payload: { saleId },
    }),
  );
};

export const getReceiptFile = (contractSaleId) => (dispatch) => {
  dispatch({
    type: 'RECEIPT_PROCESSING',
    payload: {},
  });
  dispatch(
    wsSend({
      type: 'GET_RECEIPT_FILE_REQ',
      payload: {
        contractSaleId,
      },
    }),
  );
};

export const resetReceiptFile = () => ({
  type: 'RESET_RECEIPT_FILE',
  payload: {},
});

export default {};
