const defaultState = {
  allIntegrations: [], // all possible integrations
  filterValue: 'all',
  getProcessing: false,
  oAuthData: {
    getProcessing: false,
    modalOpen: false,
    integration: {
      config: {},
      display: {},
      status: '',
      type: '',
      error: '',
    },
  },
  saveProcessing: false,
  searchString: '',
  showSaveButton: false,
  removeIntegrationProcessing: false,
  setupModal: {
    authRequirements: {},
    getProcessing: false,
    modalOpen: false,
    selectedIntegration: {},
    submitProcessing: false,
    submitSuccess: false,
    setOAuthProcessing: true,
  },
};

const integrationsReducer = function profile(state = defaultState, action) {
  switch (action.type) {
    case 'GET_SHOP_INTEGRATIONS_INFO':
      return {
        ...state,
        getProcessing: true,
      };
    case 'GET_SHOP_INTEGRATIONS_INFO_RES':
      return {
        ...state,
        getProcessing: false,
        allIntegrations: action.payload,
      };
    case 'INTEGRATIONS_TAB_FILTER_INTEGRATIONS':
      return {
        ...state,
        filterValue: action.filterValue,
      };
    case 'INTEGRATIONS_TAB_SEARCH_INTEGRATIONS':
      return {
        ...state,
        searchString: action.searchString.toLowerCase(),
      };
    case 'INTEGRATIONS_TAB_RESET_SETTINGS':
      return defaultState;
    case 'UPDATE_SHOP_INTEGRATIONS_INFO_RES':
      return {
        ...state,
        ...action.payload,
        saveProcessing: false,
        showSaveButton: false,
      };
    case 'INTEGRATIONS_SETUP_MODAL_OPEN':
      return {
        ...state,
        setupModal: {
          ...state.setupModal,
          getProcessing: true,
          modalOpen: true,
          integrationData: {},
          selectedIntegration: action.selectedIntegration,
        },
      };
    case 'INTEGRATIONS_SETUP_MODAL_CLOSE':
      return {
        ...state,
        setupModal: {
          ...state.setupModal,
          modalOpen: false,
          integrationData: {},
          selectedIntegration: {},
          submitSuccess: false,
        },
      };
    case 'INTEGRATIONS_SETUP_MODAL_RESET':
      return {
        ...state,
        setupModal: defaultState.setupModal,
      };
    case 'INTEGRATIONS_SETUP_MODAL_UPDATE_CLYDE_CONTROLLED_INTEGRATION_VALUE':
      return {
        ...state,
        setupModal: {
          ...state.setupModal,
          selectedIntegration: {
            ...state.setupModal.selectedIntegration,
            clydeOptions: {
              ...state.setupModal.selectedIntegration.clydeOptions,
              [action.key]: action.value,
            },
          },
        },
      };
    case 'INTEGRATIONS_SETUP_MODAL_UPDATE_FRIGG_CONTROLLED_INTEGRATION_VALUE':
      return {
        ...state,
        setupModal: {
          ...state.setupModal,
          integrationData: {
            ...state.setupModal.integrationData,
            [action.key]: action.value,
          },
          selectedIntegration: {
            ...state.setupModal.selectedIntegration,
          },
        },
      };
    case 'INTEGRATIONS_SETUP_MODAL_PROCESSING':
      return {
        ...state,
        setupModal: {
          ...state.setupModal,
          getProcessing: true,
        },
      };
    case 'INTEGRATIONS_GET_FRIGG_AUTH_REQUIREMENTS_RES':
      return {
        ...state,
        setupModal: {
          ...state.setupModal,
          authRequirements: action.payload.authRequirements,
          getProcessing: false,
        },
      };
    case 'INTEGRATIONS_SETUP_MODAL_CONNECT_ACCOUNT':
      return {
        ...state,
        setupModal: {
          ...state.setupModal,
          submitProcessing: true,
        },
      };
    case 'INTEGRATIONS_SETUP_MODAL_CONNECT_ACCOUNT_RES':
      return {
        ...state,
        allIntegrations: action.payload,
        setupModal: {
          ...state.setupModal,
          submitProcessing: false,
          submitSuccess: true,
        },
      };
    case 'INTEGRATIONS_CREATE_FRIGG_O_AUTH_INTEGRATION':
      return {
        ...state,
        oAuthData: {
          ...state.oAuthData,
          ...action.oAuthData,
          getProcessing: true,
          modalOpen: true,
        },
      };
    case 'INTEGRATIONS_CREATE_FRIGG_O_AUTH_INTEGRATION_RES':
      return {
        ...state,
        oAuthData: {
          ...state.oAuthData,
          getProcessing: false,
          integration: action.payload,
        },
      };
    case 'INTEGRATIONS_O_AUTH_REDIRECT_MODAL_CLOSE':
      return {
        ...state,
        oAuthData: {
          ...state.oAuthData,
          modalOpen: false,
        },
      };
    case 'INTEGRATIONS_SET_FRIGG_OAUTH_DATA_RES':
      return {
        ...state,
        setupModal: {
          ...state,
          setOAuthProcessing: false,
        },
      };
    case 'UPDATE_AFFIRM_INTEGRATION_REQ':
      return {
        ...state,
        removeIntegrationProcessing: true,
      };
    case 'UPDATE_AFFIRM_INTEGRATION_RES':
      return {
        ...state,
        removeIntegrationProcessing: false,
      };
    case 'UPDATE_KLAVIYO_INTEGRATION_REQ':
      return {
        ...state,
        removeIntegrationProcessing: true,
      };
    case 'UPDATE_KLAVIYO_INTEGRATION_RES':
      return {
        ...state,
        removeIntegrationProcessing: false,
      };
    case 'INTEGRATIONS_DELETE_FRIGG_INTEGRATION_RES':
      return {
        ...state,
        allIntegrations: action.payload,
        removeIntegrationProcessing: false,
      };
    case 'ERROR':
      switch (action.payload.reqType) {
        case 'GET_SHOP_INTEGRATIONS_INFO_REQ':
          return {
            ...state,
            getProcessing: false,
          };
        default:
          return state;
      }
    default:
      return state;
  }
};

export default integrationsReducer;
