import React, { Component } from 'react';

import OrderInfo from './OrderInfo';
import OrderTable from './OrderTable';
import ProductRegistrationTable from './ProductRegistrationTable';

class OrderSearch extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
    const {
      customers: { selectedCustomer },
      shopId,
      _getCustomerOrders,
    } = this.props;

    _getCustomerOrders({
      shopId,
      contact: selectedCustomer.contact,
      contactType: selectedCustomer.dbContactType,
      customerId: selectedCustomer.customerId,
    });
  }

  handleSelectOrderClick = (e, order) => {
    this.props._selectOrder(order);
  };

  handleSelectOrderKey = (e, order) => {
    if (e.key === ' ' || e.key === 'Enter') {
      e.preventDefault();
      this.props._selectOrder(order);
    }
  };

  handleSelectRegistrationClick = (registration) => {
    this.props._selectRegistration(registration);
  };

  handleSelectRegistrationKey = (e, registration) => {
    if (e.key === ' ' || e.key === 'Enter') {
      e.preventDefault();
      this.props._selectRegistration(registration);
    }
  };

  render() {
    const {
      claimsOverView,
      isProcessing,
      orders,
      ordersOverview,
      productRegistrations,
      selectedCustomer,
    } = this.props.customers;
    const { platform } = this.props;

    return (
      <div className='orders'>
        <div className='orders__back'>
          <button
            data-test='backToCustomers'
            className='button-nostyle orders__back__button'
            onClick={ this.props._backToCustomerSearch }
          >
            <div className='orders__back__button__image'>
              <div className='orders__back__button__image__arrow' />
            </div>
            <span className='orders__back__button__text'>
              Back to customers
            </span>
          </button>
        </div>

        <div className='orders__header'>
          <div className='orders__header__title'>
            <span>All orders for</span>
            <h1>{ ordersOverview.customerName }</h1>
          </div>
        </div>

        <OrderInfo
          claimsOverView={ claimsOverView }
          isProcessing={ isProcessing }
          ordersOverview={ ordersOverview }
          selectedCustomer={ selectedCustomer }
        />

        <OrderTable
          orderIdsToHightlight={ selectedCustomer.orderIdsToHightlight }
          orders={ orders }
          isProcessing={ isProcessing }
          selectOrderClick={ this.handleSelectOrderClick }
          selectOrderKey={ this.handleSelectOrderKey }
          platform={ platform }
        />

        { productRegistrations?.length > 0 && (
          <ProductRegistrationTable
            productRegistrations={ productRegistrations }
            isProcessing={ isProcessing }
            selectRegistrationClick={ this.handleSelectRegistrationClick }
            selectRegistrationKey={ this.handleSelectRegistrationKey }
          />
        ) }
      </div>
    );
  }
}

export default OrderSearch;
