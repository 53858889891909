import React from 'react';
import classnames from 'classnames';

import formatOrderDate from '../Shared/formatOrderDate';

const ProductRegistrationTableRow = ({
  productRegistration,
  selectRegistrationClick,
  selectRegistrationKey,
}) => {
  const {
    productName,
    placeOfPurchase,
    purchasedContractsCount,
    openClaimsCount,
    productPurchaseDate,
    serialNumber,
  } = productRegistration;

  const formattedDate = formatOrderDate(productPurchaseDate);

  const rowClassName = classnames(
    'product-registrations__list__table-body__row',
    {
      'product-registrations__list__table-body__row__clickable':
        purchasedContractsCount > 0,
    },
  );

  const handleClick = () => {
    if (purchasedContractsCount > 0) {
      selectRegistrationClick(productRegistration);
    }
  };

  const handleKeyDown = (e) => {
    if (purchasedContractsCount > 0) {
      selectRegistrationKey(e, productRegistration);
    }
  };

  return (
    <div
      className={ rowClassName }
      tabIndex='0'
      onClick={ handleClick }
      onKeyDown={ handleKeyDown }
    >
      <div className='product-registrations__list__table-body__row__cell-product'>
        { productName }
      </div>
      <div className='product-registrations__list__table-body__row__cell-date'>
        { formattedDate }
      </div>
      <div className='product-registrations__list__table-body__row__cell-pop'>
        { placeOfPurchase }
      </div>
      <div className='product-registrations__list__table-body__row__cell-serial-number'>
        { serialNumber || '--' }
      </div>
      <div className='product-registrations__list__table-body__row__cell-contracts'>
        { purchasedContractsCount }
      </div>
      <div className='product-registrations__list__table-body__row__cell-claims'>
        { openClaimsCount }
      </div>
    </div>
  );
};

export default ProductRegistrationTableRow;
