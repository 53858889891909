import React, { useEffect, useRef } from 'react';
import classnames from 'classnames';
import moment from 'moment';
import { LoadingDots, ClydeButton } from '@joinclyde/clyde-react-components';

const OrderInfo = (props) => {
  const {
    selectedOrder: {
      address,
      orderClaims,
      cartLink,
      transferHistory,
    },
    isProcessing,
    selectedCustomer,
    selectedOrder,
    receiptUrl,
    receiptProcessing,
    _getReceiptFile,
    _resetReceiptFile,
  } = props;

  const receiptDownloadClicked = useRef(false);

  const handleReceiptDownload = () => {
    const { receiptSaleId, saleId } = selectedOrder;
    _getReceiptFile(receiptSaleId || saleId);
    receiptDownloadClicked.current = true;
  };

  useEffect(() => {
    if (receiptDownloadClicked.current && receiptUrl) {
      window.open(receiptUrl, '_blank', 'noopener,noreferrer');
      receiptDownloadClicked.current = false;
      _resetReceiptFile();
    }
  }, [receiptUrl]);

  const eligibleText = selectedOrder.eligibleProductCount === 1 ? 'eligible product' : 'eligible products';
  const activeText = selectedOrder.activeContractCount === 1 ? 'active contract' : 'active contracts';
  const expiredText = selectedOrder.expiredContractCount === 1 ? 'expired contract' : 'expired contracts';
  const cancelledText = selectedOrder.cancelledContractCount === 1 ? 'cancelled contract' : 'cancelled contracts';
  const fulfilledText = selectedOrder.fulfilledContractCount === 1 ? 'fulfilled contract' : 'fulfilled contracts';
  const submittedText = orderClaims.claimsOpen === 1 ? 'Claim open' : 'Claims open';
  const approvedText = orderClaims.claimsResolved === 1 ? 'Claim resolved' : 'Claims resolved';
  const deniedText = orderClaims.claimsDenied === 1 ? 'Claim denied' : 'Claims denied';
  const missingCustomerInfoText = 'Missing Customer Information Update via Shopify Admin';

  const contactClass = (info) => classnames({
    'alert-text': info === missingCustomerInfoText,
  });

  return (
    <div className='products-view__info'>
      <div className='products-view__info__content products-view__info__content--contact'>
        <h6>Contact on Order</h6>
        <ul>
          {
            [selectedOrder.fullName, selectedCustomer.contact].reduce((acc, li) => {
              if (!li) {
                if (!acc.includes(missingCustomerInfoText)) {
                  return [...acc, missingCustomerInfoText];
                }
                // don't push same alert message twice
                return acc;
              }
              return [...acc, li];
            }, []).map((info) => (<li key={ info } className={ contactClass(info) }>{ info }</li>))
          }
        </ul>
      </div>

      <div className='products-view__info__content products-view__info__content--address'>
        <h6>Address on Order</h6>
        {
          isProcessing
            ? <LoadingDots dotColor='clydeBlack' dotSize='medium' />
            : <ul>
                <li>{ `${address.address1}` }</li>
                { address.address2 ? <li>{ `${address.address2}` }</li> : null }
                <li>{ address.city }, { address.province } { address.country }</li>
              </ul>
        }
      </div>

      <div className='products-view__info__content products-view__info__content--contracts'>
        <h6>Contracts on Order</h6>
        <ul>
          <li>{ `${selectedOrder.eligibleProductCount} ${eligibleText}` }</li>
          <li>{ `${selectedOrder.activeContractCount} ${activeText}` }</li>
          <li>{ `${selectedOrder.fulfilledContractCount} ${fulfilledText}` }</li>
          <li>{ `${selectedOrder.cancelledContractCount} ${cancelledText}` }</li>
          <li>{ `${selectedOrder.expiredContractCount} ${expiredText}` }</li>
        </ul>
        {
          transferHistory.length > 0 && <div className='products-view__info__content--transfer'>
            <span><strong>Transferred on:</strong> { moment(transferHistory[0].transferDate).format('MMMM DD, YYYY') } </span>
            <span>By: { transferHistory[0].customerEmail }</span>
          </div>
        }

      </div>

      <div className='products-view__info__content products-view__info__content--claims'>
        <h6>Claims on Order</h6>
        {
          isProcessing
            ? <LoadingDots dotColor='clydeBlack' dotSize='medium' />
            : <ul>
                <li>{ `${orderClaims.claimsOpen} ${submittedText}` }</li>
                <li>{ `${orderClaims.claimsResolved} ${approvedText}` }</li>
                <li>{ `${orderClaims.claimsDenied} ${deniedText}` }</li>
              </ul>
        }
      </div>

      <div className='products-view__info__content products-view__info__content--hiclyde'>
        <h6>hiclyde.com</h6>
        <ul>
          <li>{ selectedCustomer.hasHiClydeAccount ? 'Active account' : 'No account' }</li>
        </ul>
        { selectedOrder.eligibleProductCount > 0 && cartLink
            && <React.Fragment>
                <h6 className='products-view__info__content--hiclyde__pp-header'>Post Purchase Link</h6>
                <ul>
                  <li><a href={ cartLink } rel='noopener noreferrer' target='_blank'>{ cartLink }</a></li>
                </ul>
              </React.Fragment>
        }
        { !!selectedOrder.receiptFile
          && <ClydeButton
           className='products-view__download-button'
           processing={ receiptProcessing }
           onClick={ handleReceiptDownload }
          >
            Download Receipt
          </ClydeButton>
        }
      </div>
    </div>
  );
};

export default OrderInfo;
