import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { bigCommerceInstall } from '../../../actions/authActions';

import BigCommerceInstall from './BigCommerceInstall';

const mapStateToProps = (state) => state.auth;

const mapDispatchToProps = (dispatch) => bindActionCreators(
  { _bigCommerceInstall: bigCommerceInstall },
  dispatch,
);

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(BigCommerceInstall));
