import React from 'react';
import { Link } from 'react-router-dom';
import { LoadingDots } from '@joinclyde/clyde-react-components';

import NavBarButton from './NavBarButton';
import LaunchProcessing from '../Shopify/LaunchProcessing';

const NavBar = (props) => {
  const {
    isOnboarding,
    step,
    history,
    platform,
    isAdmin,
    loading,
    profile,
    _logout,
    claimsForResolutionCount,
  } = props;

  const handleLogout = () => {
    _logout();
    history.push('/login');
  };

  const basicTabs = [
    {
      imageSrc: 'Performance.svg', name: 'Performance', link: 'performance', pageNames: ['Performance'],
    },
    {
      imageSrc: 'Sales.svg', name: 'Sales', link: 'sales', pageNames: ['Sales'],
    },
    {
      imageSrc: 'Customers.svg', name: 'Customers', link: 'customers', pageNames: ['Customers'],
    },
    {
      imageSrc: 'Products.svg', name: 'Products', link: 'products', pageNames: ['Products'],
    },
    {
      imageSrc: 'Claims.svg', name: 'Claims', link: 'claims', pageNames: ['Claims', 'Claim'],
    },
  ];
  const adminTabs = [
    {
      imageSrc: 'Performance.svg', name: 'Performance', link: 'performance', pageNames: ['Performance'],
    },
    {
      imageSrc: 'Sales.svg', name: 'Sales', link: 'sales', pageNames: ['Sales'],
    },
    {
      imageSrc: 'Customers.svg', name: 'Customers', link: 'customers', pageNames: ['Customers'],
    },
    {
      imageSrc: 'Products.svg', name: 'Products', link: 'products', pageNames: ['Products'],
    },
    {
      imageSrc: 'Contracts.svg', name: 'Contracts', link: 'contracts', pageNames: ['Contracts'],
    },
    {
      imageSrc: 'Claims.svg', name: 'Claims', link: 'claims', pageNames: ['Claims', 'Claim'],
    },
    {
      imageSrc: 'Customize.svg', name: 'Call-to-Action', link: 'customize', pageNames: ['Call-to-Action'],
    },
  ];
  const onboardingPages = [
    { title: 'Welcome' },
    { title: 'Store info' },
    { title: 'Call-to-Action' },
    { title: 'Billing' },
    { title: 'Terms and Conditions' },
  ];
  const urlPagenameMap = {
    welcome: 'Welcome',
    customize: 'Call-to-Action',
    sales: 'Sales',
    customers: 'Customers',
    settings: 'Settings',
    products: 'Products',
    contracts: 'Contracts',
    claims: 'Claims',
    performance: 'Performance',
  };
  let pageName;
  let useTabs;
  let location;
  if (isOnboarding) {
    pageName = onboardingPages[step].title;
    useTabs = [];
  } else {
    const selectedPath = props.location.pathname.split('/')[1];
    pageName = urlPagenameMap[selectedPath];
    location = props.location.pathname.slice(1);
    useTabs = !isAdmin ? basicTabs : adminTabs;
  }

  return (
    <div className='nav-bar'>
      <div className='nav-bar__top'>
        <div className='nav-bar__top__content'>
          <h5>{ profile.shopName }</h5>
          <LaunchProcessing />
          <h5 className='nav-bar-page-name'>{ pageName }</h5>
          { platform !== 'BigCommerce'
            && <div onClick={ handleLogout } className='nav-bar-logout'>
              <img className='nav-bar-logout__img' alt='Log out' data-test='logOut' src='/images/logout.png'/>
            </div>
          }
        </div>
      </div>
      <div
        data-collapse='small'
        data-animation='default'
        data-duration='400'
        className='nav-bar__left'>
        <div className='nav-bar__left__content'>
          <nav className='nav-buttons'>
            { loading
              ? <LoadingDots dotSize='large' dotColor='clydeBlack' />
              : useTabs.map((tab, index) => (
                  <NavBarButton
                    claims={ claimsForResolutionCount }
                    key={ `nav-${index}` }
                    tabIdx={ index }
                    buttonStep={ index }
                    linkName={ tab.name }
                    link={ tab.link }
                    imageSrc={ tab.imageSrc }
                    activeTab={ tab.pageNames.indexOf(pageName) > -1 }
                  />
              ))
            }
          </nav>
        </div>
        { !isOnboarding
          && <span className='nav-buttons-bottom'>
            <a
              href='https://docs.joinclyde.com/docs'
              className='nav-buttons-bottom__item nav-buttons-bottom__item--support'
              rel='noopener noreferrer'
              target='_blank'>
              <img
                className='nav-buttons-bottom__item__image nav-buttons-bottom__item__image--support'
                alt='terms'
                data-test='questionMark'
                src='/images/QuestionMark.svg' />
            </a>
            <Link data-test='settingsIcon' className='nav-buttons-bottom__item' to='/settings/account'>
            {
              location && location.includes('settings')
                ? <img className='nav-buttons-bottom__item__image' alt='settings' src='/images/Settings.svg'/>
                : <img className='nav-buttons-bottom__item__image' alt='settings' src='/images/SettingsEmpty.svg'/>
            }
            </Link>
          </span>
        }
      </div>
      <div className='nav-bar__clyde-logo'>
        <img className='nav-bar__clyde-logo__image' alt='clyde' src='/images/Clyde_Nav_Icon.svg'/>
      </div>
    </div>
  );
};

export default NavBar;
