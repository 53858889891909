import React from 'react';

import AffirmSetup from './Integrations/AffirmSetup';
import KlaviyoSetup from './Integrations/KlaviyoSetup';

const ClydeControlled = (props) => {
  const {
    selectedIntegration,
    userId,
    _connectIntegration,
    _updateValue,
  } = props;

  const handleSubmit = (integration, data) => {
    _connectIntegration(integration, {
      ...data,
      userId,
    });
  };

  switch (selectedIntegration.id) {
    case 'affirm':
      return (
        <AffirmSetup
          integration={ selectedIntegration }
          _onSubmit={ handleSubmit }
          _onTextChange={ _updateValue } />
      );
    case 'klaviyo':
      return (
        <KlaviyoSetup
          integration={ selectedIntegration }
          _onSubmit={ handleSubmit }
          _onTextChange={ _updateValue } />
      );
    default:
      return null;
  }
};

export default ClydeControlled;
