import React from 'react';
import classnames from 'classnames';
import Transition from 'react-transition-group/Transition';

const ProductImportSummary = (props) => {
  const {
    show,
    step,
    importData,
  } = props;

  const duration = 1000;
  const defaultStyle = {
    opacity: '0',
    transition: 'opacity 400ms ease',
  };
  const transitionStyles = {
    entering: { display: 'block', opacity: '0' },
    entered: { display: 'block', opacity: '1' },
    exiting: { display: 'block', opacity: '1' },
    exited: { display: 'block', opacity: '1' },
  };
  return (
    <Transition in={ show } timeout={ duration }>
      { (state) => (
        <div style={ { ...defaultStyle, ...transitionStyles[state] } } className='import-summary'>
          <div className='row'>
            <div className='col-4'>
              <div className={ classnames('import-summary-tile',
                { 'import-summary-tile--collapsed': step !== 'confirm' }) }>
                <div className='import-summary-tile__count import-summary-tile__count--blue'>{ importData.insertCount }</div>
                <div className='import-summary-tile__subtitle'>
                { step === 'confirm'
                  ? `Products will be added`
                  : `Products added`
                }
                </div>
                <hr/>
                <ol className='import-summary__list'>
                { step === 'confirm'
                  && importData.insert.map((product, i) => (
                      <li key={ `success-import-${i}` } className='import-summary__list__item'>
                        { product.sku }
                      </li>
                  ))
                }
                </ol>
              </div>
            </div>
            <div className='col-4'>
              <div className={ classnames('import-summary-tile',
                { 'import-summary-tile--collapsed': step !== 'confirm' }) }>
                <div className='import-summary-tile__count import-summary-tile__count--yellow'>{ importData.updateCount }</div>
                <div className='import-summary-tile__subtitle'>
                { step === 'confirm'
                  ? `Products can be updated`
                  : `Products updated`
                }
                </div>
                <hr/>
                <ol className='import-summary__list'>
                { step === 'confirm'
                  && importData.skuDuplicates.map((product, i) => (
                      <li key={ `success-import-${i}` } className='import-summary__list__item'>
                        { product.sku }
                      </li>
                  ))
                }
                </ol>
              </div>
            </div>
            <div className='col-4'>
              <div className={ classnames('import-summary-tile',
                { 'import-summary-tile--collapsed': step !== 'confirm' }) }>
                <div className='import-summary-tile__count import-summary-tile__count--red'>
                  { importData.ignoreCount || 0 }
                </div>
                <div className='import-summary-tile__subtitle'>
                { step === 'confirm'
                  ? `Products will be ignored`
                  : `Products ignored`
                }
                </div>
                <hr/>
                <ol className='import-summary__product-list'>
                  { step === 'confirm'
                    && importData.ignore.map((product, i) => (
                        <li key={ `failed-import-${i}` } className='import-summary__product-list__item'>
                          { `Row: ${product.row + 1}` }
                          <ul className='import-summary__error-list'>
                          { product.errors.map((error, errorIdx) => (
                              <li key={ `error-${errorIdx}` } className='import-summary__error-list__item'>
                                { error }
                              </li>
                          )) }
                          </ul>
                        </li>
                    ))
                  }
                </ol>
              </div>
            </div>
          </div>
        </div>
      ) }
    </Transition>
  );
};

export default ProductImportSummary;
