import { countries } from '../lib/countriesAndCurrencies';

const defaultState = {
  firstName: '',
  lastName: '',
  email: '',
  secondaryEmail: '',
  shopName: '',
  phoneNumber: '',
  domain: '',
  address1: '',
  address2: '',
  city: '',
  province: '',
  zip: '',
  language: '',
  country: '',
  contactId: '',
  contactType: '',
  warrantyType: 'none',
  oemWarrantyLength: 0,
  warrantyDataAccessible: false,
  getProcessing: false,
  saveProcessing: false,
  formErrors: {
    firstName: false,
    lastName: false,
    email: false,
    secondaryEmail: false,
    phoneNumber: false,
    shopName: false,
    domain: false,
    address1: false,
    city: false,
    province: false,
    zip: false,
    oemWarrantyLength: false,
  },
  showSaveButton: false,
};

const profileReducer = function profile(state = defaultState, action) {
  const { payload } = action;
  switch (action.type) {
    case 'SHOP_GET_PROCESSING':
      return { ...state, getProcessing: true };
    case 'GET_SHOP_INFO_RES':
      return {
        ...state,
        firstName: payload.firstName,
        lastName: payload.lastName,
        shopName: payload.shopName,
        domain: payload.domain,
        getProcessing: false,
        ...payload.contactInfo[0],
        contactId: payload.contactInfo[0].id,
        language: payload.language || countries[payload.country]?.defaultLanguage || 'en-US',
      };
    case 'SET_TEXT_FIELD':
      return {
        ...state,
        [payload.profileField]: payload.text,
        showSaveButton: true,
      };
    case 'SET_BOOLEAN_FIELD':
      return {
        ...state,
        [payload.profileField]: payload.value === 'true',
        showSaveButton: true,
      };
    case 'SHOP_PROFILE_SAVE_PROCESSING':
      return { ...state, saveProcessing: true };
    case 'UPDATE_SHOP_INFO_RES':
      return {
        ...state,
        ...payload,
        saveProcessing: false,
        showSaveButton: false,
      };
    case 'COMPLETE_ONBOARDING_PROFILE_RES':
      return state;
    case 'GET_ONBOARDING_INFO_RES':
      return { ...state, shopName: payload.shopName };
    case 'SET_VALIDATION_ERRORS':
      return {
        ...state,
        formErrors: payload.formErrors,
      };
    case 'LOGOUT':
      return defaultState;
    case 'ERROR':
      switch (payload.reqType) {
        case 'GET_SHOP_INFO_REQ':
          return { ...state, getProcessing: false };
        case 'UPDATE_SHOP_INFO_REQ':
          return { ...state, saveProcessing: false };
        default:
          return state;
      }
    default:
      return state;
  }
};

export default profileReducer;
