export const DEFAULT_STATE = {
  loaded: false,
  country: 'US',
  language: 'en-US',
  currency: 'USD',
};

const localeReducer = (state = DEFAULT_STATE, action) => {
  let country;
  let language;
  let currency;
  switch (action.type) {
    case 'GET_SHOP_INFO_RES':
      ({ country, language, currency } = action.payload);
      return {
        loaded: true,
        country,
        language,
        currency,
      };
    default:
      return state;
  }
};

export default localeReducer;
