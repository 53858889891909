import React from 'react';
import { Link } from 'react-router-dom';
import classnames from 'classnames';

import { tabLinkMap, tabNameMap } from './constants';

const TabHeaderButton = (props) => {
  const {
    isActive,
    tabType,
  } = props;

  const tabClass = classnames('settings-tab-header-button', {
    'settings-tab-header-button--active': isActive,
  });

  return (
    <Link
      className={ tabClass }
      onMouseUp={ (e) => { e.currentTarget.blur(); } }
      to={ `/settings/${tabLinkMap[tabType]}` }>
      { tabNameMap[tabType] }
    </Link>
  );
};

export default TabHeaderButton;
