import React from 'react';
import { LoadingDots } from '@joinclyde/clyde-react-components';

const SupportingInfoFiles = ({
  supportingInfoProcessing,
  supportingInfoFiles,
  openImageModal,
}) => {
  if (supportingInfoProcessing) {
    return (
      <LoadingDots
        className='claims-detail-card__group__file-list__loading'
        dotColor='clydeBlack'
        dotSize='small' />
    );
  }

  if (!supportingInfoFiles || supportingInfoFiles.length === 0) {
    return <span>None</span>;
  }

  return (
      <>
        {
        supportingInfoFiles.map((file, idx) => (
          <button
            className='button-no-style claims-file-button'
            key={ idx }
            onClick={ (e) => { openImageModal(e, file); } }>
            File { idx + 1 }
          </button>
        ))
        }
      </>
  );
};

export default SupportingInfoFiles;
