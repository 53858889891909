import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { ChromePicker } from 'react-color';
import { ClydeTextInput } from '@joinclyde/clyde-react-components';

import { selectColor } from '../../actions/customizeActions';

class ClydeColorSelect extends Component {
  constructor(props) {
    super(props);
    this.presetColors = [];
    this.selectedColor = '#000000';
    this.state = {
      displayColorPicker: false,
    };
  }

  handleChange = (color) => {
    this.selectedColor = color.hex.toUpperCase();
  }

  toggleColorSelect = (event) => {
    event.preventDefault();
    this.setState({ displayColorPicker: !this.state.displayColorPicker });
  }

  handleClose = () => {
    this.props._selectColor(this.selectedColor);
    this.setState({ displayColorPicker: false });
  }

  handleTextChange = (event) => {
    this.props._selectColor(event.target.value.toUpperCase());
  }

  render() {
    const cover = {
      position: 'fixed',
      top: '0px',
      right: '0px',
      bottom: '0px',
      left: '0px',
    };

    const popover = {
      position: 'absolute',
      top: '250px',
      zIndex: '2',
    };

    const colorStyle = this.props.useColor !== ''
      ? { backgroundColor: this.props.useColor }
      : { opacity: 0.14 };

    return (
      <div id='clyde-color-selector' >
        { this.state.displayColorPicker
          && <div style={ popover }>
            <div style={ cover } onClick={ this.handleClose } />
            <ChromePicker
            presetColors={ this.presetColors }
            color={ this.props.useColor }
            onChange={ this.handleChange }
            />
          </div>
        }
        <div className='clyde-color-select-wrapper'>
          <div style={ colorStyle } href='' onClick={ this.toggleColorSelect } className='clyde-color-select'></div>
          <ClydeTextInput
            onChange={ this.handleTextChange }
            value={ this.props.color }
            name='color'
            id='clyde-color-selector-input'
            placeholder='#'
            className={ this.props.validColor
              ? 'form-field clyde-color-select-input'
              : 'form-field clyde-color-select-input form-field clyde-color-select-input--invalid' }/>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => state.customize;

const mapDispatchToProps = (dispatch) => bindActionCreators({
  _selectColor: selectColor,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ClydeColorSelect);
