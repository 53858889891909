import React from 'react';

export default function ContractTermsLink({ contractId, children, ...restAnchorProps }) {
  return (
    <a { ...restAnchorProps }
      href={ `/terms/${contractId}` }
      target='_blank' rel='noopener noreferrer'
    >
      { children }
    </a>
  );
}
