import React from 'react';
import { ClydeButton } from '@joinclyde/clyde-react-components';
import SharedHeader from '../../SetupModal/Integrations/SharedHeader';

const Error = (props) => {
  const {
    _closeModal,
    error,
    integration: {
      display: {
        name,
        icon,
      },
    },
  } = props;

  const closeConfigModal = () => {
    _closeModal();
  };

  return (
    <div className='integrations-oauth-redirect-modal-error'>
      <SharedHeader
        logo={ icon }
        name={ name } />
      <p>{ error }</p>
      <ClydeButton onClick={ closeConfigModal }>
        Close
      </ClydeButton>
    </div>
  );
};

export default Error;
