const defaultState = {
  initialState: true,
  isOnboarding: false,
  step: 0,
  maxStep: 0,
  getProcessing: true,
};

const onboardingReducer = function products(state = defaultState, action) {
  switch (action.type) {
    case 'NEXT_STEP':
      return { ...state, step: state.step + 1, maxStep: Math.max(state.step + 1, state.maxStep) };
    case 'PREVIOUS_STEP':
      return { ...state, step: state.step - 1 };
    case 'FINISH_ONBOARDING_RES':
      return { ...state, isOnboarding: false };
    case 'SET_STEP':
      return { ...state, step: action.payload };
    case 'GET_ONBOARDING_INFO_RES':
      return { ...state, ...action.payload, initialState: false };
    case 'LOGOUT':
      return { ...state, ...defaultState };
    default:
      return state;
  }
};

export default onboardingReducer;
