import { wsSend } from './websocketActions';

export const selectGraphType = (graphType) => ({
  type: 'SELECT_PERFORMANCE_GRAPH_TYPE',
  payload: graphType,
});

export const selectChangeType = (changeType) => ({
  type: 'SELECT_PERFORMANCE_CHANGE_TYPE',
  payload: changeType,
});

export const getGraphData = (dateRange, platform) => (dispatch) => {
  dispatch({
    type: 'GET_GRAPH_DATA',
    payload: dateRange,
  });
  dispatch(wsSend({
    type: 'GET_GRAPH_DATA_REQ',
    payload: {
      dateRange,
      platform,
    },
  }));
};
