import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { LoadingDots } from '@joinclyde/clyde-react-components';

import CreateUser from './CreateUser';
import UserManagementUserItem from './UserManagementUserItem';
import { getAllShopUsers } from '../../../../actions/userManagementActions';

class UserManagementTile extends Component {
  state = {
    showLiveKeys: true,
    showSecret: false,
  }

  UNSAFE_componentWillMount() {
    this.props._getAllShopUsers();
  }

  buildUsersList = () => {
    const usersList = [];
    for (const user of this.props.allUsers) {
      usersList.push(<UserManagementUserItem user={ user } key={ user.email } />);
    }
    return usersList;
  }

  render() {
    return (
      <div className='settings-tile settings-tile--user-management'>
        <div className='settings-tile__title settings-tile__title--user-management'>User Management</div>
        {
          this.props.loading
            ? <div className='settings-tile__content settings-tile__content--user-management'>
                <div className='tile-loading-container'>
                  <LoadingDots dotColor='clydeBlack' dotSize='large' />
                </div>
            </div>
            : <div className='settings-tile__content settings-tile__content--user-management'>
              <h4>Create a User</h4>
                <CreateUser />
              <h4>All Users</h4>
              <div className='d-none d-lg-flex row user-management__column-labels'>
                <h5 className='col-12 col-lg-3'>Email</h5>
                <h5 className='col-12 col-lg-2'>Last Login</h5>
                <h5 className='col-12 col-lg-2'>Type</h5>
                <h5 className='col-12 col-lg-1'>Login</h5>
                <h5 className='col-12 col-lg-1'>Status</h5>
              </div>
              { this.buildUsersList() }
            </div>
          }
      </div>
    );
  }
}

const mapStateToProps = (state) => ({ ...state.userManagement });

const mapDispatchToProps = (dispatch) => bindActionCreators({
  _getAllShopUsers: getAllShopUsers,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(UserManagementTile);
