import React, { Component } from 'react';
import moment from 'moment';
import { Helmet } from 'react-helmet';

import ClaimCard from './Cards/ClaimCard';
import ClaimHistoryCard from './Cards/ClaimHistoryCard';
import ContractCard from './Cards/ContractCard';
import CustomerCard from './Cards/CustomerCard';
import ProductCard from './Cards/ProductCard';
import Resolution from '../ClaimResolution';

class ClaimDetail extends Component {
  componentDidMount() {
    const {
      match: { params: { id }},
      _fetchClaimData,
    } = this.props;

    _fetchClaimData(id);
  }

  render() {
    const {
      claim,
      claimHistory,
      contract,
      customer,
      history,
      match: { params: { id: claimId }},
      processing,
      product,
      remainingLimitOfLiability,
      productParts,
      supportingInfoFiles,
      shopClaimSettings,
      _populateCopWithCustomer,
      transferHistory,
    } = this.props;

    const isLimitedWarranty = claim.warrantyType === 'limitedWarranty';
    const dateFiled = claim.createdAt ? moment(claim.createdAt).format('MM/DD/YYYY') : null;
    const showResolutionInput = claim.claimState === 'approved'
      || claim.claimState === 'resolutionPending'
      || claim.claimState === 'repairInitiated'
      || (claim.claimState.indexOf('resolve') > -1);

    return (
      <div className='page-container'>
        <Helmet>
          <title>Clyde | Claim Detail</title>
        </Helmet>

        <div className='page-layout'>
          <div className='claims-detail'>
            <div className='claims__back'>
              <button
                className='button-nostyle orders__back__button'
                onClick={ () => history.push('/claims') }
              >
                <div className='orders__back__button__image'>
                  <div className='orders__back__button__image__arrow' />
                </div>
                <span className='orders__back__button__text'>
                  Back to all Claims
                </span>
              </button>
            </div>

            <div className='claims-detail-header'>
              <div className='claims-detail-header-container'>
                <h1>Claim #{ claim.claimExternalId || claimId }</h1>
                {
                  isLimitedWarranty
                  && <span className='limited-warranty-pill'>Limited Warranty</span>
                }
              </div>

              <div className='claims-detail-header__info'>
                <span className='claims-detail-header__info__date'>Date Filled: { dateFiled }</span>
                {
                  claim.claimState === 'approved' && !claim.resolutionId
                  && <span className='claims-detail-header__info__state'>Needs Resolution</span>
                }
              </div>
              { claim.xClaimId && <strong><p className='xclaim-warning'>This claim cannot be altered at this time.
              Please contact Partner Services if you have any questions about this claim.</p></strong> }
            </div>

            <div className='claims-detail-content'>
              <div className='claims-detail-content__column'>
                <CustomerCard
                  customer={ customer }
                  history={ history }
                  processing={ processing.customer }
                  _populateCopWithCustomer={ _populateCopWithCustomer } />
                { contract
                  && <ContractCard
                    contract={ contract }
                    processing={ processing.contract }
                    transferHistory={ transferHistory } />
                }
                <ProductCard product={ product } processing={ processing.product } />
              </div>

              <div className='claims-detail-content__column'>
                <ClaimCard
                  claim={ claim }
                  claimProcessing={ processing.claim }
                  supportingInfoFiles={ supportingInfoFiles }
                  supportingInfoProcessing={ processing.supportingInfoFiles } />
                <ClaimHistoryCard
                  claimHistory={ claimHistory }
                  denialReason={ claim.denialReason }
                  serviceType={ claim.serviceType }
                  createdAt={ claim.createdAt }
                  claimState={ claim.claimState }
                  processing={ processing.claimHistory } />
              </div>
            </div>

            { (showResolutionInput && !claim.xClaimId)
              && <Resolution
                claimId={ claimId }
                remainingLimitOfLiability={ remainingLimitOfLiability }
                productParts={ productParts }
                shopClaimSettings={ shopClaimSettings }
                onDetailPage
              />
            }

          </div>
        </div>
      </div>
    );
  }
}

export default ClaimDetail;
