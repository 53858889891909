// GENERAL
export const adminTabs = [
  'account',
  'userManagement',
  'integrations',
];

export const basicTabs = [
  'account',
];

export const tabLinkMap = {
  account: 'account',
  integrations: 'integrations',
  userManagement: 'user-management',
  waw: 'warranty-anywhere',
  registrations: 'registrations',
};

export const tabNameMap = {
  account: 'Account',
  integrations: 'Integrations',
  userManagement: 'User Management',
  waw: 'Warranty Anywhere',
  registrations: 'Product Registration',
};

// INTEGRATIONS TAB
export const filterOptions = [
  {
    name: 'View All',
    value: 'all',
  },
  {
    name: 'Connected Apps',
    value: 'connected',
  },
  {
    name: 'Ecommerce',
    value: 'ecommerce',
  },
  {
    name: 'Communication',
    value: 'communication',
  },
  {
    name: 'Productivity',
    value: 'productivity',
  },
  {
    name: 'MarketPlace',
    value: 'market',
  },
];
