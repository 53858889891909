import React from 'react';

const TermsHeader = (props) => {
  const { selectAll, termsOnOrder } = props;

  return (
    <div className='cop-bulk-sale__content__terms'>
      <div className='cop-bulk-sale__terms-header'>
        <span>None</span>
        <button value='none' className='button-nostyle' onClick={ selectAll }>
          Select all
        </button>
      </div>
      {
        termsOnOrder.map((term, idx) => (
          <div className='cop-bulk-sale__terms-header' key={ `term-${idx}` }>
            <span>{ term } Year</span>
            <button value={ term } className='button-nostyle' onClick={ selectAll }>
              Select all
            </button>
          </div>
        ))
      }
    </div>
  );
};

export default TermsHeader;
