import React from 'react';
import Img from 'react-image';
import { ClydeCheckbox } from '@joinclyde/clyde-react-components';
import { Currency } from '../../../../Utils/Currency';

const CatalogueItem = (props) => {
  const {
    cartproductIds, product, idx, updateCart,
  } = props;

  return (
    <div className='catalogue-item'>

      <div className='catalogue-item__left'>
        <ClydeCheckbox
          backgroundColor='clydeBlack'
          id={ `cat_item_checkbox_${idx}` }
          className='catalogue-item__check'
          checked={ cartproductIds.indexOf(product.productId) > -1 }
          onChange={ (e) => updateCart(e, product) }
        />
        <Img
          className='catalogue-item-img'
          alt={ product.name }
          src={ [product.imageLink, '/images/default-image.svg'] } />
        <div className='catalogue-item-info'>
          <span className='catalogue-item-info__name'>{ product.name }</span>
          <span className='catalogue-item-info__sku'>
            <strong>SKU: </strong>
            { product.sku }
          </span>
        </div>
      </div>
      <div className='catalogue-item__right'>
        <span className='catalogue-item-info__price'><Currency amount={ product.price } /></span>
      </div>
    </div>
  );
};

export default CatalogueItem;
