import React from 'react';
import Img from 'react-image';

import { ReactComponent as AlertCircle } from '../../../../../../images/alert-circle.svg';
import { ReactComponent as ClydeLogo } from '../../../../../../images/clyde-circle.svg';
import { ReactComponent as SetupArrows } from '../../../../../../images/integrations-setup-arrows.svg';

const SharedHeader = (props) => {
  const { headerType } = props;
  switch (headerType) {
    case 'alert': {
      return (
        <>
          <div className='integrations-setup-modal__logos'>
            <div className='integrations-setup-modal__logos__other'>
              <AlertCircle className='integrations-setup-modal__logos__other__alert' />
            </div>
          </div>

          <h2 className='integrations-setup-modal__header'>
            Contact Clyde to Connect Integration
          </h2>
        </>
      );
    }
    default: {
      const { logo, name } = props;
      return (
        <>
          <div className='integrations-setup-modal__logos'>
            <ClydeLogo className='integrations-setup-modal__logos__clyde' />
            <SetupArrows className='integrations-setup-modal__logos__arrows' />
            <div className='integrations-setup-modal__logos__other'>
              <Img
                alt={ `${name} logo` }
                className='integration-outside-logo'
                src={ [logo, '/images/warranty-shield.svg'] } />
            </div>
          </div>

          <h2 className='integrations-setup-modal__header'>
            Connect { name } to your Clyde Account
          </h2>
        </>
      );
    }
  }
};

export default SharedHeader;
