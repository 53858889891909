const defaultState = {
  productRegistrationsExport: [],
  viewRegistrableProducts: false,
};

const productRegistrationsReducer = (state = defaultState, action) => {
  switch (action.type) {
    case 'GET_PRODUCT_REGISTRATIONS_EXPORT_RES':
      return {
        ...state,
        productRegistrationsExport: action.payload,
      };
    case 'TOGGLE_REGISTRABLE_PRODUCT_VIEW':
      return {
        ...state,
        viewRegistrableProducts: action.payload.viewRegistrableProducts || !state.viewRegistrableProducts,
      };
    default:
      return state;
  }
};

export default productRegistrationsReducer;
