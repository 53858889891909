import React from 'react';
import qs from 'qs';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { LoadingDots } from '@joinclyde/clyde-react-components';
import { shopifyInstall } from '../../actions/authActions';

class ShopifyInstall extends React.Component {
  componentDidMount() {
    const query = this.props.location.search.slice(1);
    this.props.shopifyInstall(qs.parse(query));
  }

  render() {
    return (
      <div className='gradient'>
        <LoadingDots dotColor='clydeBlack' dotSize='large' />
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    { shopifyInstall },
    dispatch,
  );
}

export default withRouter(connect(null, mapDispatchToProps)(ShopifyInstall));
