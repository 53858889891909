import React, { useState, useEffect } from 'react';
import { ClydeButton, ClydeCheckbox } from '@joinclyde/clyde-react-components';

const AcknowledgementsModal = (props) => {
  const { _onAccept, platform } = props;
  const [responsibilityAccepted, toggleResponsibility] = useState(false);
  const [marketingAccepted, toggleMarketing] = useState(false);
  const [pricingAccepted, togglePricing] = useState(false);
  const [shopifyPaymentsAccepted, toggleShopifyPayments] = useState(false);
  const [showError, toggleError] = useState(false);

  useEffect(() => {
    document.body.style.position = 'fixed';
    return () => {
      document.body.style.position = '';
    };
  }, []);

  const accept = () => {
    if (
      !pricingAccepted
      || !marketingAccepted
      || !responsibilityAccepted
      || (!shopifyPaymentsAccepted && platform === 'Shopify')
    ) {
      toggleError(true);
    } else {
      _onAccept();
    }
  };

  return (
    <div className='modal-container'>
      <div className='modal-container__backdrop' />
      <div className='clyde-modal-content acknowledgement-modal-content'>
        <div className='onboarding-acknowledgement-modal'>
          <h2>Program Requirements</h2>
          <h1>One last thing!</h1>
          <p>
            <strong>Limited Warranty Responsibility:</strong> <br />
            By partnering with Clyde, you agree to honor your products' limited warranties
            and acceptresponsibilityfor resolving claims covered by the limited warranty.
            If your support team is unresponsive to customers' limited warranty claims,
            Clyde may cover the cost of these claims and reserves the right to
            invoice you and/orwithhold post-purchase revenue payouts from you to
            cover the cost of claims covered by your limited warranty.
          </p>
          <ClydeCheckbox
            backgroundColor='clydeBlack'
            className='acknowledgement-modal__check'
            label='I agree'
            dataTestValue='agreeCheckbox1'
            checked={ responsibilityAccepted }
            onChange={ () => toggleResponsibility(!responsibilityAccepted) }
          />
          <hr />
          <p>
            <strong>Marketing:</strong> <br />
            All marketing materials associated with your Clyde program including,
            but not limited to advertisements, website promotions, emails, and FAQ pages
            must be submitted to Clyde for approval before launch.
            Clyde reserves the right to suspend any programs found to have distributed
            unapproved marketing collateral until collateral is in compliance.
          </p>
          <ClydeCheckbox
            backgroundColor='clydeBlack'
            className='acknowledgement-modal__check'
            label='I agree'
            dataTestValue='agreeCheckbox2'
            checked={ marketingAccepted }
            onChange={ () => toggleMarketing(!marketingAccepted) }
          />
          <hr />
          <p>
            <strong>Pricing:</strong> <br />
            Protection plan rates are based on program performance.
            Clyde reserves the right to increase or decrease your
            store's rates in order to account for program performance.
          </p>
          <ClydeCheckbox
            backgroundColor='clydeBlack'
            className='acknowledgement-modal__check'
            label='I agree'
            dataTestValue='agreeCheckbox3'
            checked={ pricingAccepted }
            onChange={ () => togglePricing(!pricingAccepted) }
          />
          { platform === 'Shopify' && <>
            <hr />
            <p>
              <strong>Manual payment flows:</strong> <br />
              If you utilize any manual payment collection flows on your Shopify shop,
              be sure to collect the appropriate amount for any contracts purchased
              on the order.
            </p>
            <ClydeCheckbox
              backgroundColor='clydeBlack'
              className='acknowledgement-modal__check'
              label='I agree'
              dataTestValue='agreeCheckbox4'
              checked={ shopifyPaymentsAccepted }
              onChange={ () => toggleShopifyPayments(!shopifyPaymentsAccepted) }
            />
          </> }
          <div className='acknowledgement-modal__button' data-test='modalFooter'>
            <ClydeButton
              className='acknowledgement-modal__button__button'
              onClick={ accept }
              data-test='finish'
            >
              Finish
            </ClydeButton>
            {
              showError && (
                !responsibilityAccepted
                || !marketingAccepted
                || !pricingAccepted
                || (!shopifyPaymentsAccepted && platform === 'Shopify')
              )
              && <span className='acknowledgement-modal__button__error' data-test='errorCard'>
                Please agree to all terms to proceed.
              </span>
            }
          </div>
        </div>
      </div>
    </div>
  );
};

export default AcknowledgementsModal;
