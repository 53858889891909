import React from 'react';
import { Link } from 'react-router-dom';

const WawSettingsHeader = ({ pageName, editRegistrableProducts }) => (
  <div className='waw-settings__header-section'>
  <div className='waw-settings__header-section-description'>
    <h2>{ `${pageName} Settings` }</h2>
    <p>{ `Configure your ${pageName.toLowerCase()} form for your shop below` }</p>
  </div>
  { pageName === 'Registration'
    && <div>
      <Link
          to='/products'
          className={ `waw-settings__header-section-registrable-products-button` }
          onClick={ editRegistrableProducts }
        >Edit Registrable Products</Link>
    </div>
  }
</div>
);

export default WawSettingsHeader;
