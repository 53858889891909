import React, { Component } from 'react';
import OnboardingDirections from './OnboardingDirections';

class OnboardingWelcome extends Component {
  render() {
    return (
      <div id='onboarding-welcome-component'>
        <div className='page-container'>
          <div className='page-layout'>
            <OnboardingDirections pageKey='welcome'/>
            <div className='page-layout__content'>
              <div className='welcome-header' data-test='welcomeHeader'>What's next?</div>
              <div className='welcome-step-container'>
                <div className='welcome-step row align-items-start'>
                  <div className='welcome-step__image-container'>
                    <img alt='' className='welcome-step__image-container__image' src='/images/cta-step.svg'/>
                  </div>
                  <div className='col-lg-10 col-md-10 col-sm-9 col-xs-6'>
                    <div className='welcome-step-text'>Quick compliance check</div>
                    <div>
                      Our team is reviewing your store to make sure it's fully eligible for a
                      product protection program. We take compliance seriously to ensure we
                      never expose our merchants to any regulatory risk.
                    </div>
                  </div>
                </div>
                <div className='welcome-step row align-items-start'>
                <div className='welcome-step__image-container'>
                    <img alt='' className='welcome-step__image-container__image' src='/images/connect-step.svg'/>
                  </div>
                  <div className='col-lg-10 col-md-10 col-sm-9 col-xs-6'>
                    <div className='welcome-step-text'>Onboard your store</div>
                    <div>Add your store's information, customize the Clyde CTAs, and link your bank account.</div>
                  </div>
                </div>
                { this.props.platform !== 'Clyde'
                  && <div className='welcome-step row align-items-start'>
                    <div className='welcome-step__image-container'>
                        <img alt='' className='welcome-step__image-container__image' src='/images/launch-step.svg'/>
                    </div>
                    <div className='col-lg-10 col-md-10 col-sm-9 col-xs-6'>
                      <div className='welcome-step-text'>Match your products and launch your program</div>
                      <div>
                        A Merchant Onboarding Specialist will match your product catalog
                        to protection plans backed by the industry's top insurance companies,
                        perform testing, and launch your program!
                      </div>
                    </div>
                  </div>
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default OnboardingWelcome;
