const defaultState = {
  claim: {
    claimExternalId: null,
    claimState: '',
    claimType: '',
    createdAt: '',
    denialReason: null,
    descriptionOfProblem: '',
    failureDate: '',
    productStillFunctional: '',
    productUsedAsIntended: '',
  },
  claimHistory: [],
  contract: {
    contractAdh: '',
    contractDop: '',
    contractId: '',
    contractTerm: '',
    deliveryDate: '',
  },
  customer: {
    address: '',
    city: '',
    customerAccountId: null,
    customerId: null,
    email: '',
    firstName: '',
    lastName: '',
    phone: '',
    state: '',
    zip: '',
  },
  processing: {
    claim: false,
    claimHistory: false,
    contract: false,
    customer: false,
    product: false,
    resolutionSubmit: false,
    supportingInfoFiles: false,
  },
  product: {
    productManufacturer: '',
    productName: '',
    productPurchasePrice: 0,
    productRetailPrice: 0,
    productSku: '',
  },
  remainingLimitOfLiability: 0,
  productParts: [],
  supportingInfoFiles: [],
  shopClaimSettings: {
    retailerResolutions: false,
    repairEnabled: false,
    replaceEnabled: false,
    promoCodeEnabled: false,
    payoutEnabled: false,
    depotRepairEnabled: false,
  },
  reviewData: {
    cost: null,
    type: null,
  },
  transferHistory: [],
};

const claimDetailReducer = (state = defaultState, action) => {
  switch (action.type) {
    case 'FETCH_CLAIM_DETAILS':
      return {
        ...state,
        processing: {
          ...state.processing,
          claim: true,
          claimHistory: true,
          contract: true,
          customer: true,
          product: true,
          supportingInfoFiles: true,
        },
      };
    case 'FETCH_CLAIM_DETAILS_RES':
      return {
        ...state,
        claim: action.payload.claim,
        xClaimId: action.payload.xClaimId,
        claimHistory: action.payload.claimHistory,
        customer: action.payload.customer,
        processing: {
          ...state.processing,
          claim: false,
          claimHistory: false,
          customer: false,
        },
        resolution: {
          ...state.resolution,
          ...action.payload.resolution,
        },
        shopClaimSettings: {
          ...state.shopClaimSettings,
          retailerResolutions: action.payload.retailerResolutions,
          repairEnabled: action.payload.repairEnabled,
          replaceEnabled: action.payload.replaceEnabled,
          promoCodeEnabled: action.payload.promoCodeEnabled,
          payoutEnabled: action.payload.payoutEnabled,
          depotRepairEnabled: action.payload.depotRepairEnabled,
        },
        transferHistory: [...action.payload.transferHistory],
      };
    case 'FETCH_CLAIM_SALE_DETAILS_RES':
      return {
        ...state,
        contract: action.payload.contract,
        processing: {
          ...state.processing,
          contract: false,
          product: false,
        },
        product: action.payload.product,
      };
    case 'FETCH_CLAIM_PRODUCT_PARTS_RES':
      return {
        ...state,
        productParts: action.payload,
      };
    case 'FETCH_CLAIM_LOL_DETAILS_RES':
      return {
        ...state,
        remainingLimitOfLiability: action.payload,
      };
    case 'FETCH_CLAIM_SUPPORTING_INFO_RES':
      return {
        ...state,
        processing: {
          ...state.processing,
          supportingInfoFiles: false,
        },
        supportingInfoFiles: action.payload,
      };
    case 'SUBMIT_CLAIM_NEEDS_REVIEW_UPDATE_RES':
      return {
        ...state,
      };
    case 'FETCH_CLAIM_REVIEW_INFO_RES':
      return {
        ...state,
        reviewData: {
          ...action.payload.data,
        },
      };
    default:
      return state;
  }
};

export default claimDetailReducer;
