import React, { Component } from 'react';

import CustomerForm from './CustomerForm';
import customerInfoValidator from '../../../../../lib/customerInfoValidator';

class NewCustomer extends Component {
  state = {
    errors: [],
  }

  handleChange = (name, value) => {
    const { _updateNewOrderCustomerInput } = this.props;
    _updateNewOrderCustomerInput(name, value);
  }

  handleSubmit = (e) => {
    e.preventDefault();
    const {
      newOrder: { customerInfo },
      _moveToCartStep,
    } = this.props;

    const errors = customerInfoValidator(customerInfo, {
      optional: new Set(['phone']),
    });

    this.setState({ errors });
    if (errors.length === 0) {
      _moveToCartStep();
    }
  }

  render() {
    const { newOrder: { customerInfo }} = this.props;
    const { errors } = this.state;

    return (
      <div className='new-order-customer'>
        <div className='new-order-content'>
          <CustomerForm
            customerInfo={ customerInfo }
            errors={ errors }
            handleTextChange={ this.handleChange } />

          <div className='new-order-content__actions'>
            <button
              className='button-nostyle new-order-button new-order-button--filled'
              onClick={ this.handleSubmit }>
              Add Products To Order
            </button>
          </div>
        </div>
      </div>
    );
  }
}

export default NewCustomer;
