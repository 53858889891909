import React, { Component } from 'react';
import classNames from 'classnames';

import { claimStatusSwitcher } from '../../../../Utils/claimHelper';
import SmallButton from '../../../Shared/SmallButton';

class ProductSaleItem extends Component {
  state = {
    toggleOpen: false,
    buttonOpen: false,
  }

  handleButtonToggle = (e) => {
    e.stopPropagation();
    const { id, _setOpenActionButton } = this.props;
    if (this.state.buttonOpen) {
      e.currentTarget.blur();
    }
    this.setState((prevState) => ({ buttonOpen: !prevState.buttonOpen }));
    _setOpenActionButton(id);
  }

  handleFileClaim = () => {
    const {
      product,
      index,
      isEligible,
      _showFileClaim,
    } = this.props;
    product.index = index.toString();
    product.isEligible = isEligible;
    _showFileClaim('limitedWarranty', product);
    this.setState({ buttonOpen: false });
  }

  handleShowClaimHistory = () => {
    const { product, _showDisplayClaimHistory, index } = this.props;
    product.index = index.toString();
    _showDisplayClaimHistory('limitedWarranty', product);
    this.setState({ buttonOpen: false });
  }

  getEligibilityCopy = ({
    name,
    daysLeft,
    daysLeftText,
    oemWarrantyLengthText,
    isEligible,
    dateStillNeeded,
  }) => {
    if (isEligible) {
      return <><h6>{ name }</h6><span> | Eligible for { daysLeft } { dateStillNeeded ? `${daysLeftText} from ${dateStillNeeded}` : `more ${daysLeftText}` }</span></>;
    }
    return <><h6>{ name }</h6><span> | { oemWarrantyLengthText } Limited Warranty </span></>;
  }

  render() {
    const {
      product: {
        daysLeft,
        dateStillNeeded,
        name,
        oemWarrantyLength,
      },
      id,
      selectedClaim: claim,
      warrantyStatus,
      isEligible,
      manageOEMWarranty,
    } = this.props;
    const { toggleOpen, buttonOpen } = this.state;
    const isActive = manageOEMWarranty;
    const claimExists = claim && Object.keys(claim).length > 0;
    const classPrefix = isActive ? 'active' : 'expired';
    const daysLeftText = daysLeft === 1 ? 'day' : 'days';
    const oemWarrantyLengthText = oemWarrantyLength === 1 ? '1 month' : `${oemWarrantyLength} months`;

    const claimClass = classNames(`contract-item-details-claim claim__font--${claim.claimState}`, {
      'contract-item-details-claim--open': toggleOpen,
    });

    const fileClaimButtonClass = classNames('button-no-style active-contract-details__menu__button', {
      'cop-section-actions__menu__button--disabled': claim.fullyReplaced,
    });
    return (
      <div className='product-oem-warranty'>
        <div className='product-oem-warranty__left'>
          {
            this.getEligibilityCopy({
              name,
              daysLeft,
              daysLeftText,
              oemWarrantyLengthText,
              isEligible,
              dateStillNeeded,
            })
          }
        </div>

        <div className='product-oem-warranty__right'>
          { manageOEMWarranty
            && <>
              <div>
                <span>Claim Status: </span>
                <span className={ claimClass }>{ claimStatusSwitcher(claim.claimState) }</span>
              </div>

              { (isActive || claimExists)
                && <SmallButton
                  id={ `${id}_action_button` }
                  customClass={ toggleOpen ? `${warrantyStatus}-contract-details-button--open` : 'active-contract-details-button' }
                  onClick={ this.handleButtonToggle }
                  isOpen={ buttonOpen }
                />
              }
            </>
          }
        </div>

        { buttonOpen
          && <div className={ `${classPrefix}-contract-details__menu` }>
            <ul>
              { claimExists
                && <li>
                  <button
                    className={ `button-nostyle ${classPrefix}-contract-details__menu__button` }
                    onClick={ this.handleShowClaimHistory }>
                    See claim history for this contract
                  </button>
                </li>
              }

              { isActive
                && <>
                  <li>
                    <button
                      className={ fileClaimButtonClass }
                      onClick={ this.handleFileClaim }>
                      {
                        claim.fullyReplaced
                          ? 'Cannot file claim — Warranty fully replaced'
                          : 'File a claim on this product'
                      }
                    </button>
                  </li>
                </>
              }
            </ul>
          </div>
        }
      </div>
    );
  }
}

export default ProductSaleItem;
