import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { ClydeButton } from '@joinclyde/clyde-react-components';
import { Helmet } from 'react-helmet';

import OnboardingDirections from '../Onboarding/OnboardingDirections';
import CustomizeSettings from './CustomizeSettings';
import { getCTAInfo, updateCTAInfo } from '../../actions/customizeActions';

class Customize extends Component {
  UNSAFE_componentWillMount() {
    this.props._getCTAInfo();
  }

  handleSave = () => {
    this.props._updateCTAInfo(this.props.cta);
  }

  render() {
    return (
      <div id='customize-store-component'>
        <Helmet>
          <title>Clyde | Call-to-Action</title>
        </Helmet>

        <div className='page-container'>
          <div className='page-layout'>
            <OnboardingDirections pageKey='customize'/>
            <div className='title-wrapper'>
              <h1>Call-to-Action</h1><br/>
            </div>
            <div className='customize-content'>
              <div className='row align-items-start'>
                <div className='col-12'>
                  <CustomizeSettings platform={ this.props.platform }/>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='process-div'>
          <div className='fixed-save-bar'>
            { this.props.cta.showSaveButton && <ClydeButton
              className='button--small test button'
              id='customize-settings-save-button'
              disabled={ this.props.cta.validColor === false }
              onClick={ this.handleSave }
              processing={ this.props.cta.saveProcessing }
              successText='Saved'
            >
              Save
            </ClydeButton> }
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  cta: state.customize,
  platform: state.auth.platform,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  _updateCTAInfo: updateCTAInfo,
  _getCTAInfo: getCTAInfo,
  // displayCTASaveButton,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Customize);
