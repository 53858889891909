import React from 'react';
import { ClydeTooltip } from '@joinclyde/clyde-react-components';
import classnames from 'classnames';

import { formatDate } from '../../../lib/timeUtils';

const OrderTableRow = (props) => {
  const {
    order,
    highlightRow,
    idx,
    selectOrderClick,
    selectOrderKey,
    platform,
  } = props;

  const formattedDate = formatDate(order.orderDate);
  const formattedProducts = order.productNames.join(', ');
  const orderIdVal = order.merchantOrderId && platform === 'Shopify'
    ? `${order.merchantOrderId} / ${order.orderId}`
    : order.orderId;

  const className = classnames('orders__list__table-body__row', {
    'orders__list__table-body__row--test': order.isTest,
    'orders__list__table-body__row--highlighted': highlightRow,
  });

  return (
    <div
      key={ `clt-${idx}` }
      data-test={ `orderRow-${idx}` }
      className={ className }
      tabIndex='0'
      onClick={ (e) => selectOrderClick(e, order) }
      onKeyDown={ (e) => selectOrderKey(e, order) }>
      <div className='orders__list__table-body__row__cell-id'>{ order.isTest && <sup className='test-order-flag' >Test</sup> }{ orderIdVal }</div>
      <div className='orders__list__table-body__row__cell-date'>{ formattedDate }</div>
        <div
          data-tip
          data-for={ `product-names-tip-${idx}` }
          className='orders__list__table-body__row__cell-products'>
          <ClydeTooltip
            id={ `product-names-tip-${idx}` }
            label={
              <div className='tooltip-text'>{ formattedProducts }</div>
            }
            hideIndicator>
              <div className='order__list__table-body__row__tooltip__child'>
              { formattedProducts }
              </div>
          </ClydeTooltip>
      </div>
      <div className='orders__list__table-body__row__cell-eligible'>{ order.eligibleProductCount }</div>
      <div className='orders__list__table-body__row__cell-contracts'>{ order.activeContractCount }</div>
      <div className='orders__list__table-body__row__cell-claims'>{ order.openClaims || 0 }</div>

    </div>
  );
};

export default OrderTableRow;
