import React,
{ Component } from 'react';
import classnames from 'classnames';
import validator from 'validator';
import moment from 'moment';
import {
  ClydeButton,
  ClydeTextInput,
  ClydeTooltip,
} from '@joinclyde/clyde-react-components';

const MAX_VERIFICATION_ATTEMPTS = 3;

class BillingAccountTile extends Component {
  state = {
    values: {
      deposit1: '',
      deposit2: '',
    },
    errors: new Set(),
    processing: false,
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.processing) {
      this.setState({ processing: false });
    }
  }

  handleVerify = () => {
    const { _verifyStripeMicrodeposits, account } = this.props;
    const { values, errors } = this.state;

    if (!errors.size) {
      this.setState({ processing: true }, () => {
        _verifyStripeMicrodeposits(account.key, values.deposit1, values.deposit2);
      });
    }
  };

  handleSetDefault = () => {
    const { _setStripeDefaultPaymentMethod, account } = this.props;
    if (account.verified) {
      this.setState({ processing: true }, () => {
        _setStripeDefaultPaymentMethod(account.key);
      });
    }
  };

  handleChange = (e) => {
    const { values, errors } = this.state;
    const { name, value } = e.target;

    const nextErrors = new Set(errors);

    if (this.amountIsError(value)) {
      nextErrors.add(name);
    } else {
      nextErrors.delete(name);
    }

    this.setState({
      values: {
        ...values,
        [name]: value,
      },
      errors: nextErrors,
    });
  };

  amountIsError = (value) => (!(validator.isInt(value, { min: 0, max: 99 }) || validator.isEmpty(value)))

  renderVerifyButton(disabled) {
    const { errors, processing } = this.state;
    const button = (
      <ClydeButton
        btnStyle='secondary'
        btnSize='medium'
        disabled={ disabled }
        data-tip
        data-for='stripe-verify-button-warning'
        className={ classnames(
          '',
          { 'stripe-verify-button--error': errors.size > 0 },
        ) }
        style={ { display: 'inline-block' } }
        onClick={ () => this.handleVerify() }
        processing={ processing }
      >
        Verify
      </ClydeButton>
    );

    if (!errors.size) {
      return button;
    }

    return (
      <ClydeTooltip
        label={
          <div className='tooltip-text'>
            Please use positive whole numbers in the input boxes. No
            decimals or negatives!
          </div>
        }
        hideIndicator
      >
        { button }
      </ClydeTooltip>
    );
  }

  renderVerifiedAccount(account) {
    const { processing } = this.state;
    return (
      <div>
        <div className='row bank-account__content'>
          <div className='col-lg-4 col-3'></div>
          <div className='col-lg-2 col-3'></div>
          <div className='col-6'></div>
        </div>
        <div className='row bank-account__content'>
          <div className='col-lg-4 col-3'>
            <div>{ account.bankName }</div>
          </div>
          <div className='col-lg-2 col-3'>
            <div>******{ account.last4 }</div>
          </div>
          <div className='col-4'></div>
          <div className='col-2' style={ { textAlign: 'center' } }>
            { account.default === true ? (
              <div className='stripe-default-account-text'>(Default)</div>
            ) : (
              <ClydeButton
                btnStyle='link-secondary'
                onClick={ this.handleSetDefault }
                processing={ processing }
                style={ { display: 'inline-block' } }
              >
                Set Default
              </ClydeButton>
            ) }
          </div>
        </div>
      </div>

    );
  }

  renderUnverifiedAccount(account) {
    const { billingAttempts } = this.props;
    const depositIsDisabled = (
      billingAttempts >= MAX_VERIFICATION_ATTEMPTS || moment().diff(this.props.lastUpdate, 'hours') < 24
    ) && process.env.NODE_ENV !== 'development';

    return (
      <div className='row bank-account__content'>
        <div className='col-lg-4 col-3'>
          <div>{ account.bankName }</div>
        </div>
        <div className='col-lg-2 col-3'>
          <div>******{ account.last4 }</div>
        </div>
        <div className='col-lg-2 col-2'>
          <ClydeTextInput
            onChange={ this.handleChange }
            className='form-field stripe-microdeposit-input'
            id='deposit1'
            name='deposit1'
            placeholder='Amount (¢)'
            disabled={ depositIsDisabled }
            value={ account.deposit1 }
            typeNumber
          />
        </div>
        <div className='col-lg-2 col-2'>
          <ClydeTextInput
            onChange={ this.handleChange }
            className='form-field stripe-microdeposit-input'
            id='deposit2'
            name='deposit2'
            placeholder='Amount (¢)'
            disabled={ depositIsDisabled }
            value={ account.deposit2 }
            typeNumber
          />
        </div>
        <div className='col-lg-2 col-2' style={ { textAlign: 'center' } }>
          { this.renderVerifyButton(depositIsDisabled) }
        </div>
        { !depositIsDisabled && (
          <div>
            <div className='bank-verification-warning'>
              { MAX_VERIFICATION_ATTEMPTS - billingAttempts } verification attempts left.
              { (billingAttempts === MAX_VERIFICATION_ATTEMPTS - 1)
                && (
                  <span>
                    Please contact Clyde support
                    to avoid this billing account being locked out.
                  </span>
                )
              }
            </div>
          </div>
        ) }
      </div>
    );
  }

  render() {
    const { account } = this.props;

    return (
      <div className='bank-account'>
        { account.verified
          ? this.renderVerifiedAccount(account)
          : this.renderUnverifiedAccount(account)
        }
      </div>
    );
  }
}

export default BillingAccountTile;
