import React, { Component } from 'react';
import { ClydeTextInput } from '@joinclyde/clyde-react-components';

import CartItem from './CartItem';
import { Currency } from '../../../../Utils/Currency';

class Cart extends Component {
  state = {
    errors: [],
  }

  handleSearchChange = (e) => {
    const { name, value } = e.currentTarget;
    const { _updateNewOrderInput } = this.props;
    _updateNewOrderInput(name, value);
  }

  handleSearchSubmit = (e) => {
    e.preventDefault();
    const {
      newOrder: { productSearch },
      _runNewOrderSearch,
    } = this.props;
    _runNewOrderSearch(productSearch);
  }

  updateCartItemPrice = (e, idx) => {
    const { _updateNewOrderCartPrice } = this.props;
    const { value } = e.currentTarget;
    let newPrice = value;
    if (parseFloat(newPrice)) {
      newPrice = +(parseFloat(newPrice).toFixed(2));
    }
    _updateNewOrderCartPrice(idx, newPrice);
  }

  updateCartItemQuantity = (e, idx) => {
    const { _updateNewOrderCartQuantity } = this.props;
    const { value } = e.currentTarget;
    _updateNewOrderCartQuantity(idx, parseInt(value, 10));
  }

  removeItem = (e, idx) => {
    e.preventDefault();
    e.currentTarget.blur();
    const {
      newOrder: { cart },
      _updateCart,
    } = this.props;
    const newCart = [...cart];
    newCart.splice(idx, 1);
    _updateCart(newCart);
  }

  calculateTotalCartQuantity = () => {
    const { newOrder: { cart }} = this.props;
    let totalProductsQuantity = 0;
    let matchedProductsQuantity = 0;
    let cartTotal = 0;
    let missingPrice = false;
    for (const item of cart) {
      if (!item.price || item.price <= 0) {
        missingPrice = true;
      }
      const quantity = item.quantity || 0;
      const itemPrice = item.price || 0;
      totalProductsQuantity += quantity;
      cartTotal += quantity * itemPrice;
      if (item.contractIds && item.contractIds[0]) {
        matchedProductsQuantity += quantity;
      }
    }

    return {
      totalProductsQuantity,
      matchedProductsQuantity,
      cartTotal,
      missingPrice,
    };
  }

  handleSubmit = (e, totalProductsQuantity, missingPrice) => {
    e.preventDefault();
    const {
      newOrder: { cart },
      submitOrder,
    } = this.props;

    const errors = [];
    if (cart.length <= 0) {
      errors.push('submitError');
    }
    if (missingPrice) {
      errors.push('priceError');
    }
    if (cart.length > 0 && totalProductsQuantity <= 0) {
      errors.push('quantityError');
    }
    this.setState({ errors });

    if (errors.length === 0) {
      submitOrder();
    }
  }

  render() {
    const {
      newOrder: {
        cart,
        productSearch,
      },
      _moveToCustomerStep,
    } = this.props;
    const { errors } = this.state;

    const {
      totalProductsQuantity,
      matchedProductsQuantity,
      cartTotal,
      missingPrice,
    } = this.calculateTotalCartQuantity();

    return (
      <div className='new-order-cart'>
        <form className='new-order-cart__search' onSubmit={ this.handleSearchSubmit }>
          <label htmlFor='search_products' className='new-order-cart__search__label'>
            Search products to add to order:
          </label>
          <div className='new-order-cart__search__inputs'>
            <ClydeTextInput
              id='search_products'
              className='new-order-cart-search__text'
              onChange={ this.handleSearchChange }
              placeholder='Product Name or SKU'
              name='productSearch'
              value={ productSearch }
            />
            <button
              className='button-nostyle new-order-cart__search__button'>
              Browse products
            </button>
          </div>
        </form>

        <div className='new-order-content'>
          {
            cart.length > 0
              && <div className='new-order-content__header'>
                <div className='new-order-content__header__right'>
                  <span className='new-order-cart-header-price'>Price</span>
                  <span className='new-order-cart-header-quantity'>Quantity</span>
                </div>
              </div>
          }
          <div className='new-order-content__items'>
            {
              cart.map((product, idx) => (
                <CartItem
                  key={ `cart-${idx}` }
                  idx={ idx }
                  product={ product }
                  updateQuantity={ this.updateCartItemQuantity }
                  updatePrice={ this.updateCartItemPrice }
                  removeItem={ this.removeItem } />
              ))
            }
          </div>

          <div className='new-order-content__summary'>
            <div className='new-order-content__summary__center'>
              <div className='new-order-cart-summary__text'>
                <span>Total products in this order</span>
                <span>{ totalProductsQuantity }</span>
              </div>
              <div className='new-order-cart-summary__text'>
                <span>Eligible products in this order</span>
                <span>{ matchedProductsQuantity }</span>
              </div>
              <div className='cop-small-modal-line' />
              <div className='new-order-cart-summary__text'>
                <span>Order Total</span>
                <span><Currency amount={ cartTotal } /></span>
              </div>
            </div>
          </div>

          <div className='new-order-content__actions'>
            {
              errors.indexOf('submitError') > -1
                && <span className='new-order-content__actions__error'>Cannot record an empty order.</span>
            }
            {
              errors.indexOf('quantityError') > -1
                && <span className='new-order-content__actions__error'>Item quantities must be greater than zero.</span>
            }
            {
              errors.indexOf('priceError') > -1
                && <span className='new-order-content__actions__error'>Item prices must be provided and greater than zero.</span>
            }
            <button
              id='confirm-change-order-customer-button'
              className='button-nostyle new-order-button new-order-button--empty'
              onClick={ (e) => this.handleSubmit(e, totalProductsQuantity, missingPrice) }>
              Record order
            </button>
            <button
              className='button-nostyle button-fakelink'
              onClick={ _moveToCustomerStep }>
              Back to Customer
            </button>
          </div>
        </div>
      </div>
    );
  }
}

export default Cart;
