import React, { Component, createRef } from 'react';
import {
  LoadingDots,
  ClydeTooltip,
} from '@joinclyde/clyde-react-components';

import { CSVLink } from 'react-csv';
import RegistrationSettingSwitch from './RegistrationSettingSwitch';
import WawSettingsHeader from './WawSettingsHeader';

class WawSettingsTab extends Component {
  constructor() {
    super();
    this.csvLinkRef = createRef();
  }

  componentDidMount = () => {
    this.props._getShopPreferences();
    this.props._getProductRegistrationsExport(this.props.shopId);
  }

  editRegistrableProducts = () => {
    this.props._toggleViewRegistrableProducts(true);
  }

  handleRegistrationCsvExport = () => {
    this.csvLinkRef.current.link.click();
  }

  _toggleCollectSerialNumber = (currentValue, offState) => {
    let collectSerialNumber = 'hidden';
    if (offState === 'hidden' && currentValue === 'hidden') {
      collectSerialNumber = 'optional';
    } else if (offState === 'optional') {
      if (currentValue === 'optional') {
        collectSerialNumber = 'required';
      } else {
        collectSerialNumber = 'optional';
      }
    }
    this.props._setShopPreferences({
      collectSerialNumber,
    });
  }

  render() {
    const {
      page,
      collectSerialNumber,
      productRegistrationsExport,
      loading,
    } = this.props;
    const filenameDate = new Date().toLocaleDateString().replace(/\//g, '_');
    const exportsFilename = `${filenameDate}_product_registrations.csv`;
    const pageName = page === 'registrations' ? 'Registration' : 'Warranty Anywhere';
    return (
      <React.Fragment>
      <div className='settings-tab settings-tab--waw'>
        {
          loading || !page
            ? <LoadingDots className='waw-settings__loading' dotColor='clydeBlack' dotSize='medium' />
            : <React.Fragment>
              <WawSettingsHeader pageName={ pageName } editRegistrableProducts={ this.editRegistrableProducts }/>
              {
                page === 'registrations' && <div className='waw-settings__body'>
                  <div className='waw-settings__body_serial-number-section'>
                    <div className='waw-settings__body_serial-number-toggle'>
                      <h3>Collect Serial Number</h3>
                      <RegistrationSettingSwitch
                        toggleFunction={ this._toggleCollectSerialNumber }
                        offState={ 'hidden' }
                        currentValue={ collectSerialNumber }
                        on={ collectSerialNumber !== 'hidden' }
                      />
                    </div>
                    {
                      collectSerialNumber !== 'hidden'
                      && <div className='waw-settings__body_serial-number-toggle'>
                        <div className='waw-settings__body_serial-number-toggle-optional'>
                          <p>Is this a required field?</p>
                        </div>
                        <RegistrationSettingSwitch
                          toggleFunction={ this._toggleCollectSerialNumber }
                          offState={ 'optional' }
                          on={ collectSerialNumber !== 'optional' }
                          currentValue={ collectSerialNumber }
                        />
                      </div>
                    }
                  </div>
                </div>
              }
              </React.Fragment>
        }
      </div>
      { productRegistrationsExport.length > 0 && page === 'registrations'
          && <div className='actions-container waw-settings'>
            <ClydeTooltip
              id='registrations-export'
              position='right'
              label={
                <div className='actions-container__tooltip__text'>
                  Export Product Registrations
                </div>
              }
              hideIndicator={ true }
            >
              <div
                onClick={ this.handleRegistrationCsvExport }
                className='actions-container__button'
                data-tip
                data-for='registrations-export'
              >
                <img
                  className='actions-container__button__image'
                  alt='Export product registrations'
                  src='/images/download.svg'
                />
              </div>
            </ClydeTooltip>
          </div>
        }
          <CSVLink
            ref={ this.csvLinkRef }
            className='hidden-link'
            filename={ exportsFilename }
            data={ productRegistrationsExport }
          />
      </React.Fragment>
    );
  }
}

export default WawSettingsTab;
