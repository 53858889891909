import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Transition from 'react-transition-group/Transition';

import { toggleVariant } from '../../../actions/productsActions';

const VariantSwitch = (props) => {
  const {
    reviewed,
    noMatch,
    productIndex,
    productId,
    variantIndex,
    _toggleVariant,
  } = props;

  const handleToggle = () => { _toggleVariant(productId, productIndex, variantIndex); };

  const duration = 200;
  const defaultStyle = {
    transition: 'left 200ms ease-in-out',
  };
  const transitionStyles = {
    entering: { left: '18px' },
    entered: { left: '18px' },
    exiting: { left: '4px' },
    exited: { left: '4px' },
  };

  return (
    <div>
    { reviewed && !noMatch
      && <Transition in={ props.on } timeout={ duration }>
        { (state) => (
          <div onClick={ handleToggle } className={ props.on ? 'toggle-variant toggle-variant--on' : 'toggle-variant toggle-variant--off' }>
            <div className='toggle-variant__dot' style={ { ...defaultStyle, ...transitionStyles[state] } }></div>
          </div>
        ) }
      </Transition>
    }
    </div>
  );
};

const mapDispatchToProps = (dispatch) => bindActionCreators({ _toggleVariant: toggleVariant }, dispatch);

export default connect(null, mapDispatchToProps)(VariantSwitch);
