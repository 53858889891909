import { combineReducers } from 'redux';
import authReducer from './authReducer';
import claimDetail from './claims/claimDetailReducer';
import claimResolution from './claims/claimResolutionReducer';
import claimsList from './claims/claimsListReducer';
import productsReducer from './productsReducer';
import productRegistrationsReducer from './productRegistrationsReducer';
import profileReducer from './profileReducer';
import contactInfoReducer from './contactInfoReducer';
import developersReducer from './developersReducer';
import integrationsReducer from './integrationsReducer';
import customizeReducer from './customizeReducer';
import onboardingReducer from './onboardingReducer';
import billingReducer from './billingReducer';
import autopilotReducer from './autopilotReducer';
import contractSaleReducer from './contractSaleReducer';
import performanceReducer from './performanceReducer';
import salesReducer from './salesReducer';
import userReducer from './userReducer';
import userManagementReducer from './userManagementReducer';
import customersReducer from './customersReducer';
import newOrderReducer from './newOrderReducer';
import errorReducer from './errorReducer';
import licenseReducer from './licenseReducer';
import preferencesReducer from './preferencesReducer';
import localeReducer from './localeReducer';

const rootReducer = combineReducers({
  auth: authReducer,
  claimDetail,
  claimResolution,
  claimsList,
  onboarding: onboardingReducer,
  products: productsReducer,
  productRegistrations: productRegistrationsReducer,
  contractSale: contractSaleReducer,
  profile: profileReducer,
  contactInfo: contactInfoReducer,
  developers: developersReducer,
  integrations: integrationsReducer,
  customize: customizeReducer,
  billing: billingReducer,
  autopilot: autopilotReducer,
  performance: performanceReducer,
  sales: salesReducer,
  user: userReducer,
  userManagement: userManagementReducer,
  customers: customersReducer,
  newOrder: newOrderReducer,
  error: errorReducer,
  license: licenseReducer,
  preferences: preferencesReducer,
  locale: localeReducer,
});

export default rootReducer;
