import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import {
  backToCustomerSearch,
  cancelFileClaim,
  confirmFileClaim,
  getOrderDetails,
  getSaleInfoForClaim,
  updateFileClaimInput,
  showFileClaimResolution,
  sendHiClydeReferral,
} from '../../../../actions/customersActions';

import { getClaimsForResolution, getIssueTypes } from '../../../../actions/claims/claimsListActions';

import Modal from './Modal';

const mapStateToProps = (state) => {
  const { customers } = state;
  return {
    customerName: customers.ordersOverview.customerName,
    fileClaim: customers.fileClaim,
    orderId: customers.selectedOrder.clydeOrderId,
    shopClaimSettings: customers.shopClaimSettings,
    shopId: state.auth.shopId,
    shopName: state.contactInfo.shopName,
    issueTypes: state.claimsList.issueTypes,
  };
};

const mapDispatchToProps = (dispatch) => bindActionCreators({
  _backToCustomerSearch: backToCustomerSearch,
  _cancelFileClaim: cancelFileClaim,
  _sendHiClydeReferral: sendHiClydeReferral,
  _confirmFileClaim: confirmFileClaim,
  _getOrderDetails: getOrderDetails,
  _getSaleInfoForClaim: getSaleInfoForClaim,
  _updateFileClaimInput: updateFileClaimInput,
  _showResolutionForm: showFileClaimResolution,
  _getClaimsForResolution: getClaimsForResolution,
  _getIssueTypes: getIssueTypes,
}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Modal);
