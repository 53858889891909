import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import {
  saveNewContractSaleSerialNumber,
  showDisplayClaimHistory,
  showFileClaim,
  showCancelContract,
  showUpdateOEM,
} from '../../../../../actions/customersActions';

import ContractSaleItem from './ContractSaleItem';

const mapStateToProps = (state) => ({
  seriaNumberProcessing: state.customers.actionsProcessing.saveNewContractSaleSerialNumber,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  _saveNewContractSaleSerialNumber: saveNewContractSaleSerialNumber,
  _showDisplayClaimHistory: showDisplayClaimHistory,
  _showFileClaim: showFileClaim,
  _showCancelContract: showCancelContract,
  _showUpdateOEM: showUpdateOEM,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ContractSaleItem);
