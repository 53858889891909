import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import {
  getShopInfo,
  setIsBasicAuth,
  updateShopInfo,
} from '../../../../actions/developersActions';

import DevelopersSettingsTile from './DevelopersSettingsTile';

const mapStateToProps = (state) => ({
  developers: state.developers,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  _getShopInfo: getShopInfo,
  _updateShopInfo: updateShopInfo,
  _setIsBasicAuth: setIsBasicAuth,
}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(DevelopersSettingsTile);
