import validator from 'validator';

const checkValidations = (profile, platform) => {
  const formErrors = {};
  for (const field in profile) {
    switch (field) {
      case 'firstName':
      case 'lastName':
      case 'address1':
      case 'city':
        if (validator.isEmpty(profile[field])) formErrors[field] = true;
        break;
      case 'shopName':
        if (platform === 'Shopify') break;
        if (validator.isEmpty(profile.shopName)) formErrors.shopName = true;
        break;
      case 'domain':
        if (platform === 'Shopify') break;
        if (!validator.isURL(profile.domain)) formErrors.domain = true;
        break;
      case 'email':
        if (!validator.isEmail(profile.email)) formErrors.email = true;
        break;
      case 'secondaryEmail':
        if (profile.secondaryEmail && !validator.isEmail(profile.secondaryEmail)) {
          formErrors.secondaryEmail = true;
        }
        break;
      case 'phoneNumber':
        if (phoneError(profile.phoneNumber)) formErrors.phoneNumber = true;
        break;
      case 'province':
        if (provinceError(profile.province, profile.country)) formErrors.province = true;
        break;
      case 'country':
        if (validator.equals(profile.country, '--')) formErrors.country = true;
        break;
      case 'zip':
        if (zipError(profile.zip, profile.country)) formErrors.zip = true;
        break;
      default:
        break;
    }
  }
  return formErrors;
};

export const additionalContactValidations = (contact) => {
  const fields = Object.keys(contact);
  const formErrors = {};
  for (const field of fields) {
    switch (field) {
      case 'email':
      case 'secondaryEmail':
        if (contact[field] && !validator.isEmail(contact[field])) {
          formErrors[field] = true;
        }
        break;
      case 'phoneNumber':
        if (phoneError(contact.phoneNumber)) {
          formErrors.phoneNumber = true;
        }
        break;
      default:
        break;
    }
  }

  if (
    !contact.email && !contact.phoneNumber
    && (contact.firstName || contact.lastName)
  ) {
    formErrors.email = true;
  }

  return formErrors;
};

const phoneError = (phoneNumber) => {
  if (!phoneNumber || validator.isEmpty(phoneNumber)) return false;
  if (!validator.isMobilePhone(phoneNumber, 'any')) return true;
  return false;
};

const provinceError = (province, country) => {
  const usStates = [
    'AL', 'AK', 'AZ', 'AR', 'CA', 'CO', 'CT', 'DE', 'DC', 'FL', 'GA', 'HI', 'ID', 'IL', 'IN',
    'IA', 'KS', 'KY', 'LA', 'ME', 'MD', 'MA', 'MI', 'MN', 'MS', 'MO', 'MT', 'NE', 'NV', 'NH',
    'NJ', 'NM', 'NY', 'NC', 'ND', 'OH', 'OK', 'OR', 'PA', 'RI', 'SC', 'SD', 'TN', 'TX', 'UT',
    'VT', 'VA', 'WA', 'WV', 'WI', 'WY', 'GU', 'PR', 'VI',
  ];
  if (country === 'US' && !usStates.find((state) => state === province)) {
    return true;
  } if (validator.isEmpty(province)) {
    return true;
  }
  return false;
};

const zipError = (zip, country) => {
  if (validator.isEmpty(zip) && validator.equals(country, '--')) return true;
  const locale = validator.isPostalCodeLocales.find((postalLocale) => postalLocale === country);
  if (locale && !validator.isPostalCode(zip, locale)) return true;
  return false;
};

export default checkValidations;
