import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import {
  moveToCartStep,
  updateNewOrderCustomerInput,
} from '../../../../../actions/newOrderActions';

import NewCustomer from './NewCustomer';

const mapStateToProps = (state) => ({ newOrder: state.newOrder });

const mapDispatchToProps = (dispatch) => bindActionCreators({
  _moveToCartStep: moveToCartStep,
  _updateNewOrderCustomerInput: updateNewOrderCustomerInput,
}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(NewCustomer);
