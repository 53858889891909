import React from 'react';
import classnames from 'classnames';

const SelectVariant = (props) => (
    <div>
      { props.product.Variants.length > 1
        ? <div className='row'>
          <div className='sale-modal-step col-12'>{ props.step }. Choose variant:</div>
          { props.product.Variants.map((variant, index) => (
              <span key={ `variant-${index}` }>
              { variant.Contracts.length > 0
                && <div
                  className={ classnames(
                    'select-variant-tile',
                    { 'select-variant-tile--active': props.activeVariant && variant.ProductID === props.activeVariant.ProductID },
                  ) }
                  onClick={ () => props.select(variant) }>
                    { variant.VariantName }
                </div>
              }
              </span>
          )) }
          </div>
        : <div className='row'>
            <div className='sale-modal-step col-12'>1. Choose variant:</div>
            <div className='select-variant-tile select-variant-tile--active'>Default</div>
          </div>
      }
    </div>
);

export default SelectVariant;
