import { wsSend } from '../websocketActions';

export const populateCustomersStateWithClaimsCustomerInfo = (customerInfo) => ({
  type: 'POPULATE_CUSTOMERS_STATE_WITH_CLAIMS_CUSTOMER_INFO',
  payload: customerInfo,
});

export const fetchClaimData = (claimId) => (dispatch) => {
  dispatch({
    type: 'FETCH_CLAIM_DETAILS',
    payload: {},
  });
  dispatch(wsSend({
    type: 'FETCH_CLAIM_DETAILS_REQ',
    payload: { claimId },
  }));
};

export const submitClaimNeedsReviewUpdate = (claimId) => (dispatch) => {
  dispatch({
    type: 'SUBMIT_CLAIM_NEEDS_REVIEW_UPDATE',
    payload: {},
  });
  dispatch(wsSend({
    type: 'SUBMIT_CLAIM_NEEDS_REVIEW_UPDATE_REQ',
    payload: { claimId },
  }));
};

export const submitDataForReview = (claimId, data) => (dispatch) => {
  dispatch({
    type: 'SUBMIT_DATA_FOR_REVIEW',
    payload: {},
  });
  dispatch(wsSend({
    type: 'SUBMIT_DATA_FOR_REVIEW_REQ',
    payload: { claimId, data },
  }));
};
