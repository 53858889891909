import React from 'react';
import classnames from 'classnames';
import { LoadingDots } from '@joinclyde/clyde-react-components';

const OrderInfo = (props) => {
  const {
    claimsOverView, isProcessing, ordersOverview, selectedCustomer,
  } = props;

  const eligibleText = ordersOverview.totalEligibleProductCount === 1 ? 'eligible product' : 'eligible products';
  const activeText = ordersOverview.totalActiveContractCount === 1 ? 'active contract' : 'active contracts';
  const fulfilledText = ordersOverview.totalFulfilledContractCount === 1 ? 'fulfilled contract' : 'fulfilled contracts';
  const cancelledText = ordersOverview.totalCancelledContractCount === 1 ? 'cancelled contract' : 'cancelled contracts';
  const expiredText = ordersOverview.totalExpiredContractCount === 1 ? 'expired contract' : 'expired contracts';
  const submittedText = claimsOverView.claimsOpen === 1 ? 'Claim open' : 'Claims open';
  const approvedText = claimsOverView.claimsResolved === 1 ? 'Claim resolved' : 'Claims resolved';
  const deniedText = claimsOverView.claimsDenied === 1 ? 'Claim denied' : 'Claims denied';
  let hiClydeText = 'No account';
  if (selectedCustomer.hasHiClydeAccount && selectedCustomer.emailVerified) {
    hiClydeText = 'Account verified';
  } else if (selectedCustomer.hasHiClydeAccount) {
    hiClydeText = 'Account unverified';
  }
  const missingCustomerInfoText = 'Missing Customer Information Update via Shopify Admin';

  const contactClass = classnames({
    'alert-text': !selectedCustomer.contact,
  });

  return (
    <section className='orders__info'>
      <div data-test='customerContactCard' className='orders__info__content orders__info__content--contact'>
        <h6>Customer Info</h6>
        <ul>
          <li className={ contactClass }>{ selectedCustomer.contact || missingCustomerInfoText }</li>
          { selectedCustomer.phone && selectedCustomer.phone !== selectedCustomer.contact
            && <li>{ selectedCustomer.phone }</li>
          }
        </ul>
      </div>

      <div className='orders__info__content orders__info__content--contracts'>
        <h6>Contracts Overview</h6>
        {
          isProcessing
            ? <LoadingDots dotColor='clydeBlack' dotSize='medium' />
            : <ul>
                <li>{ `${ordersOverview.totalEligibleProductCount} ${eligibleText}` }</li>
                <li>{ `${ordersOverview.totalActiveContractCount} ${activeText}` }</li>
                <li>{ `${ordersOverview.totalFulfilledContractCount} ${fulfilledText}` }</li>
                <li>{ `${ordersOverview.totalCancelledContractCount} ${cancelledText}` }</li>
                <li>{ `${ordersOverview.totalExpiredContractCount} ${expiredText}` }</li>
              </ul>
        }
      </div>

      <div className='orders__info__content orders__info__content--claims'>
        <h6>Claims Overview</h6>
        {
          isProcessing
            ? <LoadingDots dotColor='clydeBlack' dotSize='medium' />
            : <ul>
                <li>{ `${claimsOverView.claimsOpen} ${submittedText}` }</li>
                <li>{ `${claimsOverView.claimsResolved} ${approvedText}` }</li>
                <li>{ `${claimsOverView.claimsDenied} ${deniedText}` }</li>
              </ul>
        }
      </div>

      <div className='orders__info__content orders__info__content--hiclyde'>
        <h6>hiclyde.com</h6>
        {
          isProcessing
            ? <LoadingDots dotColor='clydeBlack' dotSize='medium' />
            : <ul>
                <li>{ hiClydeText }</li>
              </ul>
        }
      </div>
    </section>
  );
};

export default OrderInfo;
