import uuidv4 from 'uuid/v4';
import Cookies from 'universal-cookie';

// Action types to be dispatched by the user
export const WEBSOCKET_CONNECT = 'WEBSOCKET:CONNECT';
export const WEBSOCKET_DISCONNECT = 'WEBSOCKET:DISCONNECT';
export const WEBSOCKET_SEND = 'WEBSOCKET:SEND';

// Action types dispatched by the WebSocket implementation
export const WEBSOCKET_CONNECTING = 'WEBSOCKET:CONNECTING';
export const WEBSOCKET_OPEN = 'WEBSOCKET:OPEN';
export const WEBSOCKET_CLOSED = 'WEBSOCKET:CLOSED';

const cookies = new Cookies();

// These actions are more concerned with connection state
// and are trigged async by the WebSocketMiddleware

export const wsConnect = () => ({
  type: WEBSOCKET_CONNECT,
  payload: {
    url: window._env_.REACT_APP_WEBSOCKET_URL,
    token: cookies.get('clydeToken'),
    platform: cookies.get('clydePlatform'),
  },
});

export const wsDisconnect = () => ({
  type: WEBSOCKET_DISCONNECT,
});

// Converts the action object to an action with type "WEBSOCKET_SEND",
// which gets intercepted by themiddleware and sent to the ws server
export const wsSend = (action) => {
  action.id = uuidv4();
  if (cookies.get('clydeShopId')) {
    action.payload.shopId = cookies.get('clydeShopId');
  }

  return {
    type: WEBSOCKET_SEND,
    payload: JSON.stringify(action),
  };
};

export const wsConnecting = (event, websocket) => ({
  type: WEBSOCKET_CONNECTING,
  payload: {
    event,
    websocket,
  },
});

export const wsOpen = (event) => ({
  type: WEBSOCKET_OPEN,
  payload: {
    event,
  },
});

export const wsClosed = (event) => ({
  type: WEBSOCKET_CLOSED,
  payload: {
    event,
  },
});

export const wsMessage = (event) => {
  const data = JSON.parse(event.data);
  return {
    type: data.type,
    payload: data.payload,
  };
};

export default {};
