import React, { Component } from 'react';
import { SuccessAnimation, LoadingDots } from '@joinclyde/clyde-react-components';

import TermsHeader from './TermsHeader';
import BulkCart from './BulkCart';
import BulkCheckout from './BulkCheckout';

class BulkContractSaleModal extends Component {
  componentDidMount() {
    const { bulkContractSale, clydeOrderId, _getContractsForBulkSale } = this.props;
    const lineItemIds = Object.keys(bulkContractSale.cart);
    _getContractsForBulkSale(clydeOrderId, lineItemIds);
  }

  handleContractSelect = (e, clydeLineItemId, contract, idx) => {
    const { _selectBulkCopSaleContract } = this.props;
    let selectedContract;
    if (contract) {
      const { target: { value }} = e;
      selectedContract = {
        ...contract,
        term: parseInt(value, 10),
        clydeLineItemId,
      };
    }

    _selectBulkCopSaleContract(clydeLineItemId, idx, selectedContract);
  }

  handleContractSelectAll = (e) => {
    const {
      bulkContractSale: { productContracts },
      _selectAllBulkCopSaleContracts,
    } = this.props;
    const { target: { value }} = e;
    const newCart = {};

    for (const pc of productContracts) {
      // eslint-disable-next-line eqeqeq
      const selectedContract = pc.contracts.find((c) => c.term == value);
      newCart[pc.clydeLineItemId] = [];
      for (let pcidx = 0; pcidx < pc.eligibleQuantity; pcidx++) {
        if (selectedContract) {
          newCart[pc.clydeLineItemId].push({
            ...selectedContract,
            clydeLineItemId: pc.clydeLineItemId,
          });
        } else {
          newCart[pc.clydeLineItemId].push(undefined);
        }
      }
    }

    e.currentTarget.blur();
    _selectAllBulkCopSaleContracts(newCart);
  }

  handleRecordSales = () => {
    const {
      bulkContractSale: {
        cart,
      },
      clydeOrderId,
      _recordBulkContractSale,
    } = this.props;
    const cartContracts = Object.values(cart).flat();
    const sales = cartContracts.filter((contract) => contract); // filter out empty spaces
    _recordBulkContractSale(clydeOrderId, sales);
  }

  finishSale = () => {
    const { clydeOrderId, _getOrderDetails, _cancelBulkContractSale } = this.props;
    _getOrderDetails(clydeOrderId);
    _cancelBulkContractSale();
    // MARKER: AJC 01/17/20 This introduces a low-impact race-condition: if COP data refreshes before
    // the readonly DB is updated with the sale inserts, COP won't display the newly updated order until a refresh.
  }

  render() {
    const {
      bulkContractSale: {
        actionComplete,
        cart,
        productContracts,
        processing,
        submitProcessing,
        termsOnOrder,
      },
      _cancelBulkContractSale,
    } = this.props;

    return (
      <div className='cop-modal-background' onClick={ _cancelBulkContractSale }>
        <div className='cop-modal-foreground cop-modal-foreground--cop-bulk-sale' onClick={ (e) => e.stopPropagation() }>
          {
            actionComplete
              ? <div className='cop-modal-foreground__action-complete'>
                  <SuccessAnimation text='Recorded!' onSuccess={ this.finishSale } />
                </div>
              : <React.Fragment>
                  <div className='cop-modal-header'>
                    <button className='button-nostyle cop-modal-header__cancel' onClick={ _cancelBulkContractSale }>
                      &#x2715;
                    </button>
                    <p className='cop-modal-header__explanation'>Keep your customer's purchases protected</p>
                    <h1 className='cop-modal-header__header'>Record a contract sale</h1>
                  </div>

                  {
                    processing
                      ? <div className='cop-modal--loading'>
                          <LoadingDots dotColor='clydeBlack' dotSize='large' />
                        </div>
                      : <React.Fragment>
                          <div className='cop-modal-content cop-bulk-sale__content'>
                            <TermsHeader
                              termsOnOrder={ termsOnOrder }
                              selectAll={ this.handleContractSelectAll } />

                            <BulkCart
                              cart={ cart }
                              productContracts={ productContracts }
                              termsOnOrder={ termsOnOrder }
                              selectContract={ this.handleContractSelect } />
                          </div>

                          <BulkCheckout
                            cart={ cart }
                            submitProcessing={ submitProcessing }
                            recordSales={ this.handleRecordSales } />
                        </React.Fragment>
                  }
                </React.Fragment>
          }
        </div>
      </div>
    );
  }
}

export default BulkContractSaleModal;
