import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import {
  createUser,
  clearFeedback,
  clearSuccess,
} from '../../../../../actions/userManagementActions';

import CreateUser from './CreateUser';

const mapStateToProps = (state) => ({
  isProcessing: state.userManagement.isProcessing,
  isSuccess: state.userManagement.isSuccess,
  newUser: state.userManagement.newUser,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  _createUser: createUser,
  _clearFeedback: clearFeedback,
  _clearSuccess: clearSuccess,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(CreateUser);
