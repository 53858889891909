import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import BulkContractSaleModal from './BulkContractSale';

import {
  getOrderDetails,
  cancelBulkContractSale,
  recordBulkContractSale,
  getContractsForBulkSale,
  selectBulkCopSaleContract,
  selectAllBulkCopSaleContracts,
} from '../../../../actions/customersActions';

const mapStateToProps = (state) => ({
  bulkContractSale: state.customers.bulkContractSale,
  clydeOrderId: state.customers.selectedOrder.clydeOrderId,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  _getOrderDetails: getOrderDetails,
  _cancelBulkContractSale: cancelBulkContractSale,
  _recordBulkContractSale: recordBulkContractSale,
  _getContractsForBulkSale: getContractsForBulkSale,
  _selectBulkCopSaleContract: selectBulkCopSaleContract,
  _selectAllBulkCopSaleContracts: selectAllBulkCopSaleContracts,
}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(BulkContractSaleModal);
