import { connect } from 'react-redux';

import {
  closeSetupModal,
  resetSetupModal,
} from '../../../../actions/integrationsActions';

import SetupModal from './SetupModal';

const mapStateToProps = (state) => ({
  isFriggIntegration: state.integrations.setupModal.selectedIntegration.friggIntegration,
  isModalOpen: state.integrations.setupModal.modalOpen,
  submitSuccess: state.integrations.setupModal.submitSuccess,
});

const mapDispatchToProps = (dispatch) => ({
  _closeModal: () => {
    dispatch(closeSetupModal());
  },
  _resetModal: () => {
    dispatch(resetSetupModal());
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SetupModal);
