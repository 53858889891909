import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import {
  getCustomerOrders,
  backToCustomerSearch,
  selectOrder,
  selectRegistration,
} from '../../../actions/customersActions';

import OrderSearch from './OrderSearch';

const mapStateToProps = (state) => ({
  customers: state.customers,
});

const mapDispatchToProps = (dispatch) => bindActionCreators(
  {
    _getCustomerOrders: getCustomerOrders,
    _backToCustomerSearch: backToCustomerSearch,
    _selectOrder: selectOrder,
    _selectRegistration: selectRegistration,
  },
  dispatch,
);

export default connect(mapStateToProps, mapDispatchToProps)(OrderSearch);
