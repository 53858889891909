import React from 'react';
import zxcvbn from 'zxcvbn';
import { ClydeTextInput } from '@joinclyde/clyde-react-components';

import errorMessages from '../../lib/errorMessages';

class ClydeFirstLoginForm extends React.Component {
  state = {
    email: '',
    tempPassword: '',
    passwordFeedback: '',
    newPassword: '',
  };

  componentDidMount() {
    this.tempPasswordInput.focus();
  }

  handlePasswordChange = (e) => {
    const { name, value } = e.target;
    this.setState({
      [name]: value,
    });
  }

  handleSubmit = (e) => {
    e.preventDefault();
    const passwordStrengthScore = zxcvbn(this.state.newPassword).score;
    if (passwordStrengthScore < 3) {
      this.setState({
        passwordFeedback: errorMessages.StrongerPasswordRequired,
      });
      return;
    }
    const data = {
      email: this.props.email,
      tempPassword: this.state.tempPassword,
      newPassword: this.state.newPassword,
    };
    this.props.submitAction(data);
  }

  render() {
    return (
      <div className='clyde-login-form clyde-login row'>
        <ClydeTextInput
          id='email'
          placeholder='Email address'
          className='col-12 clyde-login-form__input'
          name='email'
          value={ this.props.email }
          disabled
        />
        <input
          spellCheck='false'
          id='tempPassword'
          placeholder='Temp Password'
          className='col-12 clyde-login-form__input'
          type='password'
          name='tempPassword'
          onChange={ (e) => { this.handlePasswordChange(e); } }
          ref={ (input) => { this.tempPasswordInput = input; } }
          value={ this.state.tempPassword }
        />
        <input
          spellCheck='false'
          id='newPassword'
          placeholder='New Password'
          className='col-12 clyde-login-form__input'
          type='password'
          name='newPassword'
          onChange={ (e) => { this.handlePasswordChange(e); } }
          value={ this.state.newPassword }
        />
        <button type='submit' onClick={ (e) => { this.handleSubmit(e); } } className='col-lg-12 clyde-login-form__button'>Change Password</button>
        <div className='clyde-login-form__feedback' >{ this.state.passwordFeedback }</div>
      </div>
    );
  }
}

export default ClydeFirstLoginForm;
