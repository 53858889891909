import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import {
  getGraphData,
  selectChangeType,
  selectGraphType,
} from '../../actions/performanceActions';

import Performance from './Performance';

const mapStateToProps = (state) => ({
  platform: state.auth.platform,
  ...state.performance,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  _getGraphData: getGraphData,
  _selectChangeType: selectChangeType,
  _selectGraphType: selectGraphType,
}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Performance);
