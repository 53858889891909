import { connect } from 'react-redux';

import {
  clearResolutionDetail,
  closeResolution,
  fetchResolution,
  handleResolutionDataChange,
  submitResolution,
  submitDepotRepairUpdate,
} from '../../../actions/claims/claimResolutionActions';
import {
  fetchClaimData,
  submitClaimNeedsReviewUpdate,
  submitDataForReview,
} from '../../../actions/claims/claimDetailActions';
import { getClaimsForResolution } from '../../../actions/claims/claimsListActions';

import ClaimResolution from './Resolution';

const mapStateToProps = (state) => ({
  ...state.claimResolution,
  shopId: state.auth.shopId,
  productInformation: { ...state.claimDetail.product },
  claim: state.claimDetail.claim,
  reviewData: state.claimDetail.reviewData,
});

const mapDispatchToProps = (dispatch) => ({
  _clearResolutionDetail: () => {
    dispatch(clearResolutionDetail());
  },
  _closeResolution: (claimId, resolution) => {
    dispatch(closeResolution(claimId, resolution));
  },
  _fetchClaimData: (claimId) => {
    dispatch(fetchClaimData(claimId));
  },
  _fetchResolution: (claimId) => {
    dispatch(fetchResolution(claimId));
  },
  _handleResolutionDataChange: (name, value) => {
    dispatch(handleResolutionDataChange(name, value));
  },
  _submitResolution: (claimId, resolution) => {
    dispatch(submitResolution(claimId, resolution));
  },
  _getClaimsForResolution: (shopId) => {
    dispatch(getClaimsForResolution(shopId));
  },
  _submitDepotRepairUpdate: (claimId, updateInformation) => {
    dispatch(submitDepotRepairUpdate(claimId, updateInformation));
  },
  _submitClaimNeedsReviewUpdate: (claimId) => {
    dispatch(submitClaimNeedsReviewUpdate(claimId));
  },
  _submitDataForReview: (claimId, data) => {
    dispatch(submitDataForReview(claimId, data));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ClaimResolution);
