import React from 'react';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { LoadingDots } from '@joinclyde/clyde-react-components';

class LoginProcessing extends React.Component {
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.loginProcessing === true
      && nextProps.loginProcessing === false
      && nextProps.loginFailed === false) {
      window.location = '/products';
    } else if (this.props.loginProcessing === true
      && nextProps.loginProcessing === false
      && nextProps.loginFailed === true) {
      if (this.props.firstTimeUser) {
        this.props.history.goBack();// May not need all the rigamorole above and can just use this.
      } else {
        this.props.history.push('/login');
      }
    }
  }

  render() {
    return (
      <div className='gradient'>
        <LoadingDots dotColor='clydeBlack' dotSize='large' />
      </div>
    );
  }
}

const mapStateToProps = (state) => state.auth;

export default withRouter(connect(mapStateToProps)(LoginProcessing));
