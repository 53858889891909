import React, { Component } from 'react';
import classnames from 'classnames';
import { isValidPhoneNumber } from 'libphonenumber-js';
import {
  ClydeTextInput,
  ClydeButton,
  ClydeSelect,
  ClydeTooltip,
} from '@joinclyde/clyde-react-components';
import { Currency } from '../../Utils/Currency';

import { stateCodes } from '../../Utils/regionCodes';
import { decorateClassComponent } from '../../../providers/LocaleProvider';
import PhoneInput from '../../Utils/PhoneInput';

class EnterCustomer extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      customer: {
        email: '',
        firstName: '',
        lastName: '',
        phone: '',
        address1: '',
        address2: '',
        province: '',
        city: '',
        zip: '',
        serialNumber: '',
        country: context.country,
      },
      quantity: 1,
      customerCost: this.props.contract.Cost.toFixed(2),
      country: context.country,
      formErrors: {},
      missingFields: {},
      formValid: false,
      formChecked: false,
    };
    this.stateCodes = stateCodes[context.country.toLowerCase()];
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (
      nextProps.contract.contractId
      && this.props.contract.contractId !== nextProps.contract.contractId
    ) {
      this.setState({
        customerCost: this.props.contract.Cost.toFixed(2),
      });
    }
  }

  checkAgree = () => {
    this.setState({ formChecked: !this.state.formChecked });
  }

  handleQuantityChange = (e) => {
    const useQuantity = Math.round(Math.abs(e.target.value));
    this.setState({ quantity: useQuantity }, () => { this.validateField('quantity', useQuantity); });
  }

  handleCostChange = (e) => {
    const { name } = e.target;
    const { value } = e.target;

    this.setState({ [name]: value }, () => { this.validateField(name, value); });
  }

  fixCost = (e) => {
    const { name } = e.target;
    const { value } = e.target;

    parseFloat(value)
      ? this.setState({ [name]: parseFloat(value).toFixed(2) })
      : this.setState({
        [name]: (
          this.props.contract.Markup
          + this.props.contract.BackendFee
          + this.props.contract.PlatformFee
          + this.props.contract.BaseCost
        ).toFixed(2),
      });
  }

  handleChange = (name, value) => {
    this.setState({
      customer: {
        ...this.state.customer,
        [name]: value,
      },
    }, () => {
      this.validateField(name, value);
    });
  }

  bindChangeHandler = (name) => (e) => {
    if (typeof e === 'string') {
      this.handleChange(name, e);
    } else {
      this.handleChange(name, e.target.value);
    }
  };

  handleProvinceChange = (option) => {
    const useValue = option ? option.target.value : '';
    this.setState({ customer: { ...this.state.customer, province: useValue }}, () => { this.validateField('province', useValue); });
  }

  checkLicense = (state) => {
    const licenseInfo = this.props.licensingInfo;
    const today = new Date().setHours(0, 0, 0, 0);
    const issuedDate = new Date(licenseInfo[`${state.toLowerCase()}DealerIdIssuedDate`]).setHours(0, 0, 0, 0);
    if (state === 'CA') {
      const expirationDate = new Date(licenseInfo.caDealerIDExpirationDate).setHours(0, 0, 0, 0);
      return licenseInfo.caDealerId && today >= issuedDate && expirationDate > today && !!issuedDate;
    }
    return licenseInfo.flDealerId && today >= issuedDate && issuedDate;
  }

  validateField = (name, value) => {
    const validationErrors = this.state.formErrors;
    switch (name) {
      case 'email':
        value === '' || value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)
          ? delete validationErrors[name]
          : validationErrors[name] = 'Invalid email';
        break;
      case 'phone':
        if (isValidPhoneNumber(value, this.context.country)) {
          delete validationErrors[name];
        } else {
          validationErrors[name] = 'Invalid phone number format.';
        }
        break;
      case 'zip':
        value === '' || value.match(/^\d{5}/i)
          ? delete validationErrors[name]
          : validationErrors[name] = 'Invalid zip code.';
        break;
      case 'quantity':
        parseInt(value, 10) ? delete validationErrors[name] : validationErrors[name] = 'Bad quantity';
        break;
      case 'customerCost':
        parseFloat(value, 10) >= Math.round(100 * (
          this.props.contract.BackendFee
          + this.props.contract.PlatformFee
          + this.props.contract.BaseCost
        )) / 100
          ? delete validationErrors[name]
          : validationErrors[name] = 'Invalid customer cost.';
        break;
      case 'country':
        value === 'US'
          ? delete validationErrors[name]
          : validationErrors[name] = 'Invalid address - country';
        break;
      case 'province':
        if (this.props.contract.Underwriter === 'Asurion' && (value === 'CA' || value === 'FL')) {
          const validLicense = this.checkLicense(value);
          validLicense
            ? delete validationErrors[name]
            : validationErrors[name] = 'Invalid address - missing license';
        } else {
          delete validationErrors[name];
        }
        break;
      default:
        break;
    }
    this.setState({
      formErrors: validationErrors,
      formValid: Object.keys(validationErrors).length === 0,
    });
  }

  fieldsComplete = () => {
    const missingFields = {};
    Object.keys(this.state.customer).forEach((key) => {
      const emptyField = key !== 'phone'
        && key !== 'email'
        && key !== 'serialNumber'
        && key !== 'address2'
        && key !== 'province'
        && this.state.customer[key].trim().length === 0;
      if (emptyField) {
        missingFields[key] = true;
      }
    });

    if (this.state.customer.email.trim().length === 0 && this.state.customer.phone.trim().length === 0) {
      missingFields.phone = true;
      missingFields.email = true;
    }

    if (this.stateCodes.length && this.state.customer.province === '') {
      missingFields.province = true;
    }

    if (this.props.contract.RequireSerialNumber === true) {
      if (!isValidPhoneNumber(this.state.customer.phone, this.context.country)) {
        missingFields.phone = true;
      }
      if (this.state.customer.email.trim().length === 0) {
        missingFields.email = true;
      }
      if (this.state.customer.serialNumber.trim().length === 0) {
        missingFields.serialNumber = true;
      }
    }
    if (Object.keys(missingFields).length > 0) {
      this.setState({ missingFields });
      return false;
    }
    return true;
  }

  handleSubmit = () => {
    this.setState({ missingFields: {}});
    const productId = this.props.variant.ProductID;
    const productPrice = this.props.variant.Price;
    const contractId = this.props.contract.ID;
    const backendFee = this.props.contract.BackendFee;
    const platformFee = this.props.contract.PlatformFee;
    const baseCost = this.props.contract.BaseCost;
    if (this.fieldsComplete() && this.state.formValid === true) {
      // (productId, contractId, backendFee, contractBaseCost, customerInfo, customerCost, quantity) => {
      this.props._submitSale(
        productId,
        contractId,
        productPrice,
        backendFee,
        platformFee,
        baseCost,
        this.state.customer,
        parseFloat(this.state.customerCost, 10), this.state.quantity,
      );
    }
  }

  generateLabelClass = (hasErrors) => classnames(
    'sale-customer-label',
    { 'sale-customer-label--invalid': hasErrors },
  )

  render() {
    const {
      state: {
        quantity,
        customerCost,
        formErrors,
        missingFields,
        customer,
        formChecked,
        submitSaleProcessing,
      },
      props: {
        step,
        contract,
      },
      context: {
        currency,
        country,
      },
      generateLabelClass,
    } = this;

    const errors = formErrors;

    return (
      <div className='row'>
        <div className='sale-modal-step col-12'>{ step }. Enter customer info:</div>
        <div className='col-6'>
          <label className={ generateLabelClass(errors.email || missingFields.email) } htmlFor='customer-email'>Customer email</label>
          <ClydeTextInput
            id='customer-email'
            placeholder='example@joinclyde.com'
            onChange={ this.bindChangeHandler('email') }
            value={ customer.email }
            className='form-field'
          />
        </div>
        <div className='col-6'>
          { contract && contract.RequireSerialNumber === false
            ? (
              <>
                <label className={ generateLabelClass(errors.phone || missingFields.phone) } htmlFor='customer-phone'>Customer Phone</label>
                <PhoneInput value={ customer.phone } onChange={ this.bindChangeHandler('phone') } />
              </>
            )
            : null
          }
        </div>
        <div className='col-6'>
          <label className={ generateLabelClass(errors.firstName || missingFields.firstName) } htmlFor='customer-firstName'>First name</label>
          <ClydeTextInput
            id='customer-firstName'
            onChange={ this.bindChangeHandler('firstName') }
            value={ customer.firstName }
            className='form-field'
          />
        </div>
        <div className='col-6'>
          <label className={ generateLabelClass(errors.lastName || missingFields.lastName) } htmlFor='customer-lastName'>Last name</label>
          <ClydeTextInput
            id='customer-lastName'
            onChange={ this.bindChangeHandler('lastName') }
            value={ customer.lastName }
            className='form-field'
          />
        </div>
        <div className='col-6'>
          <label className={ generateLabelClass(errors.address1 || missingFields.address1) } htmlFor='customer-address1'>Address 1</label>
          <ClydeTextInput
            id='customer-address1'
            onChange={ this.bindChangeHandler('address1') }
            value={ customer.address1 }
            className='form-field'
          />
        </div>
        <div className='col-6'>
          <label className={ generateLabelClass(errors.address2 || missingFields.address2) } htmlFor='customer-address2'>Address 2</label>
          <ClydeTextInput
            id='customer-address2'
            onChange={ this.bindChangeHandler('address2') }
            value={ customer.address2 }
            className='form-field'
          />
        </div>
        <div className='col-6'>
          <label className={ generateLabelClass(errors.city || missingFields.city) } htmlFor='customer-city'>City</label>
          <ClydeTextInput
            id='customer-city'
            onChange={ this.bindChangeHandler('city') }
            value={ customer.city }
            className='form-field'
          />
        </div>
        <div className='col-6'>
          <label className={ generateLabelClass(errors.province || missingFields.province) } htmlFor='customer-province'>State</label>
          { this.stateCodes.length ? (
            <ClydeSelect
              id='customer-province'
              className='clyde-select customer'
              style={ { height: '38px' } }
              value={ customer.province }
              options={ this.stateCodes.map((code) => ({ text: code, value: code })) }
              overflow
              onChange={ this.handleProvinceChange }/>
          ) : 'N/A' }
          {
            errors.province
              ? <span className='sale-customer-field-error'>Invalid address - missing/expired license</span>
              : null
          }
        </div>
        <div className='col-3'>
          <label className={ generateLabelClass(errors.zip || missingFields.zip) } htmlFor='customer-zip'>Zip Code</label>
          <ClydeTextInput
            id='customer-zip'
            maxLength='5'
            placeholder='#####'
            onChange={ this.bindChangeHandler('zip') }
            value={ customer.zip }
            className='form-field'
          />
        </div>
        <div className='col-3'>
          <label className={ generateLabelClass(errors.country || missingFields.country) } htmlFor='customer-country'>Country</label>
          <ClydeTextInput
            id='customer-country'
            value={ `${country} only` }
            className='form-field'
            onChange={ this.bindChangeHandler('country') }
            disabled
          />
          {
            errors.country
              ? <span className='sale-customer-field-error'>Invalid address - country</span>
              : null
          }
        </div>
        { contract && contract.RequireSerialNumber === false
          ? <div className='col-3'>
            <label className={ generateLabelClass(errors.quantity || missingFields.quantity) } htmlFor='contract-quantity'>Contract Quantity</label>
            <ClydeTextInput
              id='contract-quantity'
              name='quantity'
              onChange={ this.handleQuantityChange }
              value={ quantity }
              className='form-field'
              typeNumber
            />
          </div>
          : null
        }
        <div className='col-3 mb-0 sale-modal__customer-cost'>
          <label className={ generateLabelClass(errors.customerCost || missingFields.customerCost) } htmlFor='customer-cost'>
            <span>Cost per contract ({ currency })</span>
            <div>
              <ClydeTooltip
                position='left'
                label= {
                  <div className='tooltip-text'>
                    This value represents how much the customer purchased each contract for.
                    The value here must be greater than or equal to the premium we have to collect for each contract (
                      <Currency amount={ contract.BaseCost + contract.BackendFee + contract.PlatformFee } />
                    ).
                  </div>
                }
              />
            </div>
          </label>
          <input
            id='customer-cost'
            name='customerCost'
            type='number'
            onBlur={ this.fixCost }
            onChange={ this.handleCostChange }
            value={ customerCost }
            className='form-field clyde-text-input'
          />
        </div>
        { contract && contract.RequireSerialNumber === true
          && <span className='row col-12'>
            <div className='sale-modal-step col-12 row '>
              Additional information
              <div className='ml-1'>
                <ClydeTooltip
                  label={
                    <div className='tooltip-text'>This contract requires the customer's phone number and product serial number in order to be fulfilled. If this info isn't available, we'll email the customer prompting them to record it on our website once their product arrives.</div>
                  }
                />
              </div>
            </div>
            <div className='col-6'>
              <label className={ generateLabelClass(missingFields.serialNumber) } htmlFor='customer-serial-number'>Product serial number</label>
              <ClydeTextInput
                id='customer-serial-number'
                onChange={ this.bindChangeHandler('serialNumber') }
                className='form-field'
              />
            </div>
            <div className='col-6'>
              <label className={ generateLabelClass(errors.phone || missingFields.phone) } htmlFor='customer-phone'>Phone</label>
              <PhoneInput value={ customer.phone } onChange={ this.bindChangeHandler('phone') } />
            </div>
          </span>
        }
        <div className='col-12'>
          <div className='col-9 offset-3'>
            <div className='sale-checkbox-container'>
              { formChecked === true
                ? <div onClick={ this.checkAgree } className='sale-checkbox sale-checkbox--checked'><span>&#x2714;</span></div>
                : <div onClick={ this.checkAgree } className='sale-checkbox'></div>
              }
              { contract
                && <div onClick={ this.checkAgree } className='sale-checkbox-text'>
                  <Currency amount={ quantity * customerCost } />
                  &nbsp; has been collected for this sale.
                </div>
              }
            </div>
          </div>
          <div className='col-6 offset-3'>
            <ClydeButton
              id='products-save-button'
              className={ formChecked ? 'finish-sale' : 'finish-sale finish-sale--disabled' }
              onClick={ this.handleSubmit }
              processing={ submitSaleProcessing }
              disabled={ formChecked === false }
              successText='Done'
            >
              Record Sale
            </ClydeButton>
          </div>
        </div>
      </div>
    );
  }
}

decorateClassComponent(EnterCustomer);

export default EnterCustomer;
