import React from 'react';

const Badge = (props) => {
  const { count } = props;

  return (
        <span className='badge'>{ count }</span>
  );
};

export default Badge;
