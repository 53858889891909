import React, { createContext, useContext } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { showError } from '../actions/errorActions';

const ErrorContext = createContext(() => {});

function ErrorProvider({ children, _showError }) {
  return (
    <ErrorContext.Provider value={ _showError }>
      { children }
    </ErrorContext.Provider>
  );
}

const mapDispatchToProps = (dispatch) => bindActionCreators({ _showError: showError }, dispatch);

export default connect(null, mapDispatchToProps)(ErrorProvider);

export const useErrors = () => useContext(ErrorContext);
