import React, { Component } from 'react';
import { ClydeRadio, ClydeTextInput, ClydeTooltip } from '@joinclyde/clyde-react-components';
import classnames from 'classnames';

import OnboardingDirections from '../OnboardingDirections';
import ContactForm from '../../Settings/Shared/ContactForm';
import AddressForm from '../../Settings/Shared/AddressForm';

import { languages } from '../../../lib/countriesAndCurrencies';

class Profile extends Component {
  state = { instrStep: 1 }

  UNSAFE_componentWillMount() {
    this.props._getShopInfo();
  }

  componentWillUnmount() {
    if (this.timeout) {
      clearTimeout(this.timeout);
    }
  }

  componentDidMount() {
    this.timeout = setTimeout(() => {
      this.setState({ instrStep: 2 });
    }, 1000);
  }

  nextInstr = () => {
    this.setState({ instrStep: this.state.instrStep + 1 });
  }

  handleChange = (event) => {
    const { name, value } = event.target;
    const numeric = ['phoneNumber', 'zip', 'oemWarrantyLength'].includes(name);

    if (name === 'warrantyDataAccessible') {
      this.props._setBoolean(name, value);
      return;
    }

    this.props._setText(name, value, numeric);
  }

  handleContactChange = (type) => (event) => {
    const { name, value } = event.target;
    const numeric = name === 'phoneNumber';
    this.props._setContactText({
      name,
      value,
      numeric,
      index: type === 'claims' ? 2 : 0,
    });
  }

  render() {
    const { contactInfo, profile, platform } = this.props;

    return (
      <div id='onboarding-basic-info-component'>
        <div className='page-container'>
          <div className='page-layout'>
            <OnboardingDirections pageKey='store-info' />
            <div className='title-wrapper'>
              <h2 className='title-wrapper__prefixed-subtitle'>Update this information if anything about your store changes.</h2>
              <h1>Basic information</h1>
              <br/>
            </div>
            <div className='page-layout__content'>
              <div className='row'>
                <div className='col-12 col-lg-6 col-md-6 col-sm-6 col-xs-12'>
                  <label
                    className={ classnames('profile-label',
                      { 'profile-label--invalid': profile.formErrors.shopName }) }
                    htmlFor='store-name'>Shop Name</label>
                  {
                    platform !== 'Clyde'
                      ? <ClydeTextInput
                        name='shopName'
                        data-test='shopName'
                        value={ profile.shopName }
                        id='store-name'
                        className='form-field'
                        disabled='disabled'
                        maxLength='100'
                      />
                      : <ClydeTextInput
                        onChange={ this.handleChange }
                        name='shopName'
                        data-test='shopName'
                        value={ profile.shopName }
                        id='store-name'
                        className='form-field'
                        maxLength='100'
                      />
                  }
                </div>
                <div className='col-12 col-lg-6 col-md-6 col-sm-6 col-xs-12'>
                  <label
                    className={ classnames('profile-label',
                      { 'profile-label--invalid': profile.formErrors.domain }) }
                    htmlFor='domain'>Shop URL</label>
                  {
                    platform !== 'Clyde'
                      ? <ClydeTextInput
                        name='domain'
                        value={ profile.domain }
                        id='domain'
                        className='form-field'
                        maxLength='100'
                        disabled
                      />
                      : <ClydeTextInput
                        onChange={ this.handleChange }
                        name='domain'
                        value={ profile.domain }
                        id='domain'
                        className='form-field'
                        maxLength='100'
                      />
                  }
                </div>
                <div className='col-12 col-lg-6 col-md-6 col-sm-6 col-xs-12'>
                  <label className='profile-label' htmlFor='language'>
                    Shop Language
                  </label>
                  <select
                    className='form-field form-field--white form-field--pointer'
                    data-test='shopLanguage'
                    id='language'
                    name='language'
                    onChange={ this.handleChange }
                    value={ profile.language }
                  >
                    { languages.map(({ name, language }) => (
                      <option key={ language } value={ language }>{ name }</option>
                    )) }
                  </select>
                </div>
              </div>
              <ContactForm
                contactInfo={ profile }
                formErrors={ profile.formErrors }
                handleChange={ this.handleChange }
                tipStep={ this.state.instrStep }
                updateStep={ this.nextInstr }
              />
              <ContactForm
                contactInfo={ { ...contactInfo.contactList[0], type: 'Technical Contact (optional)' } }
                formErrors={ contactInfo.formErrors[0] }
                handleChange={ this.handleContactChange('technical') }
              />
              <div className='row col-sm-3 subtitle--claims-contact'>
                <ClydeTooltip
                  position='right'
                  label={
                    <div className='tooltip-text tooltip-text--right'>
                      Clyde receives many claims for issues covered by the limited warranty.
                      When this happens, we seamlessly hand them off to your support team to resolve.
                      We'll need a point-of-contact to route these claims to.
                    </div>
                  }
                  hideIndicator>
                    <h5
                      className='settings-tile__content__subtitle '
                      data-tip
                      data-for='claims-contact-tooltip' >
                      Claims Contact (optional)
                    </h5>
                </ClydeTooltip>
                </div>
              <ContactForm
                contactInfo={ { ...contactInfo.contactList[2], type: '' } }
                formErrors={ contactInfo.formErrors[2] }
                handleChange={ this.handleContactChange('claims') }
                className='profile__claims-contact'
              />
              <AddressForm
                contactInfo={ profile }
                formErrors={ profile.formErrors }
                handleChange={ this.handleChange }
              />

              <div className='row profile__last-row' data-test='radioGroup'>
                <div className='col-12 col-lg-8 col-md-8 col-sm-6 col-xs-12'>
                  <div className='row col-5 mt-4'>
                  <ClydeTooltip
                    label={
                      <div className='tooltip-text'>
                        Clyde receives many claims for issues covered by the limited warranty.
                        We may ask you to provide your store's limited warranty information
                        so that we can seamlessly direct your customers back to your support
                        team in order to resolve these types of claims.
                      </div>
                    }
                    hideIndicator>
                    <h5 className='settings-tile__content__subtitle subtitle--no-padding mt-0'>
                      <span data-tip data-for='warranty-info-tooltip'>Warranty Information</span>
                    </h5>
                  </ClydeTooltip>
                  </div>
                  <label className='profile-label'>
                    Which best describes your store's catalog?
                  </label>
                  <div className='profile__warranty-info__radio-group' data-test='radioItem-'>
                    <ClydeRadio
                      checked={ profile.warrantyType === 'catalog' }
                      id='type__catalog'
                      name='warrantyType'
                      value='catalog'
                      onChange={ this.handleChange }
                    />
                    <label htmlFor='type__catalog'>Single limited warranty term for the entire catalog</label>
                  </div>
                  <div className='profile__warranty-info__radio-group' data-test='radioItem-'>
                    <ClydeRadio
                      checked={ profile.warrantyType === 'product' }
                      id='type__product'
                      name='warrantyType'
                      value='product'
                      onChange={ this.handleChange }
                    />
                    <label htmlFor='type__product'>Different limited warranty terms for different products</label>
                  </div>
                  <div className='profile__warranty-info__radio-group' data-test='radioItem-'>
                    <ClydeRadio
                      checked={ profile.warrantyType === 'component' }
                      id='type__component'
                      name='warrantyType'
                      value='component'
                      onChange={ this.handleChange }
                    />
                    <label htmlFor='type__component'>Multiple terms for different components of products</label>
                  </div>
                  <div className='profile__warranty-info__radio-group' data-test='radioItem-'>
                    <ClydeRadio
                      checked={ profile.warrantyType === 'none' }
                      id='type__none'
                      name='warrantyType'
                      value='none'
                      onChange={ this.handleChange }
                    />
                    <label htmlFor='type__none'>No limited warranty offered</label>
                  </div>
                </div>
                <div className='col-12 col-lg-4 col-md-4 col-sm-6 col-xs-12'>
                  { profile.warrantyType === 'catalog'
                    && <>
                      <label
                        className={ classnames('profile-label', 'profile-label--oem-length',
                          { 'profile-label--invalid': profile.formErrors.oemWarrantyLength }) }
                      >
                        Warranty Length (in months)
                      </label>
                      <ClydeTextInput
                        onChange={ this.handleChange }
                        name='oemWarrantyLength'
                        dataTestValue='warrantyLength'
                        value={ profile.oemWarrantyLength }
                        id='oemWarrantyLength'
                        className='form-field'
                        typeNumber
                      />
                    </>
                  }
                  { (profile.warrantyType === 'product' || profile.warrantyType === 'component')
                    && <>
                      <label className='profile-label profile-label--oem-length'>
                        Is limited warranty data accessible in the product catalog,
                        by manufacturer, or in any other form?
                      </label>
                      <div className='profile__warranty-info__radio-group' data-test='radioItem-'>
                        <ClydeRadio
                          checked={ profile.warrantyDataAccessible }
                          id='accessible__true'
                          name='warrantyDataAccessible'
                          value={ 'true' }
                          onChange={ this.handleChange }
                        />
                        <label htmlFor='accessible__true'>Yes</label>
                      </div>
                      <div className='profile__warranty-info__radio-group' data-test='radioItem-'>
                        <ClydeRadio
                          checked={ !profile.warrantyDataAccessible }
                          id='accessible__false'
                          name='warrantyDataAccessible'
                          value={ 'false' }
                          onChange={ this.handleChange }
                        />
                        <label htmlFor='accessible__false'>No</label>
                      </div>
                    </>
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Profile;
