import React from 'react';

const SmallButton = (props) => {
  const {
    customClass, id, isOpen, onClick,
  } = props;

  let buttonClassName = 'button-nostyle cop-button-small';
  let arrowClassName = 'cop-button-small__arrow';
  if (isOpen) {
    buttonClassName += ' cop-button-small--open';
    arrowClassName += ' cop-button-small__arrow--open';
  } else {
    buttonClassName += ' cop-button-small--closed';
    arrowClassName += ' cop-button-small__arrow--closed';
  }
  buttonClassName += customClass ? ` ${customClass}` : '';

  return (
    <button
      type='button'
      id={ id }
      className={ buttonClassName }
      onClick={ onClick }
      onKeyDown={ (e) => e.stopPropagation() }>
      <div className={ arrowClassName } />
    </button>
  );
};

export default SmallButton;
