const isIntegrationConnected = (integration) => {
  // Check if Frigg-controlled integration is connected
  if (integration.friggIntegration) {
    const { integrationOptions } = integration;
    if (integrationOptions && integrationOptions.status === 'ENABLED') {
      return true;
    }

    return false;
  }

  // Check if Clyde-controlled integration is connected
  if (integration.id === 'affirm' && integration.clydeOptions.showAffirm) {
    return true;
  }
  if (integration.id === 'klaviyo' && integration.clydeOptions.klaviyoIntegration) {
    return true;
  }

  // Current integration is NOT connected
  return false;
};

export default isIntegrationConnected;
