import { wsSend } from './websocketActions';

export const getShopPreferences = () => (dispatch) => {
  dispatch(wsSend({
    type: 'GET_SHOP_PREFERENCES_REQ',
    payload: {},
  }));
  dispatch({
    type: 'GET_SHOP_PREFERENCES_PROCESSING',
    payload: {},
  });
};

export const setShopPreferences = (preferences) => (dispatch) => {
  dispatch(wsSend({
    type: 'SET_SHOP_PREFERENCES_REQ',
    payload: {
      preferences,
    },
  }));
};
