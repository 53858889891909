import React from 'react';
import Img from 'react-image';
import { ClydeCheckbox } from '@joinclyde/clyde-react-components';
import { Currency } from '../../../../Utils/Currency';

const CatalogueItem = (props) => {
  const {
    cartproductIds, product, idx, updateCart,
  } = props;

  return (
    <div className='platform-catalogue-item-wrapper'>
      <div className='catalogue-item'>
        <Img
          className='catalogue-item-img'
          alt={ product.name }
          src={ [product.imageLink, '/images/default-image.svg'] } />
        <div className='catalogue-item-info'>
          <span className='catalogue-item-info__name'>{ product.name }</span>
        </div>
      </div>

      <ul className='catalogue-item-variants'>
        {
          product.variants.map((variant, vdx) => (
            <li key={ `variant-${vdx}` }>
              <div className='catalogue-item__left'>
                <ClydeCheckbox
                  backgroundColor='clydeBlack'
                  id={ `cat_item_checkbox_${idx}` }
                  name='premiumCollected'
                  className='catalogue-item__check'
                  checked={ cartproductIds.indexOf(variant.productId) > -1 }
                  onChange={ (e) => updateCart(e, variant) }
                />
                <div className='catalogue-item-info'>
                  <span className='catalogue-item-info__name'>
                    { variant.variantName === 'Default Title' ? 'Default Variant' : variant.variantName }
                  </span>
                </div>
              </div>
              <div className='catalogue-item__right'>
                <span className='catalogue-item-info__price'><Currency amount={ variant.price } /></span>
              </div>
            </li>
          ))
        }
      </ul>
    </div>
  );
};

export default CatalogueItem;
