import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import BillingSettingsTile from './index';
import BillingInstructionModal from './BillingInstructionModal';
import OnboardingDirections from '../../../Onboarding/OnboardingDirections';
import { completeBillingStep } from '../../../../actions/billingActions';
import { nextStep } from '../../../../actions/onboardingActions';

const BillingConnect = ({
  onboarding,
  instructionsDismissed,
  _completeBillingStep,
  _nextStep,
}) => (
    <div id='setup-billing-component'>
      <div className='page-container'>
        <div className='page-layout'>
          <OnboardingDirections pageKey='bank-connect'/>
          <div className='title-wrapper'>
            <h2 className='title-wrapper__prefixed-subtitle'>Set a default account for collecting contract premiums.</h2>
            <h1>Set up your Billing</h1>
            <br/>
          </div>
          <BillingSettingsTile onComplete={ () => { _completeBillingStep(); _nextStep(); } } />
        </div>
      </div>
      { onboarding && !instructionsDismissed
        && <BillingInstructionModal />
      }
    </div>
);

const mapStateToProps = (state) => ({
  ...state.billing,
  onboarding: state.onboarding.isOnboarding,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  _completeBillingStep: completeBillingStep,
  _nextStep: nextStep,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(BillingConnect);
