import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import {
  cancelResendSaleEmail,
  confirmResendSaleEmail,
  updateContactResendSaleEmail,
} from '../../../../actions/customersActions';

import ResendSaleEmailModal from './ResendSaleEmail';

const mapStateToProps = (state) => ({
  order: state.customers.selectedOrder,
  resendSaleEmail: state.customers.resendSaleEmail,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  _cancelResendSaleEmail: cancelResendSaleEmail,
  _confirmResendSaleEmail: confirmResendSaleEmail,
  _updateContactResendSaleEmail: updateContactResendSaleEmail,
}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ResendSaleEmailModal);
