import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import {
  getOrderInfo,
  cancelResend,
  confirmResendInfo,
  exitResend,
} from '../../../actions/salesActions';

import ResendSaleEmailModal from './ResendSaleEmailModal';

function mapStateToProps(state) {
  return {
    orderInfo: state.sales.orderInfo,
    resendStep: state.sales.resendStep,
    processingGetOrderInfo: state.sales.processingGetOrderInfo,
    processingResend: state.sales.processingResend,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    _getOrderInfo: getOrderInfo,
    _cancelResend: cancelResend,
    _confirmResendInfo: confirmResendInfo,
    _exitResend: exitResend,
  }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(ResendSaleEmailModal);
