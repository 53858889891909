import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';

import { finishOnboarding } from '../../actions/onboardingActions';

import TermsAndConditions from './TermsAndConditions';

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    finishOnboarding,
  }, dispatch);
}

export default withRouter(connect(null, mapDispatchToProps)(TermsAndConditions));
