import { wsSend } from './websocketActions';

export const getShopInfo = (userId) => (dispatch) => {
  dispatch({
    type: 'GET_SHOP_INTEGRATIONS_INFO',
    payload: {},
  });
  dispatch(wsSend({
    type: 'GET_SHOP_INTEGRATIONS_INFO_REQ',
    payload: { userId },
  }));
};

export const filterIntegrations = (filterValue) => ({
  type: 'INTEGRATIONS_TAB_FILTER_INTEGRATIONS',
  filterValue,
});

export const searchIntegrations = (searchString) => ({
  type: 'INTEGRATIONS_TAB_SEARCH_INTEGRATIONS',
  searchString,
});

export const resetIntegrationsTab = () => ({
  type: 'INTEGRATIONS_TAB_RESET_SETTINGS',
});

export const openSetupModal = (selectedIntegration) => ({
  type: 'INTEGRATIONS_SETUP_MODAL_OPEN',
  selectedIntegration,
});

export const closeSetupModal = () => ({
  type: 'INTEGRATIONS_SETUP_MODAL_CLOSE',
});

export const resetSetupModal = () => ({
  type: 'INTEGRATIONS_SETUP_MODAL_RESET',
});

export const updateSetupModalClydeControlledIntegrationValue = (key, value) => ({
  type: 'INTEGRATIONS_SETUP_MODAL_UPDATE_CLYDE_CONTROLLED_INTEGRATION_VALUE',
  key,
  value,
});

export const updateSetupModalFriggControlledIntegrationValue = (key, value) => ({
  type: 'INTEGRATIONS_SETUP_MODAL_UPDATE_FRIGG_CONTROLLED_INTEGRATION_VALUE',
  key,
  value,
});

export const getFriggAuthRequirements = (userId, integrationType) => (dispatch) => {
  dispatch({
    type: 'INTEGRATIONS_SETUP_MODAL_PROCESSING',
    payload: {},
  });
  dispatch(wsSend({
    type: 'INTEGRATIONS_GET_FRIGG_AUTH_REQUIREMENTS_REQ',
    payload: { userId, integrationType },
  }));
};

export const connectClydeControlledIntegration = (integration, data) => (dispatch) => {
  let wsType = '';
  switch (integration) {
    case 'affirm':
      wsType = 'INTEGRATIONS_CONNECT_AFFIRM_REQ';
      break;
    case 'klaviyo':
      wsType = 'INTEGRATIONS_CONNECT_KLAVIYO_REQ';
      break;
    default:
      wsType = 'INTEGRATIONS_CONNECT_INVALID_REQ'; // Will throw error on BE
      break;
  }

  dispatch(setupModalConnectAccount);
  dispatch(wsSend({
    type: wsType,
    payload: data,
  }));
};

export const deleteFriggControlledIntegration = (userId, integrationId, integrationType) => (dispatch) => {
  dispatch(wsSend({
    type: 'INTEGRATIONS_DISCONNECT_FRIGG_INTEGRATION_REQ',
    payload: { integrationId, userId, integrationType },
  }));
};

const setupModalConnectAccount = {
  type: 'INTEGRATIONS_SETUP_MODAL_CONNECT_ACCOUNT',
};

export const handleOAuthRedirectData = (userId, integrationType, oAuthData) => (dispatch) => {
  dispatch({
    type: 'INTEGRATIONS_CREATE_FRIGG_O_AUTH_INTEGRATION',
    payload: oAuthData,
  });
  dispatch(wsSend({
    type: 'INTEGRATIONS_CREATE_FRIGG_O_AUTH_INTEGRATION_REQ',
    payload: {
      integrationType,
      oAuthData,
      userId,
    },
  }));
};

export const closeOAuthRedirectModal = () => ({
  type: 'INTEGRATIONS_O_AUTH_REDIRECT_MODAL_CLOSE',
});

export const setFriggOAuthData = (userId, data, name) => (dispatch) => {
  dispatch({
    type: 'INTEGRATIONS_SET_FRIGG_OAUTH_DATA_PROCESSING',
  });
  dispatch(wsSend({
    type: 'INTEGRATIONS_SET_FRIGG_OAUTH_DATA_REQ',
    payload: {
      userId,
      data,
      name,
    },
  }));
};
export default {};
