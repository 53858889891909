const defaultState = {
  products: [],
  productTypes: [], // [{ name: "Bike" }, ...]
  filterTypes: [],
  productTags: [], // [{ id: "...", name: "Steel" }, ...]
  filterTags: [],
  productManufacturers: [],
  filterManufacturers: [],
  productSearch: '',

  pageNumber: 1,
  productCount: 0,

  refresh: false,
  preservePage: true,

  openUploadModal: false,
  importStep: 'start',
  importProductsProcessing: false,
  importSummary: {
    insert: [],
    skuDuplicates: [],
    ignore: [],
    ignoreCount: 0,
    insertCount: 0,
    updateCount: 0,
  },
  importFileKey: '',
  importFileRowCount: 0,
  importResult: {
    inserted: [],
    updated: [],
    ignored: [],
    ignoreCount: 0,
    insertCount: 0,
    updateCount: 0,
  },

  openProductModal: false,
  saveProductProcessing: false,
  editProduct: null,
  productType: null,

  skuExport: null,
  skuExportProcessing: false,

  getFiltersProcessing: false,
  getProductsProcessing: false,
  saveProcessing: false,
  launchProcessing: false,
  launched: true,
  matchingComplete: false,
  launchInstrSeen: false,
  needLaunch: false,

  excelExport: null,
  download: false,

  showSaveButton: false,
  registrableProductChange: false,
};

const productsReducer = (state = defaultState, action) => {
  const { payload } = action;
  switch (action.type) {
    case 'SET_SEARCH':
      return {
        ...state,
        productSearch: payload,
      };
    case 'SET_PAGE_NUMBER':
      return {
        ...state,
        pageNumber: payload.pageNumber,
        refresh: true,
        preservePage: true,
        registrableProductChange: false,
      };
    case 'GET_FILTERS_PROCESSING':
      return {
        ...state,
        getFiltersProcessing: true,
      };
    case 'GET_PRODUCTS_PROCESSING':
      return {
        ...state,
        getProductsProcessing: true,
      };
    case 'GET_PRODUCTS_RES':
      return {
        ...state,
        products: payload.products,
        launched: payload.launched,
        matchingComplete: payload.matchingComplete,
        getProductsProcessing: false,
        pageNumber: payload.pageNumber,
        needLaunch: payload.needLaunch,
        refresh: false,
        preservePage: true,
        registrableProductChange: false,
      };
    case 'SEND_PRODUCT_COUNT':
      return {
        ...state,
        productCount: parseInt(payload.productCount, 10),
      };
    case 'GET_FILTERS_RES':
      return {
        ...state,
        productTags: payload.tags,
        productTypes: payload.types,
        productManufacturers: payload.manufacturers,
        getFiltersProcessing: false,
      };
    case 'SET_FILTER_TAGS':
      return {
        ...state,
        filterTags: payload.tags,
        refresh: true,
        preservePage: false,
        registrableProductChange: false,
      };
    case 'SET_FILTER_TYPES':
      return {
        ...state,
        filterTypes: payload.types,
        refresh: true,
        preservePage: false,
        registrableProductChange: false,
      };
    case 'SET_FILTER_MANUFACTURERS':
      return {
        ...state,
        filterManufacturers: payload.manufacturers,
        refresh: true,
        preservePage: false,
        registrableProductChange: false,
      };
    case 'OPEN_NEW_PRODUCT_MODAL':
      return {
        ...state,
        openProductModal: true,
        productType: 'new',
        editProduct: null,
      };
    case 'OPEN_EDIT_PRODUCT_MODAL':
      return {
        ...state,
        openProductModal: true,
        productType: 'edit',
        editProduct: payload.product,
      };
    case 'SAVE_PRODUCT_PROCESSING':
      return { ...state, saveProductProcessing: true };
    case 'SAVE_PRODUCT_RES':
      return {
        ...state,
        refresh: true,
        openProductModal: false,
        productType: null,
        editProduct: null,
        saveProductProcessing: false,
      };
    case 'REGISTRABLE_PRODUCT_RES':
      return {
        ...state,
        refresh: true,
        registrableProductChange: true,
      };
    case 'UPDATE_PLATFORM_PRODUCT_RES':
      return {
        ...state,
        refresh: true,
        openProductModal: false,
        productType: null,
        editProduct: null,
        saveProductProcessing: false,
      };
    case 'CLOSE_PRODUCT_MODAL':
      return {
        ...state,
        openProductModal: false,
        productType: null,
        editProduct: null,
        productError: null,
      };
    case 'OPEN_UPLOAD_MODAL':
      return { ...state, openUploadModal: true };
    case 'IMPORT_SUMMARY_RES':
      return {
        ...state,
        importProductsProcessing: false,
        importSummary: payload.importSummary,
        importFileKey: payload.importFileKey,
        importFileRowCount: payload.rowCount,
        importStep: 'confirm',
      };
    case 'IMPORT_PRODUCTS_PROCESSING':
      return {
        ...state,
        importProgressPercent: 0,
        importProductsProcessing: true,
      };
    case 'IMPORT_PRODUCTS_RES':
      return {
        ...state,
        importProductsProcessing: false,
        importResult: payload.importResult,
        importFileKey: '',
        importFileRowCount: 0,
        refresh: true,
        importStep: 'finish',
      };
    case 'CLOSE_UPLOAD_MODAL':
      return {
        ...state,
        openUploadModal: false,
        importProductsProcessing: false,
        importFileKey: '',
        importFileRowCount: 0,
        importSummary: defaultState.importSummary,
        importResult: defaultState.importResult,
        importStep: 'start',
      };
    case 'GET_SKU_EXPORT_PROCESSING':
      return { ...state, skuExportProcessing: true };
    case 'GET_SKU_EXPORT_RES':
      return { ...state, skuExport: payload, skuExportProcessing: false };
    case 'RESET_EXPORT':
      return { ...state, skuExport: null };
    case 'PRODUCTS_LAUNCH_PROCESSING':
      return { ...state, launchProcessing: true };
    case 'LAUNCH_SHOP_PRODUCTS_RES':
      return {
        ...state,
        launchProcessing: false,
        launched: payload.launched,
        needLaunch: false,
        openLaunchModal: false,
        showSaveButton: false,
      };
    case 'GET_LAUNCHING_STATUS_RES':
      return { ...state, launchProcessing: payload };
    case 'LAUNCH_STARTED':
      return { ...state, launchProcessing: true };
    case 'LAUNCH_FINISHED':
      return {
        ...state,
        launchProcessing: false,
        launched: true,
        needLaunch: false,
      };
    case 'TOGGLE_PRODUCT_ACTIVE_RES':
      return {
        ...state,
        products: [
          ...state.products.slice(0, payload.productIndex),
          {
            ...state.products[payload.productIndex],
            Active: payload.active,
            Variants: state.products[payload.productIndex].Variants.map((variant) => (
              { ...variant, Active: payload.active }
            )),
          },
          ...state.products.slice(payload.productIndex + 1),
        ],
        needLaunch: payload.needLaunch || state.needLaunch,
        showSaveButton: payload.needLaunch || state.needLaunch,
      };
    case 'TOGGLE_PRODUCT_VARIANT_ACTIVE_RES':
      const returnVariants = [
        ...state.products[payload.productIndex].Variants.slice(0, payload.variantIndex),
        {
          ...state.products[payload.productIndex].Variants[payload.variantIndex],
          Active: payload.active,
        },
        ...state.products[payload.productIndex].Variants.slice(payload.variantIndex + 1),
      ];
      return {
        ...state,
        products: [
          ...state.products.slice(0, payload.productIndex),
          {
            ...state.products[payload.productIndex],
            Variants: returnVariants,
          },
          ...state.products.slice(payload.productIndex + 1),
        ],
        needLaunch: payload.needLaunch || state.needLaunch,
        showSaveButton: payload.needLaunch || state.needLaunch,
      };
    case 'DISMISS_LAUNCH_INSTR':
      return { ...state, launchInstrSeen: true };
    case 'GET_RATE_SHEET_EXPORT_RES':
      return { ...state, ...payload, download: true };
    case 'TOGGLE_DOWNLOAD_FALSE':
      return { ...state, download: false };
    case 'LOGOUT':
      return { ...state, ...defaultState };
    case 'ERROR':
      switch (payload.reqType) {
        case 'GET_PRODUCTS_REQ':
          return { ...state, getProductsProcessing: false };
        case 'GET_FILTERS_REQ':
          return { ...state, getFiltersProcessing: false };
        case 'UPDATE_PRODUCT_REQ':
        case 'CREATE_PRODUCT_REQ':
          return { ...state, saveProductProcessing: false };
        case 'IMPORT_SUMMARY_REQ':
          return {
            ...state,
            importProductsProcessing: false,
            importStep: 'start',
          };
        case 'IMPORT_PRODUCTS_REQ':
          return {
            ...state,
            importProductsProcessing: false,
            importStep: 'start',
          };
        case 'GET_SKU_EXPORT_REQ':
          return { ...state, skuExportProcessing: false };
        case 'LAUNCH_SHOP_PRODUCTS_REQ':
          if (payload.name === 'NoConcurrentLaunch') {
            return state;
          }
          return { ...state, launchProcessing: false };

        default:
          return state;
      }
    default:
      return state;
  }
};

export default productsReducer;
