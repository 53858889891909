import React from 'react';
import { ClydeTextInput } from '@joinclyde/clyde-react-components';

import SharedHeader from '../../SharedHeader';
import SharedButtons from '../../SharedButtons';

const KlaviyoSetup = (props) => {
  const {
    integration: {
      logo: integrationLogo,
      name: integrationName,
      clydeOptions: {
        klaviyoIntegration,
        klaviyoPublicKey,
      },
    },
    _onSubmit,
    _onTextChange,
  } = props;

  const handleSubmit = (e) => {
    e.preventDefault();
    _onSubmit('klaviyo', {
      klaviyoPublicKey,
    });
  };

  const handleTextChange = (e) => {
    const { name, value } = e.target;
    _onTextChange(name, value);
  };

  return (
    <form className='integrations-setup-modal-form' onSubmit={ handleSubmit }>
      <SharedHeader
        headerType={ klaviyoIntegration ? 'connect' : 'alert' }
        logo={ integrationLogo }
        name={ integrationName } />

      {
        klaviyoIntegration
          ? <>
              <p className='integrations-setup-modal-form__info'>
                Input your information below to complete your integration
              </p>

              <div className='integrations-setup-modal-form__inputs'>
                <div className='integrations-setup-modal-form__inputs__input-group'>
                  <label htmlFor='klaviyoPublicKey'>Public Key</label>
                  <ClydeTextInput
                    id='klaviyoPublicKey'
                    name='klaviyoPublicKey'
                    onChange={ handleTextChange }
                    placeholder='Public Key'
                    value={ klaviyoPublicKey } />
                </div>
              </div>
            </>
          : <p className='integrations-setup-modal-form__info'>
              The Klaviyo integration is included with Clyde, but has compliance restrictions.
              Please contact <a href='mailto:support@joinclyde.com'>support@joinclyde.com</a>.
            </p>
      }

      <SharedButtons buttonType={ klaviyoIntegration ? 'connect' : 'contact' } />
    </form>
  );
};

export default KlaviyoSetup;
