import React from 'react';
import moment from 'moment';
import { LoadingDots } from '@joinclyde/clyde-react-components';

import {
  claimStatusSwitcher,
  denialReasonSwitcher,
  isDeniedClaim,
} from '../../../Utils/claimHelper';

const ClaimHistoryCard = (props) => {
  const {
    claimHistory,
    denialReason,
    processing,
    serviceType,
    createdAt,
    claimState,
  } = props;
  const lineHeight = 52 * (claimHistory.length - 1);

  return (
    <div className='claim-history-card claims-detail-card--claim-history'>
      <h3>Claim history</h3>

      {
        processing
          ? <LoadingDots className='claims-detail-card__loading' dotColor='clydeBlack' dotSize='medium' />
          : <React.Fragment>
              {
                claimHistory.map((event, idx) => {
                  const useDate = event.createdAt || createdAt;
                  const timestamp = moment(useDate).format('MMMM DD, YYYY – h:mm A');

                  return (
                    <div className='claim-history-card__event' key={ idx }>
                      <div className='claim-history-card__dot' />
                      <div>
                        <span className='claims-detail-card-event__title'>{ timestamp }</span>
                        <span className='claim-history-card__event__info'>
                          { event.claimState ? claimStatusSwitcher(event.claimState, serviceType) : claimState }
                        </span>
                        {
                          isDeniedClaim(event.claimState)
                            && <span className='claims-detail-card-event__title'>{ denialReasonSwitcher(denialReason) }</span>
                        }
                      </div>
                    </div>
                  );
                })
              }

              {
                claimHistory.length > 1
                  && <div className='claim-history-card__line' style={ { height: `${lineHeight}px` } } />
              }
            </React.Fragment>
      }
    </div>
  );
};

export default ClaimHistoryCard;
