import React from 'react';
import { ClydeButton } from '@joinclyde/clyde-react-components';

const ConfirmLaunch = (props) => {
  const saveText = props.launched ? 'Update my store' : 'Launch';
  const successText = props.launched ? 'Updated' : 'Launched';
  const { platform } = props;
  let platformImage;
  switch (platform) {
    case 'Shopify':
      platformImage = '/images/shopify.png';
      break;
    case 'BigCommerce':
      platformImage = '/images/bigCommerce.png';
      break;
    default:
      break;
  }
  return (
    <div className='modal-container'>
      <div className='modal-container__backdrop'></div>
      <div className='clyde-modal-content'>
        <div className='launch-modal'>
          { props.billingReady
            ? <div>
              { props.launched
                ? <div className='launch-modal-text'>{ props.updateText }</div>
                : <div>
                  <div className=''>You’re about to offer protection live on your store.</div>
                  <div className='launch-modal-text'>Ready to launch?</div>
                </div>
              }
            </div>
            : <div>You need to set up billing before we can offer contracts</div>
          }
          <div className='launch-logos row'>
            <div className='offset-2 col-3'>
              <img className='launch-logos__image' alt='' src='/images/Clyde-Circle-Light.png' />
            </div>
            <div className='col-2'>
              <img className='launch-logos__image' alt='' src='/images/arrows.svg' />
            </div>
            <div className='col-3'>
              <img className='launch-logos__image' alt='' src={ platformImage } />
            </div>
          </div>
          <div className='row'>
            { props.billingReady
              ? <div className='col-lg-6 offset-lg-3 col-md-12 col-sm-12'>
                  <ClydeButton
                    id='launch-button'
                    className='confirm-launch-button'
                    onClick={ () => { props.handleLaunch(); } }
                    processing={ props.launchProcessing }
                    successText={ successText }
                  >
                    { saveText }
                  </ClydeButton>
                </div>
              : <div onClick={ props.updateBilling } className='col-lg-4 offset-lg-4 col-md-6 offset-md-3 col-sm-12 confirm-launch-button'>
                Update billing
              </div>
            }
          </div>
          <div className='row'>
            { props.launchProcessing
              ? <div className='launch-started-text col-12'>
                <div>This may take a few minutes.</div>
                <div>You can leave or close this tab at any time.</div>
              </div>
              : <div onClick={ props.closeLaunchModal } className='col-lg-4 offset-lg-4 col-md-6 offset-md-3 col-sm-12 cancel-launch-button'>{ props.cancelText || 'Cancel' }</div>
            }
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConfirmLaunch;
