import { wsSend } from '../websocketActions';

export const clearResolutionDetail = () => ({
  type: 'CLEAR_RESOLUTION_DETAIL',
  payload: {},
});

export const handleResolutionDataChange = (name, value) => ({
  type: 'HANDLE_CLAIM_DETAIL_RESOLUTION_DATA_CHANGE',
  payload: {
    name,
    value,
  },
});

export const fetchResolution = (claimId) => (dispatch) => {
  dispatch({
    type: 'FETCH_CLAIM_RESOLUTION',
    payload: {},
  });
  dispatch(wsSend({
    type: 'FETCH_CLAIM_RESOLUTION_REQ',
    payload: { claimId },
  }));
};

export const submitResolution = (claimId, resolution) => (dispatch) => {
  dispatch({
    type: 'SUBMIT_CLAIM_RESOLUTION',
    payload: {},
  });
  dispatch(wsSend({
    type: 'SUBMIT_CLAIM_RESOLUTION_REQ',
    payload: { claimId, resolution },
  }));
};

export const closeResolution = (claimId, resolution) => (dispatch) => {
  dispatch({
    type: 'CLOSE_CLAIM_RESOLUTION',
    payload: {},
  });
  dispatch(wsSend({
    type: 'CLOSE_CLAIM_RESOLUTION_REQ',
    payload: { claimId, resolution },
  }));
};

export const submitDepotRepairUpdate = (claimId, updateInformation) => (dispatch) => {
  dispatch({
    type: 'SUBMIT_DEPOT_REPAIR_UPDATE',
    payload: {},
  });
  dispatch(wsSend({
    type: 'SUBMIT_DEPOT_REPAIR_UPDATE_REQ',
    payload: { claimId, updateInformation },
  }));
};
