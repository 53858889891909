import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import {
  getShopInfo,
  filterIntegrations,
  resetIntegrationsTab,
  searchIntegrations,
} from '../../../actions/integrationsActions';

import IntegrationsTab from './IntegrationsTab';

const mapStateToProps = (state) => ({
  userId: state.auth.userId,
  ...state.integrations,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  _getShopInfo: getShopInfo,
  _filterIntegrations: filterIntegrations,
  _resetIntegrationsTab: resetIntegrationsTab,
  _searchIntegrations: searchIntegrations,
}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(IntegrationsTab);
