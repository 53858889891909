const defaultState = {
  modalOpen: false,
  modalPage: null,
  product: null,
  variant: null,
  contract: {},
  submitSaleProcessing: false,
  saleComplete: false,
};

const contractSale = function profile(state = defaultState, action) {
  switch (action.type) {
    case 'START_SALE':
      return {
        ...state, product: action.payload.product, modalOpen: true, modalPage: 'variants',
      };
    case 'SELECT_VARIANT':
      return {
        ...state, variant: action.payload.variant, contract: {}, modalPage: 'contracts',
      };
    case 'SELECT_CONTRACT':
      return { ...state, contract: action.payload.contract, modalPage: 'customer' };
    case 'SUBMIT_SALE_PROCESSING':
      return { ...state, submitSaleProcessing: true };
    case 'SUBMIT_SALE_RES':
      return {
        ...state,
        modalPage: 'finish',
        submitSaleProcessing: false,
      };
    case 'EXIT_SALE':
      return {
        ...state,
        modalOpen: false,
        modalPage: null,
        product: null,
        variant: null,
        contract: {},
        customer: {},
      };
    case 'LOGOUT':
      return { ...state, ...defaultState };
    case 'ERROR':
      switch (action.payload.reqType) {
        case 'SUBMIT_SALE_REQ':
          return { ...state, submitSaleProcessing: false };
        default:
          return state;
      }
    default:
      return state;
  }
};

export default contractSale;
