import React, { useState, useEffect } from 'react';
import Mustache from 'mustache';
import { LoadingDots } from '@joinclyde/clyde-react-components';

import SharedHeader from '../SharedHeader';
import SharedButtons from '../SharedButtons';
import FriggInputs from './FriggInputs';
import inIframe from '../../../../../../lib/inIframe';

const FriggSetup = (props) => {
  const {
    userId,
    authRequirements,
    getProcessing,
    selectedIntegration,
    integrationData,
    _setFriggOAuthData,
    _updateValue,
  } = props;

  const friggInputsRequired = authRequirements.data
    && authRequirements.data.jsonSchema
    && authRequirements.data.jsonSchema.required
    && authRequirements.data.jsonSchema.required.length > 0;

  let buttonUrl = null;
  if (authRequirements.type === 'oauth2' && friggInputsRequired) {
    const decodedUrl = decodeURI(authRequirements.url);
    buttonUrl = Mustache.render(decodedUrl, { subdomain: integrationData.subdomain });
  } else {
    buttonUrl = decodeURI(authRequirements.url);
  }
  const [errors, setErrors] = useState({});
  const [formDirty, setFormDirty] = useState(false);
  const formHasErrors = Object.values(errors).some((errorField) => errorField);

  useEffect(() => {
    if (friggInputsRequired) {
      for (const requirement of authRequirements.data.jsonSchema.required) {
        setErrors({
          ...errors,
          [requirement]: true,
        });
      }
    }
  }, [authRequirements]);

  const handleSubmit = (e) => {
    e.preventDefault();
    setFormDirty(true);

    if (!formHasErrors) {
      if (authRequirements.type === 'oauth2') {
        // lowercase entity name to match frigg backend
        _setFriggOAuthData(userId, integrationData, selectedIntegration.name.toLowerCase());
        window.location.href = buttonUrl;
      } else if (authRequirements.type === 'basic') {
        // MARKER AJC 2022/01/24 Flesh this out when writing out basic auth flow
        // _connectIntegration();
      }
    }
  };

  const verifyInputs = (name, value) => {
    if (name) {
      if (value.trim() === '') {
        setErrors({
          ...errors,
          [name]: true,
        });
      } else {
        setErrors({
          ...errors,
          [name]: false,
        });
      }
    }
  };

  const handleTextChange = (e) => {
    const { name, value } = e.target;
    _updateValue(name, value);
    verifyInputs(name, value);
  };

  const iFrameMessage = (
    <div>
      <SharedHeader
        logo={ selectedIntegration.entityOptions.display?.icon }
        name={ selectedIntegration.name }
      />
      <div className='integrations-setup-modal__message'>
        Setting up this integration requires us to redirect you to {
          selectedIntegration.name
        } to grant necessary permissions.
        This redirect isn't possible while using our Dashboard from
        within BigCommerce. Please open the {
          <a
            href={ window.location.href }
            target='_blank'
            rel='noopener noreferrer'
          >Clyde Dashboard</a>
        } in a separate tab to complete this integration.</div>
    </div>
  );

  const AuthForm = (
    <div className='integrations-setup-modal__body'>
      <SharedHeader
        logo={ selectedIntegration.entityOptions.display?.icon }
        name={ selectedIntegration.name }
      />
      {
        getProcessing
          ? <div className='integrations-setup-modal__body__loading'>
              <LoadingDots dotColor='clydeBlack' dotSize='large' />
            </div>
          : <form className='integrations-setup-modal-form' onSubmit={ handleSubmit }>
              <p className='integrations-setup-modal-form__info'>
                {
                  friggInputsRequired
                    ? 'Input your information below to complete your integration'
                    : 'Click below to connect your account'
                }
              </p>
              {
                friggInputsRequired
                && (
                  <FriggInputs
                    integration={ selectedIntegration }
                    integrationData={ integrationData }
                    jsonSchema={ authRequirements.data.jsonSchema }
                    uiSchema={ authRequirements.data.uiSchema }
                    errorMap={ errors }
                    _setFormDirty={ () => setFormDirty(true) }
                    _formDirty={ formDirty }
                    _handleTextChange={ handleTextChange }
                  />
                )
              }
              {
                formHasErrors && formDirty
                  && <div className='integrations-setup-modal-form__inputs__input-group-error'>
                    Please complete all required fields
                  </div>
              }
            <SharedButtons
              buttonType={ authRequirements.type }
              oAuthUrl={ buttonUrl }
            />
          </form>
    }
  </div>
  );

  // If using Dashboard within BigCommerce UI display message else display auth form
  return authRequirements.type === 'oauth2' && inIframe()
    ? iFrameMessage : AuthForm;
};

export default FriggSetup;
