import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { ClydeTooltip } from '@joinclyde/clyde-react-components';
import { Helmet } from 'react-helmet';

import NewProduct from './NewProduct';
import ProductImport from '../CSVUpload/ProductImport';
import SaleDetails from '../ContractSale/SaleDetails';

import ProductFilters from './ProductFilters';
import PlatformProductList from './PlatformProductList';
import SaveButton from '../Utils/SaveButton';
import ConfirmLaunch from './Shared/ConfirmLaunch';

class Products extends Component {
  state = {
    showLaunchModal: false,
    view: 'products',
    allSelected: false,
    onlyIncludeRegistrableProducts: false,
  }

  UNSAFE_componentWillMount() {
    this.props._getCTAInfo();
    this.props._getBillingInfo();
    this.props._getLicensingInfo();
    if (
      this.props.platform === 'Shopify'
      || this.props.platform === 'BigCommerce'
    ) {
      this.props._getShopSettings();
    }
  }

  toggleLaunchModal() {
    this.setState({ showLaunchModal: !this.state.showLaunchModal });
  }

  toggleSelectRegistrable = () => {
    this.props._toggleViewRegistrableProducts();
  }

  goToBilling() {
    this.toggleLaunchModal();
    this.props.history.push('/settings');
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (
      !this.props.platform
      && (nextProps.platform === 'Shopify' || nextProps.platform === 'BigCommerce')
    ) {
      this.props._getShopSettings();
      this.props._getLaunchingStatus();
    }

    if (nextProps.products.download) {
      const link = document.createElement('a');
      link.href = `data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,${
        nextProps.products.excelExport}`;
      const date = new Date().toLocaleDateString();
      link.download = `Clyde Rate Sheet ${date.replace(/\//g, '-')}.xlsx`;
      link.click();
      setTimeout(() => {
        this.props._toggleDownloadFalse();
      }, 500);
    }
    this.refreshAllSelected();
  }

  refreshAllSelected = () => {
    if (this.props.products.products.every((product) => product.RegistrableProduct)) {
      this.setState({ allSelected: true });
    } else {
      this.setState({ allSelected: false });
    }
  }

  handleLaunch() {
    this.toggleLaunchModal();
    this.props._launchProducts();
  }

  toggleLaunchInstrRef = React.createRef();

  closeLaunchInstr() {
    this.toggleLaunchInstrRef.click();
    this.props._dismissLaunchInstr();
  }

  export(e) {
    e.preventDefault();
    if (!this.props.products.download) this.props._getRateSheetExport();
  }

  render() {
    const {
      props: { products, platform, viewRegistrableProducts },
    } = this;

    const defaultBillingAcct = this.props.billingAccounts.find((acct) => acct.default);
    const billingReady = this.props.billingInvoice
      || (defaultBillingAcct && defaultBillingAcct.verified);
    const showLaunch = products.showSaveButton
      || (products.matchingComplete && (!products.launched || products.needLaunch));

    const platformsWithTooltipView = ['Clyde', 'Magento', 'SFCC'];
    const showTooltips = platformsWithTooltipView.indexOf(platform) >= 0;
    return (
      <div>
        <div>
          <div className='page-container'>
            <Helmet>
              <title>Clyde | Products</title>
            </Helmet>

            <div className='page-layout'>
              { showTooltips
                && <div className='actions-container'>
                  <ClydeTooltip
                    id='export-rate-sheet'
                    position='right'
                    label={
                      <div className='actions-container__tooltip__text'>Export rate sheet</div>
                    }
                    hideIndicator >
                    <div
                      onClick={ (e) => this.export(e) }
                      data-tip
                      data-for='export-rate-sheet'
                      className='actions-container__button'>
                      <img
                        className='actions-container__button__image actions-container__button__image--export'
                        alt='Export rate sheet'
                        src='/images/download.svg' />
                    </div>
                  </ClydeTooltip>
                  <ClydeTooltip
                    id='upload-products'
                    label={
                      <div className='actions-container__tooltip__text'>Import products </div>
                    }
                    position='right'
                    hideIndicator>
                      <div
                        onClick={ this.props._openUploadModal }
                          data-tip
                          data-for='import-products'
                          className='actions-container__button'>
                          <img
                            className='actions-container__button__image'
                            alt='Import products'
                            src='/images/upload.svg' />
                      </div>
                  </ClydeTooltip>
                  <ClydeTooltip
                    id='new-product'
                    label={
                      <div className='actions-container__tooltip__text'>New product</div>
                    }
                    position='right'
                    hideIndicator >
                    <div
                      onClick={ this.props._openNewProductModal }
                      data-tip
                      data-for='new-product'
                      data-test='newProduct'
                      className='actions-container__button'>
                      <img
                        className='actions-container__button__image'
                        alt='New product'
                        src='/images/new-contracts.svg' />
                      </div>
                  </ClydeTooltip>
                </div>
              }
              <div className='title-wrapper'>
                <div className='row align-items-end'>
                  <div className='col-12'>
                    <h1>Manage Products</h1>
                  </div>
                  <div className='col-12'>
                    { this.props.showRegistrationPage
                      ? <button
                        className={ `products-view__eligible-registrable-products-button ${this.state.selectRegistrable ? 'products-view__eligible-registrable-products-button-toggled' : ''}` }
                        onClick={ () => this.toggleSelectRegistrable() }
                      >Select Registrable Products</button>
                      : null }
                    <ProductFilters
                      platform={ platform }
                    />
                  </div>
                </div>
              </div>
              <PlatformProductList
                selectRegistrable={ viewRegistrableProducts }
                allSelected={ this.state.allSelected }
                onlyIncludeRegistrableProducts={ this.props.onlyIncludeRegistrableProducts }
                toggleSelectRegistrable={ this.toggleSelectRegistrable }
              />
              <div>
                { this.props.products.openProductModal
                  ? <NewProduct />
                  : null
                }
                { this.props.products.openUploadModal === true
                  ? <ProductImport />
                  : null
                }
                { this.props.contractSaleModalOpen === true
                  ? <SaleDetails
                      platform={ platform }
                      billingReady={ billingReady }
                      updateBilling={ () => { this.goToBilling(); } }
                      />
                  : null
                }
                { this.state.showLaunchModal
                  ? <ConfirmLaunch
                    platform= { platform }
                    updateText={ 'Ready to push your updates?' }
                    handleLaunch={ () => { this.handleLaunch(); } }
                    closeLaunchModal={ () => { this.toggleLaunchModal(); } }
                    launched={ this.props.products.launched }
                    launchProcessing={ this.props.products.launchProcessing }
                    billingReady={ billingReady }
                    updateBilling={ () => { this.goToBilling(); } }
                  />
                  : null
                }
              </div>
            </div>
          </div>
          { !showTooltips
            ? <div className='process-div'>
            <div className='fixed-save-bar'>
              <SaveButton
                id={ 'products-save-button' }
                saveFunc={ () => { this.toggleLaunchModal(); } }
                className='products-save-button'
                tooltipId={ 'contract-settings-tooltip' }
                enableTooltip={ this.props.products.launchProcessing }
                tooltipText={ 'Your contracts are currently updating. Please wait for this process to complete before beginning a new launch.' }
                processing={ this.props.products.launchProcessing }
                disabled={ false }
                visible={ showLaunch }
                text={ this.props.products.launched ? 'Launch updates' : 'Launch' }
                successText={ 'Launched' }>
                <div ref={ this.toggleLaunchInstrRef } id='toggle-launch-instr' className='toggle-launch-instructions' data-tip data-for='launch-instr' data-scroll-hide='false' data-event='click'></div>
              </SaveButton>
              <Link to='/contracts' className='contract-settings-link'>Manage contracts</Link>
              <div
                className='rate-sheet-export-button'
                onClick={ (e) => this.export(e) }>
                <span>Export Rate Sheet</span>
              </div>
            </div>
          </div>
            : null
          }
        </div>
      </div>
    );
  }
}

export default Products;
