import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import {
  countProductCatalgoue,
  finishNewOrder,
  hideNewOrderModal,
  submitNewOrder,
} from '../../../../actions/newOrderActions';
import { populateCustomersStateWithNewOrderInfo } from '../../../../actions/customersActions';

import NewOrder from './NewOrder';

const mapStateToProps = (state) => ({
  newOrder: state.newOrder,
  shopId: state.auth.shopId,
  shopName: state.profile.shopName, // Get from somewhere more reliable on refactor
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  _countProductCatalgoue: countProductCatalgoue,
  _finishNewOrder: finishNewOrder,
  _hideNewOrderModal: hideNewOrderModal,
  _submitNewOrder: submitNewOrder,
  _populateCustomersStateWithNewOrderInfo: populateCustomersStateWithNewOrderInfo,
}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(NewOrder);
