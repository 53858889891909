import { wsSend } from './websocketActions';

export const nextStep = () => ({
  type: 'NEXT_STEP',
  payload: {},
});

export const previousStep = () => ({
  type: 'PREVIOUS_STEP',
  payload: {},
});

export const finishOnboarding = () => wsSend({
  type: 'FINISH_ONBOARDING_REQ',
  payload: {},
});

export const setStep = (step) => ({
  type: 'SET_STEP',
  payload: step,
});

export const getOnboardingInfo = () => (dispatch) => {
  dispatch({
    type: 'ONBOARDING_GET_PROCESSING',
    payload: {},
  });
  dispatch(wsSend({
    type: 'GET_ONBOARDING_INFO_REQ',
    payload: {},
  }));
};

export default {};
