import React, { Component } from 'react';
import moment from 'moment';
import {
  VictoryAxis,
  VictoryChart,
  VictoryLine,
  VictoryTheme,
  VictoryVoronoiContainer,
} from 'victory';
import { ClydeCheckbox } from '@joinclyde/clyde-react-components';

import CustomVictoryLabel from './CustomVictoryLabel';
import { decorateClassComponent } from '../../providers/LocaleProvider';

class SalesGraph extends Component {
  state = {
    showCombinedGraph: true,
    showPosGraph: true,
    showPpGraph: true,
  }

  toggleShowGraph = (e) => {
    const { name } = e.currentTarget;
    if (e.type === 'click') {
      e.currentTarget.blur();
    }
    this.setState((prevState) => ({ [name]: !prevState[name] }));
  }

  processGraphData = () => {
    const {
      combinedSales, posSales, ppSales, changeType, graphType,
    } = this.props;
    let selectedGraphName = '';
    let selectedCombinedGraph = [];
    let selectedPosGraph = [];
    let selectedPpGraph = [];

    switch (graphType) {
      case 'contracts':
        selectedGraphName = 'Contracts Sold';
        selectedCombinedGraph = combinedSales.graphData.contractCount;
        selectedPosGraph = posSales.graphData.contractCount;
        selectedPpGraph = ppSales.graphData.contractCount;
        break;
      case 'contractRevenue':
        selectedGraphName = 'Contract Revenue';
        selectedCombinedGraph = combinedSales.graphData.contractRevenue;
        selectedPosGraph = posSales.graphData.contractRevenue;
        selectedPpGraph = ppSales.graphData.contractRevenue;
        break;
      case 'storeRevenue':
        selectedGraphName = 'Your Profit';
        selectedCombinedGraph = combinedSales.graphData.storeRevenue;
        selectedPosGraph = posSales.graphData.storeRevenue;
        selectedPpGraph = ppSales.graphData.storeRevenue;
        break;
      case 'productValue':
        selectedGraphName = 'Product Value Covered';
        selectedCombinedGraph = combinedSales.graphData.productValue;
        selectedPosGraph = posSales.graphData.productValue;
        selectedPpGraph = ppSales.graphData.productValue;
        break;
      default:
        break;
    }
    switch (changeType) {
      case 'day':
        selectedGraphName += ' — Day Over Day';
        selectedCombinedGraph = selectedCombinedGraph.map(this.buildDayPoint);
        selectedPosGraph = selectedPosGraph.map(this.buildDayPoint);
        selectedPpGraph = selectedPpGraph.map(this.buildDayPoint);
        break;
      case 'week':
        selectedGraphName += ' — Week Over Week';
        selectedCombinedGraph = this.buildWeekPoints(selectedCombinedGraph);
        selectedPosGraph = this.buildWeekPoints(selectedPosGraph);
        selectedPpGraph = this.buildWeekPoints(selectedPpGraph);
        break;
      case 'total':
        selectedGraphName += ' — Running Total';
        selectedCombinedGraph = selectedCombinedGraph.map(this.buildTotalPoint);
        selectedPosGraph = selectedPosGraph.map(this.buildTotalPoint);
        selectedPpGraph = selectedPpGraph.map(this.buildTotalPoint);
        break;
      default:
        break;
    }

    return {
      selectedGraphName, selectedCombinedGraph, selectedPosGraph, selectedPpGraph,
    };
  }

  buildTotalPoint = ({ date, value, runSum }) => ({ x: moment.utc(date), y: runSum, n: value })

  buildDayPoint = ({ date, value, runSum }) => ({ x: moment.utc(date), y: value, n: runSum })

  buildWeekPoints = (graphData) => {
    const currentYear = moment().year();
    const weekHash = {};

    for (const day of graphData) {
      const { date, value, runSum } = day;
      const year = moment(date).year();
      let week = moment(date).week();

      // If data point's year is before current year, data point's week must be negative
      if (currentYear > year) {
        const yearDiff = currentYear - year;
        week -= (yearDiff * 52);
      }

      if (weekHash[week]) {
        weekHash[week].y += value;
        weekHash[week].n = runSum;
      } else {
        weekHash[week] = {
          x: moment().week(week),
          y: value,
          n: runSum,
        };
      }
    }

    return Object.values(weekHash);
  }

  buildContainerComponent = (selectedGraphData) => {
    const { showCombinedGraph, showPosGraph, showPpGraph } = this.state;
    const disabled = selectedGraphData.length === 0 || (!showCombinedGraph && !showPosGraph && !showPpGraph);
    return (
      <VictoryVoronoiContainer
        voronoiBlacklist={ ['ppScatter', 'posScatter', 'totalScatter'] }
        disable={ disabled }
        domain='x'
        labels={ (thisPoint) => this.buildPointLabels(thisPoint) }
        labelComponent={ <CustomVictoryLabel moment= { moment } /> } />
    );
  }

  buildPointLabels = (point) => {
    const { changeType, graphType, formatMoney } = this.props;
    const { currency } = this.context;
    const { y, n, childName: graph } = point;

    let amountToday;
    let amountTotal;
    switch (changeType) {
      case 'day':
      case 'week':
        amountToday = y;
        amountTotal = n;
        break;
      case 'total':
      default:
        amountToday = n;
        amountTotal = y;
        break;
    }

    const graphName = graph === 'Total' ? 'Combined Sales' : graph;
    let label = '';
    switch (graphType) {
      // \n breaks the string into separate array elements behind-the-scenes in Victory; this allows for easy
      // processing in CustomVictoryLabel. Building an array here does not work, for some reason.
      case 'contracts':
        label += `${graphName} Running Total: ${amountTotal}\n`;
        label += `${graphName} Contracts Bought: ${amountToday}\n `;
        break;
      case 'contractRevenue':
      case 'storeRevenue':
        label += `${graphName} Running Total: ${formatMoney(amountTotal, currency)}\n`;
        label += `${graphName} Revenue Earned: ${formatMoney(amountToday, currency)}\n `;
        break;
      default:
        break;
    }

    return label;
  }

  buildDateLabels = (date) => {
    const threeMonthsAgo = moment(this.props.graphRange.endDate).subtract(3, 'months');
    if (moment(this.props.graphRange.startDate).isSameOrBefore(threeMonthsAgo)) {
      // Renders x axis as month names if range > three months
      return moment(date).format('MMMM');
    }
    return moment(date).format('L');
  }

  formatAxisTicks = (tick) => {
    const { graphType } = this.props;
    return graphType === 'contracts' ? tick : `$${tick}`;
  }

  render() {
    const { changeType, graphRange } = this.props;
    const { showCombinedGraph, showPosGraph, showPpGraph } = this.state;
    const {
      selectedGraphName,
      selectedCombinedGraph,
      selectedPosGraph,
      selectedPpGraph,
    } = this.processGraphData();

    let xLabel = 'Date';
    if (changeType === 'week') {
      xLabel = 'Week of';
    }

    return (
      <div className='performance-graph-tile'>
        <div className='performance-graph-tile__title'>
          <span>{ selectedGraphName }</span>
        </div>

        <div className='performance-graph-tile__graph'>
          <VictoryChart
            padding={ {
              top: 25, right: 15, bottom: 67, left: 75,
            } }
            width={ 900 }
            height={ 450 }
            containerComponent={ this.buildContainerComponent(selectedCombinedGraph) }
            theme={ VictoryTheme.material }>
            <VictoryAxis
              label={ xLabel }
              scale='time'
              style={ {
                axisLabel: { padding: 40, fontFamily: 'Volte Semibold', fontSize: '18px' },
                tickLabels: { fontFamily: 'Volte' },
              } }
              tickFormat={ (date) => this.buildDateLabels(date) }
              domain={ [moment(graphRange.startDate), moment(graphRange.endDate)] } />
            <VictoryAxis
              label={ selectedGraphName }
              style={ {
                axisLabel: { padding: 50, fontFamily: 'Volte Semibold', fontSize: '18px' },
                tickLabels: { fontFamily: 'Volte' },
              } }
              domain={ [0, 10] }
              tickFormat={ this.formatAxisTicks }
              dependentAxis />

            {
              showPpGraph
                && <VictoryLine
                  name='Post Purchase'
                  data={ selectedPpGraph }
                  style={ {
                    data: { stroke: '#e07400', strokeWidth: '2', strokeDasharray: '10,5' },
                    labels: { textAnchor: 'start', fontFamily: 'Volte', fill: '#e07400' },
                  } } />
            }
            {
              showPosGraph
                && <VictoryLine
                  name='Time of Sale'
                  data={ selectedPosGraph }
                  style={ {
                    data: { stroke: '#e07400', strokeWidth: '2', strokeDasharray: '2,2' },
                    labels: { textAnchor: 'start', fontFamily: 'Volte', fill: '#e07400' },
                  } } />
            }
            {
              showCombinedGraph
                && <VictoryLine
                  name='Total'
                  data={ selectedCombinedGraph }
                  style={ {
                    data: { stroke: '#e07400', strokeWidth: '2' },
                    labels: { textAnchor: 'start', fontFamily: 'Volte', fill: '#e07400' },
                  } } />
            }
          </VictoryChart>
        </div>

        <div className='performance-change-type-input'>
          <div className='performance-change-type-input__group'>
            <ClydeCheckbox
              backgroundColor='clydeBlack'
              id='showCombinedGraph'
              name='showCombinedGraph'
              checked={ showCombinedGraph }
              onChange={ this.toggleShowGraph }
            />
            <label
              htmlFor='showCombinedGraph'
              className='performance-toggle-label'>
              <svg className='performance-toggle-line' viewBox='0 0 30 10' xmlns='http://www.w3.org/2000/svg'>
                <line x1='0' y1='5' x2='30' y2='5' stroke='#e07400' strokeWidth='2' />
              </svg>
              Total
            </label>
          </div>

          <div className='performance-change-type-input__group'>
            <ClydeCheckbox
              backgroundColor='clydeBlack'
              id='showPosGraph'
              name='showPosGraph'
              checked={ showPosGraph }
              onChange={ this.toggleShowGraph }
            />
            <label
              htmlFor='showPosGraph'
              className='performance-toggle-label'>
              <svg className='performance-toggle-line' viewBox='0 0 30 10' xmlns='http://www.w3.org/2000/svg'>
                <line x1='0' y1='5' x2='30' y2='5' stroke='#e07400' strokeWidth='2' strokeDasharray='2 2' />
              </svg>
              Time of Sale
            </label>
          </div>

          <div className='performance-change-type-input__group'>
            <ClydeCheckbox
              backgroundColor='clydeBlack'
              id='showPpGraph'
              name='showPpGraph'
              checked={ showPpGraph }
              onChange={ this.toggleShowGraph }
            />
            <label
              htmlFor='showPpGraph'
              className='performance-toggle-label'>
              <svg className='performance-toggle-line' viewBox='0 0 30 10' xmlns='http://www.w3.org/2000/svg'>
                <line x1='0' y1='5' x2='30' y2='5' stroke='#e07400' strokeWidth='2' strokeDasharray='5 3' />
              </svg>
              Post Purchase
            </label>
          </div>
        </div>
      </div>
    );
  }
}

decorateClassComponent(SalesGraph);

export default SalesGraph;
