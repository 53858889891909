import React from 'react';
import { ClydeNotificationMessage } from '@joinclyde/clyde-react-components';

const LimitedNotification = (props) => {
  const {
    isLimitedClaim,
    isDop,
    step,
    clydeCovered,
    shopName,
    limitedWarrantyExpired,
  } = props;

  if (
    !isLimitedClaim
    || (isDop && step === 'claim')
  ) {
    return null;
  }

  let text;
  let type;
  let customClass = 'cop-file-claim__limited-notification';

  if (step === 'claim') {
    type = 'alert';
    if (clydeCovered) {
      text = "Your customer is still covered by this product's limited warranty.";
      customClass = `${customClass} cop-file-claim__limited-notification--purple`;
    } else if (limitedWarrantyExpired) {
      text = <>
        This product’s limited warranty coverage has expired. <br />
        You can submit a limited warranty claim at your discretion.
      </>;
    } else {
      return null;
    }
  }

  if (step === 'resolution') {
    text = `A claim was successfully submitted. This issue is still covered by ${shopName}’s Limited Warranty program.`;
    type = 'success';
  }

  return (
    <ClydeNotificationMessage
      text={ text }
      type={ type }
      className={ customClass }
    />
  );
};

export default LimitedNotification;
