import React, { Component } from 'react';
import classnames from 'classnames';
import { isEmpty } from 'lodash';
import checkValidations, { additionalContactValidations } from '../../Utils/ProfileValidation';
import WarningModal from './WarningModal';

class OnboardingProcess extends Component {
  state = { showWarning: false };

  handleNextStep = () => {
    const {
      onboarding: { step },
      profile,
      platform,
      technicalContact,
      claimsContact,
      customize,
      _completeBillingStep,
      _completeCustomizeStep,
      _completeProfileStep,
      _setContactValidationErrors,
      _setValidationErrors,
      _nextStep,
    } = this.props;

    const stepMap = ['welcome', 'shopInfo', 'customize', 'connect'];

    switch (stepMap[step]) {
      case 'welcome':
        break;
      case 'shopInfo':
        const formErrors = checkValidations(profile, platform);
        const technicalContactErrors = additionalContactValidations(technicalContact);
        const claimsContactErrors = additionalContactValidations(claimsContact);

        if (isEmpty(formErrors) && isEmpty(technicalContactErrors) && isEmpty(claimsContactErrors)) {
          _completeProfileStep(profile, technicalContact, claimsContact);
        } else {
          _setValidationErrors(formErrors);
          _setContactValidationErrors([technicalContactErrors, {}, claimsContactErrors]);
          return;
        }
        break;
      case 'customize':
        _completeCustomizeStep(customize);
        break;
      case 'connect':
        _completeBillingStep();
        break;
      default:
        break;
    }

    if (stepMap[step] === 'shopInfo') {
      const { showWarning } = this.state;
      this.setState({ showWarning: !showWarning });
      if (!showWarning) {
        return;
      }
    }

    _nextStep();
  }

  render() {
    const {
      props: { onboarding, _setStep },
      state: { showWarning },
    } = this;

    const steps = [
      { classname: 'welcome', label: 'Welcome' },
      { className: 'basic-info', label: 'Shop Info' },
      { className: 'customize', label: 'Customize' },
      { className: 'connect', label: 'Billing' },
    ];

    const stepCircleClasses = (index) => classnames(
      `onboarding-step onboarding-step--step-${index}`,
      {
        'onboarding-step--active': onboarding.step === index,
        'onboarding-step--complete': onboarding.step > index,
      },
    );

    const stepTextClasses = (index) => classnames(
      'onboarding-text', { 'onboarding-text--active': onboarding.step === index },
    );

    return (
      <>
        <div className='onboarding-process-container'>
          <div className='onboarding-process-content'>
            <div className='process-bar-container'>
              <div className='process-bar'/>
              { steps.map((step, index) => (
                index <= onboarding.maxStep
                  ? <div
                      key={ `onboarding-${index}` }
                      data-test={ `onboardingItem-${index}` }
                      onClick={ () => _setStep(index) }
                      className={ stepCircleClasses(index) }>
                      <h5 className={ stepTextClasses(index) }>
                        { step.label }
                      </h5>
                    </div>
                  : <div
                      key={ `onboarding-${index}` }
                      className={ stepCircleClasses(index) }>
                      <h5 className={ stepTextClasses(index) }>
                        { step.label }
                      </h5>
                    </div>
              ))
              }
            </div>
            <div className='process-button-container' id='onboarding-next-button' >
              <span onClick={ this.handleNextStep } className='process-button' data-test='next'>
                Next
              </span>
            </div>
          </div>
        </div>
        { showWarning && <WarningModal _nextStep={ this.handleNextStep } /> }
      </>
    );
  }
}

export default OnboardingProcess;
