import React, { Component } from 'react';

import Moment from 'moment';
import { extendMoment } from 'moment-range';
import { DateRange } from 'react-date-range';
import { ClydeButton, ClydeTextInput } from '@joinclyde/clyde-react-components';
import { formatDate } from '../../../../lib/timeUtils';

class ClaimsExportModal extends Component {
  moment = extendMoment(Moment);

  state = {
    dateRange: {
      startDate: new Date(),
      endDate: new Date(),
    },
    showCalendar: false,
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.claimExportFile.download === true) {
      const prefix = this.props.allClaims ? '' : 'LW ';
      const link = document.createElement('a');
      link.href = `data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,${
        nextProps.claimExportFile.data}`;
      link.download = `${prefix}Claims Export - ${formatDate(this.state.dateRange.startDate)}-${formatDate(this.state.dateRange.endDate)}.xlsx`;
      link.click();
      link.remove();
      setTimeout(() => {
        this.props._setClaimsDownloadFalse();
        this.props._cancelClaimsExport();
      }, 500);
    }
  }

  componentDidMount() {
    this.setState({
      dateRange: {
        startDate: this.moment().subtract('14', 'day'),
        endDate: this.moment(),
      },
    });
  }

  handleRangeChange = (newRange) => {
    this.setState({
      showCalendar: false,
      dateRange: {
        startDate: newRange.startDate.format(),
        endDate: newRange.endDate.format(),
      },
    });
  }

  toggleCalendar = (e) => {
    e.stopPropagation();
    this.setState({ showCalendar: !this.state.showCalendar });
  }

  buildDefaultRanges = () => ({
    'Past 14 Days': { startDate: this.moment().subtract('14', 'day'), endDate: this.moment() },
    'Past Month': { startDate: this.moment().subtract('1', 'month'), endDate: this.moment() },
    'Past 2 Months': { startDate: this.moment().subtract('2', 'month'), endDate: this.moment() },
  })

  handleSubmit = () => {
    this.props._submitClaimsExport(
      this.state.dateRange,
      this.props.allClaims,
    );
  }

  render() {
    return (
      <div className='modal-container'>
        <div className='modal-container__backdrop' />
        <div className='clyde-modal-content clyde-modal-content--export-modal'>
          <div className='export-modal-content'>
            <div
              className='export-modal-content__cancel-export'
              onClick={ this.props._cancelClaimsExport }
            >
              &#x2715;
            </div>
            <h1>{ this.props.allClaims ? 'Export claims data' : 'Export limited warranty claims data' }</h1>
            <br />
            <h5>{ this.props.allClaims ? 'Download an excel file containing claims recorded between the below dates.'
              : 'Download an excel file containing your limited warranty claims recorded between the below dates.' }</h5>
            <hr />
            <div>
              <div className='export-modal-content__dates' onClick={ this.toggleCalendar }>
                <div className='export-modal-content__dates__group'>
                  <label htmlFor='startDate'>Start Date</label>
                  <ClydeTextInput
                    className='export-modal-content__dates__group-inputs'
                    name='startDate'
                    value={ formatDate(this.state.dateRange.startDate) }
                    readOnly
                  />
                </div>
                <div className='export-modal-content__dates__group'>
                <label htmlFor='endDate'>End Date</label>
                <ClydeTextInput
                  className='export-modal-content__dates__group-inputs'
                  name='endDate'
                  value={ formatDate(this.state.dateRange.endDate) }
                  readOnly
                />
                </div>
              </div>
              <br/>
              <div onClick={ (e) => e.stopPropagation() }>
                <div className={ `export-modal-content__calendar--${this.state.showCalendar ? 'visible' : 'invisible'}` }>
                  <DateRange
                    maxDate={ this.moment() }
                    minDate={ this.moment().subtract('2', 'month') }
                    ranges={ this.buildDefaultRanges() }
                    onChange={ this.handleRangeChange }
                    moveRangeOnFirstSelection={ false }
                    twoStepChange
                    showMonthAndYearPickers
                    showMonthArrow
                    showSelectionPreview
                  />
                </div>
              </div>
            </div>
            <div className='export-modal-content__submit-row'>
              <ClydeButton
                className='export-modal-content__submit-button'
                onClick={ this.handleSubmit }
                processing={ this.claimExportProcessing }
                successText='Done'
              >
                Export
              </ClydeButton>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ClaimsExportModal;
