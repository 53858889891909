import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import {
  setPage, editProduct, createRegistrableProduct, deleteRegistrableProduct,
} from '../../../actions/productsActions';
import { startRecordSale } from '../../../actions/contractSaleActions';

import PlatformProductList from './PlatformProductList';
import { toggleOnlyIncludeRegistrableProducts } from '../../../actions/customizeActions';

const mapStateToProps = (state) => ({
  products: state.products.products,
  productCount: state.products.productCount,
  loading: state.products.getProductsProcessing,
  roundTotal: state.autopilot.roundTotal,
  pageNumber: state.products.pageNumber,
  contractSaleModalOpen: state.contractSale.modalOpen,
  displayName: state.customize.displayName,
  shopBranded: state.customize.branded,
  onlyIncludeRegistrableProducts: state.customize.onlyIncludeRegistrableProducts,
  registrableProductChange: state.products.registrableProductChange,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  _setPage: setPage,
  _startRecordSale: startRecordSale,
  _editProduct: editProduct,
  _createRegistrableProduct: createRegistrableProduct,
  _deleteRegistrableProduct: deleteRegistrableProduct,
  _toggleOnlyIncludeRegistrableProducts: toggleOnlyIncludeRegistrableProducts,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(PlatformProductList);
