import React from 'react';
import Img from 'react-image';

import BulkCartRadioGroup from './BulkCartRadioGroup';

const buildProductContractRows = (pc, cart, selectContract, termsOnOrder, lineItemIdx) => {
  const rows = [];

  for (let pcidx = 0; pcidx < pc.eligibleQuantity; pcidx++) {
    const {
      contracts, clydeLineItemId, productImage, productName,
    } = pc;
    rows.push(
      <div className='cop-bulk-sale-cart' key={ `${productName}-${pcidx}` }>
        <div className='cop-bulk-sale-cart__left'>
          <Img alt={ productName } src={ [productImage, '/images/default-image.svg'] } />
          <div className='cop-bulk-sale-cart__left__text'>
            <span className='cop-bulk-sale-cart__left__text__top'>Eligible</span>
            <span className='cop-bulk-sale-cart__left__text__bottom'>{ productName }</span>
          </div>
        </div>

        <div className='cop-bulk-sale-cart__right'>
          <BulkCartRadioGroup
            cart={ cart }
            clydeLineItemId={ clydeLineItemId }
            contracts={ contracts }
            selectContract={ selectContract }
            pcidx={ pcidx }
            lineItemIdx={ lineItemIdx }
            productName={ productName }
            termsOnOrder={ termsOnOrder } />
        </div>
      </div>,
    );
  }

  return rows;
};

const BulkCart = (props) => {
  const {
    cart, productContracts, selectContract, termsOnOrder,
  } = props;

  return (
    <div className='cop-bulk-sale__content__cart'>
      {
        productContracts.map((pc, idx) => (
          buildProductContractRows(pc, cart, selectContract, termsOnOrder, idx)
        ))
      }
    </div>
  );
};

export default BulkCart;
