import React, { Component } from 'react';
import { LoadingDots } from '@joinclyde/clyde-react-components';

class ImageUpload extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fileName: '',
      errorText: null,
    };
  }

  openFileDialog = (e) => {
    e.preventDefault();
    document.getElementById('logo-file').click();
    // MARKER: AJC 10/15/19 To remove above DOM manipulation would require a reworking of the image upload button
  }

  selectFile = (e) => {
    const splitFilePath = e.target.value.split('\\');
    const fileName = splitFilePath[splitFilePath.length - 1];

    const { files } = e.target;
    if (FileReader && files && files.length) {
      if (!['png', 'jpg', 'jpeg', 'gif'].includes(files[0].type.split('/')[1]) || files[0].size > 128000) {
        this.setState({ errorText: 'Please use a png, gif, or jpeg no larger than 128kb.' });
        return;
      }
      const fr = new FileReader();
      fr.onload = () => {
        document.getElementById('shop-logo').src = fr.result;
        this.props.setImage(fr.result);
      };
      fr.readAsDataURL(files[0]);
    }
    this.setState({ fileName, errorText: null });
  }

  render() {
    const imageUrl = this.props.imageUrl || 'https://s3.amazonaws.com/clyde-static-files/email-images/picture.svg';
    return (
      <div id='clyde-image-upload'>
        <div className='row logo-settings-section' id='logo-upload-area'>
          <div className='col-4 offset-1 text-center' id='logo-container'>
            { this.props.loading
              ? <LoadingDots dotColor='clydeBlack' />
              : <img id='shop-logo' alt='shop-logo' className='logo-container__thumb' src={ imageUrl }/>
            }
          </div>
          <div className='col-4 offset-1 row logo-upload-container'>
            <div className='col-12 logo-upload-container__button' onClick={ this.openFileDialog }>
              Upload
            </div>
            <div className='col-12 logo-upload-container__error-text'>
              { this.state.errorText }
            </div>
          </div>
        </div>
        <div className='logo-upload-input-container'>
          <input id='logo-file' type='file' onChange={ this.selectFile }/>
        </div>
      </div>
    );
  }
}

export default ImageUpload;
