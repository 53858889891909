import React from 'react';
import classnames from 'classnames';

const PlatformButton = (props) => {
  const { _setLoginPlatform, platform, embeddedBy } = props;

  if (platform.name !== 'Clyde' && platform.name !== embeddedBy && embeddedBy) {
    return null;
  }

  const innerButton = <>
    <img className='platform-option__logo' src={ platform.imageSrc } alt={ platform.name }/>
    <span className='platform-option__name'>{ platform.displayName }</span>
  </>;

  const buttonClass = classnames(
    'platform-container',
    {
      'col-6': !embeddedBy,
      'col-12': embeddedBy,
    },
  );

  return (
    <div className={ buttonClass }>
      { platform.name === 'BigCommerce'
        ? <a
          className='platform-option'
          href='https://login.bigcommerce.com/login/'
          rel='noopener noreferrer'
          target='_blank'
        >
          { innerButton }
        </a>
        : <div className='platform-option' onClick={ () => { _setLoginPlatform(platform.name); } }>
          { innerButton }
        </div>
      }
    </div>
  );
};

export default PlatformButton;
