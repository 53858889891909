import React from 'react';
import { Link } from 'react-router-dom';
import { ClydeTooltip } from '@joinclyde/clyde-react-components';
import Badge from './Badge';

const NavBarButton = (props) => {
  const {
    activeTab,
    link,
    linkName,
    imageSrc,
    claims,
  } = props;

  const isClaimsLink = (link === 'claims');
  const hasClaims = (claims > 0);
  const activeLink = activeTab ? 'nav-buttons__item--active' : '';
  const activeIcon = activeTab ? 'nav-buttons__icon--active' : '';

  return (
    <>
      <ClydeTooltip position='right' hideIndicator label={
          <div className='nav-bar__tooltip__text'>
            { linkName }
          </div>
        }>
        <Link data-test={ `${link}Icon` } data-tip data-for={ `tooltip-${link}` } to={ `/${link}` } data-ix={ link } className={ `nav-buttons__item ${activeLink}` }>
        { isClaimsLink && hasClaims ? <Badge count={ claims } /> : '' }
        <img className={ activeIcon } alt={ `tab-${link}` } src={ `/images/${imageSrc}` } width='24'/>
        </Link>
      </ClydeTooltip>
    </>
  );
};

export default NavBarButton;
