import { wsSend } from '../websocketActions';

export const getShopClaims = (fetchOptions) => (dispatch) => {
  dispatch({
    type: 'GET_SHOP_CLAIMS_PROCESSING',
    payload: {},
  });
  dispatch(wsSend({
    type: 'GET_SHOP_CLAIMS_REQ',
    payload: { ...fetchOptions },
  }));
};

export const setClaimPageNumber = (pageNumber, fetchOptions) => (dispatch) => {
  dispatch({
    type: 'SET_CLAIMS_PAGE_NUMBER',
    payload: { pageNumber },
  });
  dispatch(getShopClaims({ ...fetchOptions, pageNumber }));
};

export const setClaimsFilter = (filter, fetchOptions) => (dispatch) => {
  dispatch({
    type: 'SET_CLAIMS_FILTER',
    payload: { filter },
  });
  dispatch(getShopClaims({
    ...fetchOptions,
    pageNumber: 1,
    filter,
  }));
};

export const setWarrantyFilter = (filter, fetchOptions) => (dispatch) => {
  dispatch({
    type: 'SET_WARRANTY_FILTER',
    payload: { filter },
  });
  dispatch(getShopClaims({
    ...fetchOptions,
    pageNumber: 1,
    warrantyFilter: filter,
  }));
};

export const setClaimsSort = (sort, fetchOptions) => (dispatch) => {
  dispatch({
    type: 'SET_CLAIMS_SORT',
    payload: { sort },
  });
  dispatch(getShopClaims({
    ...fetchOptions,
    pageNumber: 1,
    sort,
  }));
};

export const setClaimSearchType = (type) => (dispatch) => {
  dispatch({
    type: 'SET_CLAIMS_SEARCH_TYPE',
    payload: { type },
  });
};

export const setClaimSearchValue = (value) => (dispatch) => {
  dispatch({
    type: 'SET_CLAIMS_SEARCH_VALUE',
    payload: { value },
  });
};

export const getClaimsForResolution = (shopId) => (dispatch) => {
  dispatch({
    type: 'GET_CLAIMS_FOR_RESOLUTION_PROCESSING',
    payload: {},
  });
  dispatch(wsSend({
    type: 'GET_CLAIMS_FOR_RESOLUTION_REQ',
    payload: { shopId },
  }));
};

export const getClaimsExport = (range, allClaims) => (dispatch) => {
  const type = allClaims ? 'ALL' : 'LIMITED';
  dispatch({
    type: 'CLAIMS_EXPORT_PROCESSING',
    payload: {},
  });
  dispatch(wsSend({
    type: `GET_${type}_CLAIMS_EXPORT_REQ`,
    payload: range,
  }));
};

export const setClaimsDownloadFalse = () => (dispatch) => {
  dispatch({
    type: 'CLAIMS_EXPORT_DOWNLOAD_FALSE',
    payload: {},
  });
};

export const getIssueTypes = () => (dispatch) => {
  dispatch(wsSend({
    type: 'GET_ISSUE_TYPES_REQ',
    payload: {},
  }));
};
