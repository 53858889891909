const defaultState = {
  actionComplete: false,
  cart: [],
  customerInfo: {
    email: '',
    phone: '',
    firstName: '',
    lastName: '',
    address1: '',
    address2: '',
    city: '',
    province: '',
    zip: '',
    country: '',
  },
  productCatalogue: [],
  productCatalogueCount: 0,
  productSearch: '',
  requestProcessing: false,
  showCatalogueModal: false,
  submitProcessing: false,
  step: 'customer', // possible values: customer, cart
};

const newOrderReducer = (state = defaultState, action) => {
  switch (action.type) {
    case 'HIDE_CATALOGUE_MODAL':
      return {
        ...state,
        showCatalogueModal: false,
      };
    case 'UPDATE_NEW_ORDER_CART_PRICE': {
      const { itemIdx, newPrice } = action.payload;
      const newCart = [...state.cart];
      newCart[itemIdx].price = newPrice;
      return {
        ...state,
        cart: newCart,
      };
    }
    case 'UPDATE_NEW_ORDER_CART_QUANTITY': {
      const { itemIdx, newQuantity } = action.payload;
      const newCart = [...state.cart];
      newCart[itemIdx].quantity = newQuantity;
      return {
        ...state,
        cart: newCart,
      };
    }
    case 'UPDATE_NEW_ORDER_INPUT':
      return {
        ...state,
        [action.payload.name]: action.payload.value,
      };
    case 'MOVE_TO_NEW_ORDER_CART_STEP':
      return {
        ...state,
        step: 'cart',
      };
    case 'MOVE_TO_NEW_ORDER_CUSTOMER_STEP':
      return {
        ...state,
        step: 'customer',
      };
    case 'UPDATE_NEW_ORDER_CUSTOMER_INPUT':
      return {
        ...state,
        customerInfo: {
          ...state.customerInfo,
          [action.payload.name]: action.payload.value,
        },
      };
    case 'NEW_ORDER_PRODUCT_COUNT_PROCESSING':
      return {
        ...state,
        requestProcessing: true,
      };
    case 'NEW_ORDER_PRODUCT_COUNT_RES':
      return {
        ...state,
        requestProcessing: false,
        productCatalogueCount: action.payload,
      };
    case 'NEW_ORDER_PRODUCT_SEARCH_PROCESSING':
      return {
        ...state,
        requestProcessing: true,
        showCatalogueModal: true,
      };
    case 'NEW_ORDER_PRODUCT_SEARCH_RES':
      return {
        ...state,
        requestProcessing: false,
        productCatalogue: action.payload,
      };
    case 'UPDATE_NEW_ORDER_CART':
      return {
        ...state,
        cart: action.payload,
      };
    case 'NEW_ORDER_SUBMIT_PROCESSING':
      return {
        ...state,
        submitProcessing: true,
      };
    case 'NEW_ORDER_SUBMIT_RES':
      return {
        ...state,
        actionComplete: true,
        orderId: action.payload,
        submitProcessing: false,
      };
    case 'FINISH_NEW_ORDER':
      return defaultState;
    case 'GET_SHOP_INFO_RES':
      return {
        ...state,
        customerInfo: {
          ...state.customerInfo,
          country: action.payload.country,
        },
      };

    default:
      return state;
  }
};

export default newOrderReducer;
