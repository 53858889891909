import moment from 'moment';

const renderOrderDate = (orderDate) => {
  if (!orderDate) {
    return 'Unknown';
  }

  return moment(orderDate).utc().format('MM/DD/YYYY');
};

export default renderOrderDate;
