import { wsSend } from './websocketActions';

export const getUserEmail = () => (dispatch) => {
  dispatch(wsSend({
    type: 'GET_USER_EMAIL_REQ',
    payload: {},
  }));
};

export const submitUserUpdate = (password, newPassword) => (dispatch) => {
  dispatch(wsSend({
    type: 'UPDATE_USER_REQ',
    payload: { password, newPassword },
  }));
  dispatch({
    type: 'UPDATE_USER_PROCESSING',
    payload: {},
  });
};

export const clearSettingsStatus = () => ({
  type: 'CLEAR_SETTINGS_STATUS',
});

export const showSaveButton = () => ({
  type: 'SHOW_SAVE_BUTTON',
});

export default {};
