import React, {
  createContext, useContext, useEffect,
} from 'react';
import { connect } from 'react-redux';
import { getShopInfo } from '../actions/profileActions';
import { DEFAULT_STATE } from '../reducers/localeReducer';

const LocaleContext = createContext(DEFAULT_STATE);

function LocaleProvider(props) {
  const {
    dispatch,
    shopId,
    locale,
    children,
  } = props;

  // Load shop on mount
  useEffect(() => {
    dispatch(getShopInfo(shopId));
  }, []);

  if (!locale.loaded) {
    return null;
  }

  return (
    <LocaleContext.Provider value={ locale }>
      { children }
    </LocaleContext.Provider>
  );
}

export default connect(({ locale }) => ({ locale }))(LocaleProvider);

export function useLocale() {
  return useContext(LocaleContext);
}

export function decorateClassComponent(Component) {
  Component.contextType = LocaleContext;
}
