const defaultState = {
  closed: false,
  openCosts: '',
  resolutionProductPartIds: [],
  cost: '',
  executor: '',
  fullReplacement: false,
  id: null,
  programType: 'repair',
  serviceType: 'depot',
  payoutType: '',
  payoutDate: '',
  processing: {
    close: false,
    fetch: false,
    files: false,
    submit: false,
  },
  promoCode: '',
  proofFiles: [],
  receiptFiles: [],
  retailerTicketId: '',
  settings: {
    proofOfWork: '',
  },
  shippingCarrier: '',
  shippingNumber: '',
  source: '',
  uploadedProofFiles: [],
  replaceCost: 0,
  isReplaceRequested: false,
};

const claimResolutionReducer = (state = defaultState, action) => {
  switch (action.type) {
    case 'FETCH_CLAIM_RESOLUTION':
      return {
        ...state,
        processing: {
          ...state.processing,
          fetch: true,
          files: true,
        },
      };
    case 'FETCH_CLAIM_RESOLUTION_RES':
      let reponseObject = {
        ...state,
        ...action.payload.claimResolution,
        ...action.payload.depotRepairInfo,
        settings: {
          ...action.payload.claimSettings,
        },
        processing: {
          ...state.processing,
          fetch: false,
        },
      };

      const isClosed = action.payload.claimResolution && action.payload.claimResolution.closed;
      // When depot repair is active, shipping cost are part of total costs
      if (!isClosed && reponseObject.settings && reponseObject.settings.depotRepairEnabled) {
        reponseObject = {
          ...reponseObject,
          cost: action.payload.depotRepairInfo.depotRepaircost,
        };
      }

      const { depotRepairEnabled } = action.payload.claimSettings;
      const hasResolutionRecord = Object.keys(action.payload.claimResolution).length > 0;
      const isRepair = action.payload.claimResolution.programType && action.payload.claimResolution.programType === 'repair';

      if (depotRepairEnabled && hasResolutionRecord && isRepair) {
        reponseObject.serviceType = 'depot';
      }

      return reponseObject;
    case 'SUBMIT_DEPOT_REPAIR_UPDATE_RES':
      let newStateObject = {
        ...state,
        ...action.payload.depotRepairInfo,
      };

      const { depotRepairRecord: { depotRepairSelectedParts }} = action.payload;

      if (action.payload.depotRepairRecord.depotRepairStep === 3) {
        newStateObject = {
          ...newStateObject,
          cost: action.payload.depotRepairRecord.depotRepaircost,
          resolutionProductPartIds: depotRepairSelectedParts ? depotRepairSelectedParts.map((part) => part.id) : [],
          serviceType: 'depot',
        };
      }
      return newStateObject;
    case 'FETCH_CLAIM_RESOLUTION_FILES_RES':
      return {
        ...state,
        processing: {
          ...state.processing,
          files: false,
        },
        uploadedProofFiles: action.payload.proofFiles,
      };
    case 'CLEAR_RESOLUTION_DETAIL':
      return defaultState;
    case 'HANDLE_CLAIM_DETAIL_RESOLUTION_DATA_CHANGE':
      return {
        ...state,
        [action.payload.name]: action.payload.value,
      };
    case 'SUBMIT_CLAIM_RESOLUTION':
      return {
        ...state,
        processing: {
          ...state.processing,
          submit: true,
          files: true,
        },
      };
    case 'SUBMIT_CLAIM_RESOLUTION_RES':
      return {
        ...state,
        ...action.payload,
        processing: {
          ...state.processing,
          submit: false,
        },
        proofFiles: [],
      };
    case 'CLOSE_CLAIM_RESOLUTION':
      return {
        ...state,
        processing: {
          ...state.processing,
          close: true,
          files: true,
        },
      };
    case 'CLOSE_CLAIM_RESOLUTION_RES':
      return {
        ...state,
        ...action.payload,
        processing: {
          ...state.processing,
          close: false,
        },
        proofFiles: [],
      };
    default:
      return state;
  }
};

export default claimResolutionReducer;
