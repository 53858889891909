import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Helmet } from 'react-helmet';

import NavBar from '../NavBar';
import OnboardingProcess from './OnboardingProcess';
import Welcome from './OnboardingWelcome';
import Profile from './Profile';
import Customize from '../Customize';
import BillingConnect from '../Settings/AccountTab/BillingSettingsTile/BillingConnect';
import Terms from '../TermsAndConditions';

class Onboarding extends Component {
  componentDidMount() {
    if (!this.props.isOnboarding) {
      this.props.history.push('/products');
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (!nextProps.isOnboarding) {
      this.props.history.push('/products');
    }
  }

  render() {
    const Components = [
      Welcome,
      Profile,
      Customize,
      BillingConnect,
      Terms,
    ];
    const CurrentStepComponent = Components[this.props.step];

    return (
      <div className='page-wrapper'>
        <Helmet>
          <title>Clyde | Onboarding</title>
        </Helmet>

        <NavBar activeTab={ this.props.location.pathname.slice(1) }/>
          <CurrentStepComponent platform={ this.props.platform }/>
          { this.props.step !== 4
            ? <OnboardingProcess currentStep={ this.props.step }/>
            : null
          }
      </div>
    );
  }
}

const mapStateToProps = (state) => ({ ...state.onboarding, platform: state.auth.platform });

export default withRouter(connect(mapStateToProps)(Onboarding));
