import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getShopPreferences, setShopPreferences } from '../../../actions/preferencesActions';
import { getProductRegistrationsExport, toggleViewRegistrableProducts } from '../../../actions/productRegistrationsActions';

import WawSettingsTab from './WawSettingsTab';

const mapStateToProps = (state) => ({
  usesCheckoutWidget: state.customize.usesCheckoutWidget,
  showRegistrationPage: state.customize.showRegistrationPage,
  collectSerialNumber: state.preferences.collectSerialNumber,
  shopId: state.auth.shopId,
  loading: state.preferences.loading,
  productRegistrationsExport: state.productRegistrations.productRegistrationsExport,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  _getShopPreferences: getShopPreferences,
  _setShopPreferences: setShopPreferences,
  _getProductRegistrationsExport: getProductRegistrationsExport,
  _toggleViewRegistrableProducts: toggleViewRegistrableProducts,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(WawSettingsTab);
