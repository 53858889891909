import { connect } from 'react-redux';

import {
  fetchClaimData,
  populateCustomersStateWithClaimsCustomerInfo,
} from '../../../actions/claims/claimDetailActions';

import ClaimDetail from './Detail';

const mapStateToProps = (state) => ({
  ...state.claimDetail,
});

const mapDispatchToProps = (dispatch) => ({
  _fetchClaimData: (claimId) => {
    dispatch(fetchClaimData(claimId));
  },
  _populateCopWithCustomer: (customerInfo) => {
    dispatch(populateCustomersStateWithClaimsCustomerInfo(customerInfo));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ClaimDetail);
