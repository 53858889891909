import React from 'react';

const nullContractCheck = (cart, lineItemId, idx) => cart[lineItemId] && cart[lineItemId][idx] === undefined;

// !! for middle check b/c without it cart[lineItemId][idx] can return undefined
const selectedContractCheck = (cart, lineItemId, idx, contractId) => cart[lineItemId]
&& !!cart[lineItemId][idx]
&& cart[lineItemId][idx].contractId === contractId;

const BulkCartRadioGroup = (props) => {
  const {
    cart,
    clydeLineItemId,
    contracts,
    pcidx,
    lineItemIdx,
    productName,
    selectContract,
    termsOnOrder,
  } = props;

  const contractsToDisplay = termsOnOrder.map((term) => (
    // eslint-disable-next-line eqeqeq
    contracts.find((c) => c.term == term)
  ));

  return (
    <div className='cop-bulk-sale-radio-group'>
      <div className='cop-bulk-sale-radio-group__column'>
        <input
          type='radio'
          id={ `radio_${productName}_${lineItemIdx}_none` }
          className='cop-bulk-sale-radio-group__column__radio'
          name={ `${productName}_${lineItemIdx}_${pcidx}` }
          onChange={ (e) => selectContract(e, clydeLineItemId, null, pcidx) }
          checked={ nullContractCheck(cart, clydeLineItemId, pcidx) }
          value='' />
      </div>
      {
        contractsToDisplay.map((contract, cidx) => (
          contract
            ? <div
                key={ `${productName}-${contract.category}-${cidx}` }
                className='cop-bulk-sale-radio-group__column'>
                <input
                  type='radio'
                  id={ `radio_${productName}_${lineItemIdx}_${contract.category}_${cidx}` }
                  className='cop-bulk-sale-radio-group__column__radio'
                  name={ `${productName}_${lineItemIdx}_${pcidx}` }
                  onChange={ (e) => selectContract(e, clydeLineItemId, contract, pcidx) }
                  checked={ selectedContractCheck(cart, clydeLineItemId, pcidx, contract.contractId) }
                  value={ contract.term } />
              </div>
            : <div
                key={ `${productName}-fake_category-${cidx}` }
                className='cop-bulk-sale-radio-group__column cop-bulk-sale-radio-group__column--fake'>
                N/A
              </div>
        ))
      }
    </div>
  );
};

export default BulkCartRadioGroup;
