import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import {
  togglePrompt,
  selectType,
  selectCartType,
  setCustomLocation,
  setProductPageLocation,
  setShopImageData,
  setDisplayName,
  togglePostPurchase,
} from '../../../actions/customizeActions';

import CustomizeSettings from './CustomizeSettings';

const mapStateToProps = (state) => ({ cta: state.customize });

const mapDispatchToProps = (dispatch) => bindActionCreators({
  _togglePrompt: togglePrompt,
  _setCustomLocation: setCustomLocation,
  _selectType: selectType,
  _selectCartType: selectCartType,
  _setProductPageLocation: setProductPageLocation,
  _setShopImageData: setShopImageData,
  _setDisplayName: setDisplayName,
  _togglePostPurchase: togglePostPurchase,
}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CustomizeSettings);
