import React from 'react';
import {
  ClydeFileUpload,
} from '@joinclyde/clyde-react-components';

import ResolutionInputGroup from './ResolutionInputGroup';
import ProofOfWorkFiles from './ProofOfWorkFiles';

const ProofOfWorkInput = (props) => {
  const {
    closed,
    processing,
    proofFiles,
    retailerTicketId,
    settings: { proofOfWork },
    uploadedProofFiles,
    openFileModal,
    _handleResolutionDataChange,
  } = props;

  switch (proofOfWork) {
    case 'ticketNumber':
      return (
        <ResolutionInputGroup
          label='Ticket Number'
          readOnlyView={ closed }
          name='retailerTicketId'
          value={ retailerTicketId }
          _onChange={ (e) => { _handleResolutionDataChange(e.target.name, e.target.value); } } />
      );
    case 'fileUpload':
    default:
      return (
        <div className='claim-detail-resolution__group claim-detail-resolution__group--proof-files'>
          { closed
            ? (uploadedProofFiles.length > 0 && <span className='claims-detail-card__group__title'>Uploaded Files</span>)
            : <>
              <ClydeFileUpload
                buttonText='Upload Proof of Work Files'
                className='claim-detail-resolution-upload'
                id='proofFiles'
                uploadedFiles={ proofFiles }
                setUploadedFiles={ (files) => { _handleResolutionDataChange('proofFiles', files); } } />
              <span className='claim-detail-resolution__group__detail'>
                Invoice, repair report, shipping info
              </span>
            </>
          }
          <div className='claim-resolution-upload-uploaded-files'>
            <ProofOfWorkFiles
              infoProcessing={ processing.files }
              uploadedProofFiles={ uploadedProofFiles }
              openFileModal={ openFileModal }
            />
          </div>
        </div>
      );
  }
};

export default ProofOfWorkInput;
