import React from 'react';
import { ClydeTextInput } from '@joinclyde/clyde-react-components';

const ResolutionInputGroup = (props) => {
  const {
    readOnlyView,
    value,
    name,
    label,
    closedLabel,
    _onChange,
  } = props;

  return (
    <div className='claim-detail-resolution__group'>
      { readOnlyView
        ? <>
          <span className='claims-detail-card__group__title'>{ closedLabel || label }</span>
          <span>{ value || 'N/A' }</span>
        </>
        : <>
          <label htmlFor={ name }>{ label }</label>
          <ClydeTextInput
            className='claim-detail-resolution-input'
            id={ name }
            name={ name }
            onChange={ _onChange }
            value={ value || '' }
          />
        </>
      }
    </div>
  );
};

export default ResolutionInputGroup;
