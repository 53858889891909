import React, { Component } from 'react';
import Pagination from 'react-js-pagination';
import { LoadingDots, ClydeTextInput } from '@joinclyde/clyde-react-components';

import ClydeCatalogueItem from './ClydeCatalogueItem';
import PlatformCatalogueItem from './PlatformCatalogueItem';
import formatMoney from '../../../../../lib/formatMoney';

class ProductCatalogue extends Component {
  state = {
    activePage: 1,
  }

  handleSearchChange = (e) => {
    const { name, value } = e.currentTarget;
    const { _updateNewOrderInput } = this.props;
    _updateNewOrderInput(name, value);
  }

  handleSearchSubmit = (e) => {
    e.preventDefault();
    const {
      newOrder: { productSearch },
      _runNewOrderSearch,
    } = this.props;
    _runNewOrderSearch(productSearch);
  }

  addOrRemoveFromCart = (e, catalogueItem) => {
    e.currentTarget.blur();
    const {
      newOrder: { cart },
      _updateCart,
    } = this.props;
    const newCart = [...cart];
    const cartItemIdx = cart.findIndex((cartItem) => cartItem.productId === catalogueItem.productId);
    if (cartItemIdx > -1) {
      newCart.splice(cartItemIdx, 1);
    } else {
      const newCartItem = catalogueItem;
      newCartItem.quantity = 1;
      newCart.push(newCartItem);
    }

    _updateCart(newCart);
  }

  handlePageChange = (pageNumber) => {
    const {
      newOrder: { productSearch },
      _runNewOrderSearch,
    } = this.props;
    _runNewOrderSearch(productSearch, pageNumber);
    this.setState({ activePage: pageNumber });
  }

  selectCatalogueItemComponent = () => {
    const { platform } = this.props;
    switch (platform) {
      case 'Shopify':
        return PlatformCatalogueItem;
      case 'BigCommerce':
        return PlatformCatalogueItem;
      case 'Clyde':
      default:
        return ClydeCatalogueItem;
    }
  }

  render() {
    const {
      newOrder: {
        cart,
        productCatalogue,
        productCatalogueCount,
        productSearch,
      },
      _hideCatalogueModal,
      processing,
    } = this.props;
    const { activePage } = this.state;
    const CatalogueItem = this.selectCatalogueItemComponent();
    const productIdsInCart = cart.map((item) => item.productId);

    return (
      <div className='cop-modal-background-light' onClick={ _hideCatalogueModal }>
        <div className='cop-small-modal-foreground catalogue-modal' onClick={ (e) => e.stopPropagation() }>

          <div className='cop-small-modal-header'>
            <button className='button-nostyle cop-small-modal-header__cancel' onClick={ _hideCatalogueModal }>
              &#x2715;
            </button>
            <h6 className='cop-small-modal-header__header'>Select products</h6>
          </div>

          {
            processing
              ? <div className='cop-modal--loading'>
                  <LoadingDots dotColor='clydeBlack' dotSize='large' />
                </div>
              : <React.Fragment>
                  <div className='catalogue-modal__search'>
                    <form className='catalogue-modal-search__form' onSubmit={ this.handleSearchSubmit }>
                      <ClydeTextInput
                        id='search_products'
                        className='catalogue-modal-search__form__text'
                        onChange={ this.handleSearchChange }
                        placeholder='Product Name or SKU'
                        name='productSearch'
                        value={ productSearch }
                      />
                    </form>
                    <Pagination
                      innerClass='pagination catalogue-modal-search__pagination'
                      itemClass='page-item'
                      linkClass='page-link'
                      activePage={ activePage }
                      itemsCountPerPage={ 25 }
                      totalItemsCount={ productCatalogueCount }
                      pageRangeDisplay={ 5 }
                      onChange={ this.handlePageChange } />
                  </div>

                  <div className='cop-small-modal-line' />

                  <div className='cop-small-modal-content'>
                    {
                      productCatalogue.map((product, idx) => (
                        <CatalogueItem
                          key={ `cat-${idx}` }
                          product={ product }
                          idx={ idx }
                          cartproductIds={ productIdsInCart }
                          formatMoney={ formatMoney }
                          updateCart={ this.addOrRemoveFromCart } />
                      ))
                    }
                  </div>
                </React.Fragment>
          }

          <div className='catalogue-modal-bottom'>
            <button
              className='catalogue-modal__button'
              onClick={ _hideCatalogueModal }>
              Add To Order
            </button>
            <span className='catalogue-modal__count'>
              { cart.length } { cart.length === 1 ? 'item' : 'items' } in order
            </span>
          </div>

        </div>
      </div>
    );
  }
}

export default ProductCatalogue;
