import React, { Component } from 'react';

import Moment from 'moment';
import { extendMoment } from 'moment-range';
import { DateRange } from 'react-date-range';
import { ClydeButton, ClydeTextInput } from '@joinclyde/clyde-react-components';
import { formatDate } from '../../../lib/timeUtils';

class InvoiceExportModal extends Component {
  constructor(props) {
    super(props);
    this.moment = extendMoment(Moment);
    this.state = {
      showCalendar: false,
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.invoiceExportFile.download === true) {
      const link = document.createElement('a');
      link.href = `data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,
      ${nextProps.invoiceExportFile.data}`;
      link.download = nextProps.invoiceExportFile.name;
      link.click();
      setTimeout(() => {
        this.props._setInvoiceDownloadFalse();
      }, 500);
    }
  }

  componentDidMount() {
    this.props._setInvoiceRange({
      startDate: this.moment().subtract('14', 'day'),
      endDate: this.moment(),
    });
  }

  handleRangeChange = (newRange) => {
    this.setState({
      showCalendar: false,
    });
    this.props._setInvoiceRange({
      startDate: newRange.startDate.format(),
      endDate: newRange.endDate.format(),
    });
  }

  toggleCalendar = (e) => {
    e.stopPropagation();
    this.setState({ showCalendar: !this.state.showCalendar });
  }

  closeCalendar = (e) => {
    e.preventDefault();
    this.setState({ showCalendar: false });
  }

  buildDefaultRanges = () => ({
    'Past 14 Days': { startDate: this.moment().subtract('14', 'day'), endDate: this.moment() },
    'Past Month': { startDate: this.moment().subtract('1', 'month'), endDate: this.moment() },
    'Past 3 Months': { startDate: this.moment().subtract('3', 'month'), endDate: this.moment() },
    'Past 6 Months': { startDate: this.moment().subtract('6', 'month'), endDate: this.moment() },
    'Past Year': { startDate: this.moment().subtract('1', 'year'), endDate: this.moment() },
  })

  handleSubmitInvoiceExport = () => {
    this.props._submitInvoiceExport(
      this.props.invoiceExport,
    );
  }

  render() {
    return (
      <div className='modal-container'>
        <div className='modal-container__backdrop'></div>
        <div className='clyde-modal-content clyde-modal-content--export-modal'>
          <div className='export-modal-content'>
            <div
              className='export-modal-content__cancel-export'
              onClick={ this.props._cancelInvoiceExport }
            >
              &#x2715;
            </div>
            <h1>Export invoice data</h1>
            <br />
            <h5>
              Download an Excel file containing all of your contract sales billed between the below dates,
              as well as credits applied on those billing dates from promotions or refunded contracts.
            </h5>
            <hr />
            <div>
              <div className='export-modal-content__dates' onClick={ this.toggleCalendar }>
                <div className='export-modal-content__dates__group'>
                  <label htmlFor='startDate'>Start Date</label>
                  <ClydeTextInput
                    className='export-modal-content__dates__group'
                    name='startDate'
                    value={ formatDate(this.props.invoiceExport.startDate) }
                    readOnly
                  />
                </div>
                <div className='export-modal-content__dates__group'>
                <label htmlFor='endDate'>End Date</label>
                <ClydeTextInput
                  className='export-modal-content__dates__group'
                  name='endDate'
                  value={ formatDate(this.props.invoiceExport.endDate) }
                  readOnly
                />
                </div>
              </div>
              <br/>
              <div onClick={ (e) => e.stopPropagation() }>
                <div className={ `export-modal-content__calendar--${this.state.showCalendar ? 'visible' : 'invisible'}` }>
                  <DateRange
                    maxDate={ this.moment() }
                    minDate={ this.moment('2018-06-01') }
                    ranges={ this.buildDefaultRanges() }
                    onChange={ this.handleRangeChange }
                    moveRangeOnFirstSelection={ false }
                    twoStepChange
                    showMonthAndYearPickers
                    showMonthArrow
                    showSelectionPreview
                  />
                </div>
              </div>
            </div>
            <div className='export-modal-content__submit-row'>
              <ClydeButton
                className='export-modal-content__submit-button'
                onClick={ this.handleSubmitInvoiceExport }
                processing={ this.invoiceExportProcessing }
                successText='Done'
              >
                Export
              </ClydeButton>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default InvoiceExportModal;
