import React, { useCallback } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Transition from 'react-transition-group/Transition';

import { toggleProduct } from '../../../actions/productsActions';

const ProductSwitch = (props) => {
  const {
    productIds,
    index,
    active,
    reviewed,
    noMatch,
    _toggleProduct,
  } = props;

  const handleToggle = useCallback(() => {
    _toggleProduct(productIds, index, active);
  }, [_toggleProduct, productIds, index, active]);

  const duration = 200;
  const defaultStyle = { transition: 'left 200ms ease-in-out' };

  const transitionStyles = {
    entering: { left: '25px' },
    entered: { left: '25px' },
    exiting: { left: '4px' },
    exited: { left: '4px' },
  };

  if (reviewed && !noMatch) {
    const onOffClass = `product-switch--${active}`;
    return (
      <Transition in={ active !== 'off' } timeout={ duration }>
      { (state) => (
        <div onClick={ handleToggle } className={ `product-switch ${onOffClass}` }>
          <div className='product-switch__contents' style={ { ...defaultStyle, ...transitionStyles[state] } }>
            <span className='product-switch__contents__on'>ON</span>
            <span className='product-switch__contents__off'>OFF</span>
          </div>
        </div>
      ) }
      </Transition>
    );
  } if (reviewed && noMatch) {
    return (
      <div className='product-no-action product-no-action--red'>...</div>
    );
  }
  return (
      <div className='product-no-action product-no-action--green'>...</div>
  );
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    _toggleProduct: toggleProduct,
  }, dispatch);
}

export default connect(null, mapDispatchToProps)(ProductSwitch);
