import React from 'react';

class ClydeLoginForm extends React.Component {
  state = {
    password: '',
    errorMessage: '',
    error: false,
  }

  componentDidMount() {
    this.emailInput.focus();
  }

  // If platform changes, refocus and clear out any old error messages
  componentDidUpdate(prevProps) {
    if (prevProps.platform !== this.props.platform) {
      this.emailInput.focus();
      this.setState({ error: false, errorMessage: '' });
    }
  }

  handlePasswordChange = (e) => {
    this.setState({ password: e.target.value, error: false, errorMessage: '' });
  }

  handleEmailChange = (e) => {
    this.props.setEmail(e.target.value);
    this.setState({ error: false, errorMessage: '' });
  }

  handleSubmit = (e) => {
    e.preventDefault();
    if (this.props.email.trim().length && this.state.password.trim().length) {
      const data = {
        email: this.props.email,
        password: this.state.password,
      };
      this.setState({
        error: false,
        errorMessage: '',
      });
      this.props.submitAction(data);
    } else {
      this.setState({
        error: true,
        errorMessage: 'Username and password required',
      });
    }
  }

  render() {
    return (
      <div className='clyde-login-form clyde-login row'>
        <input
          required
          spellCheck='false'
          id='email'
          placeholder='Email address'
          className='col-12 clyde-login-form__input'
          type='text'
          name='email'
          data-test='email'
          onChange={ (e) => { this.handleEmailChange(e); } }
          ref={ (input) => { this.emailInput = input; } }
          value={ this.props.email } />
        <input
          required
          spellCheck='false'
          id='password'
          placeholder='Password'
          className='col-12 clyde-login-form__input'
          type='password'
          name='password'
          data-test='password'
          onChange={ (e) => { this.handlePasswordChange(e); } }
          value={ this.state.password } />
        <button type='submit' onClick={ (e) => { this.handleSubmit(e); } } className='col-lg-12 clyde-login-form__button' data-test='login'>Log in</button>
        { this.state.error && <span className='login-form__error col-lg-12'>{ this.state.errorMessage }</span> }
      </div>
    );
  }
}

export default ClydeLoginForm;
