import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router';

import {
  getShopifyShopOAuthLink,
  setLoginPlatform,
  submitUserLogin,
  setEmail,
  submitUserFirstLogin,
  clearExpiredToken,
  checkUserFirstLogin,
  resetUserPasswordByEmail,
} from '../../../actions/authActions';

import LogIn from './LogIn';

const mapStateToProps = (state) => state.auth;

const mapDispatchToProps = (dispatch) => bindActionCreators({
  _getShopifyShopOAuthLink: getShopifyShopOAuthLink,
  _setLoginPlatform: setLoginPlatform,
  _submitUserLogin: submitUserLogin,
  _setEmail: setEmail,
  _submitUserFirstLogin: submitUserFirstLogin,
  _clearExpiredToken: clearExpiredToken,
  _checkUserFirstLogin: checkUserFirstLogin,
  _resetUserPasswordByEmail: resetUserPasswordByEmail,
}, dispatch);

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(LogIn));
