import React, { Component } from 'react';
import { CSVDownload } from 'react-csv';
import { ClydeTextInput, ClydePillSelect } from '@joinclyde/clyde-react-components';

class ProductFilters extends Component {
  state = {
    typeFiltersValue: [],
    tagFiltersValue: [],
    manufacturerFiltersValue: [],
    downloadSkus: false,
  }

  UNSAFE_componentWillMount() {
    this.handleRefresh(this.props, true);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.refresh === false && nextProps.refresh === true) {
      if (nextProps.preservePage === true) {
        this.handleRefresh(nextProps, false);
      } else {
        this.handleRefresh(nextProps, true);
      }
    }
    if (nextProps.skuExport && nextProps.skuExport.length > 0) {
      this.setState({ downloadSkus: true }, () => {
        this.props._resetExport();
        this.setState({ downloadSkus: false });
      });
    }
  }

  handleTypeFilterChange = (value) => {
    this.setState({ typeFiltersValue: value });
    this.props._setFilterTypes(value);
  }

  handleTagFilterChange = (value) => {
    this.setState({ tagFiltersValue: value });
    this.props._setFilterTags(value);
  }

  handleManufacturerFilterChange = (value) => {
    this.setState({ manufacturerFiltersValue: value });
    this.props._setFilterManufacturers(value);
  }

  handleSetSearch = (e) => {
    this.props._setSearch(e.target.value);
  }

  handleSearchSubmit = (e) => {
    e.preventDefault();
    this.handleRefresh(this.props, true);
  }

  handleRefresh = (props, resetPage) => {
    this.props._runSearch(
      props.filterTypes,
      props.filterTags,
      props.filterManufacturers,
      props.productSearch,
      resetPage ? 1 : props.pageNumber,
    );
    this.props._getProductFilters();
  }

  render() {
    const {
      productTypes,
      productTags,
      productManufacturers,
      productSearch,
    } = this.props;

    const productTypeFilters = productTypes.map((type) => ({
      label: type.name,
      value: type.name,
    }));
    const productTagFilters = productTags.map((tag) => ({
      label: tag.name,
      value: tag.id,
    }));
    const productManufacturerFilters = productManufacturers.map((manufacturer) => ({
      label: manufacturer.name,
      value: manufacturer.name,
    }));

    return (
      <div className='product-filters-row row'>
        <div className='col-12'>

          <div className='row'>
            <div className='col-6 product-filter--padding-override'>
              <div className='product-filter product-filter--search'>
                <form onSubmit={ this.handleSearchSubmit }>
                  <ClydeTextInput
                    placeholder='Product Name or SKU'
                    className='product-search form-field'
                    onChange={ this.handleSetSearch }
                    value={ productSearch }
                    dataTestValue='productSearch'
                  />
                  <input type='submit' className='hidden-input'/>
                </form>
              </div>
            </div>
          </div>

          <div className='row'>
            <div className='col-3 product-filter--padding-override'>
              <div className='product-filter'>
                <ClydePillSelect
                  direction='row'
                  placeholder='Types'
                  value={ this.state.typeFiltersValue }
                  options={ productTypeFilters }
                  onChange={ this.handleTypeFilterChange }
                />
              </div>
            </div>

            <div className='col-3 product-filter--padding-override'>
              <div className='product-filter'>
                <ClydePillSelect
                  direction='row'
                  placeholder='Manufacturer'
                  value={ this.state.manufacturerFiltersValue }
                  options={ productManufacturerFilters }
                  onChange={ this.handleManufacturerFilterChange }
                />
              </div>
            </div>

            <div className='col-3 product-filter--padding-override'>
              <div className='product-filter'>
                {
                  this.props.platform !== 'Clyde'
                    ? <ClydePillSelect
                        direction='row'
                        placeholder='Tags'
                        style={ { height: '38px' } }
                        value={ this.state.tagFiltersValue }
                        closeOnSelect={ false }
                        multi
                        options={ productTagFilters }
                        removeSelected={ false }
                        onChange={ this.handleTagFilterChange }/>
                    : <div className='product-filter__export' onClick={ this.props._getSkuExport }>Export SKUs
                      { this.state.downloadSkus
                        && <CSVDownload target='_parent' filename='clyde_skus.csv' data={ this.props.skuExport } />
                      }
                    </div>
                }
              </div>
            </div>
          </div>

        </div>
      </div>
    );
  }
}

export default ProductFilters;
