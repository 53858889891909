import React, { Component } from 'react';
import { LoadingDots, ClydeButton } from '@joinclyde/clyde-react-components';

import { additionalContactValidations } from '../../../Utils/ProfileValidation';
import ContactForm from '../../Shared/ContactForm';

class ContactSettingsTile extends Component {
  handleSave = () => {
    const { contactInfo, _updateShopContacts, _setValidationErrors } = this.props;
    let hasErrors = false;
    const formErrors = contactInfo.contactList.map((contact) => {
      const contactErrors = additionalContactValidations(contact);
      if (Object.keys(contactErrors).length) {
        hasErrors = true;
      }
      return contactErrors;
    });

    if (!hasErrors) {
      _updateShopContacts(contactInfo);
    }
    _setValidationErrors(formErrors);
  }

  handleContactChange = (index) => (event) => {
    const { name, value } = event.target;
    const numeric = name === 'phoneNumber';
    this.props._setText({
      name, value, numeric, index,
    });
  }

  render() {
    const {
      contactList,
      formErrors,
      getProcessing,
      showSaveButton,
      saveProcessing,
    } = this.props.contactInfo;

    return (
      <div>
        <div className='settings-tile'>
          <div className='settings-tile__title'>Additional Contact Info</div>
          {
            getProcessing
              ? <div className='settings-tile__content settings-tile__content--profile'>
                  <div className='settings-tile__content__loading-container settings-tile__content__loading-container--profile'>
                    <LoadingDots dotColor='clydeBlack' dotSize='large' />
                  </div>
              </div>
              : <div className='settings-tile__content'>
                { contactList.map((contact, index) => (
                  <ContactForm
                    key={ `${contact.type}-form` }
                    contactInfo={ contact }
                    formErrors={ formErrors[index] }
                    handleChange={ this.handleContactChange(index) }
                  />
                )) }
              </div>
          }
        </div>
        <div className='profile-settings-button-container'>
          { showSaveButton && <ClydeButton
            id='settings-save-button'
            className='button--small settings-save-button'
            onClick={ this.handleSave }
            processing={ saveProcessing }
            successText='Saved'
          >
            Save
          </ClydeButton> }
        </div>
      </div>
    );
  }
}

export default ContactSettingsTile;
