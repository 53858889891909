import { connect } from 'react-redux';
import {
  getShopClaims,
  setClaimPageNumber,
  setClaimsFilter,
  setClaimsSort,
  setClaimSearchType,
  setClaimSearchValue,
  setWarrantyFilter,
} from '../../../actions/claims/claimsListActions';

import { getShopSettings } from '../../../actions/autopilotActions';

import ClaimsList from './ClaimsList';

const mapStateToProps = (state) => ({
  claims: state.claimsList.claims,
  claimsCount: state.claimsList.claimsCount,
  pageNumber: state.claimsList.pageNumber,
  selectedFilter: state.claimsList.selectedFilter,
  selectedSort: state.claimsList.selectedSort,
  searchType: state.claimsList.searchType,
  searchValue: state.claimsList.searchValue,
  fetchPending: state.claimsList.fetchPending,
  selectedWarrantyFilter: state.claimsList.selectedWarrantyFilter,
  manageOEMWarranty: state.autopilot.manageOEMWarranty,
});

const mapDispatchToProps = (dispatch) => ({
  _getShopClaims: (fetchOptions) => {
    dispatch(getShopClaims(fetchOptions));
  },
  _setClaimPageNumber: (pageNumber, fetchOptions) => {
    dispatch(setClaimPageNumber(pageNumber, fetchOptions));
  },
  _setClaimsFilter: (filter, fetchOptions) => {
    dispatch(setClaimsFilter(filter, fetchOptions));
  },
  _setClaimsSort: (sort, fetchOptions) => {
    dispatch(setClaimsSort(sort, fetchOptions));
  },
  _setSearchType: (type) => {
    dispatch(setClaimSearchType(type));
  },
  _setSearchValue: (value) => {
    dispatch(setClaimSearchValue(value));
  },
  _setWarrantyFilter: (value, fetchOptions) => {
    dispatch(setWarrantyFilter(value, fetchOptions));
  },
  _getShopSettings: () => {
    dispatch(getShopSettings());
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ClaimsList);
