import { wsSend } from './websocketActions';

export const setSearch = (searchString) => ({
  type: 'SET_SEARCH',
  payload: searchString,
});

export const setPage = (pageNumber) => ({
  type: 'SET_PAGE_NUMBER',
  payload: { pageNumber },
});

export const dismissLaunchInstr = () => ({
  type: 'DISMISS_LAUNCH_INSTR',
  payload: {},
});

export const runSearch = (types, tags, manufacturers, search, pageNumber) => (dispatch) => {
  dispatch({
    type: 'GET_PRODUCTS_PROCESSING',
    payload: {},
  });
  dispatch(wsSend({
    type: 'GET_PRODUCTS_REQ',
    payload: {
      types, tags, manufacturers, search, pageNumber,
    },
  }));
};

export const setFilterTypes = (filterTypes) => {
  const types = filterTypes.map((type) => ({
    name: type.value,
  }));
  return {
    type: 'SET_FILTER_TYPES',
    payload: { types },
  };
};

export const setFilterTags = (filterTags) => {
  const tags = filterTags.map((tag) => ({
    id: tag.value,
    name: tag.label,
  }));
  return {
    type: 'SET_FILTER_TAGS',
    payload: { tags },
  };
};

export const setFilterManufacturers = (filterManufacturers) => {
  const manufacturers = filterManufacturers.map((manufacturer) => ({ name: manufacturer.label }));

  return {
    type: 'SET_FILTER_MANUFACTURERS',
    payload: { manufacturers },
  };
};

export const getProductFilters = () => (dispatch) => {
  dispatch({
    type: 'GET_FILTERS_PROCESSING',
    payload: {},
  });
  dispatch(wsSend({
    type: 'GET_FILTERS_REQ',
    payload: {},
  }));
};

export const toggleProduct = (productIds, productIndex, active) => (dispatch) => {
  dispatch(wsSend({
    type: 'TOGGLE_PRODUCT_ACTIVE_REQ',
    payload: { productIds, productIndex, active },
  }));
};

export const toggleVariant = (productId, productIndex, variantIndex) => (dispatch) => {
  dispatch(wsSend({
    type: 'TOGGLE_PRODUCT_VARIANT_ACTIVE_REQ',
    payload: { productId, productIndex, variantIndex },
  }));
};

export const getLaunchingStatus = () => wsSend({
  type: 'GET_LAUNCHING_STATUS_REQ',
  payload: {},
});

export const launchProducts = () => (dispatch) => {
  dispatch({
    type: 'PRODUCTS_LAUNCH_PROCESSING',
    payload: {},
  });
  dispatch(wsSend({
    type: 'LAUNCH_SHOP_PRODUCTS_REQ',
    payload: {},
  }));
};

export const editProduct = (product) => ({
  type: 'OPEN_EDIT_PRODUCT_MODAL',
  payload: { product },
});

export const updateProductParts = (parts, productId) => (dispatch) => {
  dispatch(wsSend({
    type: 'UPDATE_PRODUCT_PARTS_REQ',
    payload: {
      parts,
      productId,
    },
  }));
};

export const updateProduct = (product, productId) => (dispatch) => {
  dispatch(wsSend({
    type: 'UPDATE_PRODUCT_REQ',
    payload: { id: productId, product },
  }));
  dispatch({
    type: 'SAVE_PRODUCT_PROCESSING',
    payload: {},
  });
};

export const createRegistrableProduct = (productIds) => (dispatch) => {
  dispatch(wsSend({
    type: 'CREATE_REGISTRABLE_PRODUCT_REQ',
    payload: { productIds },
  }));
};

export const deleteRegistrableProduct = (productIds) => (dispatch) => {
  dispatch(wsSend({
    type: 'DELETE_REGISTRABLE_PRODUCT_REQ',
    payload: { productIds },
  }));
};

export const openNewProductModal = () => ({
  type: 'OPEN_NEW_PRODUCT_MODAL',
  payload: {},
});

export const createProduct = (product, parts) => (dispatch) => {
  dispatch(wsSend({
    type: 'CREATE_PRODUCT_REQ',
    payload: { product, parts },
  }));
  dispatch({
    type: 'SAVE_PRODUCT_PROCESSING',
    payload: {},
  });
};

export const closeProductModal = () => ({
  type: 'CLOSE_PRODUCT_MODAL',
  payload: {},
});

export const openUploadModal = () => ({
  type: 'OPEN_UPLOAD_MODAL',
  payload: {},
});

export const getImportSummary = (productData) => (dispatch) => {
  dispatch(wsSend({
    type: 'IMPORT_SUMMARY_REQ',
    payload: { productData },
  }));
  dispatch({
    type: 'IMPORT_PRODUCTS_PROCESSING',
    payload: {},
  });
};

export const importProducts = (updateBySku, importFileKey, rowCount) => (dispatch) => {
  dispatch(wsSend({
    type: 'IMPORT_PRODUCTS_REQ',
    payload: { updateBySku, importFileKey, rowCount },
  }));
  dispatch({
    type: 'IMPORT_PRODUCTS_PROCESSING',
    payload: {},
  });
};

export const closeUploadModal = (importFileKey) => (dispatch) => {
  if (importFileKey && importFileKey !== '') {
    dispatch(wsSend({
      type: 'CANCEL_IMPORT',
      payload: { importFileKey },
    }));
  }
  dispatch({
    type: 'CLOSE_UPLOAD_MODAL',
    payload: {},
  });
};

export const getSkuExport = () => (dispatch) => {
  dispatch(wsSend({
    type: 'GET_SKU_EXPORT_REQ',
    payload: {},
  }));
  dispatch({
    type: 'GET_SKU_EXPORT_PROCESSING',
    payload: {},
  });
};

export const resetExport = () => ({
  type: 'RESET_EXPORT',
  payload: {},
});

export const getRateSheetExport = () => wsSend({
  type: 'GET_RATE_SHEET_EXPORT_REQ',
  payload: { },
});

export const toggleDownloadFalse = () => ({
  type: 'TOGGLE_DOWNLOAD_FALSE',
  payload: { },
});

export const updatePlatformProduct = (product) => (dispatch) => {
  dispatch(wsSend({
    type: 'UPDATE_PLATFORM_PRODUCT_REQ',
    payload: { product },
  }));
  dispatch({
    type: 'UPDATE_PLATFORM_PRODUCT_PROCESSING',
    payload: {},
  });
};

export default {};
