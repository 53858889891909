import React from 'react';
import classnames from 'classnames';
import { LoadingDots, SmallModal } from '@joinclyde/clyde-react-components';

import Enabled from './Enabled';
import Error from './Error';

const OAuthRedirectModal = (props) => {
  const {
    modalOpen,
    getProcessing,
    integration,
    _closeModal,
    _reloadIntegrationsTab,
  } = props;

  const integrationStatusSwitcher = () => {
    if (!modalOpen) {
      // don't show an integration status component if modal isn't open
      return null;
    }

    switch (integration.status) {
      case 'ENABLED':
        return (
          <Enabled
            integrationName={ integration.display.name }
            _closeModal={ _closeModal }
            _reloadIntegrationsTab={ _reloadIntegrationsTab } />
        );
      case 'ERROR':
        return (
          <Error
            integration={ integration }
            error={ integration.error }
            _closeModal={ _closeModal }/>
        );
      default:
        return null;
    }
  };

  const modalClass = classnames('integrations-oauth-redirect-modal', {
    'integrations-oauth-redirect-modal--loading': getProcessing,
    [`integrations-oauth-redirect-modal--${integration.status}`]: !!integration.status,
  });

  return (
    <SmallModal
      active={ modalOpen }
      className={ modalClass }
      closeModal={ _closeModal }
      processing={ false }>

      {
        getProcessing
          ? <LoadingDots dotColor='clydeBlack' dotSize='large' />
          : integrationStatusSwitcher()
      }

    </SmallModal>
  );
};

export default OAuthRedirectModal;
