import React from 'react';

import IntegrationTile from '../IntegrationTile';

import { ReactComponent as SearchResults } from '../../../../images/search-results.svg';
import { ReactComponent as IntegrationResults } from '../../../../images/integration-results.svg';

const IntegrationsSettingsResults = (props) => {
  const {
    availableIntegrations,
    filterValue,
    userId,
    _getFriggAuthRequirements,
    _openSetupModal,
    platform,
  } = props;

  if (availableIntegrations.length) {
    return (
      <div className='integration-settings-body__result'>
        {
          availableIntegrations.map((integration) => {
            // Marker 9/7/22: disabling affirm integration for shopify pp extension
            if (platform === 'Shopify' && integration.id === 'affirm') {
              return null;
            }
            return (<IntegrationTile
              integration={ integration }
              key={ integration.id }
              userId={ userId }
              _getFriggAuthRequirements={ _getFriggAuthRequirements }
              _openSetupModal={ _openSetupModal } />);
          })
        }
      </div>
    );
  }

  return (
    filterValue === 'connected'
      ? <div className='integration-settings-body__no-result'>
          <div><IntegrationResults/></div>
          <h3>No connected integrations</h3>
          <p>Browse our integrations to get started.</p>
        </div>
      : <div className='integration-settings-body__no-result'>
          <div><SearchResults/></div>
          <h3>No results found</h3>
          <p>We couldn't find any results. Try using our filters to find your integration.</p>
        </div>
  );
};

export default IntegrationsSettingsResults;
