import moment from 'moment';

const defaultState = {
  claims: [],
  pageNumber: 1,
  claimsCount: 0,
  selectedFilter: 'none',
  selectedSort: 'requiresAction',
  searchType: 'email',
  searchValue: '',
  fetchPending: false,
  selectedWarrantyFilter: 'extendedWarranty',
  issueTypes: [],

  // export modal states
  claimsExportProcessing: false,
  claimsExportFile: {
    data: null,
    name: null,
    download: false,
  },
};

const claimsListReducer = (state = defaultState, action) => {
  switch (action.type) {
    case 'GET_SHOP_CLAIMS_PROCESSING':
      return {
        ...state,
        fetchPending: true,
      };

    case 'GET_CLAIMS_RES':
      return {
        ...state,
        fetchPending: false,
        claims: action.payload.map((claim) => {
          let claimClass = '';
          if (claim.claimState === 'approved' || claim.claimState === 'repairInitiated') {
            claimClass = 'new';
          }
          if (claim.claimResolutionId) {
            claimClass = 'pending';
          }
          if (claim.claimClosed) {
            claimClass = 'resolved';
          }
          return {
            ...claim,
            lastUpdated: moment(claim.lastUpdated.slice(0, 10)).format('M/D/YY'),
            claimClass,
          };
        }),
      };

    case 'SET_CLAIMS_PAGE_NUMBER':
      return {
        ...state,
        pageNumber: action.payload.pageNumber,
      };

    case 'SET_CLAIMS_FILTER':
      return {
        ...state,
        pageNumber: 1,
        selectedFilter: action.payload.filter,
      };

    case 'SET_WARRANTY_FILTER':
      return {
        ...state,
        pageNumber: 1,
        selectedWarrantyFilter: action.payload.filter,
      };

    case 'SET_CLAIMS_SORT':
      return {
        ...state,
        pageNumber: 1,
        selectedSort: action.payload.sort,
      };

    case 'SET_CLAIMS_SEARCH_TYPE':
      return {
        ...state,
        searchType: action.payload.type,
      };

    case 'SET_CLAIMS_SEARCH_VALUE':
      return {
        ...state,
        searchValue: action.payload.value,
      };

    case 'CLAIM_COUNT_RES':
      return {
        ...state,
        fetchPending: false,
        claimsCount: Number(action.payload.count),
      };

    case 'CLAIMS_FOR_RESOLUTION_COUNT_RES':
      return {
        ...state,
        claimsForResolutionCount: action.payload,
      };
    case 'GET_LIMITED_CLAIMS_EXPORT_RES':
    case 'GET_ALL_CLAIMS_EXPORT_RES':
      return {
        ...state,
        claimsExportProcessing: false,
        claimsExportFile: {
          ...state.claimsExportFile,
          data: action.payload,
          name: action.payload.fileName,
          download: true,
        },
      };
    case 'CLAIMS_EXPORT_DOWNLOAD_FALSE':
      return {
        ...state,
        claimsExportFile: {
          ...state.invoiceExportFile,
          data: null,
          name: null,
          download: false,
        },
      };
    case 'ERROR':
      switch (action.payload.reqType) {
        case 'GET_CLAIMS_EXPORT_REQ':
          return {
            ...state,
            claimsExportProcessing: false,
            claimsExportFile: {
              ...state.claimsExportFile,
              data: null,
              name: null,
              download: false,
            },
          };
        default:
          return state;
      }
    case 'GET_ISSUE_TYPES_RES':
      return {
        ...state,
        issueTypes: action.payload.issueTypes,
      };
    default:
      return state;
  }
};

export default claimsListReducer;
