import React, { Component } from 'react';
import marked from 'marked';
import { ClydeCheckbox } from '@joinclyde/clyde-react-components';
import OnboardingDirections from '../Onboarding/OnboardingDirections';
import NavBar from '../NavBar';

import AcknowledgementsModal from './AcknowledgementsModal';

class TermsAndConditions extends Component {
  state = {
    checked: false,
    termsText: '',
    showModal: false,
  }

  UNSAFE_componentWillMount() {
    const termsFilePath = require('../../lib/terms.md');
    fetch(termsFilePath)
      .then((response) => response.text())
      .then((text) => {
        this.setState({
          termsText: marked(text),
        });
      });
  }

  checkAgree = () => {
    this.setState((prevState) => ({ checked: !prevState.checked }));
  }

  handleSubmit = () => {
    this.props.finishOnboarding();
    if (this.props.platform !== 'Clyde') {
      this.props.history.push('/contracts');
    } else {
      this.props.history.push('/products');
    }
  }

  showAcknowledgments = () => {
    this.setState({ showModal: true });
  }

  render() {
    const {
      props: { location },
      state: { showModal, termsText, checked },
    } = this;

    return (
      <>
        { showModal && <AcknowledgementsModal platform={ this.props.platform } _onAccept={ this.handleSubmit } /> }
        <div id='terms-and-conditions-component' >
          <div className='page-wrapper'>
            <NavBar activeTab={ location.pathname.slice(1) }/>
            <div className='page-container'>
              <div className='page-layout'>
                <OnboardingDirections pageKey='terms'/>
                <div className='page-layout__content'>
                  <div className='terms-container' data-test='termsContainer' >
                    <img className='terms-container__logo' alt='' src='/images/Clyde_Logo_Wordmark.svg'/>
                    <div className='terms-container__text' dangerouslySetInnerHTML={ { __html: termsText } }></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='process-div'>
            <div className='fixed-save-bar'>
              <div className='row terms-agree-row'>
                <div className='col-10'>
                  <div className='terms-agree no-select'>
                    {
                       <ClydeCheckbox
                        backgroundColor='clydeBlack'
                        checked={ checked }
                        onChange={ this.checkAgree }
                        id='terms-agree__checkbox'
                        scale='1.5, 1.5'
                        dataTestValue='readAndAgreeCheckbox'
                      />
                    }
                    <div onClick={ this.checkAgree } className='terms-agree__text'>
                      Check here to indicate that you have read and agree to the terms and conditions above.
                    </div>
                  </div>
                </div>
                <div className='col-2 terms-accept-container'>
                  <button
                    id='t-and-c-accept'
                    onClick={ this.showAcknowledgments }
                    className={ `terms-accept-button ${this.state.checked ? 'terms-accept-button--show' : 'terms-accept-button--hide'}` }
                    data-test='finish'
                  >
                    Finish
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default TermsAndConditions;
