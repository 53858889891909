import { wsSend } from './websocketActions';

export const showNewOrderModal = () => ({
  type: 'SHOW_NEW_ORDER_MODAL',
  payload: {},
});

export const hideNewOrderModal = () => ({
  type: 'HIDE_NEW_ORDER_MODAL',
  payload: {},
});

export const hideCatalogueModal = () => ({
  type: 'HIDE_CATALOGUE_MODAL',
  payload: {},
});

export const updateNewOrderCartPrice = (itemIdx, newPrice) => ({
  type: 'UPDATE_NEW_ORDER_CART_PRICE',
  payload: { itemIdx, newPrice },
});

export const updateNewOrderCartQuantity = (itemIdx, newQuantity) => ({
  type: 'UPDATE_NEW_ORDER_CART_QUANTITY',
  payload: { itemIdx, newQuantity },
});

export const updateNewOrderInput = (name, value) => ({
  type: 'UPDATE_NEW_ORDER_INPUT',
  payload: { name, value },
});

export const moveToCartStep = () => ({
  type: 'MOVE_TO_NEW_ORDER_CART_STEP',
  payload: {},
});

export const moveToCustomerStep = () => ({
  type: 'MOVE_TO_NEW_ORDER_CUSTOMER_STEP',
  payload: {},
});

export const updateNewOrderCustomerInput = (name, value) => ({
  type: 'UPDATE_NEW_ORDER_CUSTOMER_INPUT',
  payload: { name, value },
});

export const countProductCatalgoue = (shopId) => (dispatch) => {
  dispatch({
    type: 'NEW_ORDER_PRODUCT_COUNT_PROCESSING',
    payload: {},
  });
  dispatch(wsSend({
    type: 'NEW_ORDER_PRODUCT_COUNT_REQ',
    payload: { shopId },
  }));
};

export const runNewOrderSearch = (searchString, pageNumber) => (dispatch) => {
  dispatch({
    type: 'NEW_ORDER_PRODUCT_SEARCH_PROCESSING',
    payload: {},
  });
  dispatch(wsSend({
    type: 'NEW_ORDER_PRODUCT_SEARCH_REQ',
    payload: {
      searchString,
      pageNumber: pageNumber || 1,
    },
  }));
};

export const updateCart = (catalogueItem) => ({
  type: 'UPDATE_NEW_ORDER_CART',
  payload: catalogueItem,
});

export const submitNewOrder = (cart, customerInfo) => (dispatch) => {
  dispatch({
    type: 'NEW_ORDER_SUBMIT_PROCESSING',
    payload: {},
  });
  dispatch(wsSend({
    type: 'NEW_ORDER_SUBMIT_REQ',
    payload: { cart, customerInfo },
  }));
};

export const finishNewOrder = () => ({
  type: 'FINISH_NEW_ORDER',
  payload: {},
});

export default {};
