import React, { Component } from 'react';
import Pagination from 'react-js-pagination';
import { ClydeTextInput, ClydeTooltip, LoadingDots } from '@joinclyde/clyde-react-components';
import { Helmet } from 'react-helmet';

import SaleList from './SaleList';
import ResendSaleEmailModal from './ResendSaleEmailModal';
import InvoiceExportModal from './InvoiceExportModal';
import SalesImport from '../CSVUpload/SalesImport';

class Sales extends Component {
  UNSAFE_componentWillMount() {
    this.handleRefresh(this.props, true);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { refresh, shopId, _setSearch } = this.props;
    if (nextProps.shopId !== '' && shopId !== nextProps.shopId) {
      _setSearch('');
      const useProps = { ...nextProps, saleSearch: '' };
      this.handleRefresh(useProps, true);
    }

    if (refresh === false && nextProps.refresh === true) {
      if (nextProps.preservePage === true) {
        this.handleRefresh(nextProps, false);
      } else {
        this.handleRefresh(nextProps, true);
      }
    }
  }

  handlePageChange = (pageNumber) => {
    const { _setPage } = this.props;
    _setPage(pageNumber);
    this.setState({ activePage: pageNumber });
  }

  handleSetSearch = (e) => {
    const { _setSearch } = this.props;
    _setSearch(e.target.value);
  }

  handleSearchSubmit = (e) => {
    e.preventDefault();
    this.handleRefresh(this.props, true);
  }

  handleRefresh = (props, resetPage) => {
    const { _runSearch } = this.props;
    _runSearch(
      props.shopId,
      props.saleSearch,
      resetPage ? 1 : props.pageNumber,
    );
  }

  render() {
    const {
      importSalesModal,
      invoiceExportModal,
      pageNumber,
      resendContractSaleId,
      saleCount,
      sales,
      saleSearch,
      searchProcessing,
      showResendEmailModal,
      currency,
      _showConfirmResendEmail,
      _showImportSalesModal,
      _showInvoiceExportModal,
    } = this.props;

    return (
      <div className='page-container'>
        <Helmet>
          <title>Clyde | Sales</title>
        </Helmet>

        <div className='page-layout'>
          <div className='actions-container'>
            <ClydeTooltip
              hideIndicator
              id='import-invoice'
              label={ <div className='actions-container__tooltip__text'>Import sales data</div> }
              position='right'
            >
              <div
                className='actions-container__button'
                data-tip
                data-for='import-invoice'
                onClick={ _showImportSalesModal }
              >
                <img
                  alt='Import rate sheet'
                  className='actions-container__button__image actions-container__button__image--sales-import'
                  src='/images/Sales.svg'
                />
                <span className='actions-container__button__plus'>+</span>
              </div>
            </ClydeTooltip>
            <ClydeTooltip
              hideIndicator
              id='export-invoice'
              label={ <div className='actions-container__tooltip__text'>Export invoice data</div> }
              position='right'
            >
              <div
                className='actions-container__button'
                data-tip
                data-for='export-invoice'
                onClick={ _showInvoiceExportModal }
              >
                <img
                  alt='Export invoice data'
                  className='actions-container__button__image actions-container__button__image--export'
                  src='/images/download.svg'
                />
              </div>
            </ClydeTooltip>
          </div>

          <div className='title-wrapper'>
            <div className='row contract-sales-title-container'>
              <div className='col-12'>
                <h1>Contract Sales</h1>
              </div>
            </div>

            <br />

            <div className='row sale-pagination-row sale-pagination-row--top'>
              <div className='col-5'>
                <form className='sales-search-form' onSubmit={ this.handleSearchSubmit }>
                  <ClydeTextInput
                    dataTestValue='salesSearch'
                    disabled={ searchProcessing }
                    onChange={ this.handleSetSearch }
                    placeholder='Search (Name/Phone/Email/Clyde ID)'
                    value={ saleSearch }
                  />
                  <input type='submit' className='sales-search-form__hidden-input'/>
                </form>
              </div>
              <div className='col-5 offset-2'>
                <Pagination
                  activePage={ pageNumber }
                  itemClass='page-item'
                  itemsCountPerPage={ 25 }
                  linkClass='page-link'
                  onChange={ this.handlePageChange }
                  pageRangeDisplay={ 5 }
                  totalItemsCount={ Number(saleCount) }
                />
              </div>
            </div>

            {
              searchProcessing
                ? <LoadingDots dotColor='clydeBlack' dotSize='large' />
                : <SaleList
                    sales={ sales }
                    currency={ currency }
                    showConfirmResendEmail={ _showConfirmResendEmail }
                  />
            }

            { showResendEmailModal && <ResendSaleEmailModal saleId={ resendContractSaleId } /> }
            { invoiceExportModal && <InvoiceExportModal /> }
            { importSalesModal && <SalesImport /> }

            <div className='row sale-pagination-row sale-pagination-row--bottom'>
              <div className='col-5 offset-7'>
                <Pagination
                  activePage={ pageNumber }
                  itemClass='page-item'
                  itemsCountPerPage={ 25 }
                  linkClass='page-link'
                  onChange={ this.handlePageChange }
                  pageRangeDisplay={ 5 }
                  totalItemsCount={ Number(saleCount) }
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Sales;
