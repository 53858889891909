import { connect } from 'react-redux';

function PlatformGate({
  platform,
  isPlatformShop,
  isApiShop,
  children,
}) {
  if (isPlatformShop && platform === 'Clyde') {
    return null;
  }
  if (isApiShop && platform !== 'Clyde') {
    return null;
  }

  return children;
}

export default connect((state) => ({
  platform: state.auth.platform,
}))(PlatformGate);
