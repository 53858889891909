import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import {
  closeUploadSalesModal,
  getSalesImportSummary,
  importSales,
} from '../../actions/salesActions';

import CVSUpload from './CSVUpload';

const mapStateToProps = (state) => {
  const importPrompts = {
    uploadInfo: 'Upload a CSV using our template and we\'ll record your sales',
    importTitle: 'Import Sales',
    downloadPrompt: 'Download the CSV template to ensure your sales are properly recorded.',
    submitInfo: 'Once your file is verified we’ll review the orders and finish the import.',
  };

  const csvTemplateString = 'OrderID,OrderSource,ProductSKU,ContractSKU,Price,ProductLineItemID,ContractLineItemID,Quantity,FirstName,LastName,Country,Address1,Address2,Province,City,Zip,Email,Phone,OrderDate';

  return {
    importProcessing: state.sales.importSalesProcessing,
    importStep: state.sales.importStep,
    importSummary: state.sales.importSummary,
    importFileKey: state.sales.importFileKey,
    importFileRowCount: state.sales.importFileRowCount,
    importResult: state.sales.importResult,
    isProductCSV: false,
    csvTemplateString,
    importPrompts,
  };
};

const mapDispatchToProps = (dispatch) => bindActionCreators({
  _closeUploadModal: closeUploadSalesModal,
  _getImportSummary: getSalesImportSummary,
  _submitImport: importSales,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(CVSUpload);
