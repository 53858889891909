import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import { nextStep, setStep } from '../../../actions/onboardingActions';
import { completeProfileStep, setValidationErrors } from '../../../actions/profileActions';
import {
  setValidationErrors as setContactValidationErrors,
} from '../../../actions/contactInfoActions';
import { completeCustomizeStep } from '../../../actions/customizeActions';
import { completeBillingStep } from '../../../actions/billingActions';

import OnboardingProcess from './OnboardingProcess';

const mapStateToProps = (state) => ({
  onboarding: state.onboarding,
  profile: state.profile,
  customize: state.customize,
  platform: state.auth.platform,
  technicalContact: state.contactInfo.contactList[0],
  claimsContact: state.contactInfo.contactList[2],
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  _setStep: setStep,
  _nextStep: nextStep,
  _setValidationErrors: setValidationErrors,
  _setContactValidationErrors: setContactValidationErrors,
  _completeProfileStep: completeProfileStep,
  _completeCustomizeStep: completeCustomizeStep,
  _completeBillingStep: completeBillingStep,
}, dispatch);

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps,
)(OnboardingProcess));
