import React, { Component } from 'react';
import {
  ClydeButton,
  SuccessAnimation,
  ClydeTextInput,
  ClydeCheckbox,
} from '@joinclyde/clyde-react-components';

class CancelContractModal extends Component {
  state = {
    confirmation: '',
    buttonDisabled: true,
    issueShopifyRefund: true,
  }

  handleChangeConfirmation = (e) => {
    const confirmation = e.target.value;
    const buttonDisabled = confirmation.trim().toLowerCase() !== 'yes';
    this.setState({ confirmation, buttonDisabled });
  }

  handleConfirmCancel = (ppSale) => {
    const {
      cancelContract,
      orderId,
      shopId,
      _confirmCancelContract,
      _confirmCancelPpContract,
    } = this.props;

    const { buttonDisabled } = this.state;
    if (!buttonDisabled) {
      if (ppSale) {
        _confirmCancelPpContract(cancelContract.saleId, orderId, shopId, this.state.issueShopifyRefund);
      } else {
        _confirmCancelContract(cancelContract.saleId, orderId, shopId, this.state.issueShopifyRefund);
      }
    }
  }

  refreshOrder = () => {
    const { orderId, _cancelCancelContract, _getOrderDetails } = this.props;
    _cancelCancelContract();
    _getOrderDetails(orderId);
  }

  render() {
    const { cancelContract, platform, _cancelCancelContract } = this.props;

    const ppSale = cancelContract.purchaseSource === 'HI';
    const headerText = ppSale ? 'Cancel post purchase contract' : 'Cancel contract';

    return (
      <div className='cop-modal-background' onClick={ _cancelCancelContract }>
        <div className='cop-modal-foreground' onClick={ (e) => e.stopPropagation() }>
          {
            cancelContract.step !== 'finish'
              ? <React.Fragment>
                  <div className='cop-modal-header'>
                    <button className='button-nostyle cop-modal-header__cancel' onClick={ _cancelCancelContract }>
                      &#x2715;
                    </button>
                    <h1 className='cop-modal-header__header'>{ headerText }</h1>
                    <p className='cop-modal-header__explanation'>Are you sure you want to cancel this contract?</p>
                    {
                      ppSale
                        ? <p className='cop-modal-header__explanation'>
                            Clyde will issue a refund to your customer for this contract.
                          </p>
                        : <p className='cop-modal-header__explanation'>
                            Cancelling this contract will issue credits to your shop
                            <strong>if this sale has already been billed for by Clyde.</strong>
                          </p>
                    }
                  </div>

                  <div className='cop-modal-content cop-cancel-modal'>
                    <div className='cop-cancel-modal__contact'>
                      <label htmlFor='cancel-contact-input' className='cop-cancel-modal__label'>
                        You must type 'yes'
                      </label>
                      <ClydeTextInput
                        type='text'
                        id='cancel-contact-input'
                        className='cop-cancel-modal__input'
                        onChange={ this.handleChangeConfirmation }
                        value={ this.state.confirmation }
                      />
                    </div>
                  </div>

                  { platform === 'Shopify' && <div className='claim-detail-resolution__group claim-detail-resolution__group--row'>
                    <ClydeCheckbox
                      backgroundColor='clydeWhite'
                      className=''
                      id='issueShopifyRefund'
                      name='issueShopifyRefund'
                      onChange={ () => { this.setState({ issueShopifyRefund: !this.state.issueShopifyRefund }); } }
                      value={ this.state.issueShopifyRefund }
                      checked={ this.state.issueShopifyRefund }
                    />
                    <label htmlFor='fullReplacement'>Issue customer refund through Shopify</label>
                  </div> }

                  <div className='cop-modal-actions'>
                    <ClydeButton
                      id='confirm-cancel-button'
                      className='cop-modal-actions__confirm'
                      onClick={ () => this.handleConfirmCancel(ppSale) }
                      processing={ cancelContract.processing }
                      disabled={ this.state.buttonDisabled }
                      successText='Cancelled'
                    >
                      Confirm and cancel
                    </ClydeButton>
                  </div>
                </React.Fragment>
              : <div className='cop-modal-foreground__action-complete'>
                  <SuccessAnimation text='Cancelled!' onSuccess={ this.refreshOrder } />
                </div>
          }
        </div>
      </div>
    );
  }
}

export default CancelContractModal;
