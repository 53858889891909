import React from 'react';

import { SmallModal, SuccessAnimation } from '@joinclyde/clyde-react-components';

import ClydeControlled from './Integrations/ClydeControlled';
import FriggControlled from './Integrations/FriggControlled';

const SetupModal = (props) => {
  const {
    isFriggIntegration,
    isModalOpen,
    submitSuccess,
    _closeModal,
    _resetModal,
  } = props;

  let SetupForm = null;
  if (isFriggIntegration) {
    SetupForm = FriggControlled;
  } else {
    SetupForm = ClydeControlled;
  }

  return (
    <SmallModal
      active={ isModalOpen }
      className='integrations-setup-modal'
      closeModal={ _closeModal }
      processing={ false }>

      {
        submitSuccess
          ? <SuccessAnimation
              className='integrations-setup-modal__success-animation'
              onSuccess={ _resetModal }
              text='Integration connected!' />
          : <SetupForm />
      }

    </SmallModal>
  );
};

export default SetupModal;
