import React, { Component } from 'react';
import JSONPretty from 'react-json-pretty';

import OrderInfo from './OrderInfo';
import ContractItem from './Items/ContractItem';
import ProductItem from './Items/ProductItem';
import DropdownButton from '../../Utils/DropdownButton';
import { formatDate } from '../../../lib/timeUtils';

class ProductsView extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
    const {
      customers: { selectedOrder },
      _getOrderDetails,
      _getShopSettings,
    } = this.props;

    _getOrderDetails(selectedOrder.clydeOrderId);
    _getShopSettings(true);
  }

  buildOrderActions = () => {
    const {
      customers: {
        selectedOrder: {
          saleIds,
          /* Removed 4/6/2021 - Edg - removed for spam reasons, we may turn back on if we repair
          // eligibleProducts,
          */
          openClaims,
        },
        /* Removed 4/6/2021 - Edg - removed for spam reasons, we may turn back on if we repair
        // selectedCustomer: { dbContactType },
        */
      },
      _showResendSaleEmail,
      /* Removed 4/6/2021 - Edg - removed for spam reasons, we may turn back on if we repair
      // _showResendPpEmail,
      */
      _showChangeOrderCustomer,
    } = this.props;
    const orderActions = [];

    if (saleIds.length) {
      orderActions.push({
        text: 'Resend contract confirmation email',
        clickHandler: () => {
          _showResendSaleEmail();
        },
      });
    }
    /* Removed 4/6/2021 - Edg - removed for spam reasons, we may turn back on if we repair
    if (eligibleProducts.length && dbContactType === 'email') {
      orderActions.push({
        text: 'Send Post Purchase Email',
        clickHandler: () => { _showResendPpEmail(); },
      });
    }
    */
    if (!openClaims) {
      orderActions.push({
        text: 'Transfer order to new customer',
        clickHandler: _showChangeOrderCustomer,
      });
    }

    return orderActions;
  };

  buildEligibleBulkActions = () => {
    const {
      customers: { selectedCustomer },
      _showBulkContractSale,
    } = this.props;
    const eligibleBulkActions = [];

    if (selectedCustomer.dbContactType === 'email') {
      eligibleBulkActions.push({
        text: 'Choose eligible products to add contracts to',
        clickHandler: () => {
          _showBulkContractSale();
        },
      });
    }

    return eligibleBulkActions;
  };

  render() {
    const {
      currency,
      customers: {
        actionsProcessing,
        isProcessing,
        ordersOverview,
        selectedCustomer,
        selectedOrder,
        modal,
        receipt: {
          url: receiptUrl,
          processing: receiptProcessing,
        },
      },
      shopSettings: {
        shopCurrency,
        manageOEMWarranty,
      },
      history,
      step,
      _saveLineItemSerialNumber,
      _backToOrderSearch,
      _updateDeliveryDate,
      _getReceiptFile,
      _resetReceiptFile,
    } = this.props;

    const orderActions = step === 'products' && this.buildOrderActions();
    const eligibleBulkActions = step === 'products' && this.buildEligibleBulkActions();

    const orderIdVal = selectedOrder.merchantOrderId
      ? `${selectedOrder.merchantOrderId} / ${selectedOrder.orderId}`
      : selectedOrder.orderId;
    return (
      <section className='products-view'>
        <div className='products-view__back'>
          <button
            className='button-nostyle products-view__back__button'
            onClick={ _backToOrderSearch }
          >
            <div className='products-view__back__button__image' data-test='backButton'>
              <div className='products-view__back__button__image__arrow' />
            </div>
            <span className='products-view__back__button__text'>
              Back to { ordersOverview.customerName }'s orders
            </span>
          </button>
        </div>

        <div className='products-view__header'>
          <div className='products-view__header__title'>
            <h1>Order { orderIdVal }</h1>
            <span>
              Purchase Date:
              { formatDate(selectedOrder.orderDate || selectedOrder.contractEffectiveDate) }
            </span>
          </div>
          { orderActions.length > 0 ? (
            <DropdownButton
              customClasses='cop-button-big'
              options={ orderActions }
              modalOpen={ !!modal }
            />
          ) : null }
        </div>

        <OrderInfo
          isProcessing={ isProcessing }
          selectedCustomer={ selectedCustomer }
          selectedOrder={ selectedOrder }
          step={ step }
          receiptUrl= { receiptUrl }
          receiptProcessing={ receiptProcessing }
          _getReceiptFile={ _getReceiptFile }
          _resetReceiptFile={ _resetReceiptFile }
        />

        <section className='products-view__active-contracts'>
          <div className='products-view__active-contracts__header'>
            <h2>ACTIVE CONTRACTS ({ selectedOrder.activeContractCount })</h2>
          </div>
          { selectedOrder.activeContracts.map((contract, idx) => (
            <ContractItem
              key={ `ac-${idx}` }
              contract={ contract }
              history={ history }
              manageOEMWarranty={ manageOEMWarranty }
              isActive
              province={ selectedOrder.address.province }
              shopCurrency={ shopCurrency }
            />
          )) }
        </section>

        <section className='products-view__eligible-products'>
          <div className='products-view__eligible-products__header'>
            <h2>ELIGIBLE PRODUCTS ({ selectedOrder.eligibleProductCount })</h2>
            { selectedOrder.eligibleProductCount > 0
              && eligibleBulkActions.length > 0 && (
                <DropdownButton
                  customClasses='cop-button-big'
                  options={ eligibleBulkActions }
                  modalOpen={ !!modal }
                />
            ) }
          </div>
          { selectedOrder.eligibleProducts.map((product, idx) => (
            <ProductItem
              key={ `ep-${idx}` }
              product={ product }
              currency={ currency }
              clydeOrderId={ selectedOrder.clydeOrderId }
              updateDeliveryDate={ _updateDeliveryDate }
              deliveryDateProcessing={ actionsProcessing.updateDeliveryDate }
              isEligible
              _saveLineItemSerialNumber={ _saveLineItemSerialNumber }
              manageOEMWarranty={ manageOEMWarranty }
            />
          )) }
        </section>

        <section className='products-view__expired-contracts'>
          <div className='products-view__expired-contracts__header'>
            <h2>
              FULFILLED CONTRACTS ({ selectedOrder.fulfilledContractCount })
            </h2>
          </div>
          {
            selectedOrder.fulfilledContracts.map((contract, idx) => (
              <ContractItem
                key={ `cc-${idx}` }
                contract={ contract }
                history={ history }
                isActive={ false }
                manageOEMWarranty={ manageOEMWarranty }
                province={ selectedOrder.address.province }
              />
            ))
          }
        </section>

        <section className='products-view__expired-contracts'>
          <div className='products-view__expired-contracts__header'>
            <h2>
              CANCELLED CONTRACTS ({ selectedOrder.cancelledContractCount })
            </h2>
          </div>
          {
            selectedOrder.cancelledContracts.map((contract, idx) => (
              <ContractItem
                key={ `cc-${idx}` }
                contract={ contract }
                history={ history }
                isActive={ false }
                isCanceled
                manageOEMWarranty={ manageOEMWarranty }
                province={ selectedOrder.address.province }
                shopCurrency={ shopCurrency }
              />
            ))
          }
        </section>

        <section className='products-view__expired-contracts'>
          <div className='products-view__expired-contracts__header'>
            <h2>EXPIRED CONTRACTS ({ selectedOrder.expiredContractCount })</h2>
          </div>
          {
            selectedOrder.expiredContracts.map((contract, idx) => (
              <ContractItem
                key={ `ec-${idx}` }
                contract={ contract }
                history={ history }
                isActive={ false }
                manageOEMWarranty={ manageOEMWarranty }
                province={ selectedOrder.address.province }
                shopCurrency={ shopCurrency }
              />
            ))
          }
        </section>

        <section className='products-view__ineligible-products'>
          <div className='products-view__ineligible-products__header'>
            <h2>
              INELIGIBLE PRODUCTS ({ selectedOrder.ineligibleProductCount })
            </h2>
          </div>
          { selectedOrder.ineligibleProducts.map((product, idx) => (
            <ProductItem
              key={ `ip-${idx}` }
              product={ product }
              currency={ currency }
              clydeOrderId={ selectedOrder.clydeOrderId }
              updateDeliveryDate={ _updateDeliveryDate }
              deliveryDateProcessing={ actionsProcessing.updateDeliveryDate }
              isEligible={ false }
              _saveLineItemSerialNumber={ _saveLineItemSerialNumber }
              manageOEMWarranty={ manageOEMWarranty }
            />
          )) }
        </section>

        { selectedOrder.meta && (
          <section className='products-view__meta'>
            <div className='products-view__meta__header'>
              <h2>ORDER METADATA</h2>
            </div>
            <JSONPretty data={ selectedOrder.meta } />
          </section>
        ) }
      </section>
    );
  }
}

export default ProductsView;
