import { connect } from 'react-redux';

import { closeSetupModal } from '../../../../../../actions/integrationsActions';

import SharedButtons from './SharedButtons';

const mapStateToProps = (state) => ({
  submitProcessing: state.integrations.setupModal.submitProcessing,
});

const mapDispatchToProps = (dispatch) => ({
  _closeModal: () => {
    dispatch(closeSetupModal());
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SharedButtons);
