import React from 'react';
import { LoadingDots } from '@joinclyde/clyde-react-components';

const CustomerCard = (props) => {
  const {
    customer: {
      firstName,
      lastName,
      altName,
      addressChangeNote,
      email,
      phone,
      address,
      city,
      state,
      zip,
    },
    history,
    processing,
    _populateCopWithCustomer,
  } = props;

  const goToCopCustomerPage = () => {
    const customerInfo = { firstName, lastName };
    if (email) {
      customerInfo.contact = email;
      customerInfo.contactType = 'email';
    } else {
      customerInfo.contact = phone;
      customerInfo.contactType = 'phone';
    }

    _populateCopWithCustomer(customerInfo);
    history.push('/customers');
  };

  return (
    <div className='claims-detail-card claims-detail-card--customer'>
      <h3>Customer</h3>

      {
        processing
          ? <LoadingDots className='claims-detail-card__loading' dotColor='clydeBlack' dotSize='medium' />
          : <React.Fragment>
              <div className='claims-detail-card__group'>
                <span className='claims-detail-card__group__title'>Customer:</span>
                <button
                  className='button-nostyle button-fakelink claims-detail-card__group__info'
                  onClick={ goToCopCustomerPage }>
                  { firstName } { lastName }
                </button>
              </div>
              <div className='claims-detail-card__group'>
                <span className='claims-detail-card__group__title'>Email:</span>
                <span className='claims-detail-card__group__info'>{ email || 'N/A' }</span>
              </div>
              <div className='claims-detail-card__group'>
                <span className='claims-detail-card__group__title'>Phone:</span>
                <span className='claims-detail-card__group__info'>{ phone || 'N/A' }</span>
              </div>
              <div className='claims-detail-card__group'>
                <span className='claims-detail-card__group__title'>Address:</span>

                { altName && <span className='claims-detail-card__group__info'>{ altName }</span> }
                <span className='claims-detail-card__group__info'>{ address }</span>
                <span className='claims-detail-card__group__info'>{ city }, { state } { zip }</span>
                { addressChangeNote
                  && <>
                    <br />
                    <span className='claims-detail-card__group__info'><strong>Address Was Updated</strong></span>
                    <span className='claims-detail-card__group__info'>Reason Given: { addressChangeNote }</span>
                  </>
                }

              </div>
            </React.Fragment>
      }

    </div>
  );
};

export default CustomerCard;
