import React from 'react';
import { ClydeButton } from '@joinclyde/clyde-react-components';

const SharedButtons = (props) => {
  const { buttonType, submitProcessing, _closeModal } = props;
  let Button = null;

  switch (buttonType) {
    case 'contact':
      Button = (
        <a className='integrations-setup-modal__buttons__link' href='mailto:support@joinclyde.com'>
          Contact Clyde
        </a>
      );
      break;
    case 'oauth2':
      Button = (
        <button type='submit' className='integrations-setup-modal__buttons__link'>
          Connect Integration
        </button>
      );
      break;
    case 'basic':
    default:
      Button = (
        <ClydeButton
          className='integrations-setup-modal__buttons__connect'
          processing={ submitProcessing }
          type='submit'
        >
          Connect Integration
        </ClydeButton>
      );
      break;
  }

  return (
    <div className='integrations-setup-modal__buttons'>
      { Button }

      <button
        className='button-no-style integrations-setup-modal__buttons__cancel'
        disabled={ submitProcessing }
        onClick={ _closeModal }
        type='button'>
        Cancel
      </button>
    </div>
  );
};

export default SharedButtons;
