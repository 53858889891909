import React, { Component } from 'react';
import classnames from 'classnames';
import { ClydeButton, ClydeCheckbox } from '@joinclyde/clyde-react-components';
import { Currency } from '../../../Utils/Currency';

class BulkCheckout extends Component {
  state = {
    percentError: false,
    premiumCollected: false,
  }

  handleCheck = (e) => {
    if (this.state.premiumCollected) {
      e.currentTarget.blur();
    }
    this.setState((prevState) => ({ premiumCollected: !prevState.premiumCollected }));
  }

  isPercentError = (value) => {
    const numExp = /^[0-9]*$/;
    return !numExp.test(value); // regex tests if string contains *only* numbers
  }

  calculateCart = () => {
    const { cart } = this.props;
    const cartContracts = Object.values(cart); // array of arrays
    let totalPremiumCollected = 0;
    let totalMarkup = 0;
    let runningTotal = 0;
    let contractCount = 0;

    for (const lineItemContracts of cartContracts) {
      for (const contract of lineItemContracts) {
        if (!contract) {
          continue;
        }
        contractCount += 1;
        totalPremiumCollected += (contract.baseCost + contract.backendFee + contract.platformFee);
        totalMarkup += contract.markup;
        runningTotal = parseFloat((totalPremiumCollected + totalMarkup).toFixed(2));
      }
    }

    return {
      contractCount,
      totalPremiumCollected: totalPremiumCollected.toFixed(2),
      totalMarkup: totalMarkup.toFixed(2),
      totalCost: runningTotal.toFixed(2),
    };
  }

  render() {
    const { submitProcessing, recordSales } = this.props;
    const { percentError, premiumCollected } = this.state;
    const {
      contractCount, totalPremiumCollected, totalMarkup, totalCost,
    } = this.calculateCart();

    return (
      <div className='cop-modal-actions cop-bulk-sale-checkout'>
        <div className='cop-bulk-sale-checkout__info'>
          <span>Contract quantity:</span>
          <span>{ contractCount }</span>
        </div>
        <div className='cop-bulk-sale-checkout__info'>
          <span>Premium cost collected:</span>
          <Currency amount={ totalPremiumCollected } />
        </div>
        <div className='cop-bulk-sale-checkout__info'>
          <span>Your markup:</span>
          <Currency amount={ totalMarkup } />
        </div>
        { percentError
          && <div className='cop-bulk-sale-checkout__info cop-bulk-sale-checkout__info--error'>
            <span>Percentage must be a positive, whole-number value.</span>
          </div>
        }
        <div className='cop-bulk-sale-checkout__line' />
        <div className='cop-bulk-sale-checkout__info'>
          <span>Total:</span>
          <Currency amount={ totalCost } />
        </div>
        <div className='cop-bulk-sale-checkout__check'>
          <ClydeCheckbox
            backgroundColor='clydeWhite'
            id='vow'
            name='premiumCollected'
            className={ classnames('cop-bulk-sale-checkout__check__input',
              { 'cop-bulk-sale-checkout__check__input--disabled': contractCount === 0 || percentError }) }
            disabled={ contractCount === 0 || percentError }
            checked={ premiumCollected }
            onChange={ this.handleCheck }
          />

          <label
            htmlFor='vow'
            className={ classnames('cop-bulk-sale-checkout__check__label',
              { 'cop-bulk-sale-checkout__check__label--disabled': contractCount === 0 || percentError }) }>
            I have collected at least <Currency amount={ totalCost } /> for this transaction
             or have decided to buy this contract on behalf of the customer.
          </label>
        </div>

        <ClydeButton
          id='confirmUpdateButton'
          className='cop-modal-actions__confirm'
          onClick={ recordSales }
          processing={ submitProcessing }
          disabled={ contractCount === 0 || !premiumCollected || percentError }
          successText={ `Sale${contractCount > 1 ? 's' : ''} Recorded` }
          preserveButton
        >
          { `Record sale${contractCount > 1 ? 's' : ''}` }
        </ClydeButton>
      </div>
    );
  }
}

export default BulkCheckout;
