// Keys come from Frigg API
// https://documenter.getpostman.com/view/10679972/TzJpgeTq#19967f97-f764-450e-87d0-9e0d1c860500
const integrationStatusMap = {
  ENABLED: 'Connected',
  DISABLED: 'Connect Account',
  NEEDS_CONFIG: 'Needs Configuration',
  PROCESSING: 'Processing',
  ERROR: 'Integration Error',
  LINK_ONLY: 'Connect',
};

const determineIntegrationStatus = (integration) => {
  if (integration.id === 'alloy') {
    return {
      text: integrationStatusMap.LINK_ONLY,
      value: 'LINK_ONLY',
      link: 'https://docs.joinclyde.com/docs/alloy',
    };
  }

  if (integration.friggIntegration) {
    if (integration.integrationOptions) {
      const { status } = integration.integrationOptions;
      return {
        text: integrationStatusMap[status],
        value: status,
      };
    }
    return {
      text: integrationStatusMap.DISABLED,
      value: 'DISABLED',
    };
  }

  if (integration.id === 'affirm') {
    if (integration.clydeOptions.showAffirm) {
      return {
        text: integrationStatusMap.ENABLED,
        value: 'ENABLED',
      };
    }
    return {
      text: integrationStatusMap.DISABLED,
      value: 'DISABLED',
    };
  }

  if (integration.id === 'klaviyo') {
    if (integration.clydeOptions.klaviyoIntegration && integration.clydeOptions.sendKlaviyoEvents) {
      return {
        text: integrationStatusMap.ENABLED,
        value: 'ENABLED',
      };
    }
    if (!integration.clydeOptions.klaviyoIntegration || !integration.clydeOptions.sendKlaviyoEvents) {
      return {
        text: integrationStatusMap.DISABLED,
        value: 'DISABLED',
      };
    }
  }
  return {
    text: integrationStatusMap.DISABLED,
    value: 'Disabled',
  };
};

export default determineIntegrationStatus;
