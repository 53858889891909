import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { logout } from '../../actions/authActions';

import NavBar from './NavBar';

const mapStateToProps = (state) => ({
  ...state.onboarding,
  isAdmin: state.auth.accountType === 'admin',
  loading: state.auth.loading,
  platform: state.auth.platform,
  profile: state.profile,
  claimsForResolutionCount: state.claimsList.claimsForResolutionCount,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({ _logout: logout }, dispatch);

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(NavBar));
