import { wsSend } from './websocketActions';

export const setText = ({
  name, value, numeric, index,
}) => {
  let payloadValue = value;
  // Marker: 2021/10/11 EK Originally used isNaN - this has an implicit type coercion
  // However we want to display that the coercion is happening, we do this by adding '+' and using Number.isNaN
  if (numeric && Number.isNaN(+value.replace(/-| /g, ''))) {
    return { type: '', payload: {}};
  }
  if (numeric) { payloadValue = value.replace('.', ''); }
  if (name === 'province') { payloadValue = value.toUpperCase(); }
  return {
    type: 'SET_CONTACT_TEXT_FIELD',
    payload: {
      name,
      value: payloadValue,
      index,
    },
  };
};

export const updateShopContacts = (contactInfo) => {
  const { contactList } = contactInfo;

  return (dispatch) => {
    dispatch({
      type: 'SHOP_CONTACTS_SAVE_PROCESSING',
      payload: {},
    });
    dispatch(wsSend({
      type: 'UPDATE_SHOP_CONTACTS_REQ',
      payload: { contactInfo: contactList },
    }));
  };
};

export const setValidationErrors = (formErrors) => ({
  type: 'SET_CONTACT_VALIDATION_ERRORS',
  payload: { formErrors },
});

export default {};
