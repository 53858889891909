import React from 'react';

import BillingSettingsTile from './BillingSettingsTile';
import ContactSettingsTile from './ContactSettingsTile';
import DevelopersSettingsTile from './DevelopersSettingsTile';
import ProfileSettingsTile from './ProfileSettingsTile';
import UserSettingsTile from './UserSettingsTile';

const AccountTab = (props) => {
  const { accountType, platform } = props;

  let allowedTiles = [];
  switch (accountType) {
    case 'admin':
      allowedTiles = [
        { id: 'billingSettings', Tile: BillingSettingsTile },
        { id: 'profileSettings', Tile: ProfileSettingsTile },
        { id: 'contactSettings', Tile: ContactSettingsTile },
        { id: 'userSettings', Tile: UserSettingsTile },
        { id: 'developersSettings', Tile: DevelopersSettingsTile },
      ];
      break;
    case 'basic':
    default:
      allowedTiles = [
        { id: 'userSettings', Tile: UserSettingsTile },
      ];
      break;
  }

  return (
    <div className='settings-tab settings-tab--account'>
      {
        allowedTiles.map((tileObject) => {
          const { id, Tile } = tileObject;
          return (
            <div className='settings-tile-container' key={ id }>
              <Tile platform={ platform }/>
            </div>
          );
        })
      }
    </div>
  );
};

export default AccountTab;
