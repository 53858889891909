import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { exitSale, selectVariant, selectContract } from '../../../actions/contractSaleActions';

import SaleDetails from './SaleDetails';

const mapStateToProps = (state) => ({
  ...state.contractSale,
  displayName: state.customize.displayName,
  shopBranded: state.customize.branded,
  currency: state.billing.currency,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  _exitSale: exitSale,
  _selectVariant: selectVariant,
  _selectContract: selectContract,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(SaleDetails);
