import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import Alert from 'react-s-alert';

import 'react-s-alert/dist/s-alert-default.css';
import 'react-s-alert/dist/s-alert-css-effects/stackslide.css';
import errorMessages from '../../lib/errorMessages';

import { clearError } from '../../actions/errorActions';

class Errors extends React.Component {
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.errors.length !== 0) {
      const errorName = nextProps.errors[0];
      // If the shop id doesn't match the ws/cookie details, reload the page.
      if (errorName === 'ShopIdMismatch') {
        window.location.reload();
      } else {
        const errorMessage = errorMessages[errorName];
        Alert.error(errorMessage, {
          position: 'top',
          effect: 'stackslide',
          timeout: Errors.getTimeout(errorName),
        });
      }
      this.props._clearError();
    }
  }

  static getTimeout(errorName) {
    if (errorName === 'MissingShopifyShopData') {
      return 5000;
    }
    return 1500;
  }

  render() {
    return (
      <Alert stack={ { limit: 10 } } />
    );
  }
}

const mapStateToProps = (state) => state.error;

const mapDispatchToProps = (dispatch) => bindActionCreators({ _clearError: clearError }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Errors);
