import React from 'react';
import qs from 'qs';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { LoadingDots } from '@joinclyde/clyde-react-components';
import Cookies from 'universal-cookie';
import { verifyBCOAuth } from '../../actions/authActions';

const cookies = new Cookies();

class VerifyBigCommerce extends React.Component {
  componentDidMount() {
    const query = this.props.location.search.slice(1);
    this.props._verifyBCOAuth(qs.parse(query));
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (
      nextProps.shopId !== ''
      && cookies.get('clydeShopId')
      && nextProps.shopId === cookies.get('clydeShopId')
    ) {
      this.props.history.push('/welcome');
    }
  }

  render() {
    return (
      <div className='gradient'>
        <LoadingDots dotColor='clydeBlack' dotSize='large' />
      </div>
    );
  }
}

const mapStateToProps = (state) => state.auth;

const mapDispatchToProps = (dispatch) => bindActionCreators(
  { _verifyBCOAuth: verifyBCOAuth },
  dispatch,
);

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(VerifyBigCommerce));
