import React, { useEffect } from 'react';
import { ClydeTextInput, LoadingDots } from '@joinclyde/clyde-react-components';
import classnames from 'classnames';

import SetupModal from './SetupModal';
import OAuthRedirectModal from './OAuthRedirect/Modal';

import IntegrationsSettingsResults from './IntegrationsSettingsResults';
import isIntegrationConnected from './isIntegrationConnected';
import { filterOptions } from '../constants';

const IntegrationsTab = (props) => {
  const {
    allIntegrations,
    filterValue,
    searchString,
    userId,
    _filterIntegrations,
    _getShopInfo,
    _resetIntegrationsTab,
    _searchIntegrations,
  } = props;

  useEffect(() => {
    _getShopInfo(userId);

    return () => {
      _resetIntegrationsTab();
    };
  }, []);

  const availableIntegrations = allIntegrations.filter((integration) => {
    const { categories, id: integrationId } = integration;
    let categoryMatch;
    if (filterValue === 'all') {
      categoryMatch = true;
    } else if (filterValue === 'connected') {
      categoryMatch = isIntegrationConnected(integration);
    } else {
      categoryMatch = categories.includes(filterValue);
    }
    const idMatch = integrationId.indexOf(searchString) === 0; // idx of 0 searches only start of id

    return categoryMatch && idMatch;
  });

  return (
    <div className='settings-tab settings-tab--integrations'>
      <div className='integration-settings__header-section'>
        <div className='integration-settings__header-section-description'>
          <h3>Set up integrations and connected apps</h3>
          <p>Connect all your favorite apps below and optimize your workflow</p>
        </div>
        <div className='integration-settings__search'>
          <ClydeTextInput
            className='integration-settings__search-input'
            name='integrationsSearchValue'
            onChange={ (e) => _searchIntegrations(e.target.value) }
            onSubmit={ (e) => _searchIntegrations(e.target.value) }
            placeholder='Search'
            search
            value={ searchString }
          />
        </div>
      </div>

      <div className='integration-settings-body'>
        <div className='integration-settings-body__filter-option-section'>
          {
            filterOptions.map((option, idx) => {
              const className = classnames('integration-settings-body__filter-option', {
                'integration-settings-body__filter-option-selected': filterValue === option.value,
              });
              return (
                <button
                  className={ className }
                  key={ `option-${idx}` }
                  onClick={ () => _filterIntegrations(option.value, option.integrations) }>
                  { option.name }
                </button>
              );
            })
          }
        </div>

        <div className='integration-settings-body__tile-section'>
          {
            props.getProcessing
              ? <div className='tile-loading-container'>
                  <LoadingDots dotColor='clydeBlack' dotSize='large' />
                </div>
              : <IntegrationsSettingsResults
                  availableIntegrations={ availableIntegrations }
                  filterValue={ filterValue } />
          }
        </div>
      </div>

      <SetupModal />
      <OAuthRedirectModal />
    </div>
  );
};

export default IntegrationsTab;
