import React from 'react';
import classnames from 'classnames';
import { useIntl } from 'react-intl';
import ContractTermsLink from '../Utils/ContractTermsLink';

const ContractTile = (props) => {
  const {
    activeContract,
    contract,
    select,
    shopCurrency,
  } = props;

  const cost = +contract.Cost || +(contract.BaseCost + contract.BackendFee + contract.PlatformFee + contract.Markup);
  const intl = useIntl();
  const price = intl.formatNumber(cost.toFixed(2), { currency: shopCurrency, style: 'currency' });

  const className = classnames(
    'button-nostyle select-contract-tile',
    { 'select-contract-tile--active': activeContract && activeContract.ID === contract.ID },
  );

  return (
    <button
      className={ className }
      onClick={ () => select(contract) }
      data-test={ `contractTile-${contract.ID}` }
      type='button'
    >
        { `${contract.Term} year` }
        <span className='font-10'>
          &nbsp;({ price })
        </span>
    </button>
  );
};

const SelectContract = (props) => {
  const {
    activeContract,
    select,
    shopCurrency,
    step,
    variant,
  } = props;

  const contractId = variant.Contracts[0].ID;

  return (
    <div>
      <div className='row'>
        <div className='sale-modal-step col-12'>{ step }. Choose contract term:</div>
        { props.variant.Contracts.map((contract) => (
          <ContractTile
            activeContract={ activeContract }
            contract={ contract }
            key={ contract.ID }
            select={ select }
            shopCurrency={ shopCurrency }
          />
        )) }
        <div className='select-contract-info col-12'>
          <ContractTermsLink contractId={ contractId } className='select-contract-info__category-link'>
            <span>{ variant.Contracts[0].Category }</span>
          </ContractTermsLink>
        </div>
      </div>
    </div>
  );
};

export default SelectContract;
