import React from 'react';
import { SuccessAnimation } from '@joinclyde/clyde-react-components';

const Enabled = (props) => {
  const {
    integrationName,
    _closeModal,
    _reloadIntegrationsTab,
  } = props;

  const finishIntegration = () => {
    _closeModal();
    _reloadIntegrationsTab();
  };

  return (
    <div className='integrations-oauth-redirect-modal-enabled'>
      <SuccessAnimation
        text={ `${integrationName} successfully connected!` }
        onSuccess={ finishIntegration } />
    </div>
  );
};

export default Enabled;
