import React, { Component } from 'react';
import classnames from 'classnames';
import { SuccessAnimation } from '@joinclyde/clyde-react-components';
import { UnmountClosed } from 'react-collapse';

import SelectVariant from '../SelectVariant';
import SelectContract from '../SelectContract';
import EnterCustomer from '../EnterCustomer';

class SaleDetails extends Component {
  UNSAFE_componentWillMount() {
    if (this.props.product.Variants && this.props.product.Variants.length === 1) {
      this.props._selectVariant(this.props.product.Variants[0]);
    }
  }

  render() {
    const variantStep = this.props.platform === 'Clyde' ? 0 : 1;
    const { currency } = this.props;
    const modalClass = classnames('sale-modal',
      { 'sale-modal--finished': this.props.modalPage === 'finish' });

    const shopCurrency = this.props.variant?.ShopCurrency;

    return (
      <div className='modal-container'>
        <div className='modal-container__backdrop'></div>
        { this.props.billingReady
          ? <div className={ modalClass }>
            <div className='sale-modal__content'>
              { this.props.modalPage !== 'finish'
                ? <div>
                  <div onClick={ this.props._exitSale } className='close-sale-modal'>&#x2715;</div>
                  <div>
                    <h2>{ this.props.product.Name }</h2>
                    <h1>Record a sale:</h1>
                    <SelectVariant
                      step={ variantStep }
                      product={ this.props.product }
                      currency={ currency }
                      select={ this.props._selectVariant }
                      activeVariant={ this.props.variant }
                    />
                    <UnmountClosed
                      springConfig={ { stiffness: 300, damping: 30 } }
                      isOpened={ this.props.variant !== null }
                    >
                      <SelectContract
                        activeContract={ this.props.contract }
                        select={ this.props._selectContract }
                        shopCurrency={ shopCurrency }
                        step={ variantStep + 1 }
                        currency={ currency }
                        variant={ this.props.variant }
                      />
                    </UnmountClosed>
                    <UnmountClosed
                      springConfig={ { stiffness: 300, damping: 30 } }
                      isOpened={ this.props.contract.ID !== undefined }
                    >
                      <EnterCustomer
                        platform={ this.props.platform }
                        step={ variantStep + 2 }
                      />
                    </UnmountClosed>
                  </div>
                </div>
                : <SuccessAnimation text={ 'Sale recorded! ' } onSuccess={ this.props._exitSale } />
              }
            </div>
          </div>
          : <div className='update-billing-modal'>
            <div className='update-billing-modal__content'>
              <div>
                <div>You need to set up billing before you're able to record sales!</div>
                <div onClick={ this.props.updateBilling } className='col-8 offset-2 update-billing-modal__content__button'>
                    Update billing info
                </div>
              </div>
            </div>
          </div>
        }
      </div>
    );
  }
}

export default SaleDetails;
