import React from 'react';
import moment from 'moment';
import { LoadingDots } from '@joinclyde/clyde-react-components';

const ContractCard = (props) => {
  const {
    contract: {
      contractAdh,
      contractDop,
      contractId,
      contractTerm,
      deliveryDate,
    },
    transferHistory,
    processing,
  } = props;

  let expirationInfo = '';
  if (contractAdh) {
    const dop = deliveryDate || contractDop;
    expirationInfo = moment(dop).add(contractTerm, 'years').format('MMMM DD, YYYY');
  } else {
    const yearAmount = contractTerm === 1 ? 'year' : 'years';
    expirationInfo = `${contractTerm} ${yearAmount} after limited warranty`;
  }

  return (
    <div className='claims-detail-card claims-detail-card--contract'>
      <h3>Contract</h3>

      {
        processing
          ? <LoadingDots className='claims-detail-card__loading' dotColor='clydeBlack' dotSize='medium' />
          : <React.Fragment>
              <div className='claims-detail-card__group'>
                <span className='claims-detail-card__group__title'>Contract ID:</span>
                <span className='claims-detail-card__group__info'>{ contractId }</span>
              </div>
              <div className='claims-detail-card__group'>
                <span className='claims-detail-card__group__title'>Expires:</span>
                <span className='claims-detail-card__group__info'>{ expirationInfo }</span>
              </div>
              {
                transferHistory && transferHistory.length > 0
                && <div className='claims-detail-card__group'>
                  <span className='claims-detail-card__group__title'>Transferred on:</span>
                  <span className='claims-detail-card__group__info'>{ moment(transferHistory[0].transferDate).format('MMMM DD, YYYY') }</span>
                  <span className='claims-detail-card__group__info'>{ transferHistory[0].customerEmail }</span>
                </div>
              }
            </React.Fragment>
      }

    </div>
  );
};

export default ContractCard;
