const defaultState = {
  email: '',
  updateProcessing: false,
  passwordUpdated: false,
  showSaveButton: false,
};

const userReducer = function user(state = defaultState, action) {
  switch (action.type) {
    case 'UPDATE_USER_PROCESSING':
      return { ...state, updateProcessing: true };
    case 'GET_USER_EMAIL_RES':
      return { ...state, email: action.payload.email };
    case 'UPDATE_USER_RES':
      return {
        ...state,
        updateProcessing: false,
        passwordUpdated: true,
        showSaveButton: false,
      };
    case 'CLEAR_SETTINGS_STATUS':
      return { ...state, passwordUpdated: false };
    case 'SHOW_SAVE_BUTTON':
      return {
        ...state,
        showSaveButton: true,
      };
    case 'LOGOUT':
      return { ...state, ...defaultState };
    case 'ERROR':
      switch (action.payload.reqType) {
        case 'UPDATE_USER_REQ':
          return { ...state, updateProcessing: false };
        default:
          return state;
      }
    default:
      return state;
  }
};

export default userReducer;
