import React from 'react';
import { FormattedNumber } from 'react-intl';
import { useLocale } from '../../providers/LocaleProvider';

export function Currency({ amount, ...rest }) {
  const { currency } = useLocale();
  // Make sure null paths are all handled ('' | null | undefined)
  const value = +(amount || 0);
  return (
    <span { ...rest }>
      <FormattedNumber
        currency={ currency }
        style='currency'
        value={ value.toFixed(2) }
      />
    </span>
  );
}
