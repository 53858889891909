import React from 'react';
import { LoadingDots } from '@joinclyde/clyde-react-components';

import ProductRegistrationTableRow from './ProductRegistrationTableRow';

const ProductRegistrationTable = ({
  productRegistrations,
  isProcessing,
  selectRegistrationClick,
  selectRegistrationKey,
}) => (
    <section className='product-registrations__list'>
      <div className='product-registrations__list__table-header'>
        <h3>Products Registered:</h3>
        <div className='product-registrations__list__table-header__row'>
          <div className='product-registrations__list__table-header__row__cell-product'>
            Product
          </div>
          <div className='product-registrations__list__table-header__row__cell-date'>
            Date of Purchase
          </div>
          <div className='product-registrations__list__table-header__row__cell-pop'>
            Place of Purchase
          </div>
          <div className='product-registrations__list__table-header__row__cell-serial-number'>
            Serial Number
          </div>
          <div className='product-registrations__list__table-header__row__cell-contracts'>
            Purchased Contracts
          </div>
          <div className='product-registrations__list__table-header__row__cell-claims'>
            Open Claims
          </div>
        </div>
      </div>

      { isProcessing ? (
        <div className='orders__list__table-body orders__list__table-body--loading'>
          <LoadingDots dotColor='clydeBlack' dotSize='large' />
        </div>
      ) : (
        <div className='product-registrations__list__table-body'>
          { productRegistrations.map((productRegistration, idx) => (
              <ProductRegistrationTableRow
                key={ idx }
                productRegistration={ productRegistration }
                selectRegistrationClick={ selectRegistrationClick }
                selectRegistrationKey={ selectRegistrationKey }
              />
          )) }
        </div>
      ) }
    </section>
);

export default ProductRegistrationTable;
