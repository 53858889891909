import React, { Component } from 'react';
import classnames from 'classnames';
import Img from 'react-image';
import { Collapse } from 'reactstrap';
import Moment from 'moment';
import { Calendar } from 'react-date-range';
import TagsInput from 'react-tagsinput';
import { ClydeTextInput } from '@joinclyde/clyde-react-components';
import { formatDate } from '../../../../lib/timeUtils';
import {
  SerialNumberLayout,
  SerialNumberInput,
  SerialNumberTag,
} from './ItemSerialNumber';
import SmallButton from '../../Shared/SmallButton';
import ProductSaleItem from './ProductSaleItem';
import { Currency } from '../../../Utils/Currency';

class ProductItem extends Component {
  constructor(props) {
    super(props);
    this.state = {
      toggleOpen: false,
      calendarOpen: false,
      quantityDelivered: 1,
      serialNumbers: [],
      openActionButtonId: '',
    };
  }

  handleToggle = (e) => {
    e.stopPropagation();
    if (e.type === 'click' || e.key === ' ' || e.key === 'Enter') {
      e.preventDefault();
      if (this.state.toggleOpen) {
        e.currentTarget.blur();
      }
      this.setState((prevState) => ({ toggleOpen: !prevState.toggleOpen }));
    }
  }

  setOpenActionButtonId = (id) => {
    this.setState({ openActionButtonId: id });
  }

  handleDateSelect = (newDate) => {
    const {
      clydeOrderId,
      deliveryDateProcessing,
      product,
      updateDeliveryDate,
    } = this.props;

    if (!deliveryDateProcessing) {
      updateDeliveryDate(clydeOrderId, product.lineItemId, newDate, this.state.quantityDelivered);
    }
  }

  handleValueChange = (e) => {
    if (e.target.name === 'quantityDelivered' && (e.target.value > this.props.product.quantity)) {
      return;
    }
    this.setState({ [e.target.name]: e.target.value });
  }

  toggleCalendarOpen = (e) => {
    e.stopPropagation();
    if (this.state.calendarOpen) {
      e.currentTarget.blur();
    }
    this.setState((prevState) => ({ calendarOpen: !prevState.calendarOpen }));
  }

  buildProductRows = (quantity) => {
    const {
      product,
      product: {
        lineItemId,
        claims,
      },
      isEligible,
      manageOEMWarranty,
      currency,
    } = this.props;

    const rows = [];
    for (let n = 0; n < quantity; n++) {
      const selectedClaim = claims.find((claim) => claim.lineItemIndex === (n + 1).toString()) || {};
      const id = `${lineItemId}_epi_${n}`;
      rows.push(
        <ProductSaleItem
          id={ id }
          index={ n + 1 }
          product={ product }
          currency={ currency }
          selectedClaim={ selectedClaim }
          warrantyStatus
          isEligible={ isEligible }
          manageOEMWarranty={ manageOEMWarranty }
          _setOpenActionButton={ this.setOpenActionButtonId }
          />,
      );
    }
    return rows;
  }

  handleSerialNumberChange = (inputSerialNumbers) => {
    const {
      product: {
        lineItemId,
        lineItemSerialNumber,
        quantity,
      },
      _saveLineItemSerialNumber,
    } = this.props;

    const existingSerialNumbers = lineItemSerialNumber || [];
    if (inputSerialNumbers.length > existingSerialNumbers.length) {
      // We're trying to add a serial number
      const lineItemsWithoutSerialNumber = quantity - existingSerialNumbers.length;
      if (lineItemsWithoutSerialNumber < 1) {
        return;
      }
    }
    _saveLineItemSerialNumber(lineItemId, inputSerialNumbers);
  }

  render() {
    const {
      product,
      product: {
        oemWarrantyLength,
        oemWarrantyExpirationDate,
      },
      isEligible,
      manageOEMWarranty,
    } = this.props;
    const { calendarOpen, toggleOpen } = this.state;
    const quantityNumber = product.quantity ? product.quantity : 0;
    const sku = product.sku || 'Unknown';
    const purchasePrice = product.purchasePrice ? <Currency amount={ product.purchasePrice } /> : 'Unknown';
    const today = Moment.utc();
    const lastDayOEMEligible = Moment.utc(oemWarrantyExpirationDate);
    const isWithinOEMPeriod = lastDayOEMEligible.diff(today, 'days') > 0;
    const hasOemWarranty = oemWarrantyLength > 0 && isWithinOEMPeriod;
    const oemClass = hasOemWarranty ? 'oem' : '';
    const oemWarrantyLengthText = product.oemWarrantyLength === 1 ? '1 month' : `${product.oemWarrantyLength} months`;
    const daysLeftPlural = product.daysLeft === 1 ? 'day' : 'days';
    const classPrefix = isEligible ? 'eligible' : 'ineligible';
    const classAcronym = isEligible ? 'epi' : 'ipi';

    const termsLinkName = product.contractCategoryName
      ? product.contractCategoryName
      : 'Clyde Contract Terms & Conditions';

    const calendarClassName = calendarOpen
      ? `${classPrefix}-products__calendar--open`
      : `${classPrefix}-products__calendar--closed`;

    return (
      <div className={ `${classPrefix}-products` } id={ `${product.lineItemId}_${classAcronym}` }>

        <div
          className={ `${classPrefix}-products__content` }
          onClick={ this.handleToggle }
          onKeyDown={ this.handleToggle }
          tabIndex='0'
          role='button'
          aria-expanded={ toggleOpen }
        >
          <div className={ `${classPrefix}-products__content__left ${oemClass}` }>
            <Img alt={ product.name } src={ [product.image, '/images/default-image.svg'] } />
            <div className={ `${classPrefix}-products__content__left__body` }>
              <span className={ `${classPrefix}-products__content__left__name` }>
                {
                product.variantName
                  ? `${product.name}: ${product.variantName}`
                  : product.name
                } ({ product.quantity })
              </span>
              { product.manufacturer
                && <span className='cop-item-copy cop-item-copy--grey'>
                  { product.manufacturer }
                </span>
              }
              { isEligible
                && <span>
                  <a
                    onClick={ (e) => e.stopPropagation() }
                    onKeyDown={ (e) => e.stopPropagation() }
                    href={ `${window._env_.REACT_APP_HICLYDE_HOST}/terms?cid=${product.contracts[0] && product.contracts[0].contractId}` }
                    target='_blank'
                    rel='noopener noreferrer'>{ termsLinkName }</a>
                </span>
              }
              { product.asurionIneligibleMessage
                && <>
                  <br/>
                  <span className='cop-item-copy cop-item-copy--purple'>{ product.asurionIneligibleMessage }</span>
                </>
              }
              { product.guardsmanIneligibleMessage
                && <>
                  <br/>
                  <span className='cop-item-copy cop-item-copy--purple'>{ product.guardsmanIneligibleMessage }</span>
                </>
              }
            </div>
          </div>
          <div className={ `${classPrefix}-products__content__right ${oemClass}` }>
            <div className={ `${classPrefix}-products__content__right__body ${oemClass}` }>
              <span>SKU: { sku }</span>
              <span>Purchase Price: { purchasePrice }</span>
              <span>Manuf. warranty: { oemWarrantyLengthText }</span>
              <TagsInput
                value={ product.lineItemSerialNumber || [] }
                onChange={ this.handleSerialNumberChange }
                renderLayout={ SerialNumberLayout }
                renderInput={ (props) => (
                  <SerialNumberInput
                    { ...props }
                    disabled={
                      product.lineItemSerialNumber
                      && product.quantity - product.lineItemSerialNumber.length < 1
                    }
                  />
                ) }
                renderTag={ (props) => <SerialNumberTag { ...props } idx={ props.key } item='product' /> }
              />
            </div>
            <div className={ `${classPrefix}-products__content__right__body ${oemClass}` }>
              <span>Purchase Date: { formatDate(product.purchaseDate) }</span>
              { product.shipDate && <span>Ship Date: { formatDate(product.shipDate) }</span> }
              <span className={ classnames('all-products__delivery-date',
                { 'all-products__delivery-date--active': calendarOpen }) }>
                Delivery Date: { product.deliveryDate ? formatDate(product.deliveryDate) : 'Pending' }
                <SmallButton
                  onClick={ this.toggleCalendarOpen }
                  isOpen={ calendarOpen }
                  customClass='delivery-date' />
              </span>
              <div
                onClick={ (e) => e.stopPropagation() }
                className={ `${classPrefix}-products__calendar ${calendarClassName}` }>
                <Calendar
                  onChange={ this.handleDateSelect }
                  minDate={ Moment(product.purchaseDate).utc() }
                  maxDate={ Moment().utc() } />
              </div>
              <div onClick={ (e) => e.stopPropagation() } >
                <span>Quantity Delivered:</span>
                <ClydeTextInput
                  className='all-products__quantity-delivered'
                  name='quantityDelivered'
                  value={ this.state.quantityDelivered }
                  onChange={ this.handleValueChange }
                  typeNumber
                />
              </div>
            </div>
            {
              hasOemWarranty
              && <div className={ `${classPrefix}-products__content__right__body badge-container ${oemClass}` }>
                <span className='limited-warranty-badge'>Under Limited Warranty</span>
                { manageOEMWarranty && <span className='limited-warranty-expiration'>Coverage Expires: { formatDate(oemWarrantyExpirationDate) }</span> }
              </div>
            }
          </div>
        </div>
        <Collapse className='eligible-products__collapse' isOpen={ toggleOpen }>
          <div className='collapse-padding-wrapper'>
            <div className='eligible-products__collapse__content'>
              { this.buildProductRows(quantityNumber, daysLeftPlural, oemWarrantyLengthText) }
            </div>
          </div>
        </Collapse>
      </div>
    );
  }
}

export default ProductItem;
