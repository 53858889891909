import React, { useEffect, useState } from 'react';
import Img from 'react-image';
import classnames from 'classnames';

import IntegrationMenu from './IntegrationMenu';

import determineIntegrationStatus from './determineIntegrationStatus';

const IntegrationTile = (props) => {
  const {
    integration,
    userId,
    _getFriggAuthRequirements,
    _openSetupModal,
  } = props;

  const [isIntegrationMenuActive, setMenuActive] = useState(false);
  const status = determineIntegrationStatus(integration);

  useEffect(() => {
    setMenuActive(false);
  }, [integration]);

  const onMenuClick = () => {
    setMenuActive(!isIntegrationMenuActive);
  };

  const closeIntegrationMenu = () => {
    setMenuActive(false);
  };

  const _handleStatusButtonClick = () => {
    if (status.value === 'ENABLED') {
      return;
    }

    if (status.value === 'LINK_ONLY') {
      window.open(status.link, '_blank', 'noopener,noreferrer');
      return;
    }

    _openSetupModal(integration);
    if (integration.friggIntegration) {
      _getFriggAuthRequirements(userId, integration.entityOptions.type);
    }
  };

  const logoSrc = integration.friggIntegration
    ? integration.entityOptions.display.icon
    : integration.logo;

  const integrationMenuButtonClass = classnames('button-no-style integration-tile__header__menu-button', {
    'integration-tile__header__menu-button--open': isIntegrationMenuActive,
  });

  return (
    <div className='integration-tile'>
      <div className='integration-tile__header'>
        <button
          className={ integrationMenuButtonClass }
          onClick={ onMenuClick }
          onMouseUp={ (e) => { e.currentTarget.blur(); } }>
          <div className='integration-tile__header__menu-button__ellipse'></div>
          <div className='integration-tile__header__menu-button__ellipse'></div>
          <div className='integration-tile__header__menu-button__ellipse'></div>
        </button>
        { isIntegrationMenuActive
          && <IntegrationMenu
              integration={ integration }
              closeIntegrationMenu={ closeIntegrationMenu }
              status={ status.value } />
        }

      </div>

      <div className='integration-tile__body'>
        <div className='integration-tile__body__logo'>
          <Img
            alt={ `${integration.name} logo` }
            className='integration-outside-logo'
            src={ [logoSrc, '/images/warranty-shield.svg'] } />
        </div>

        <h3 className='integration-tile__body__header'>{ integration.name }</h3>
        <div className='integration-tile__body__text'>
          <p>{ integration.description || integration.entityOptions.display.description }</p>
        </div>

        <button
          className={ `integration-tile__body__button integration-tile__body__button--${status.value}` }
          onClick={ _handleStatusButtonClick }
          onMouseUp={ (e) => { e.currentTarget.blur(); } }
          tabIndex={ status.value === 'ENABLED' ? -1 : 0 }
          >
          { status.text }
        </button>
        { /* integrationError && <p className='integration-error'>{ integrationError }</p> */ }
      </div>
    </div>
  );
};

export default IntegrationTile;
