import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import {
  setText,
  setValidationErrors,
  updateShopContacts,
} from '../../../../actions/contactInfoActions';

import ContactSettingsTile from './ContactSettingsTile';

const mapStateToProps = (state) => ({
  contactInfo: state.contactInfo,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  _setText: setText,
  _updateShopContacts: updateShopContacts,
  _setValidationErrors: setValidationErrors,
}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ContactSettingsTile);
