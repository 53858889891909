import React, { Component } from 'react';
import Transition from 'react-transition-group/Transition';

class SettingSwitch extends Component {
  render() {
    const duration = 200;
    const defaultStyle = {
      transition: 'left 200ms ease-in-out',
    };
    const transitionStyles = {
      entering: { left: '50px' }, entered: { left: '50px' }, exiting: { left: '0px' }, exited: { left: '0px' },
    };
    const className = this.props.on ? 'autopilot-switch autopilot-switch--on' : 'autopilot-switch autopilot-switch--off';
    return (
      <Transition in={ this.props.on } timeout={ duration }>
        { (state) => (
          <div onClick={ this.props.disabled === true ? null : this.props.toggle } className={ className }>
            <div className='autopilot-switch__contents' style={ { ...defaultStyle, ...transitionStyles[state] } }>
            </div>
            <div className='autopilot-switch__text'>
              <span className='autopilot-switch__text__on'>ON</span>
              <span className='autopilot-switch__text__off'>OFF</span>
            </div>
          </div>
        ) }
      </Transition>
    );
  }
}

export default SettingSwitch;
