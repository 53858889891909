import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import { handleOAuthRedirectData } from '../../../../../actions/integrationsActions';

import LandingPage from './LandingPage';

const mapStateToProps = (state) => ({
  userId: state.auth.userId,
});

const mapDispatchToProps = (dispatch) => ({
  _handleOAuthRedirectData: (userId, integrationType, oAuthData) => {
    dispatch(handleOAuthRedirectData(userId, integrationType, oAuthData));
  },
});

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps,
)(LandingPage));
