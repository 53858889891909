import React from 'react';
import { ClydeTextInput } from '@joinclyde/clyde-react-components';
import classnames from 'classnames';

export const SerialNumberLayout = (tagComponents, inputComponent) => (
    <>
      <div
        onClick={ (e) => e.stopPropagation() }
        onKeyDown={ (e) => e.stopPropagation() }>
        { inputComponent }
      </div>
      <div>
        { tagComponents }
      </div>
    </>
);

export const SerialNumberInput = (props) => {
  const {
    onChange,
    value,
    addTag,
    disabled,
  } = props;
  const className = classnames('cop-item-serial__input', {
    'cop-item-serial__input--disabled': disabled,
  });

  return (
    <ClydeTextInput
      className={ className }
      disabled={ disabled }
      onChange={ onChange }
      onSubmit={ (e) => { addTag(e.target.value); } }
      placeholder={ disabled ? 'Serial numbers full' : 'Add a serial number' }
      value={ value } />
  );
};

export const SerialNumberTag = (props) => {
  const {
    classNameRemove,
    disabled,
    getTagDisplayValue,
    idx,
    item,
    onRemove,
    tag,
  } = props;

  return (
    <div
      className={ `cop-item-serial__tag cop-item-serial__tag--${item}` }
      key={ idx }
      onClick={ (e) => e.stopPropagation() }
      onKeyDown={ (e) => e.stopPropagation() }>
      <span className='cop-item-serial__tag__value' key={ idx }>
        { getTagDisplayValue(tag) }
      </span>
      {
        !disabled
          && <button
            className={ `button-no-style cop-item-serial__tag__remove-button ${classNameRemove}` }
            onClick={ () => { onRemove(idx); } }>
            &#x2715;
          </button>
      }
    </div>
  );
};
