import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import { createStripeIntent, resetPaymentForm } from '../../../../../actions/billingActions';
import { getShopInfo } from '../../../../../actions/profileActions';
import PaymentForm from './PaymentForm';

const stripePromise = loadStripe(window._env_.REACT_APP_STRIPE_PK);
const options = {
  mode: 'setup',
  currency: 'usd',
  paymentMethodTypes: ['us_bank_account'],
};

const StripeDecoratedPaymentForm = (props) => (
  <Elements options={ options } stripe={ stripePromise }>
    <PaymentForm { ...props } />
  </Elements>
);

const mapStateToProps = ({ billing, profile }) => ({
  stripeIntent: billing.stripeIntent,
  profile,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  _createStripeIntent: createStripeIntent,
  _resetPaymentForm: resetPaymentForm,
  _getShopInfo: getShopInfo,
}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(StripeDecoratedPaymentForm);
