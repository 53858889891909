import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import {
  cancelInvoiceExport,
  setInvoiceRange,
  submitInvoiceExport,
  setInvoiceDownloadFalse,
} from '../../../actions/salesActions';

import InvoiceExportModal from './InvoiceExportModal';

const mapStateToProps = (state) => ({
  invoiceExport: state.sales.invoiceExport,
  invoiceExportProcessing: state.sales.invoiceExportProcessing,
  invoiceExportFile: state.sales.invoiceExportFile,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  _cancelInvoiceExport: cancelInvoiceExport,
  _setInvoiceRange: setInvoiceRange,
  _submitInvoiceExport: submitInvoiceExport,
  _setInvoiceDownloadFalse: setInvoiceDownloadFalse,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(InvoiceExportModal);
