import React from 'react';
import Transition from 'react-transition-group/Transition';

const RegistrationsSettingsSwitch = (props) => {
  const {
    offState,
    toggleFunction,
    currentValue,
    title,
  } = props;

  const handleToggle = () => toggleFunction(currentValue, offState);

  const duration = 200;
  const defaultStyle = {
    transition: 'left 200ms ease-in-out',
  };
  const transitionStyles = {
    entering: { left: '33px' },
    entered: { left: '33px' },
    exiting: { left: '2px' },
    exited: { left: '2px' },
  };

  return (
    <div className='registrable-product-switch'>
      <div className='registrable-product-switch-title'>{ title }</div>
      <Transition in={ props.on } timeout={ duration }>
        { (state) => (
          <div onClick={ handleToggle } className={ props.on ? 'toggle-onlyRegistrable toggle-onlyRegistrable--on' : 'toggle-onlyRegistrable toggle-onlyRegistrable--off' }>
            <div className='toggle-onlyRegistrable__dot' style={ { ...defaultStyle, ...transitionStyles[state] } }></div>
          </div>
        ) }
      </Transition>
    </div>
  );
};

export default RegistrationsSettingsSwitch;
