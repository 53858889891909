import React, { Component } from 'react';
import classnames from 'classnames';
import {
  SuccessAnimation, ClydeButton, ClydeTextInput, ClydeTooltip,
} from '@joinclyde/clyde-react-components';

class NewProduct extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formChecked: false,
      finishCreate: false,
      validFile: false,
      fileData: '',
      imageErrorText: '',
      product: {
        name: '',
        type: '',
        description: '',
        manufacturer: '',
        sku: '',
        price: '',
        image: '',
        oemLength: '',
      },
      parts: [],
      deletedParts: [],
      formErrors: {},
      formValid: this.props.productType === 'edit',
      missingFields: {},
    };
  }

  UNSAFE_componentWillMount() {
    const {
      editProduct,
      platform,
    } = this.props;
    let platformVariantOemValue = editProduct ? editProduct.OEMWarrantyLength : '';
    const isShopifyOrBigCommerce = platform === 'Shopify' || platform === 'BigCommerce';

    if (editProduct && isShopifyOrBigCommerce) {
      const { Variants } = editProduct;
      platformVariantOemValue = Variants[0].OEMWarrantyLength;
    }

    let hasActivePart = false;
    const productParts = editProduct && editProduct.Parts ? editProduct.Parts.map((part) => {
      if (part.Active) {
        hasActivePart = true;
      }
      return {
        id: part.ID,
        name: part.Name,
        modelNumber: part.ModelNumber,
        cost: part.Cost,
        active: part.Active,
      };
    }) : [];

    if (this.props.productType === 'edit') {
      this.setState({
        hasActivePart,
        parts: productParts && productParts.length > 1 ? productParts.sort(this.alphabetizeParts) : productParts,
        product: {
          ...this.state.product,
          name: editProduct.Name || '',
          type: editProduct.Type || '',
          description: editProduct.Description || '',
          manufacturer: editProduct.Manufacturer || '',
          sku: editProduct.SKU || '',
          price: editProduct.Price || '',
          oemLength: platformVariantOemValue,
          image: editProduct.ImageLink || '',
          variants: editProduct.Variants || [],
        },
      });
    }
  }

  openFileDialog = (e) => {
    e.preventDefault();
    document.getElementById('product-image').click();
    // MARKER: AJC 10/15/19 To remove above DOM manipulation would require a reworking of the image upload button
  };

  selectFile = (e) => {
    const splitFilePath = e.target.value.split('\\');
    const fileName = splitFilePath[splitFilePath.length - 1];

    const { files } = e.target;
    if (FileReader && files && files.length) {
      if (!['png', 'jpg', 'jpeg', 'gif'].includes(files[0].type.split('/')[1]) || files[0].size > 128000) {
        this.setState({ imageErrorText: 'Please use a png, gif, or jpeg no larger than 128kb.' });
        return;
      }
      const fr = new FileReader();
      fr.onload = () => {
        this.setState({ validFile: true, imageErrorText: '', fileData: fr.result });
      };
      fr.readAsDataURL(files[0]);
    }
    this.setState({ fileName, imageErrorText: '' });
  }

  resetImage = () => {
    this.setState({ fileData: '', product: { ...this.state.product, image: '' }});
  }

  fieldsComplete(isPlatformProduct) {
    const missingFields = {};
    // don't validate fields you can't edit
    if (!isPlatformProduct) {
      Object.keys(this.state.product).forEach((key) => {
        const emptyField = key !== 'description'
          && key !== 'image'
          && key !== 'oemLength'
          && key !== 'manufacturer'
          && key !== 'variants'
          && this.state.product[key].trim().length === 0;
        if (emptyField) {
          missingFields[key] = true;
        }
      });
    }
    this.state.parts.forEach((part, index) => {
      const partFieldKeys = Object.keys(part);
      if (part.active) {
        partFieldKeys.forEach((key) => {
          const value = part[key];
          const isMissing = String(value).trim().length === 0;
          if (key !== 'id' && key !== 'modelNumber' && isMissing) {
            const fieldName = `${index}-${key}`;
            missingFields[fieldName] = true;
          }
        });
      }
    });
    if (Object.keys(missingFields).length > 0) {
      this.setState({ missingFields });
      return false;
    }
    return true;
  }

  alphabetizeParts = (a, b) => {
    let result = 0;
    let index = 0;
    const { name: aName } = a;
    const { name: bName } = b;
    const shorterLength = aName.length < bName.length ? aName.length : bName.length;
    while (result === 0 && index < shorterLength) {
      const aValue = aName.toLowerCase().charCodeAt(index);
      const bValue = bName.toLowerCase().charCodeAt(index);
      const difference = aValue - bValue;
      // we're out of letters to compare and they are identical so far
      result = index === shorterLength - 1 && !difference ? aName.length - bName.length : difference;
      index += 1;
    }
    return result;
  };

  handleSubmit = () => {
    this.setState({ missingFields: {}});
    if (this.fieldsComplete() && this.state.formValid === true) {
      const saveProduct = {
        ...this.state.product,
        price: this.state.product.price,
        image: this.state.fileData,
      };

      const {
        parts,
      } = this.state;
      if (this.props.productType === 'new') {
        this.props._createProduct(saveProduct, parts);
      } else {
        const id = this.props.editProduct.ID;
        this.props._updateProduct(saveProduct, id);
        this.props._updateProductParts(parts, id);
      }
    }
  }

  handleChange = (e) => {
    const { name, value } = e.target;

    this.setState(
      { product: { ...this.state.product, [name]: value }},
      () => { this.validateField(name, value); },
    );
  }

  handlePartChange = (e) => {
    const { name } = e.target;
    const { value } = e.target;
    const splitName = name.split('-');
    const [index, field] = splitName;
    const parts = [...this.state.parts];
    parts[index][field] = value;
    this.setState({ parts }, () => { this.validatePartField(field, value, name, index); });
  }

  handlePartDelete = (deleteIndex) => {
    const parts = [...this.state.parts];
    const part = parts[deleteIndex];
    let updatedParts;
    if (part.id) {
      // part exists in db, must be soft deleted
      part.active = false;
      updatedParts = parts;
    } else {
      updatedParts = parts.filter((existingPart, index) => index !== deleteIndex);
    }
    const anyActivePart = parts.find((existingPart) => existingPart.active);
    this.setState({
      hasActivePart: Boolean(anyActivePart),
      parts: updatedParts && updatedParts.length > 1 && this.props.productType !== 'new' ? updatedParts.sort(this.alphabetizeParts) : updatedParts,
    });
  }

  handleNewPart = () => {
    const newPart = {
      cost: '',
      name: '',
      modelNumber: '',
      active: true,
      productId: this.state.product.id,
    };
    const parts = [...this.state.parts, newPart];
    this.setState({
      hasActivePart: true,
      parts: parts && parts.length > 1 && this.props.productType !== 'new' ? parts.sort(this.alphabetizeParts) : parts,
    });
  }

  validateField(name, value) {
    const validationErrors = this.state.formErrors;
    const updatedProduct = {};
    switch (name) {
      case 'description':
        break;
      case 'price':
        if (value === '0' || parseFloat(value, 10)) {
          delete (validationErrors.price);
          if (parseFloat(value, 10) <= 0) {
            updatedProduct.price = '1';
          }
        } else {
          validationErrors.price = `Invalid price`;
        }
        break;
      case 'oemLength':
        if (!value || parseFloat(value, 10) || value === '0') {
          delete (validationErrors.oemLength);
          if (parseFloat(value, 10) < 0) {
            updatedProduct.oemLength = '';
          }
        } else {
          validationErrors.oemLength = `Invalid OEM term`;
        }
        break;
      default:
        if (value === '') {
          validationErrors[name] = `Missing product ${name} field`;
        } else {
          delete (validationErrors[name]);
        }
        break;
    }
    this.setState({
      formErrors: validationErrors,
      formValid: Object.keys(validationErrors).length === 0,
      product: {
        ...this.state.product,
        ...updatedProduct,
      },
    });
  }

  formatPrice = (e) => {
    const { value, name } = e.target;
    if ((value && !Number.isNaN(Number(value))) || parseFloat(value, 10)) {
      // anything that can be coerced to a valid float with two decimals will be
      let price = '';
      const hasDecimal = value.includes('.');
      if (hasDecimal) {
        const [dollars, cents] = value.split('.');
        const wholeDollars = dollars.length ? dollars : '0';
        let wholeCents = cents;
        if (cents.length === 0) {
          wholeCents = `00`;
        } else if (cents.length === 1) {
          wholeCents = `${cents}0`;
        } else if (cents.length > 2) {
          wholeCents = cents.slice(0, 2);
        }
        price = `${wholeDollars}.${wholeCents}`;
      } else {
        price = `${value}.00`;
      }
      if (name === 'price') {
        this.setState({
          product: {
            ...this.state.product,
            ...{ price },
          },
        });
      } else {
        const [index] = name.split('-');
        const parts = [...this.state.parts];
        parts[index].cost = price;
        this.setState({ parts });
      }
    }
  }

  validatePartField = (property, value, name, index) => {
    const validationErrors = this.state.formErrors;
    const parts = [...this.state.parts];
    switch (property) {
      case 'modelNumber':
        const duplicateParts = parts.filter((part) => part.modelNumber === value);
        if (duplicateParts.length > 1) {
          validationErrors[name] = 'A part with this model number already exists';
        } else {
          delete validationErrors[name];
        }
        break;
      case 'cost':
        if (value === '0' || parseFloat(value, 10)) {
          delete (validationErrors[name]);
          if (parseFloat(value, 10) <= 0) {
            parts[index].cost = '1';
            this.setState({ parts });
          }
        } else {
          validationErrors[name] = `Invalid cost`;
        }
        break;
      default:
        if (value === '') {
          validationErrors[name] = `Missing part ${name} field`;
        } else {
          delete (validationErrors[name]);
        }
        break;
    }
    this.setState({
      formErrors: validationErrors,
      formValid: Object.keys(validationErrors).length === 0,
    });
  }

  generateLabelClass = (hasErrors, type) => {
    let classes;
    switch (type) {
      case 'unique':
        classes = classnames(
          'product-field-label',
          { 'product-field-label--invalid-unique': hasErrors },
        );
        break;
      default:
        classes = classnames(
          'product-field-label',
          { 'product-field-label--invalid': hasErrors },
        );
    }
    return classes;
  }

  updatePlatformProduct = () => {
    if (this.fieldsComplete(true) && this.state.formValid === true) {
      const {
        _updatePlatformProduct,
        _updateProductParts,
      } = this.props;
      const id = this.state.product.variants[0].ProductID;
      if (!this.state.product.oemLength) {
        this.setState({ missingFields: { oemLength: true }});
        return;
      }
      _updatePlatformProduct(this.state.product);
      _updateProductParts(this.state.parts, id);
    }
  };

  render() {
    const {
      props: {
        productType,
        saveProductProcessing,
        exitUpload,
        _closeProductModal,
        platform,
        shopCurrency,
      },
      state: {
        formErrors,
        missingFields,
      },
      generateLabelClass,
    } = this;
    const errors = formErrors;

    const isShopifyOrBigCommerce = platform === 'Shopify' || platform === 'BigCommerce';
    const handleSubmitFn = isShopifyOrBigCommerce ? this.updatePlatformProduct : this.handleSubmit;

    return (
      <div className='modal-container'>
        <div className='modal-container__backdrop'></div>
        <div className='clyde-modal-content new-product'>
          <div className='row'>
            <div id='upload-modal' className='new-product-modal'>
              { !this.state.finishCreate ? (
                <div className='new-product-form'>
                  <div
                    onClick={ _closeProductModal }
                    className='close-product-modal'
                    data-test='close'
                  >
                    &#x2715;
                  </div>
                  { productType === 'new' ? (
                    <div className='new-product-header'>
                      <h2 className='new-product-header__prefixed-subtitle'>
                        After creating a product, Clyde will connect it with
                        contracts shortly.
                      </h2>
                      <div className='new-product-header__title-container row'>
                        <h1 className='new-product-header__title-container__title col-12'>
                          Add a product
                        </h1>
                      </div>
                    </div>
                  ) : (
                    <div className='new-product-header'>
                      <div className='new-product-header__title-container row'>
                        <h1 className='new-product-header__title-container__title col-12'>
                          Edit product
                        </h1>
                      </div>
                    </div>
                  ) }
                  <div className='new-product-content'>
                    <div className='row'>
                      <div className='col-6'>
                        <label
                          className={ generateLabelClass(
                            errors.name || missingFields.name,
                          ) }
                          htmlFor='productName'
                        >
                          Product name
                        </label>
                        <ClydeTextInput
                          className='form-field'
                          name='name'
                          id='name'
                          onChange={ this.handleChange }
                          value={ this.state.product.name }
                          disabled={ isShopifyOrBigCommerce }
                        />
                        <label
                          className={ generateLabelClass(
                            errors.type || missingFields.type,
                          ) }
                          htmlFor='type'
                        >
                          Type
                        </label>
                        <ClydeTextInput
                          className='form-field'
                          name='type'
                          id='type'
                          onChange={ this.handleChange }
                          value={ this.state.product.type }
                          disabled={ isShopifyOrBigCommerce }
                        />
                      </div>
                      <div className='col-6'>
                        <label
                          className='product-field-label'
                          htmlFor='product-image'
                        >
                          Image
                        </label>
                        { !this.state.fileData && !this.state.product.image ? (
                          <div>
                            <div
                              className='product-image-upload-container'
                              data-test='imageUploadContainer'
                              onClick={ this.openFileDialog }
                            >
                              <div className='product-image-upload'>
                                <img
                                  className='product-image-upload__image'
                                  alt='upload'
                                  src='/images/upload.png'
                                />
                              </div>
                              <div className='product-image-error'>
                                { this.state.imageErrorText }
                              </div>
                            </div>
                            <div className='hidden-input-container'>
                              <input
                                id='product-image'
                                type='file'
                                onChange={ this.selectFile }
                                disabled={ isShopifyOrBigCommerce }
                              />
                            </div>
                          </div>
                        ) : (
                          <div className='product-image-preview'>
                            <div
                              className='product-image-preview__reset'
                              onClick={ this.resetImage }
                              data-test='removeImage'
                            >
                              &#x2715;
                            </div>
                            <img
                              className='product-image-preview__image'
                              alt='uploaded'
                              src={
                                this.state.fileData || this.state.product.image
                              }
                            />
                          </div>
                        ) }
                      </div>
                      <div className='col-6'>
                        <label
                          className='product-field-label'
                          htmlFor='description'
                        >
                          Description
                        </label>
                        <ClydeTextInput
                          className='form-field'
                          name='description'
                          id='description'
                          onChange={ this.handleChange }
                          value={ this.state.product.description }
                          disabled={ isShopifyOrBigCommerce }
                        />
                      </div>
                      <div className='col-6'>
                        <label
                          className='product-field-label'
                          htmlFor='manufacturer'
                        >
                          Manufacturer
                        </label>
                        <ClydeTextInput
                          className='form-field'
                          name='manufacturer'
                          id='manufacturer'
                          onChange={ this.handleChange }
                          value={ this.state.product.manufacturer }
                          disabled={ isShopifyOrBigCommerce }
                        />
                      </div>
                      <div className='col-6'>
                        <label
                          className={ generateLabelClass(
                            errors.sku || missingFields.sku,
                          ) }
                          htmlFor='sku'
                        >
                          SKU
                          <span className='sku-tooltip'>
                            <ClydeTooltip
                              position='right'
                              label={
                                <div className='tooltip-text'>
                                  This SKU must be unique. You'll use the value
                                  here to record contract sales for this
                                  product.
                                </div>
                              }
                              hideIndicator
                            >
                              <img
                                data-tip
                                data-for='sku-info'
                                alt='sku'
                                src='/images/info-icon.svg'
                              />
                            </ClydeTooltip>
                          </span>
                        </label>
                        <ClydeTextInput
                          className='form-field'
                          name='sku'
                          id='sku'
                          onChange={ this.handleChange }
                          value={ this.state.product.sku }
                          disabled={ isShopifyOrBigCommerce }
                        />
                      </div>
                      <div className='col-6'>
                        <label
                          className={ generateLabelClass(
                            errors.price || missingFields.price,
                          ) }
                          htmlFor='price'
                        >
                          Price ({ shopCurrency })
                        </label>
                        <ClydeTextInput
                          className='form-field'
                          name='price'
                          id='price'
                          onChange={ this.handleChange }
                          onBlur={ this.formatPrice }
                          value={ this.state.product.price }
                          disabled={ isShopifyOrBigCommerce }
                        />
                      </div>
                      <div className='col-6'>
                        <label
                          className={ generateLabelClass(
                            errors.oemLength || missingFields.oemLength,
                          ) }
                          htmlFor='oemLength'
                        >
                          OEM Warranty Term (months){ ' ' }
                          { isShopifyOrBigCommerce && 'for all variants' }
                        </label>
                        <ClydeTextInput
                          className='form-field'
                          name='oemLength'
                          id='oemLength'
                          onChange={ this.handleChange }
                          value={ this.state.product.oemLength }
                          typeNumber
                        />
                      </div>
                    </div>
                    <div className='new-product-parts'>

                      { this.state.parts
                      && this.state.parts.length
                      && this.state.hasActivePart ? (
                          this.state.parts.map((part, index) => {
                            if (part.active) {
                              return (
                              <div
                                key={ `part-${index}` }
                                data-test={ `part-${index}` }
                                className='new-product-parts__list row'
                              >
                                <div className='col-3'>
                                  <label className={ generateLabelClass(errors[`${index}-name`] || missingFields[`${index}-name`]) }>Part Name</label>
                                  <ClydeTextInput
                                    className='form-field'
                                    name={ `${index}-name` }
                                    id={ `${index}-name` }
                                    onChange={ this.handlePartChange }
                                    value={ this.state.parts[index].name }
                                    dataTestValue='partName'
                                  />
                                </div>
                                <div className='col-3'>
                                  <label className={ generateLabelClass(errors[`${index}-modelNumber`], 'unique') }>Model Number</label>
                                  <ClydeTextInput
                                    className='form-field'
                                    name={ `${index}-modelNumber` }
                                    id={ `${index}-modelNumber` }
                                    onChange={ this.handlePartChange }
                                    value={ this.state.parts[index].modelNumber }
                                    dataTestValue='partModelNumber'
                                  />
                                </div>
                                <div className='col-3'>
                                  <label className={ generateLabelClass(errors[`${index}-cost`] || missingFields[`${index}-cost`]) }>Cost (USD)</label>
                                  <ClydeTextInput
                                    className='form-field'
                                    name={ `${index}-cost` }
                                    id={ `${index}-cost` }
                                    onChange={ this.handlePartChange }
                                    onBlur={ this.formatPrice }
                                    value={ this.state.parts[index].cost }
                                    dataTestValue='cost'
                                  />
                                </div>
                                <div className='col-2'>
                                  <div className='new-product-parts__controls'>
                                    <div
                                      onClick={ () => this.handlePartDelete(index)
                                      }
                                      data-test='removeAdditionalPart'
                                      className='circle minus'
                                      role='button'
                                    ></div>
                                    <div
                                      onClick={ this.handleNewPart }
                                      data-test='addAdditionalPart'
                                      className='circle plus'
                                      role='button'
                                    ></div>
                                  </div>
                                </div>
                              </div>
                              );
                            }
                            return null;
                          })
                        ) : (
                        <div className='new-product-parts__add'>
                          <p className='new-product-parts__add__text'>
                            Add New Part
                          </p>
                          <div
                            onClick={ this.handleNewPart }
                            data-test='addNewPart'
                            className='circle plus'
                            role='button'
                          ></div>
                        </div>
                        ) }
                    </div>
                    <div className='new-product-footer'>
                      <ClydeButton
                        id='products-save-button'
                        className='product-save-button'
                        onClick={ handleSubmitFn }
                        processing={ saveProductProcessing }
                        successText='Done'
                        disabled={ !this.state.formValid }
                        data-test='save'
                      >
                        Save
                      </ClydeButton>
                    </div>
                  </div>
                </div>
              ) : (
                <SuccessAnimation onSuccess={ exitUpload } />
              ) }
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default NewProduct;
