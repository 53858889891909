const defaultState = {
  allUsers: [],
  isProcessing: false,
  isSuccess: false,
  loading: true,
  newUser: {},
};

const userManagementReducer = (state = defaultState, action) => {
  switch (action.type) {
    case 'GET_ALL_SHOP_USERS_RES':
      return {
        ...state,
        loading: false,
        allUsers: action.payload,
      };
    case 'RESET_USER_PASSWORD_RES':
    case 'TOGGLE_USER_DISABLED_RES':
    case 'UPDATE_USER_ACCOUNT_TYPE_RES':
      return {
        ...state,
        loading: false,
        allUsers: state.allUsers.map((user) => (user.id === action.payload.id ? action.payload : user)),
      };
    case 'CREATE_USER':
      return {
        ...state,
        isProcessing: true,
      };
    case 'CREATE_USER_RES':
      return {
        ...state,
        allUsers: [action.payload].concat(state.allUsers),
        newUser: { ...action.payload },
        isProcessing: false,
        isSuccess: true,
      };
    case 'CLEAR_USER_FEEDBACK':
      return {
        ...state,
        isProcessing: false,
        isSuccess: false,
        newUser: {},
      };
    case 'CLEAR_USER_SUCCESS':
      return {
        ...state,
        isProcessing: false,
        isSuccess: false,
        newUser: {},
      };
    case 'ERROR':
      switch (action.payload.reqType) {
        case 'CREATE_USER_REQ':
          return {
            ...state,
            isProcessing: false,
            isSuccess: false,
          };
        default:
          return state;
      }
    default:
      return state;
  }
};

export default userManagementReducer;
