import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import {
  cancelResendPpEmail,
  confirmResendPpEmail,
} from '../../../../actions/customersActions';

import ResendPostPurchaseModal from './ResendPPEmail';

const mapStateToProps = (state) => ({
  order: state.customers.selectedOrder,
  resendPpEmail: state.customers.resendPpEmail,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  _cancelResendPpEmail: cancelResendPpEmail,
  _confirmResendPpEmail: confirmResendPpEmail,
}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ResendPostPurchaseModal);
