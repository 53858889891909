import React, { useState } from 'react';
import classnames from 'classnames';
import { UnmountClosed } from 'react-collapse';
import { ClydeTooltip, ClydeCheckbox } from '@joinclyde/clyde-react-components';

import VariantSwitch from './VariantSwitch';
import { Currency } from '../../Utils/Currency';
import PlatformGate from '../../Utils/PlatformGate';
import ContractTermsLink from '../../Utils/ContractTermsLink';

const ProductVariant = (props) => {
  const {
    variant,
    status,
    variantIndex,
    productIndex,
    selectRegistrable,
    toggleRegistrable,
    onlyIncludeRegistrableProducts,
  } = props;

  const [expandContracts, setExpandContracts] = useState(false);
  const toggleExpandContracts = () => { setExpandContracts(!expandContracts); };

  let contractText;

  if (status === 'matched') {
    contractText = `Contracts: ${variant.Contracts.length}`;
  } else if (status === 'no-match') {
    contractText = 'No contract match';
  } else if (status === 'pending') {
    contractText = 'Contracts pending';
  }
  return (
    <div>
      <div className='row variant-row ml-5'>
        <div className='col-10 col-md-6 offset-1 variant-row__name-section'>
          { selectRegistrable
            ? <ClydeCheckbox
                backgroundColor='clydeBlack'
                className='product-variant__registrable-products-checkbox'
                checked={ variant.RegistrableProduct || !onlyIncludeRegistrableProducts }
                disabled={ !onlyIncludeRegistrableProducts }
                onChange={ () => toggleRegistrable(variant.ProductID, variant.RegistrableProduct) }
                name='selectRegistrableVariant'
              />
            : null
          }
          <div>
            { selectRegistrable
              ? null
              : `${variantIndex + 1}. `
            }
            { variant.VariantName.length > 30
              ? `${variant.VariantName.slice(0, 30)}...`
              : variant.VariantName
            }
            &nbsp;—&nbsp;
            <Currency amount={ variant.Price } />
          </div>
        </div>
        <div
          onClick={ variant.Contracts.length > 0 && status === 'matched'
            ? toggleExpandContracts : null }
          className={ classnames('expand-contracts-text col-2 ml-5',
            { 'expand-contracts-text--matched': variant.Contracts.length > 0 && status === 'matched' }) }
        >
          { contractText }
          { status === 'matched'
            && <img alt=''
              id={ `variant-contracts-toggle-${variant.ProductID}` }
              className={ `expand-contracts-caret expand-contracts-caret--${expandContracts ? 'down' : 'right'}` }
              src='/images/caret.svg'
            />
          }
        </div>
        <PlatformGate isPlatformShop>
          <div className=' col-2 variant-switch-container ml-2'>
          { status === 'matched'
            && <VariantSwitch
              productId={ variant.ProductID }
              productIndex={ productIndex }
              variantIndex={ variantIndex }
              on={ variant.Active }
              reviewed={ variant.Reviewed }
              noMatch={ status === 'no-match' }
            />
          }
          </div>
        </PlatformGate>
      </div>
      { variant.Contracts.length > 0 && status === 'matched'
        && <UnmountClosed
            springConfig={ { stiffness: 300, damping: 30 } }
            className='contracts-collapse-container'
            isOpened={ expandContracts }>
          <div className='variant-contracts-list'>
          { variant.Contracts.map((contract) => {
            const merchantCost = contract.BaseCost + contract.BackendFee + contract.PlatformFee;
            return (
              <div key={ contract.ID } className='row variant-contract ml-3'>
                <ContractTermsLink contractId={ contract.ID } className='col-3 ml-5 variant-contract__name'>
                  { `${contract.Term} year ${contract.Category}` }
                </ContractTermsLink>
                <div className='col-2'>
                  SKU: { contract.MerchantSku || contract.ContractSku }
                </div>
                <div className='col-2'>
                  Merchant Cost:&nbsp;
                  <Currency amount={ merchantCost } />
                </div>
                <div className='col-2'>
                  Markup:&nbsp;
                  <Currency amount={ contract.Markup } />
                  { contract.FixedMarkup
                    && <div className='variant-contract__tooltip'>
                      <ClydeTooltip
                          label={
                            <div className='tooltip-text'>
                              Your markup settings cannot be applied to this contract type.
                            </div>
                          }
                          hideIndicator>
                      <img
                        data-tip data-for={ `contract-${contract.ID}` } alt='info'
                        className='variant-contract__tooltip__icon'
                        src='/images/info-icon.svg'/>
                        </ClydeTooltip>
                    </div>
                  }
                </div>
                <div className='col-2'>
                  Total:&nbsp;
                  <Currency amount={ merchantCost + contract.Markup } />
                </div>
              </div>
            );
          }) }
          </div>
        </UnmountClosed>
      }
    </div>
  );
};

export default ProductVariant;
