import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import {
  getProductFilters,
  setFilterTags,
  setFilterTypes,
  setFilterManufacturers,
  setSearch,
  runSearch,
  getSkuExport,
  resetExport,
} from '../../../actions/productsActions';

import ProductFilters from './ProductFilters';

const mapStateToProps = (state) => ({
  productTypes: state.products.productTypes, // [{ name: "Bike", filter: false }, ...]
  filterTypes: state.products.filterTypes,
  productTags: state.products.productTags, // [{ id: "...", name: "Steel", filter: false }, ...]
  filterTags: state.products.filterTags,
  productManufacturers: state.products.productManufacturers,
  filterManufacturers: state.products.filterManufacturers,
  productSearch: state.products.productSearch,
  pageNumber: state.products.pageNumber,

  skuExport: state.products.skuExport,
  exportReady: state.products.exportReady,

  getProductFiltersProcessing: state.products.getProductFiltersProcessing,
  refresh: state.products.refresh,
  preservePage: state.products.preservePage,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  _getProductFilters: getProductFilters,
  _setFilterTags: setFilterTags,
  _setFilterTypes: setFilterTypes,
  _setFilterManufacturers: setFilterManufacturers,
  _setSearch: setSearch,
  _runSearch: runSearch,
  _getSkuExport: getSkuExport,
  _resetExport: resetExport,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ProductFilters);
