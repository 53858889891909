import React, { useEffect } from 'react';
import qs from 'qs';

/*
  MARKER AJC: The entire point of this component is to act as the entry point for Frigg-related oAuth integration
  redirects. We get the integration name and any relevant information from the redirect URL path and query string,
  send that info down to the BE to begin the Frigg req / res cycle, then immediately move the user onto the
  Settings Integrations sub-tab where we wait for the Frigg response to continue the integration.

  The returned JSX should never actually be seen by the user.
*/

const OAuthRedirectLandingPage = (props) => {
  const {
    history,
    userId,
    location: {
      pathname,
      search,
    },
    _handleOAuthRedirectData,
  } = props;

  useEffect(() => {
    const pathArray = pathname.split('/');
    const integration = pathArray[pathArray.length - 1]?.trim();
    const queryData = qs.parse(search.slice(1));

    _handleOAuthRedirectData(userId, integration, queryData);

    history.push('/settings/integrations');
  }, []);

  return (
    <div>...redirecting</div>
  );
};

export default OAuthRedirectLandingPage;
