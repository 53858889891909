import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import {
  setSearch,
  setPage,
  runSearch,
  showConfirmResendEmail,
  showInvoiceExportModal,
  showImportSalesModal,
} from '../../actions/salesActions';

import Sales from './Sales';

const mapStateToProps = (state) => ({
  ...state.sales,
  currency: state.billing.currency,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  _setSearch: setSearch,
  _setPage: setPage,
  _runSearch: runSearch,
  _showConfirmResendEmail: showConfirmResendEmail,
  _showInvoiceExportModal: showInvoiceExportModal,
  _showImportSalesModal: showImportSalesModal,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Sales);
