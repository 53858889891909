import { connect } from 'react-redux';

import {
  getFriggAuthRequirements,
  openSetupModal,
} from '../../../../actions/integrationsActions';

import IntegrationsSettingsResults from './IntegrationsSettingsResults';

const mapStateToProps = (state) => ({
  userId: state.auth.userId,
  platform: state.auth.platform,
});

const mapDispatchToProps = (dispatch) => ({
  _getFriggAuthRequirements: (userId, integrationType) => {
    dispatch(getFriggAuthRequirements(userId, integrationType));
  },
  _openSetupModal: (selectedIntegration) => {
    dispatch(openSetupModal(selectedIntegration));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(IntegrationsSettingsResults);
