import { connect } from 'react-redux';

import {
  // connectFriggControlledIntegration,
  updateSetupModalFriggControlledIntegrationValue,
  setFriggOAuthData,
} from '../../../../../../actions/integrationsActions';

import FriggControlled from './FriggControlled';

const mapStateToProps = (state) => ({
  userId: state.auth.userId,
  authRequirements: state.integrations.setupModal.authRequirements,
  selectedIntegration: state.integrations.setupModal.selectedIntegration,
  integrationData: state.integrations.setupModal.integrationData,
  getProcessing: state.integrations.setupModal.getProcessing,
  setOAuthProcessing: state.integrations.setupModal.setOAuthProcessing,
});

const mapDispatchToProps = (dispatch) => ({
  _connectIntegration: () => {
  // _connectIntegration: (integration, data) => {
    // dispatch(connectFriggControlledIntegration(integration, data));
    // console.log('placeholder', integration, data);
  },
  _updateValue: (key, value) => {
    dispatch(updateSetupModalFriggControlledIntegrationValue(key, value));
  },
  _setFriggOAuthData: (userId, data, name) => {
    dispatch(setFriggOAuthData(userId, data, name));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(FriggControlled);
