import React, { Component } from 'react';
import classnames from 'classnames';
import {
  ClydeButton,
  ClydeCheckbox,
  ClydeSelect,
  ClydeTextInput,
  LargeModal,
  LoadingDots,
  SmallModal,
  SuccessAnimation,
  ClydeTextArea,
  ClydeFileUpload,
} from '@joinclyde/clyde-react-components';

import { formatDate } from '../../../lib/timeUtils';

import ProofOfWorkInput from './ProofOfWorkInput';
import ResolutionInputGroup from './ResolutionInputGroup';
import { resolutionStatusSwitcher } from '../../Utils/claimHelper';
import DepotResolutionSummary from './DepotResolutionSummary';

class Resolution extends Component {
  state = {
    displayedFile: {
      fileString: '',
      fileType: '',
    },
    isFileModalActive: false,
    isSuccessModalActive: false,

    // depot repair only
    carrierName: '',
    trackingNumber: '',
    repairDescription: '',
    replaceDescription: '',

    // shared between both repair flows
    shippingCost: '',
    laborCost: '',
    partsCostEstimate: '',

    // claim needs review
    claimNeedsReview: false,
  };

  componentDidMount() {
    const { claimId, _fetchResolution } = this.props;
    const {
      shopClaimSettings: {
        repairEnabled,
        replaceEnabled,
        promoCodeEnabled,
        retailerResolutions,
        depotRepairEnabled,
      },
      _handleResolutionDataChange,
    } = this.props;

    if (retailerResolutions) {
      if (repairEnabled) {
        _handleResolutionDataChange('programType', 'repair');
        _handleResolutionDataChange('serviceType', 'inHome');
      } else if (depotRepairEnabled) {
        _handleResolutionDataChange('programType', 'repair');
        _handleResolutionDataChange('serviceType', 'depot');
      } else if (replaceEnabled) {
        _handleResolutionDataChange('programType', 'replacement');
        _handleResolutionDataChange('serviceType', 'clydeReplace');
      } else if (promoCodeEnabled) {
        _handleResolutionDataChange('programType', 'replacement');
        _handleResolutionDataChange('serviceType', 'promoCode');
      }
    }

    _fetchResolution(claimId);
  }

  componentWillUnmount() {
    const { _clearResolutionDetail } = this.props;
    _clearResolutionDetail();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.processing.close === true && this.props.processing.close === false) {
      this.setState({ isSuccessModalActive: true });
    }
  }

  openFileModal = (file) => {
    this.setState({
      displayedFile: {
        fileString: `data:${file.type};base64,${file.content}`,
        fileType: file.type,
      },
      isFileModalActive: true,
    });
  }

  closeFileModal = () => {
    this.setState({
      displayedFile: {
        fileString: '',
        fileType: '',
      },
      isFileModalActive: false,
    });
  }

  closeSuccessModal = () => {
    this.setState({ isSuccessModalActive: false });
  }

  successModalTimeout = () => {
    const {
      claimId,
      onDetailPage,
      _fetchClaimData,
      shopId,
      _getClaimsForResolution,
    } = this.props;
    if (onDetailPage) {
      _fetchClaimData(claimId);
      this.closeSuccessModal();
      window.scrollTo(0, 0);
      /// Marker: AJC 2020/08/05 Remove below when SmallModal is fixed
      document.body.classList.remove('overflow-off');
      // Update claims that need resolution count when claim is resolved
      _getClaimsForResolution(shopId);
    }
  }

  handleResolutionProductPartToggle = (e) => {
    this.props._handleResolutionDataChange('resolutionProductPartIds', e.target.values);

    const { openCosts } = this.props;
    this.updateCostFields(e.target.values, openCosts);
  }

  handleResolutionOpenCostsChange = (e) => {
    const { name, value } = e.target;

    const { resolutionProductPartIds } = this.props;
    this.setState({ [name]: value }, () => {
      this.updateCostFields(resolutionProductPartIds, value);
    });
  }

  updateCostFields = (selectedPartIds) => {
    const {
      productParts,
      depotRepaircost,
      shopClaimSettings: {
        depotRepairEnabled,
      },
    } = this.props;

    const {
      shippingCost,
      laborCost,
      partsCostEstimate,
    } = this.state;

    let partTotal = 0;
    for (const part of productParts) {
      if (selectedPartIds.indexOf(part.id) !== -1) {
        partTotal += parseFloat(part.cost);
      }
    }

    const sanitizedShippingCost = !shippingCost || Number.isNaN(shippingCost) ? 0 : parseFloat(shippingCost);
    const sanitizedLaborCost = !laborCost || Number.isNaN(laborCost) ? 0 : parseFloat(laborCost);
    let sanitizedPartTotal;

    if (!partTotal || Number.isNaN(partTotal)) {
      sanitizedPartTotal = !partsCostEstimate || Number.isNaN(partsCostEstimate) ? 0 : parseFloat(partsCostEstimate);
    } else {
      sanitizedPartTotal = !partTotal || Number.isNaN(partTotal) ? 0 : parseFloat(partTotal);
    }

    const totalCost = (sanitizedShippingCost + sanitizedLaborCost + sanitizedPartTotal).toFixed(2);

    if (depotRepairEnabled) {
      const sanitizedShippingCostWithDepotRepair = !depotRepaircost || Number.isNaN(depotRepaircost)
        ? 0 : parseFloat(depotRepaircost);
      const costWithShipping = sanitizedShippingCostWithDepotRepair + sanitizedLaborCost + sanitizedPartTotal;
      this.props._handleResolutionDataChange('cost', costWithShipping.toFixed(2));
      return;
    }

    this.props._handleResolutionDataChange('cost', totalCost);
  }

  handleSubmit = (closeResolution) => {
    const {
      claimId,
      cost,
      resolutionProductPartIds,
      id,
      programType,
      serviceType,
      promoCode,
      proofFiles,
      receiptFiles,
      retailerTicketId,
      shippingCarrier,
      shippingNumber,
      productParts,
      depotRepairStep,
      _submitResolution,
      _closeResolution,
      fromCustomerShippingCost,
      laborCost: laborCostFromPreviousStep,
      partsCost: partsCostFromPreviousStep,
      remainingLimitOfLiability,
      _submitClaimNeedsReviewUpdate,
      _submitDataForReview,
      claim: {
        reviewApproved,
      },
      replaceRequested,
      productInformation: {
        productRetailPrice,
      },
    } = this.props;

    // For non-depot repair flow
    const {
      shippingCost,
      laborCost,
      partsCostEstimate,
      replaceDescription,
    } = this.state;

    const fullReplacement = this.determineFullReplacement();
    const selectedParts = productParts.filter((part) => resolutionProductPartIds.indexOf(part.id) !== -1);

    let totalPartsCost;
    if (partsCostEstimate) {
      totalPartsCost = !partsCostEstimate || Number.isNaN(partsCostEstimate) ? 0 : parseFloat(partsCostEstimate);
    } else {
      totalPartsCost = selectedParts.reduce((acc, part) => {
        const sanitizedPartCost = !part.cost || Number.isNaN(part.cost) ? 0 : parseFloat(part.cost);
        return acc + sanitizedPartCost;
      }, 0);
    }

    const sanitizedCost = !cost || Number.isNaN(cost) ? 0 : parseFloat(cost);
    const sanitizedShippingCost = !shippingCost || Number.isNaN(shippingCost) ? 0 : parseFloat(shippingCost);
    const sanitizedLaborCost = !laborCost || Number.isNaN(laborCost) ? 0 : parseFloat(laborCost);

    const sixtyPercentOfLOL = parseFloat(remainingLimitOfLiability) * 0.6;
    const isRepairOrReplace = programType === 'repair' || programType === 'replacement';
    if (!reviewApproved && isRepairOrReplace && (parseFloat(cost) > 350 || parseFloat(cost) > (sixtyPercentOfLOL))) {
      let data = {
        cost: sanitizedCost.toFixed(2),
        type: programType,
      };

      if (programType === 'repair') {
        data = {
          ...data,
          shippingCost: sanitizedShippingCost.toFixed(2),
          laborCost: sanitizedLaborCost.toFixed(2),
          repairParts: [...selectedParts],
          partsCost: totalPartsCost.toFixed(2),
        };
      }

      if (programType === 'replacement') {
        data = {
          ...data,
          shippingCarrier,
          shippingNumber,
        };
      }

      _submitClaimNeedsReviewUpdate(claimId);
      _submitDataForReview(claimId, data);
      this.setState({ claimNeedsReview: true });
      return;
    }

    const resolutionData = {
      closed: closeResolution,
      selectedParts,
      cost: sanitizedCost,
      fullReplacement,
      id,
      programType,
      serviceType,
      promoCode,
      proofFiles,
      receiptFiles,
      retailerTicketId,
      shippingCarrier,
      shippingNumber,
      source: 'dashboard',
      totalPartsCost,
      partsCost: totalPartsCost.toFixed(2),
      shippingCost: sanitizedShippingCost.toFixed(2),
      laborCost: sanitizedLaborCost.toFixed(2),
    };

    if (serviceType === 'depot' && depotRepairStep === 3) {
      const { carrierName, trackingNumber } = this.state;

      // Handle case when a replace is requested after evaluating cost of repair
      if (replaceRequested) {
        resolutionData.programType = 'replacement';
        resolutionData.fullReplacement = true;
      } else {
        resolutionData.programType = 'repair';
        resolutionData.serviceType = 'depot';
      }

      // Override parts and labor costs
      resolutionData.partsCost = replaceRequested ? 0 : partsCostFromPreviousStep;
      resolutionData.laborCost = replaceRequested ? 0 : laborCostFromPreviousStep;
      const sanitizedFromCustomerCost = !fromCustomerShippingCost || Number.isNaN(parseFloat(fromCustomerShippingCost))
        ? 0 : parseFloat(fromCustomerShippingCost);
      resolutionData.shippingCost = (sanitizedFromCustomerCost + sanitizedShippingCost).toFixed(2);

      // Need to add final shipping information and cost before closing claim
      resolutionData.shippingCarrier = carrierName;
      resolutionData.shippingNumber = trackingNumber;
      resolutionData.cost = sanitizedShippingCost + sanitizedCost;
    }

    if (programType === 'replaceFromRepair') {
      const sanitizedProductCost = !productRetailPrice || Number.isNaN(productRetailPrice)
        ? 0 : parseFloat(productRetailPrice);
      resolutionData.cost = sanitizedShippingCost + sanitizedProductCost;
      resolutionData.programType = 'replacement';
      resolutionData.serviceType = 'clydeReplace';
      resolutionData.fullReplacement = true;
      resolutionData.productCost = sanitizedProductCost;
      resolutionData.replaceDescription = replaceDescription;
    }

    if (closeResolution) {
      _closeResolution(claimId, resolutionData);
    } else {
      _submitResolution(claimId, resolutionData);
    }
  }

  determineFullReplacement = () => {
    const { fullReplacement, serviceType } = this.props;
    switch (serviceType) {
      case 'payout':
      case 'clydeReplace':
        return true; // fullReplacement assumed true
      case 'repair':
        return null; // fullReplacement not possible
      case 'depot':
        return null; // fullReplacement not possible
      case 'promoCode':
      default:
        return fullReplacement; // User selects fullReplacement
    }
  }

  handleMethodChange = (e) => {
    e.preventDefault();
    const { serviceType, _handleResolutionDataChange } = this.props;
    const { name } = e.target;

    switch (name) {
      case 'repair':
        if (serviceType !== 'inHome' || serviceType !== 'customerInstall') {
          _handleResolutionDataChange('serviceType', 'inHome');
        }
        _handleResolutionDataChange('programType', 'repair');
        break;
      case 'depot':
        _handleResolutionDataChange('serviceType', 'depot');
        _handleResolutionDataChange('programType', 'repair');
        break;
      case 'clydeReplace':
      case 'promoCode':
        _handleResolutionDataChange('serviceType', name);
        _handleResolutionDataChange('programType', 'replacement');
        break;
      default:
        break;
    }

    _handleResolutionDataChange('fullReplacement', false);

    if (!this.props.id) {
      _handleResolutionDataChange('resolutionProductPartIds', []);
      _handleResolutionDataChange('openCosts', '');
      _handleResolutionDataChange('cost', '');
    }
  }

  handleDepotReplacementRequest = () => {
    const {
      depotRepairShippingHistory,
      productInformation: {
        productRetailPrice,
      },
      _handleResolutionDataChange,
    } = this.props;

    let shippingCost;
    if (depotRepairShippingHistory) {
      ({ shippingCost } = depotRepairShippingHistory[0]);
    }
    const sanitizedShippingCost = !shippingCost || Number.isNaN(shippingCost) ? 0 : parseFloat(shippingCost);
    const sanitizedRetailPrice = !productRetailPrice || Number.isNaN(productRetailPrice)
      ? 0 : parseFloat(productRetailPrice);
    const replaceCost = sanitizedShippingCost + sanitizedRetailPrice;
    _handleResolutionDataChange('programType', 'replaceFromRepair');
    _handleResolutionDataChange('replaceCost', replaceCost);
    _handleResolutionDataChange('isReplaceRequested', true);
  }

  handleSelfInstallReplacementRequest = () => {
    const {
      productInformation: {
        productRetailPrice,
      },
      _handleResolutionDataChange,
    } = this.props;

    const {
      shippingCost,
    } = this.state;

    const sanitizedShippingCost = !shippingCost || Number.isNaN(shippingCost) ? 0 : parseFloat(shippingCost);
    const sanitizedRetailPrice = !productRetailPrice || Number.isNaN(productRetailPrice)
      ? 0 : parseFloat(productRetailPrice);
    const replaceCost = sanitizedShippingCost + sanitizedRetailPrice;
    _handleResolutionDataChange('programType', 'replaceFromRepair');
    _handleResolutionDataChange('replaceCost', replaceCost);
    _handleResolutionDataChange('isReplaceRequested', true);
  }

  disableCloseButton = () => {
    const {
      closed,
      cost,
      serviceType,
      promoCode,
      remainingLimitOfLiability,
    } = this.props;

    if (closed) {
      return true;
    }
    if (serviceType === 'promoCode' && !promoCode) {
      return true;
    }

    if (cost > remainingLimitOfLiability) {
      return true;
    }

    return false;
  }

  handleDepotRepairSubmit = () => {
    let updateInformation;
    const {
      cost,
      claimId,
      productParts,
      resolutionProductPartIds,
      depotRepairStep,
      depotRepairShippingHistory = [],
      depotRepairSelectedParts,
      depotRepaircost,
      depotRepairDescription,
      depotReplaceDescription,
      programType,
      serviceType,
      replaceCost,
      _submitDepotRepairUpdate,
      _fetchResolution,
      fromCustomerShippingCost,
      laborCost: laborCostFromPreviousStep,
      partsCost: partsCostFromPreviousStep,
      remainingLimitOfLiability,
      _submitClaimNeedsReviewUpdate,
      isReplaceRequested,
      productInformation: {
        productRetailPrice,
      },
      replaceRequested,
    } = this.props;

    const {
      carrierName,
      trackingNumber,
      repairDescription: newRepairDescription,
      replaceDescription: newReplaceDescription,
      shippingCost,
      laborCost,
      partsCostEstimate,
    } = this.state;

    // Use to make distinction from where the merchant will receive item for appraisal
    // and when the merchant sends the repaired product back.
    const shippingInfoType = depotRepairStep === 1 ? 'fromCustomer' : 'toCustomer';

    const sanitizedLaborCost = !laborCost || Number.isNaN(laborCost) ? 0 : parseFloat(laborCost);
    const sanitizedPartsEstimateCost = !partsCostEstimate || Number.isNaN(partsCostEstimate)
      ? 0 : parseFloat(partsCostEstimate);
    const sanitizedShippingCost = !shippingCost || Number.isNaN(shippingCost) ? 0 : parseFloat(shippingCost);

    if (depotRepairStep === 1) {
      updateInformation = {
        programType,
        serviceType,
        cost: 0,
        repairDescription: newRepairDescription,
        fromCustomerShippingCost: sanitizedShippingCost.toFixed(2),
        repairParts: [],
        shippingHistory: [{
          shippingCost: sanitizedShippingCost.toFixed(2),
          type: shippingInfoType,
          carrier: carrierName,
          trackingNumber,
          createdAt: new Date(),
        }],
      };
    } else if (depotRepairStep === 2 && programType === 'replaceFromRepair') {
      updateInformation = {
        programType,
        serviceType,
        cost: replaceCost.toFixed(2),
        fromCustomerShippingCost,
        productRetailPrice: parseFloat(productRetailPrice).toFixed(2),
        replaceDescription: newReplaceDescription,
        shippingHistory: [...depotRepairShippingHistory],
        replaceRequested: true,
      };
    } else if (depotRepairStep === 2) {
      const selectedParts = productParts.filter((part) => resolutionProductPartIds.indexOf(part.id) !== -1);

      let totalPartsCost;
      if (sanitizedPartsEstimateCost === 0) {
        totalPartsCost = selectedParts.reduce((acc, part) => {
          const sanitizedPartCost = !part.cost || Number.isNaN(part.cost) ? 0 : parseFloat(part.cost);
          return acc + sanitizedPartCost;
        }, 0);
      } else {
        totalPartsCost = sanitizedPartsEstimateCost;
      }

      updateInformation = {
        programType,
        serviceType,
        cost: parseFloat(cost).toFixed(2),
        laborCost: sanitizedLaborCost.toFixed(2),
        partsCost: totalPartsCost.toFixed(2),
        fromCustomerShippingCost,
        repairDescription: newRepairDescription,
        repairParts: [...selectedParts],
        shippingHistory: [...depotRepairShippingHistory],
      };
    } else if (depotRepairStep === 3) {
      // Need to add last shipping cost to running total
      const sanitizedDepotRepaircost = !depotRepaircost || Number.isNaN(depotRepaircost)
        ? 0 : parseFloat(depotRepaircost);
      const totalCost = (sanitizedDepotRepaircost + sanitizedShippingCost).toFixed(2);

      if (replaceRequested) {
        updateInformation = {
          programType,
          serviceType,
          cost: totalCost,
          productRetailPrice: parseFloat(productRetailPrice).toFixed(2),
          fromCustomerShippingCost,
          toCustomerShippingCost: sanitizedShippingCost.toFixed(2),
          replaceRequested: true,
          shippingHistory: [
            ...depotRepairShippingHistory,
            {
              shippingCost: sanitizedShippingCost.toFixed(2),
              type: shippingInfoType,
              carrier: carrierName,
              trackingNumber,
              createdAt: new Date(),
            },
          ],
        };
      } else {
        updateInformation = {
          programType,
          serviceType,
          cost: totalCost,
          laborCost: laborCostFromPreviousStep,
          partsCost: partsCostFromPreviousStep,
          fromCustomerShippingCost,
          toCustomerShippingCost: sanitizedShippingCost.toFixed(2),
          repairParts: depotRepairSelectedParts && depotRepairSelectedParts.length ? [...depotRepairSelectedParts] : [],
          shippingHistory: [
            ...depotRepairShippingHistory,
            {
              shippingCost: sanitizedShippingCost.toFixed(2),
              type: shippingInfoType,
              carrier: carrierName,
              trackingNumber,
              createdAt: new Date(),
            },
          ],
        };
      }

      if (depotRepairDescription) {
        updateInformation.repairDescription = depotRepairDescription;
      } else if (depotReplaceDescription) {
        updateInformation.replaceDescription = depotReplaceDescription;
      }
    }

    // Add check here to see if I should set for review or not
    // Needs review if the total cost of a repair or replacement is above $350 *OR*
    // 60% of the customer’s remaining Limit of Liability
    const sixtyPercentOfLOL = parseFloat(remainingLimitOfLiability) * 0.6;
    const comparisonCost = isReplaceRequested ? replaceCost : cost;
    if (depotRepairStep === 2 && (parseFloat(comparisonCost) > 350
    || parseFloat(comparisonCost) > (sixtyPercentOfLOL))) {
      _submitClaimNeedsReviewUpdate(claimId);
      _submitDepotRepairUpdate(claimId, updateInformation);
      this.setState({ claimNeedsReview: true });
    } else {
      _submitDepotRepairUpdate(claimId, updateInformation);
      if (depotRepairStep === 3) {
        this.handleSubmit(true);
      }
      _fetchResolution(claimId);
    }
  }

  handleDepotRepairTextField = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  }

  getTextForDepotButton = (step) => ({
    1: 'Submit shipping details',
    2: 'Submit quote',
    3: 'Submit shipping details',
  }[step] || '')

  closeNeedsReviewModal = () => {
    const {
      claimId,
      onDetailPage,
      _fetchClaimData,
    } = this.props;
    if (onDetailPage) {
      window.scrollTo(0, 0);
      _fetchClaimData(claimId);
      document.body.classList.remove('overflow-off');
    }
  }

  render() {
    const {
      id,
      closed,
      cost,
      fullReplacement,
      serviceType,
      programType,
      payoutType,
      payoutDate,
      onDetailPage,
      processing,
      productParts,
      promoCode,
      proofFiles,
      remainingLimitOfLiability,
      resolutionProductPartIds,
      retailerTicketId,
      settings,
      shippingCarrier,
      shippingNumber,
      uploadedProofFiles,
      shopClaimSettings: {
        repairEnabled,
        replaceEnabled,
        promoCodeEnabled,
        retailerResolutions,
        depotRepairEnabled,
      },
      _handleResolutionDataChange,
      _cancelFileClaim,
      depotRepairStep,
      depotRepairShippingHistory,
      depotRepairSelectedParts,
      depotRepairDescription,
      depotReplaceDescription,
      productInformation: {
        productRetailPrice,
      },
      replaceCost,
      partsCost: resolutionPartsCost,
      shippingCost: resolutionShippingCost,
      laborCost: resolutionLaborCost,
      fromCustomerShippingCost,
      claim,
      isReplaceRequested,
      replaceRequested,
    } = this.props;

    const {
      displayedFile: {
        fileString,
        fileType,
      },
      isFileModalActive,
      isSuccessModalActive,
      repairDescription,
      laborCost,
      partsCostEstimate,
      claimNeedsReview,
    } = this.state;

    const mainClass = classnames('claim-detail-resolution', {
      'claim-detail-resolution--modal': !onDetailPage,
    });
    const submitClass = classnames('claim-detail-resolution-submit', {
      'claim-detail-resolution-submit--modal': !onDetailPage,
    });
    const depotRepairSubmitClass = classnames('claim-detail-depot-resolution', {
      'claim-detail-resolution-submit--modal': !onDetailPage,
    });
    const promoCodeClass = classnames('button-nostyle claim-detail-selector', {
      'claim-detail-selector--selected': serviceType === 'promoCode',
    });
    const repairClass = classnames('button-nostyle claim-detail-selector', {
      'claim-detail-selector--selected': programType === 'repair' && serviceType !== 'depot',
    });
    const depotRepairClass = classnames('button-nostyle claim-detail-selector', {
      'claim-detail-selector--selected': serviceType === 'depot',
    });
    const replaceClass = classnames('button-nostyle claim-detail-selector', {
      'claim-detail-selector--selected': programType === 'replacement' && serviceType !== 'promoCode',
    });

    const closeButtonDisabled = this.disableCloseButton();
    const productPartsOptions = productParts.map((part) => ({
      value: part.id,
      text: (<div className='resolution-parts-input__option'>
        <div className='part-name'>{ part.name }</div>
        <div className='part-name'>{ part.modelNumber }</div>
        <div className='part-cost'>${ part.cost }</div>
      </div>),
    }));

    const depotProductPartsOptions = productParts.map((part) => ({
      value: part.id,
      text: (<div className='resolution-parts-input__option'>
        <div className='part-name'>{ part.name } { part.modelNumber }: ${ part.cost }</div>
      </div>),
    }));

    // this is dirty bad js math, sorry
    const availableMethodsCount = repairEnabled + replaceEnabled + promoCodeEnabled + depotRepairEnabled;
    const readOnlyView = closed || !retailerResolutions || availableMethodsCount === 0;
    const hideMethodSelectorBar = depotRepairStep && (depotRepairStep === 2 || depotRepairStep === 3);
    const depotRepairButtonDisabled = (serviceType === 'depot'
      && depotRepairStep === 2
      && (!repairDescription || repairDescription.length < 100))
      || (cost >= remainingLimitOfLiability && !readOnlyView);
    const depotRepairButtonText = this.getTextForDepotButton(depotRepairStep);
    const isReplaceFromRepair = depotReplaceDescription && depotReplaceDescription !== undefined;
    const depotResolutionSummaryData = {
      depotRepairStep,
      depotRepairShippingHistory,
      depotRepairSelectedParts,
      cost,
      depotRepairDescription,
      depotReplaceDescription,
      productRetailPrice,
      resolutionPartsCost,
      resolutionLaborCost,
      fromCustomerShippingCost,
      replaceRequested,
    };

    const payoutLabels = {
      venmo: 'Venmo',
      paypal: 'PayPal',
      ach: 'ACH - Bank Transfer',
    };

    if (!id && (!retailerResolutions || availableMethodsCount === 0)) {
      return (
        <div className={ mainClass }>
          { onDetailPage && <h3>Resolution — Pending</h3> }
          <p>Please check back later for resolution details.</p>
        </div>
      );
    }

    if (claim.claimState === 'resolutionPending') {
      return (
        <div className={ mainClass }>
          <div className='depot-repair-main-heading'>
            <h3>Resolution: { isReplaceFromRepair ? 'Replace' : 'Repair' }</h3>
            <span className='depot-repair-badge'>{ !closed ? 'In-progress' : 'Fulfilled' }</span>
          </div>
          <div className='resolution-pending-container'>
            <img className='resolution-pending-img' alt='alert circle' src='/images/alert-circle--purple.svg' />
            <span>Your claim is awaiting approval from Clyde, we will be reaching out soon.</span>
          </div>
        </div>
      );
    }

    return (
      <>
        <LargeModal
          active={ isFileModalActive }
          className='claim-detail-supporting-file-modal'
          closeModal={ this.closeFileModal }
          headerText='Supporting Info File'>
          {
            fileType === 'application/pdf'
              ? <a download='supporting_info.pdf' href={ fileString }>Download PDF</a>
              : <img alt='supporting info file' src={ fileString } />
          }
        </LargeModal>

        {
          onDetailPage && isSuccessModalActive
            && <SmallModal
              active={ isSuccessModalActive }
              className='claim-resolution-success-modal'
              closeModal={ this.closeSuccessModal }>
              <SuccessAnimation
                className='claim-resolution-success-modal__animation'
                color={ 'clydeGreen' }
                onSuccess={ this.successModalTimeout }
                text='Resolution Closed!' />
            </SmallModal>
        }

        {
          onDetailPage && claimNeedsReview
            && <SmallModal
              active={ claimNeedsReview }
              className='claim-resolution-success-modal'
              closeModal={ this.closeNeedsReviewModal }>
              <div className='claim-review-container'>
                <img alt='alert circle' src='/images/alert-circle--purple.svg' />
                <p className='claim-review-container__header'>Claim Needs To Be Reviewed.</p>
                <p className='claim-review-container__text'>
                  The claim needs to be reviewed by the Clyde team before a resolution can be provided to the customer.
                </p>

                <ClydeButton
                  className='claim-review-container__button'
                  onClick={ this.closeNeedsReviewModal }
                >
                  Return to claim
                </ClydeButton>
              </div>
            </SmallModal>
        }

        <div className={ mainClass }>
          { onDetailPage && !depotRepairEnabled
            ? <h3>Resolution{ closed && ' — Closed' }</h3>
            : <div className='depot-repair-main-heading'>
                {
                  !closed && depotRepairStep > 1
                    ? <h3>Resolution: { isReplaceFromRepair ? 'Replace' : 'Repair' }</h3>
                    : <h3>Resolution{ closed && ' — Closed' }</h3>
                }
                <span className='depot-repair-badge'>{ !closed ? 'In-progress' : 'Fulfilled' }</span>
              </div>
          }

          {
            processing.fetch
              ? <LoadingDots
                  className='claim-detail-resolution-loading'
                  dotColor='clydeBlack'
                  dotSize='large' />
              : <>
                {
                  <div className='claim-detail-resolution__group'>
                    { (readOnlyView || availableMethodsCount === 1)
                      ? <>
                        <span className='claims-detail-card__group__title'>Resolution Method</span>
                        <span>{ resolutionStatusSwitcher(serviceType) }</span>
                      </>
                      : <>
                        {
                          hideMethodSelectorBar || isReplaceRequested
                            ? ''
                            : <>
                            <span className='fake-label fake-label--black' id='resolution_method'>Select a Resolution Method</span>
                            <div className='claim-detail-resolution__group__selector'>
                              { repairEnabled
                                && <button
                                  aria-labelledby='resolution_method'
                                  className={ repairClass }
                                  name='repair'
                                  onClick={ this.handleMethodChange }
                                  onMouseUp={ (e) => { e.currentTarget.blur(); } }
                                >
                                  Repair
                                  </button>
                              }
                              { depotRepairEnabled
                                && <button
                                  aria-labelledby='resolution_method'
                                  className={ depotRepairClass }
                                  name='depot'
                                  onClick={ this.handleMethodChange }
                                  onMouseUp={ (e) => { e.currentTarget.blur(); } }
                                >
                                  Repair
                                  </button>
                              }
                              { replaceEnabled
                                && <button
                                  aria-labelledby='resolution_method'
                                  className={ replaceClass }
                                  name='clydeReplace'
                                  onClick={ this.handleMethodChange }
                                  onMouseUp={ (e) => { e.currentTarget.blur(); } }
                                >
                                  Replacement
                                  </button>
                              }
                              { promoCodeEnabled
                                && <button
                                  aria-labelledby='resolution_method'
                                  className={ promoCodeClass }
                                  name='promoCode'
                                  onClick={ this.handleMethodChange }
                                  onMouseUp={ (e) => { e.currentTarget.blur(); } }
                                >
                                  Promo Code
                                  </button>
                              }
                            </div>
                          </>
                        }
                      </>
                    }
                  </div>
                }

                { serviceType === 'payout'
                  && <>
                    { payoutType
                      && <div className='claim-detail-resolution__group--row m-bottom-16'>
                        <span className='claims-detail-card__group__title'>Payout method</span>
                        <span>{ payoutLabels[payoutType] }</span>
                      </div>
                    }
                    { payoutDate
                      && <div className='claim-detail-resolution__group m-bottom-16'>
                        <span className='claims-detail-card__group__title'>Payout date</span>
                        <span>{ formatDate(payoutDate) }</span>
                      </div>
                    }
                  </>
                }

                {
                  depotRepairEnabled
                  && serviceType === 'depot'
                  && depotRepairStep === 1
                  && <>
                    <div className='claim-detail-resolution__group m-bottom-16'>
                      <span className='claims-detail-card__depot__title--bold'>
                        Fill out shipping information below so the customer can send you the product.
                      </span>
                    </div>

                    <div className='claim-detail-resolution__depot-shipping'>
                      <div>
                        <label htmlFor='shipping-number-from-customer'>Shipping Number</label>
                        <ClydeTextInput
                          id='shipping-number-from-customer'
                          name='trackingNumber'
                          onChange={ this.handleDepotRepairTextField } />
                      </div>

                      <div>
                        <label htmlFor='shipping-carrier-from-customer'>Shipping Carrier</label>
                        <ClydeTextInput
                          id='shipping-carrier-from-customer'
                          name='carrierName'
                          onChange={ this.handleDepotRepairTextField } />
                      </div>
                    </div>

                    <div className='claim-detail-resolution__depot-shipping'>
                      <div className='depot-repair-cost-field'>
                        <label htmlFor='shipping-cost-from-customer'>Shipping Cost</label>
                        <span className='depot-repair-cost-field--sign'>$</span>
                        <ClydeTextInput
                          id='shipping-cost-from-customer'
                          name='shippingCost'
                          min={ 0 }
                          step={ 0.01 }
                          typeNumber
                          onChange={ this.handleDepotRepairTextField } />
                      </div>
                    </div>
                  </>
                }

                {
                  depotRepairEnabled
                  && serviceType === 'depot'
                  && depotRepairStep === 2
                  && <>
                    <div className='claim-detail-resolution__group--row m-bottom-36'>
                      <DepotResolutionSummary
                        { ...depotResolutionSummaryData }
                        />
                    </div>
                    <div className='claim-detail-resolution__group--row m-bottom-36'>
                      <img alt='check' className='claim-detail-resolution-check' src='/images/checkmark-circle.svg' />
                      <span className='claims-detail-card__depot__title'>
                        Your shipping details have been submitted, fill out the form below upon receiving the product.
                      </span>
                    </div>
                    {
                      productParts.length > 0
                      && <>
                        <div className='claim-detail-resolution__depot-shipping'>
                          <div className='claim-detail-resolution__group'>
                            <label>Select parts:</label>
                            <ClydeSelect
                              id='resolution-product-parts'
                              disabled={ closed }
                              onChange={ this.handleResolutionProductPartToggle }
                              defaultValue={ resolutionProductPartIds }
                              name='resolutionProductPartIds'
                              options={ depotProductPartsOptions }
                              multiple
                            />
                          </div>
                        </div>
                      </>
                    }

                    {
                      (!productParts || productParts.length === 0)
                      && <div className='claim-detail-resolution__depot-shipping'>
                        <div className='depot-repair-cost-field'>
                          <label htmlFor='parts-costs'>Parts Cost:</label>
                          <span className='depot-repair-cost-field--sign'>$</span>
                          <ClydeTextInput
                            disabled={ closed }
                            id='parts-costs'
                            min={ 0 }
                            name='partsCostEstimate'
                            onChange={ this.handleResolutionOpenCostsChange }
                            step={ 0.01 }
                            typeNumber
                            value={ partsCostEstimate } />
                        </div>
                      </div>
                    }

                    <div className='claim-detail-resolution__depot-shipping'>
                      <div className='depot-repair-cost-field'>
                        <label htmlFor='labor-costs'>Labor Cost:</label>
                        <span className='depot-repair-cost-field--sign'>$</span>
                        <ClydeTextInput
                          disabled={ closed }
                          id='labor-costs'
                          min={ 0 }
                          name='laborCost'
                          onChange={ this.handleResolutionOpenCostsChange }
                          step={ 0.01 }
                          typeNumber
                          value={ laborCost } />
                      </div>
                    </div>

                    <div className='claim-detail-resolution__depot-shipping'>
                      <div className='depot-repair-cost-field'>
                        <label htmlFor='cost'>Total Cost of Repair:</label>
                        <span className='depot-repair-cost-field--sign__cost'>$</span>
                        <span className='depot-repair-sublabel'>Cost of shipping, parts & labor</span>
                        <ClydeTextInput
                          disabled
                          id='cost'
                          min={ 0 }
                          name='cost'
                          typeNumber
                          value={ cost }
                        />
                      </div>
                    </div>

                    <div className='claim-detail-resolution__group'>
                      <label htmlFor='shipping-carrier-repair-description'>Repair description:</label>
                      <ClydeTextArea
                        id='shipping-carrier-repair-description'
                        name='repairDescription'
                        rows='4'
                        onChange={ this.handleDepotRepairTextField } />
                    </div>

                    <div className='depot-repair-description-count'>
                      <span>{ repairDescription.length } Character(Minimum 100 Characters)</span>
                    </div>
                  </>
                }

                {
                  programType === 'replaceFromRepair'
                  && <>
                    <div className='claim-detail-resolution__group--row m-bottom-16'>
                      <span className='claims-detail-card__depot__title--bold'>
                        Replacement request from Repair
                      </span>
                    </div>

                    <div className='claim-detail-resolution__depot-shipping'>
                      <div className='depot-repair-cost-field'>
                        <label htmlFor='product-price'>Cost of Replacement Product:</label>
                        <span className='depot-repair-cost-field--sign'>$</span>
                        <ClydeTextInput
                          disabled
                          id='product-price'
                          min={ 0 }
                          name='productPrice'
                          step={ 0.01 }
                          typeNumber
                          value={ productRetailPrice.toFixed(2) } />
                      </div>
                    </div>

                    <div className='claim-detail-resolution__line' />

                    <div className='claim-detail-resolution__depot-shipping'>
                      <div className='depot-repair-cost-field'>
                        <label htmlFor='replacementCost'>Total Cost of Resolution:</label>
                        <span className='depot-repair-cost-field--sign__cost'>$</span>
                        <span className='depot-repair-sublabel'>Cost of shipping & replacement product</span>
                        <ClydeTextInput
                          id='replacementCost'
                          min={ 0 }
                          name='replacementCost'
                          disabled
                          step={ 0.01 }
                          typeNumber
                          value={ replaceCost.toFixed(2) }
                          onChange={ (e) => { _handleResolutionDataChange(e.target.name, e.target.value); } }
                        />
                      </div>
                    </div>

                    <div className='claim-detail-resolution__group'>
                      <label htmlFor='shipping-carrier-repair-description'>Replacement description:</label>
                      <ClydeTextArea
                        id='shipping-carrier-repair-description'
                        name='replaceDescription'
                        rows='4'
                        onChange={ this.handleDepotRepairTextField } />
                    </div>
                  </>
                }

                {
                  depotRepairEnabled
                  && serviceType === 'depot'
                  && depotRepairStep === 3
                  && <>
                    <div className='claim-detail-resolution__group--row m-bottom-36'>
                      <DepotResolutionSummary
                        { ...depotResolutionSummaryData }
                        />
                    </div>
                    <div className='claim-detail-resolution__group--row m-bottom-36'>
                      <img alt='check' className='claim-detail-resolution-check' src='/images/checkmark-circle.svg' />
                      <span className='claims-detail-card__depot__title'>
                        Your resolution has been approved by Clyde.
                      </span>
                    </div>

                    {
                      <>
                        <div className='claim-detail-resolution__group--row m-bottom-16'>
                          <span className='claims-detail-card__depot__title--bold'>
                            Fill out shipping information below to ship back product to the customer.
                          </span>
                        </div>

                        <div className='claim-detail-resolution__depot-shipping'>
                          <div>
                            <label htmlFor='shipping-number-to-customer'>Shipping Number:</label>
                            <ClydeTextInput
                              id='shipping-number-to-customer'
                              name='trackingNumber'
                              onChange={ this.handleDepotRepairTextField } />
                          </div>

                          <div>
                            <label htmlFor='shipping-carrier-to-customer'>Shipping Carrier:</label>
                            <ClydeTextInput
                              id='shipping-carrier-to-customer'
                              name='carrierName'
                              onChange={ this.handleDepotRepairTextField } />
                          </div>
                        </div>

                        <div className='claim-detail-resolution__depot-shipping'>
                          <div className='depot-repair-cost-field'>
                            <label htmlFor='shipping-cost-from-customer'>Shipping Cost</label>
                            <span className='depot-repair-cost-field--sign'>$</span>
                            <ClydeTextInput
                              id='shipping-cost-from-customer'
                              name='shippingCost'
                              min={ 0 }
                              step={ 0.01 }
                              typeNumber
                              onChange={ this.handleDepotRepairTextField } />
                          </div>

                          {
                            !isReplaceFromRepair
                            && <>
                              <div>
                                <label htmlFor='depot-repair-files'>Upload Invoice</label>
                                <ClydeFileUpload
                                  className='depot-repair-files-input'
                                  id='depot-repair-files'
                                  buttonText='Upload Document'
                                  uploadedFiles={ proofFiles }
                                  setUploadedFiles={ (files) => { _handleResolutionDataChange('proofFiles', files); } } />
                              </div>
                            </>
                          }
                        </div>
                      </>
                    }
                  </>
                }

                {
                  depotRepairEnabled
                  && readOnlyView
                  && programType === 'repair'
                  && <>
                    <div className='claim-detail-resolution__group'>
                      <span className='claims-detail-card__group__title'>Product Parts costs:</span>
                      <span>${ resolutionPartsCost }</span>
                    </div>
                    <div className='claim-detail-resolution__group'>
                      <span className='claims-detail-card__group__title'>Shipping costs:</span>
                      <span>${ resolutionShippingCost }</span>
                    </div>
                    <div className='claim-detail-resolution__group'>
                      <span className='claims-detail-card__group__title'>Labor costs:</span>
                      <span>${ resolutionLaborCost }</span>
                    </div>
                  </>
                }

                {
                  !depotRepairEnabled
                  && programType === 'repair'
                  && productParts.length > 0
                  && <>
                    <div className='claim-detail-resolution__group'>
                      <label>Select parts:</label>
                      <ClydeSelect
                        className='resolution-parts-input'
                        id='resolution-product-parts'
                        disabled={ closed }
                        onChange={ this.handleResolutionProductPartToggle }
                        defaultValue={ resolutionProductPartIds }
                        name='resolutionProductPartIds'
                        options={ productPartsOptions }
                        multiple
                      />
                    </div>
                  </>
                }

                {
                  !depotRepairEnabled
                  && programType === 'repair'
                  && (!productParts || productParts.length === 0)
                  && <div className='claim-detail-resolution__group'>
                    {
                      readOnlyView
                        ? <>
                        <span className='claims-detail-card__group__title'>Product Parts Costs:</span>
                        <span>${ resolutionPartsCost }</span>
                      </>
                        : <>
                        <label htmlFor='parts-costs'>Parts costs:</label>
                        <div className='claim-detail-resolution-input--cost'>
                          <span className='claim-detail-resolution-input-prefix'>$</span>
                          <ClydeTextInput
                            className='claim-detail-resolution-input'
                            id='parts-costs'
                            disabled={ closed }
                            min={ 0 }
                            name='partsCostEstimate'
                            onChange={ this.handleResolutionOpenCostsChange }
                            step={ 0.01 }
                            typeNumber />
                        </div>
                      </>
                    }
                  </div>
                }

                {
                  !depotRepairEnabled
                  && programType === 'repair'
                  && <div className='claim-detail-resolution__group'>
                    {
                      readOnlyView
                        ? <>
                        <span className='claims-detail-card__group__title'>Shipping costs:</span>
                        <span>${ resolutionShippingCost }</span>
                      </>
                        : <>
                        <label htmlFor='labor-costs'>Shipping costs:</label>
                        <div className='claim-detail-resolution-input--cost'>
                          <span className='claim-detail-resolution-input-prefix'>$</span>
                          <ClydeTextInput
                            className='claim-detail-resolution-input'
                            id='shipping-costs'
                            disabled={ closed }
                            min={ 0 }
                            name='shippingCost'
                            onChange={ this.handleResolutionOpenCostsChange }
                            step={ 0.01 }
                            typeNumber
                          />
                        </div>
                        <div className='claim-detail-resolution__group claim-detail-resolution__group--row'>
                          <ClydeCheckbox
                            backgroundColor='clydeWhite'
                            className=''
                            disabled={ closed }
                            id='customerInstall'
                            name='customerInstall'
                            onChange={ (e) => { _handleResolutionDataChange('serviceType', e.target.checked ? 'customerInstall' : 'inHome'); } }
                            value={ serviceType === 'customerInstall' }
                          />
                          <label htmlFor='fullReplacement'>Customer Install?</label>
                        </div>
                      </>
                    }
                  </div>
                }

                {
                  !depotRepairEnabled
                  && programType === 'repair'
                  && <div className='claim-detail-resolution__group'>
                    {
                      readOnlyView
                        ? <>
                            <span className='claims-detail-card__group__title'>Labor costs:</span>
                            <span>${ resolutionLaborCost }</span>
                          </>
                        : <>
                        <label htmlFor='labor-costs'>Labor costs:</label>
                          <div className='claim-detail-resolution-input--cost'>
                            <span className='claim-detail-resolution-input-prefix'>$</span>
                            <ClydeTextInput
                              className='claim-detail-resolution-input'
                              id='labor-costs'
                              disabled={ closed }
                              min={ 0 }
                              name='laborCost'
                              onChange={ this.handleResolutionOpenCostsChange }
                              step={ 0.01 }
                              typeNumber
                            />
                          </div>
                      </>
                    }
                  </div>
                }

                {
                  serviceType !== 'depot'
                  && programType !== 'replaceFromRepair'
                  && <div className='claim-detail-resolution__group'>
                    { readOnlyView
                      ? <>
                        <span className='claims-detail-card__group__title'>Cost of Resolution</span>
                        <span>${ parseFloat(cost).toFixed(2) }</span>
                      </>
                      : <>
                        <label htmlFor='cost'>Cost of Resolution</label>
                        <span className='claim-detail-resolution__group__detail'>
                          Maximum limit of ${ remainingLimitOfLiability.toFixed(2) }
                        </span>
                        <div className='claim-detail-resolution-input--cost'>
                          <span className='claim-detail-resolution-input-prefix'>$</span>
                          <ClydeTextInput
                            className='claim-detail-resolution-input'
                            disabled={ closed || programType === 'repair' }
                            id='cost'
                            min={ 0 }
                            name='cost'
                            onChange={ (e) => { _handleResolutionDataChange(e.target.name, e.target.value); } }
                            step={ 0.01 }
                            typeNumber
                            value={ cost }
                          />
                        </div>
                      </>
                    }

                    {
                      cost > remainingLimitOfLiability && !readOnlyView
                        && <span className='claim-detail-resolution__group__warning'>
                          Reimbursement will not exceed ${ remainingLimitOfLiability.toFixed(2) }
                        </span>
                    }
                  </div>
                }

                {
                  serviceType === 'promoCode'
                  && <>
                    <ResolutionInputGroup
                      label='Enter the promo code*'
                      closedLabel='Promo Code'
                      readOnlyView={ readOnlyView }
                      name='promoCode'
                      value={ promoCode }
                      _onChange={ (e) => { _handleResolutionDataChange(e.target.name, e.target.value); } }
                    />
                    <div className={ classnames('claim-detail-resolution__group', {
                      'claim-detail-resolution__group--row': !closed,
                    }) }>
                      { readOnlyView
                        ? <>
                          <span className='claims-detail-card__group__title'>Full Replacement</span>
                          <span>{ fullReplacement ? 'Yes' : 'No' }</span>
                        </>
                        : <>
                          <ClydeCheckbox
                            backgroundColor='clydeWhite'
                            className=''
                            disabled={ closed }
                            id='fullReplacement'
                            name='fullReplacement'
                            onChange={ (e) => { _handleResolutionDataChange(e.target.name, !fullReplacement); } }
                            value={ fullReplacement }
                          />
                          <label htmlFor='fullReplacement'>Is this a full replacement?</label>
                        </>
                      }
                    </div>
                  </>
                }

                {
                  programType === 'replacement'
                  && serviceType !== 'promoCode'
                  && <>
                    <ResolutionInputGroup
                      label='Shipping Carrier'
                      readOnlyView={ readOnlyView }
                      name='shippingCarrier'
                      value={ shippingCarrier }
                      _onChange={ (e) => { _handleResolutionDataChange(e.target.name, e.target.value); } }
                    />

                    <ResolutionInputGroup
                      label='Shipping Number'
                      readOnlyView={ readOnlyView }
                      name='shippingNumber'
                      value={ shippingNumber }
                      _onChange={ (e) => { _handleResolutionDataChange(e.target.name, e.target.value); } }
                    />
                  </>
                }

                {
                  (closed || (serviceType !== 'depot' && programType !== 'replaceFromRepair'))
                  && <>
                    <div className='claim-detail-resolution__line' />
                    <ProofOfWorkInput
                      closed={ readOnlyView }
                      processing={ processing }
                      proofFiles={ proofFiles }
                      retailerTicketId={ retailerTicketId }
                      settings={ settings }
                      uploadedProofFiles={ uploadedProofFiles }
                      openFileModal={ this.openFileModal }
                      _handleResolutionDataChange={ _handleResolutionDataChange }
                    />
                  </>
                }
              </>
          }
        </div>

        {
          !readOnlyView && (depotRepairEnabled
            && (serviceType === 'depot' || programType === 'replaceFromRepair'))
            ? <div className={ depotRepairSubmitClass }>
            <ClydeButton
              className='claim-detail-depot-resolution__button'
              disabled={ depotRepairButtonDisabled }
              onClick={ () => { this.handleDepotRepairSubmit(); } }
              processing={ processing.submit }
              successText='Submitted!'
            >
              { depotRepairButtonText }
            </ClydeButton>

            {
              depotRepairStep === 2
              && programType !== 'replaceFromRepair'
              && <>
                <button
                  className='claim-detail-depot-replacement'
                  onClick={ () => { this.handleDepotReplacementRequest(); } }>
                  Request Replacement
                </button>
              </>
            }
          </div>
            : <div className={ submitClass }>
            <ClydeButton
              className='claim-detail-resolution-submit__button'
              disabled={ closeButtonDisabled }
              onClick={ () => { this.handleSubmit(true); } }
              processing={ processing.submit }
              successText='Submitted!'
            >
              Submit Resolution
            </ClydeButton>
            {
              programType === 'repair'
              && !replaceEnabled
              && (
                <button
                  className='claim-detail-replacement'
                  onClick={ () => { this.handleSelfInstallReplacementRequest(); } }
                >
                  Request Replacement
                </button>
              )
            }
            { !onDetailPage
              && <ClydeButton
                className='claim-detail-resolution-resolve-later__button'
                disabled={ closeButtonDisabled }
                onClick={ _cancelFileClaim }
                processing={ processing.submit }
                successText='Done'
              >
                Resolve Later
              </ClydeButton>
            }
          </div>
        }
      </>
    );
  }
}

export default Resolution;
