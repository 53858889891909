import { wsSend } from './websocketActions';

export const getAllShopUsers = () => (dispatch) => {
  dispatch(wsSend({
    type: 'GET_ALL_SHOP_USERS_REQ',
    payload: {},
  }));
};

export const updateUserAccountType = (userId, newType) => (dispatch) => {
  dispatch(wsSend({
    type: 'UPDATE_USER_ACCOUNT_TYPE_REQ',
    payload: { userId, newType },
  }));
};

export const toggleUserDisabled = (userId, toggle) => (dispatch) => {
  dispatch(wsSend({
    type: 'TOGGLE_USER_DISABLED_REQ',
    payload: { userId, toggle },
  }));
};

export const resetUserPassword = (userId) => wsSend({
  type: 'RESET_USER_PASSWORD_REQ',
  payload: { userId },
});

export const clearFeedback = () => ({
  type: 'CLEAR_USER_FEEDBACK',
  payload: {},
});

export const clearSuccess = () => ({
  type: 'CLEAR_USER_SUCCESS',
  payload: {},
});

export const createUser = (params) => (dispatch) => {
  dispatch({
    type: 'CREATE_USER',
    payload: {},
  });
  dispatch(wsSend({
    type: 'CREATE_USER_REQ',
    payload: params,
  }));
};

export default {};
