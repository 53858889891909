import { connect } from 'react-redux';

import {
  closeOAuthRedirectModal,
  getShopInfo,
} from '../../../../../actions/integrationsActions';

import Modal from './Modal';

const mapStateToProps = (state) => ({
  userId: state.auth.userId,
  ...state.integrations.oAuthData,
});

const mapDispatchToProps = (dispatch) => ({
  _closeModal: () => {
    dispatch(closeOAuthRedirectModal());
  },
  _reloadIntegrationsTab: (userId) => {
    dispatch(getShopInfo(userId));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Modal);
