import React from 'react';
import { useLocale } from '../../providers/LocaleProvider';

const { REACT_APP_WIDGETS_URL } = window._env_;

// Generate some fake contracts for previewing PDP
const baseContract = {
  contractId: '123',
  price: '12.50',
  term: 1,
  termMonths: 12,
  termLabelSingular: '1-Year',
  termLabelPlural: '1 Years',
};

export const contracts = [
  { ...baseContract },
  {
    ...baseContract,
    contractId: '456',
    price: '19.00',
    term: 2,
    termMonths: 24,
    termLabelSingular: '2-Year',
    termLabelPlural: '2 Years',
  },
  {
    ...baseContract,
    contractId: '678',
    price: '24.00',
    term: 3,
    termMonths: 36,
    termLabelSingular: '3-Year',
    termLabelPlural: '3 Years',
  },
];

function isValidColor(color) {
  return color.match(/^#[0-9a-f]{6}$/i);
}

const ProductPagePreview = ({ type, color }) => {
  const { currency } = useLocale();

  // Merge customized config with defaults
  const minimalRequiredConfig = React.useMemo(() => ({
    showXCoverBranding: true,
    customText: {},
    type,

    // Fallback to our default (black) if the user-provided color is invalid
    color: isValidColor(color) ? color : '#000000',

    // TODO: how to keep these in sync?
    // we need a higher level of abstraction provided by clyde-widgets (but lower than the sdk itself)
    phrases: {
      'cart.simple': 'Add product protection',
      'cart.headline': 'Add protection plan:',
      'cart.addButton': 'Add',

      'faq.headline': 'Frequently Asked Questions',
      'faq.backButton': 'Back to plan options',
      'faq.01.answer': 'We\'ve partnered with XCover to allow you to purchase protection plans for your products directly from our site. XCover handles claims covered by your protection plan.',
      'faq.01.question': 'What is XCover?',
      'faq.02.answer': 'When you purchase a protection plan with us you\'ll check out exactly how you normally would. You\'ll receive a confirmation email from us for your purchase, as well as an email from XCover with information about your protection plan including Terms and Conditions.',
      'faq.02.question': 'What happens next?',
      'faq.03.answer': 'You can file a claim directly through XCover. The exact link can be found in the email sent to you after you purchase your Clyde Protection Plan.',
      'faq.03.question': 'How do I file a claim?',
      'faq.04.answer': 'Every XCover Protection Plan is backed by an insurance policy. For additional details please see the \'Insurance Securing this Plan\' Section of your Plan\'s Terms and Conditions.',
      'faq.04.question': 'Is this backed by an insurance company?',
      'faq.05.answer': 'Your plan will be automatically canceled and refunded if you return your product within our return policy period. If you would like to cancel afterward or at any time, please login to the portal to cancel your plan.',
      'faq.05.question': 'What if I return my product?',
      'faq.gift.answer': 'Yes! Once your order is confirmed, check your inbox for an email from XCover and follow the instructions to create an account. Once you\'ve logged in, you can transfer the product protection plan to any person you like.',
      'faq.gift.question': 'This purchase is a gift. Can I also give the protection plan to the recipient?',

      'modal.headline': 'Protect your purchase',
      'modal.mostPopular': 'Most Popular',
      'modal.bestValue': 'Best Value',
      'modal.bullets': 'x|y|z',
      'modal.bulletsHeadline': 'Your protection plan covers:',
      'modal.faqButton': 'FAQ',
      'modal.addButton': 'Add protection plan',
      'modal.addButtonUpdate': 'Save protection plan',
      'modal.addButtonPrice': 'Add protection plan — {price}',
      'modal.declineButton': 'I don\'t need to protect my purchase',
      'modal.termsAndConditionsButton': 'Limitations and exclusions apply.\nSee Terms and Conditions.',

      'pdp.headline': 'Add product protection:',
      'pdp.declineOption': 'I don\'t need to protect my purchase',
      'pdp.learnMoreButton': 'What\'s Covered?',
      'pdp.simpleAddSingle': 'Add product protection for {price}',
      'pdp.simpleAddMultiple': 'Add product protection starting at {price}',
      'pdp.simpleAdded': 'Product protection added',

      'shared.termSingular': '{termSingular}',
      'shared.termPlural': '{termPlural}',
      'shared.termFor': '{termSingular} for {price}',
      'shared.termPlan': '{termSingular} Plan',
      'shared.termProtectionPlan': '{termSingular} Protection Plan',
      'shared.learnMoreLink': 'Learn more about your coverage',
    },
  }), [type, color]);

  // Wait for iframe contents to initialize before sending it messages
  React.useEffect(() => {
    const handler = (e) => {
      if (e.data.type === 'initialized') {
        setConfig();
        setActiveProduct();
      }
    };
    window.addEventListener('message', handler);
    return () => {
      window.removeEventListener('message', handler);
    };
  }, []);

  // iFrame element ref
  const frame = React.useRef(null);

  // Post messages to iframe if it's mounted
  const postMessage = React.useCallback((message) => {
    if (frame.current) {
      frame.current.contentWindow.postMessage(message, '*');
    }
  });

  // Update the CTA's config object
  const setConfig = React.useCallback(() => {
    postMessage({
      type: 'setConfig',
      data: minimalRequiredConfig,
    });
  }, [minimalRequiredConfig, postMessage]);

  // Automatically call setConfig when one of its dependencies changes
  React.useEffect(setConfig, [setConfig]);

  // Update the CTA's contracts
  const setActiveProduct = React.useCallback(() => {
    postMessage({
      type: 'setActiveProduct',
      data: {
        name: 'Preview Product',
        sku: 'abc123',
        price: 99.95,
        contracts,
      },
    });
  }, [postMessage]);

  const init = React.useCallback(() => {
    postMessage({
      type: 'init',
      data: {
        config: {
          settings: minimalRequiredConfig,
          locale: { currencyCode: currency },
        },
        type,
      },
      origin: window.location.origin,
    });
  }, [postMessage, currency]);

  // Setup CTA when ref is mounted
  const ctaRef = React.useCallback((el) => {
    if (frame.current) {
      return;
    }

    el.onload = init;
    frame.current = el;
  }, [init]);

  // Avoid initial render before props are correctly initialized
  if (!type) {
    return null;
  }

  return <iframe src={ `${REACT_APP_WIDGETS_URL}/pdp.html` } ref={ ctaRef } className='cta-preview'></iframe>;
};

export default ProductPagePreview;
