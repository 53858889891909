// Claim status objects
const inProcess = { value: 'inProcess', label: 'Submitted' };
const underClydeReview = { value: 'underClydeReview', label: 'Under Review' };
const underAdminReview = { value: 'underAdminReview', label: 'Under Review' };
const hiClydeHandoff = { value: 'hiClydeHandoff', label: 'Partner Managing Claim' };
const needMoreInfo = { value: 'needMoreInfo', label: 'More Info Requested' };
const customerWithdrawn = { value: 'customerWithdrawn', label: 'Customer Withdrawn' };
const approved = { value: 'approved', label: 'Approved' };
const denied = { value: 'denied', label: 'Not Covered' };
const resolutionInitiated = { value: 'resolutionInitiated', label: 'Resolution Initiated' };
const resolutionInProgress = { value: 'resolutionInProgress', label: 'Resolution In Progress' };
const resolutionOrdered = { value: 'resolutionOrdered', label: 'Resolution Ordered' };
const resolutionDelayed = { value: 'resolutionDelayed', label: 'Resolution Delayed' };
const resolutionFulfilled = { value: 'resolutionFulfilled', label: 'Resolution Fulfilled' };
const resolutionPending = { value: 'resolutionPending', label: 'Resolution Pending' };
const repairInitiated = { value: 'repairInitiated', label: 'Repair Initiated' };

export const newClaimStatuses = [
  inProcess,
  underClydeReview,
];

export const pendingClaimStatuses = [
  inProcess,
  underClydeReview,
  needMoreInfo,
];

export const needsAttentionClaimStatuses = [
  underAdminReview,
  needMoreInfo,
  approved,
];

export const nonResolvedClaimStatuses = [
  inProcess,
  underClydeReview,
  underAdminReview,
  hiClydeHandoff,
  needMoreInfo,
  customerWithdrawn,
  approved,
  denied,
];

export const openClaimStatuses = [
  inProcess,
  underClydeReview,
  underAdminReview,
  needMoreInfo,
  approved,
]; // "Open" here denoting the claim is still being processed in some way

export const closedClaimStatuses = [
  hiClydeHandoff,
  customerWithdrawn,
  denied,
  resolutionFulfilled,
]; // "Closed" here denoting that Clyde cannot / does not need to take any more action on claim

export const allClaimStatuses = [
  inProcess,
  underClydeReview,
  underAdminReview,
  hiClydeHandoff,
  needMoreInfo,
  customerWithdrawn,
  approved,
  denied,
  resolutionInitiated,
  resolutionInProgress,
  resolutionOrdered,
  resolutionDelayed,
  resolutionFulfilled,
  resolutionPending,
  repairInitiated,
];

export const resolutionStatuses = [
  resolutionInitiated,
  resolutionInProgress,
  resolutionOrdered,
  resolutionDelayed,
  resolutionFulfilled,
];

const findClaimStatus = (claims, target) => claims.find((claim) => claim.value === target);

export const claimStatusSwitcher = (claimStatusEnum) => {
  const foundStatus = findClaimStatus(allClaimStatuses, claimStatusEnum);
  return foundStatus ? foundStatus.label : 'None';
};

export const isNewClaim = (claimStatusEnum) => !!findClaimStatus(newClaimStatuses, claimStatusEnum);
export const isPendingClaim = (claimStatusEnum) => !!findClaimStatus(pendingClaimStatuses, claimStatusEnum);
export const isOpenClaim = (claimStatusEnum) => !!findClaimStatus(openClaimStatuses, claimStatusEnum);
export const isClosedClaim = (claimStatusEnum) => !!findClaimStatus(closedClaimStatuses, claimStatusEnum);

export const isApprovedClaim = (claimStatusEnum) => claimStatusEnum === approved.value;
export const isDeniedClaim = (claimStatusEnum) => claimStatusEnum === denied.value;
export const isResolvedClaim = (claimStatusEnum) => claimStatusEnum.indexOf('resolution') > -1;

// Denial reason objects
const arrivedDamaged = {
  value: 'arrivedDamaged',
  label: 'Filed within limited warranty period',
  explanation: 'The claim is not covered because it was filed within limited warranty period.',
};
const contractFullyUtilized = {
  value: 'contractFullyUtilized',
  label: 'Contract fully utilized',
  explanation: 'The claim is not covered because the contract has been fully utilized.',
};
const failedUsageQuestion = {
  value: 'failedUsageQuestion',
  label: 'Customer failed a usage question',
  explanation: 'The claim is not covered because customer failed a usage question.',
};
const lossOrTheft = {
  value: 'lossOrTheft',
  label: 'Lost and theft are not covered',
  explanation: 'Lost and theft are not covered.',
};
const manualDenial = {
  value: 'manualDenial',
  label: 'Not covered after Clyde review',
  explanation: 'The claim is not covered after being reviewed by Clyde.',
};
const nonAdhContract = {
  value: 'nonAdhContract',
  label: 'Accidental damage not covered',
  explanation: 'Accidental damage is not covered.',
};
const withinLimitedWarranty = {
  value: 'withinLimitedWarranty',
  label: 'Filed within limited warranty period',
  explanation: 'The claim is not covered because it was filed within limited warranty period.',
};

export const allDenialReasons = [
  arrivedDamaged,
  contractFullyUtilized,
  failedUsageQuestion,
  lossOrTheft,
  manualDenial,
  nonAdhContract,
  withinLimitedWarranty,
];

export const denialReasonSwitcher = (denialReasonEnum, returnExplanation) => {
  const foundDenialReason = allDenialReasons.find((status) => status.value === denialReasonEnum);
  if (!foundDenialReason) {
    return 'None';
  } if (returnExplanation) {
    return foundDenialReason.explanation;
  }
  return foundDenialReason.label;
};

export const resolutionStatusSwitcher = (status) => {
  switch (status) {
    case 'inHome':
      return 'In-home';
    case 'carryIn':
      return 'Carry-in';
    case 'customerInstall':
      return 'Customer Install';
    case 'depot':
      return 'Repair - depot';

    case 'promoCode':
      return 'Promo Code';
    case 'payout':
      return 'Payout / Cash Settlement';
    case 'clydeReplace':
      return 'Clyde Replace';
    case 'retailerReplace':
      return 'Retailer Replace';
    default:
      return 'None';
  }
};

// Claim types
export const claimTypeSwitcher = (status) => {
  switch (status) {
    case 'arrivedDamaged':
      return 'Arrived Damaged';
    case 'lossOrTheft':
      return 'Loss Or Theft';
    case 'accidentalDamage':
      return 'Accidental Damage';
    case 'stoppedWorking':
      return 'Stopped Working';
    default:
      return 'None';
  }
};
