import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { setText, setBoolean, getShopInfo } from '../../../actions/profileActions';
import { setText as setContactText } from '../../../actions/contactInfoActions';

import Profile from './Profile';

const mapStateToProps = (state) => ({ profile: state.profile, contactInfo: state.contactInfo });

const mapDispatchToProps = (dispatch) => bindActionCreators({
  _setText: setText,
  _setBoolean: setBoolean,
  _setContactText: setContactText,
  _getShopInfo: getShopInfo,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Profile);
