import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import {
  clearSettingsStatus,
  getUserEmail,
  showSaveButton,
  submitUserUpdate,
} from '../../../../actions/userActions';

import UserSettingsTile from './UserSettingsTile';

const mapStateToProps = (state) => ({
  user: state.user,
  oAuthUser: state.auth.oAuth,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  _submitUserUpdate: submitUserUpdate,
  _getUserEmail: getUserEmail,
  _clearSettingsStatus: clearSettingsStatus,
  _showSaveButton: showSaveButton,
}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(UserSettingsTile);
