import React, { createContext, useContext, useEffect } from 'react';
import uuidv4 from 'uuid/v4';
import Axios from 'axios';

const client = Axios.create({
  baseURL: `${window._env_.REACT_APP_APP_SERVER_HOST}/api`,
  timeout: 10000, // NOTE: some endpoints take a while to return
});

// Automatically set a request ID
client.interceptors.request.use((config) => {
  config.headers['X-Clyde-Request-Id'] = uuidv4();
  return config;
});

const ApiContext = createContext(client);

const ApiProvider = ({ children, shopId, token }) => {
  useEffect(() => {
    // Default headers
    client.defaults.headers.common.Authorization = `Bearer ${token}`;
    client.defaults.headers.common['X-Clyde-Shop-ID'] = shopId;
  }, [token, shopId]);

  return (
    <ApiContext.Provider value={ client }>
      { children }
    </ApiContext.Provider>
  );
};

export default ApiProvider;

export const useApi = () => useContext(ApiContext);
