import React, { useState } from 'react';
import { SmallModal } from '@joinclyde/clyde-react-components';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { connectClydeControlledIntegration, deleteFriggControlledIntegration } from '../../../../actions/integrationsActions';

const IntegrationMenu = (props) => {
  const [isRemoveIntegrationModalOpen, setIntegrationModalOpen] = useState(false);
  const [removeIntegrationName, setRemoveIntegrationName] = useState('');
  const {
    integration,
    status,
  } = props;

  const mapActionToOption = (integrationToRemove, option) => {
    if (option === 'Remove Integration') {
      removeIntegrationModal(integrationToRemove);
    }
  };

  const removeIntegration = (integrationName) => {
    switch (integrationName) {
      case 'Affirm':
        props._updateIntegration('affirm', {
          affirmPublicKey: '',
        });
        break;
      case 'Klaviyo':
        props._updateIntegration('klaviyo', { klaviyoPublicKey: '' });
        break;
      default:
        props._deleteFriggIntegration(props.userId, integration.integrationOptions.id, integrationName);
        break;
    }
    setIntegrationModalOpen(false);
  };

  const removeIntegrationModal = (integrationToRemove) => {
    setIntegrationModalOpen(true);
    setRemoveIntegrationName(integrationToRemove.name);
  };

  return (
    <div className='integration-menu'>
      { menuOptions(status).map((option) => (
        <a
          className='integration-menu__option'
          key={ option }
          onClick={ () => mapActionToOption(integration, option) }
        >
          { option }
        </a>
      )) }

      { integration.links.map((link) => (
        <a
          className='integration-menu__option'
          href={ link.url }
          key={ link.title }
          rel='noopener noreferrer'
          target='_blank'
        >
          { link.title }
        </a>
      )) }
      { isRemoveIntegrationModalOpen ? (
        <SmallModal
          active={ isRemoveIntegrationModalOpen }
          className='remove-integration-modal'
          closeModal={ () => setIntegrationModalOpen(false) }
        >
          <h2 className='remove-integration-header'>Warning:</h2>
          <p>Are you sure you want to remove this integration?
            To re-enable you will have to set up the integration again.</p>
          <button className='integrations-setup-modal__buttons__link' onClick={ () => removeIntegration(removeIntegrationName) } >Continue and Remove</button>
        </SmallModal>
      ) : null }
    </div>
  );
};

const menuOptions = (status) => {
  switch (status) {
    case 'ENABLED':
      return ['Remove Integration'];
    case 'DISABLED':
      return [];
    case 'NEEDS_CONFIG':
      return ['Remove Integration'];
    default:
      return [];
  }
};

const mapDispatchToProps = (dispatch) => bindActionCreators({
  _updateIntegration: connectClydeControlledIntegration,
  _deleteFriggIntegration: deleteFriggControlledIntegration,
}, dispatch);

const mapStateToProps = (state) => ({
  userId: state.auth.userId,
});
export default connect(mapStateToProps, mapDispatchToProps)(IntegrationMenu);
