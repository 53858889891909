import React, { Component } from 'react';
import classnames from 'classnames';
import { LoadingDots, ClydeButton, ClydeTextInput } from '@joinclyde/clyde-react-components';
import { isEmpty } from 'lodash';

import ContactForm from '../../Shared/ContactForm';
import AddressForm from '../../Shared/AddressForm';
import checkValidations from '../../../Utils/ProfileValidation';

class ProfileSettingsTile extends Component {
  UNSAFE_componentWillMount() {
    this.props._getShopInfo();
  }

  handleSave = () => {
    const {
      profile,
      platform,
      _updateShopInfo,
      _setValidationErrors,
    } = this.props;

    const formErrors = checkValidations({ ...profile }, platform);
    if (isEmpty(formErrors)) {
      _updateShopInfo(profile);
    }
    _setValidationErrors(formErrors);
  }

  handleChange = (event) => {
    const { name, value } = event.target;
    const numeric = name === 'phoneNumber' || name === 'zip';
    this.props._setText(name, value, numeric);
  }

  render() {
    return (
      <div>
        <div className='settings-tile'>
          <div className='settings-tile__title'>Profile</div>
          {
            this.props.profile.getProcessing
              ? <div className='settings-tile__content settings-tile__content--profile'>
                  <div className='settings-tile__content__loading-container settings-tile__content__loading-container--profile'>
                    <LoadingDots dotColor='clydeBlack' dotSize='large' />
                  </div>
              </div>
              : <div className='settings-tile__content'>
                <div className='row'>
                  <div className='col-12 col-lg-6 col-md-6 col-sm-6 col-xs-12'>
                    <label
                        className={ classnames('profile-label',
                          { 'profile-label--invalid': this.props.profile.formErrors.shopName }) }
                        htmlFor='store-name'>Shop Name</label>
                    {
                      this.props.platform !== 'Clyde'
                        ? <ClydeTextInput
                          disabled='disabled'
                          name='shopName'
                          value={ this.props.profile.shopName }
                          id='store-name'
                          className='form-field'
                          maxLength='100' />
                        : <ClydeTextInput
                          onChange={ this.handleChange }
                          name='shopName'
                          value={ this.props.profile.shopName }
                          id='store-name'
                          className='form-field'
                          maxLength='100' />
                    }
                  </div>
                  <div className='col-12 col-lg-6 col-md-6 col-sm-6 col-xs-12'>
                    <label
                        className={ classnames('profile-label',
                          { 'profile-label--invalid': this.props.profile.formErrors.domain }) }
                        htmlFor='domain'>Shop URL</label>

                      <ClydeTextInput
                        disabled={ this.props.platform !== 'Clyde' }
                        name='domain'
                        value={ this.props.profile.domain }
                        id='domain'
                        className='form-field'
                        maxLength='100'
                      />
                  </div>
                </div>
                <ContactForm
                  contactInfo={ this.props.profile }
                  formErrors={ this.props.profile.formErrors }
                  handleChange={ this.handleChange }
                />
                <AddressForm
                  contactInfo={ this.props.profile }
                  formErrors={ this.props.profile.formErrors }
                  handleChange={ this.handleChange }
                />
              </div>
          }
        </div>
        <div className='profile-settings-button-container'>
          { this.props.profile.showSaveButton && <ClydeButton
            id='settings-save-button'
            className='button--small settings-save-button'
            onClick={ this.handleSave }
            processing={ this.props.profile.saveProcessing }
            successText='Saved'
          >
            Save
          </ClydeButton> }
        </div>
      </div>
    );
  }
}

export default ProfileSettingsTile;
