import React from 'react';
import { VictoryTooltip } from 'victory';

const CustomLabel = (props) => {
  const {
    datum: { x },
    moment,
    style,
    text,
  } = props;

  const date = moment(x).format('MMMM Do YYYY');
  const processedText = text.slice(0, text.length - 1); // Drops last empty line from label text
  processedText.reverse(); // Swaps label order to Total => Time of Sale => Post Purchase
  const labelText = [
    date,
    ' ',
    ...processedText,
  ]; // Subsequent elements of the array render on subsequent lines in label
  const stylesToRender = [
    { textAnchor: 'start', fontFamily: 'Volte Medium', fill: '#000' },
    ...style.reverse(), // To keep styles in step with reversed text above
  ];

  return (
    <g>
      <VictoryTooltip
        { ...props }
        text={ labelText }
        style={ stylesToRender }
        cornerRadius={ 5 }
        flyoutStyle={ { stroke: 'black', fill: 'white', text: { anchor: 'start' }} } />
    </g>
  );
};

export default CustomLabel;
