import React from 'react';

const getSummaryCopy = ({
  depotRepairStep,
  depotRepairShippingHistory,
  depotRepairSelectedParts,
  cost,
  depotRepairDescription,
  depotReplaceDescription,
  productRetailPrice,
  resolutionPartsCost,
  resolutionLaborCost,
  fromCustomerShippingCost,
  replaceRequested,
}) => {
  // At step 2, there should only be 1 entry for shipping information.
  const shippingInfo = depotRepairShippingHistory ? depotRepairShippingHistory[0] : {};
  const { carrier, trackingNumber, shippingCost } = shippingInfo;
  const partsString = depotRepairSelectedParts ? depotRepairSelectedParts.map((part) => `${part.name}: $${part.cost}`) : [];

  // There is no step 1 message because we haven't collected any information at this point.
  if (depotRepairStep === 2) {
    return `Shipping Number: ${trackingNumber}  |  Shipping Carrier: ${carrier}  |  Shipping Cost: $${shippingCost || 0}`;
  } if (depotRepairStep === 3 && replaceRequested) {
    return `Cost of Shipping: $${shippingCost || 0}  |
      Cost of Replacement Product: $${productRetailPrice}  |
      Total Cost of Resolution: $${cost}  |
      Replacement Description: ${depotReplaceDescription || 'Description not provided.'}.`;
  } if (depotRepairStep === 3) {
    return `
      ${partsString.length ? `Parts: ${partsString.join(', ')}` : `Parts Estimate: $${resolutionPartsCost || 0}`} |
      Labor Cost: $${resolutionLaborCost || 0}  |
      Current Shipping Cost: $${fromCustomerShippingCost || 0}  |
      Total Cost of Repair: $${cost}  |
      Repair Description: ${depotRepairDescription || 'N/A'}.`;
  }
  return false;
};

const DepotResolutionSummary = (props) => (
    <div className='depot-resolution-summary'>
      <div className='depot-resolution-summary--header'>Shipping Details Submitted:</div>
      <div className='depot-resolution-summary--info'>
        <span> { getSummaryCopy(props) } </span>
      </div>
    </div>
);

export default DepotResolutionSummary;
