import React, { useState } from 'react';
import classnames from 'classnames';
import { LargeModal, LoadingDots } from '@joinclyde/clyde-react-components';

import { formatDate } from '../../../../lib/timeUtils';
import SupportingInfoFiles from './SupportingInfoFiles';

import {
  claimStatusSwitcher,
  claimTypeSwitcher,
  denialReasonSwitcher,
} from '../../../Utils/claimHelper';

const formatUsageQuestion = (value) => {
  switch (value) {
    case null:
      return 'Not reported';
    case true:
      return 'Yes';
    case false:
      return 'No';
    default:
      return 'Unknown';
  }
};

const ClaimCard = (props) => {
  const {
    claim: {
      claimState,
      claimType,
      createdAt,
      denialReason,
      descriptionOfProblem,
      failureDate,
      productStillFunctional,
      productUsedAsIntended,
      programType,
      claimIssueTypes,
    },
    claimProcessing,
    supportingInfoFiles,
    supportingInfoProcessing,
  } = props;

  const [isModalActive, setModalActive] = useState(false);
  const [fileString, setFileString] = useState('');
  const [fileType, setFileType] = useState('');

  const openImageModal = (e, file) => {
    setModalActive(true);
    setFileType(file.type);
    setFileString(`data:${file.type};base64,${file.content}`);
  };

  const closeImageModal = () => {
    setModalActive(false);
    setFileType('');
    setFileString('');
  };

  const formattedCreatedAt = createdAt ? formatDate(createdAt) : null;
  const formattedFailureDate = failureDate ? formatDate(failureDate) : null;
  const claimStateClassName = classnames('claim-history-card__event__info', {
    [`claim__font--${claimState}`]: !!claimState,
  });
  const descClassName = classnames('claims-detail-card__group__info', {
    'claims-detail-card__group__info--small-text': descriptionOfProblem.length > 400,
  });

  const issueTypeText = claimIssueTypes?.length
    ? claimIssueTypes.map((issue) => (issue.typeLabel)).join(', ')
    : claimTypeSwitcher(claimType);
  return (
    <div className='claims-detail-card claims-detail-card--claim'>
      <LargeModal
        active={ isModalActive }
        className='claim-detail-supporting-file-modal'
        closeModal={ closeImageModal }
        headerText='Supporting Info File'>
        {
          fileType === 'application/pdf'
            ? <a download='supporting_info.pdf' href={ fileString }>Download PDF</a>
            : <img alt='supporting info file' src={ fileString } />
        }
      </LargeModal>

      <h3>Claim</h3>

      {
        claimProcessing
          ? <LoadingDots className='claims-detail-card__loading' dotColor='clydeBlack' dotSize='medium' />
          : <React.Fragment>
              <div className='claims-detail-card__group'>
                <span className='claims-detail-card__group__title'>Status:</span>
                <span className={ claimStateClassName }>{ claimStatusSwitcher(claimState, programType) }</span>
                {
                  denialReason
                    && <span className='claims-detail-card__group__title'>{ denialReasonSwitcher(denialReason) }</span>
                }
              </div>
              <div className='claims-detail-card__group'>
                <span className='claims-detail-card__group__title'>Submitted On:</span>
                <span className='claims-detail-card__group__info'>{ formattedCreatedAt }</span>
              </div>
              {
                formattedFailureDate
                  && <div className='claims-detail-card__group'>
                    <span className='claims-detail-card__group__title'>Failure Date:</span>
                    <span className='claims-detail-card__group__info'>{ formattedFailureDate }</span>
                  </div>
              }
              <div className='claims-detail-card__group'>
                <span className='claims-detail-card__group__title'>Description:</span>
                <span className={ descClassName }>{ descriptionOfProblem }</span>
              </div>
              <div className='claims-detail-card__group'>
                <span className='claims-detail-card__group__title'>Issue Type:</span>
                <span className='claims-detail-card__group__info'>{ issueTypeText }</span>
              </div>
              <div className='claims-detail-card__group'>
                <span className='claims-detail-card__group__title'>Covered product was used as intended:</span>
                <span className='claims-detail-card__group__info'>{ formatUsageQuestion(productUsedAsIntended) }</span>
              </div>
              <div className='claims-detail-card__group'>
                <span className='claims-detail-card__group__title'>Covered product is still functional:</span>
                <span className='claims-detail-card__group__info'>{ formatUsageQuestion(productStillFunctional) }</span>
              </div>

              <div className='claims-detail-card__group'>
                <span className='claims-detail-card__group__title'>Files:</span>
                <div className='claims-detail-card__group__file-list'>
                  <SupportingInfoFiles
                    supportingInfoProcessing={ supportingInfoProcessing }
                    supportingInfoFiles={ supportingInfoFiles }
                    openImageModal={ openImageModal }
                  />
                </div>
              </div>
            </React.Fragment>
      }

    </div>
  );
};

export default ClaimCard;
