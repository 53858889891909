import React, { Component, createRef } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { ClydeTooltip } from '@joinclyde/clyde-react-components';
import { CSVLink } from 'react-csv';
import { Helmet } from 'react-helmet';

import CustomerSearch from './CustomerSearch';
import OrderSearch from './OrderSearch';
import ProductsView from './ProductsView';

import ResendSaleEmail from './Modals/ResendSaleEmail';
import ResendPPEmail from './Modals/ResendPPEmail';
import ShowClaimHistory from './Modals/ShowClaimHistory';
import FileClaim from './Modals/FileClaim';
import BulkContractSale from './Modals/BulkContractSale';
import CancelContract from './Modals/CancelContract';
import UpdateSaleOEM from './Modals/UpdateSaleOEM';
import NewOrder from './Modals/NewOrder';
import ChangeOrderCustomer from './Modals/ChangeOrderCustomer';
import { getProductRegistrationsExport } from '../../actions/productRegistrationsActions';

class Customers extends Component {
  constructor() {
    super();
    this.csvLinkRef = createRef();
  }

  componentDidMount() {
    const { shopId, _getProductRegistrationsExport } = this.props;
    _getProductRegistrationsExport(shopId);
  }

  stepSwitcher = () => {
    const {
      history, step, shopId, platform,
    } = this.props;
    switch (step) {
      case 'orders':
        return <OrderSearch shopId={ shopId } platform={ platform } />;
      case 'products':
        return <ProductsView history={ history } shopId={ shopId } step={ step } />;
      case 'registrations':
        return <ProductsView history={ history } shopId={ shopId } step={ step } />;
      case 'customers':
      default:
        return (
          <CustomerSearch
            shopId={ shopId }
            platform={ platform }
            handleRegistrationCsvExport={ this.handleRegistrationCsvExport }
          />
        );
    }
  };

  modalSwitcher = () => {
    const { history, modal } = this.props;

    if (modal) {
      document.body.classList.add('overflow-off');
    } else {
      document.body.classList.remove('overflow-off');
    }

    switch (modal) {
      case 'resendSaleEmail':
        return <ResendSaleEmail />;
      case 'resendPpEmail':
        return <ResendPPEmail />;
      case 'displayClaimHistory':
        return <ShowClaimHistory history={ history } />;
      case 'fileClaim':
        return <FileClaim />;
      case 'bulkContractSale':
        return <BulkContractSale />;
      case 'cancelContract':
        return <CancelContract />;
      case 'newOrder':
        return <NewOrder />;
      case 'changeOrderCustomer':
        return <ChangeOrderCustomer />;
      case 'updateOEM':
        return <UpdateSaleOEM />;
      case null:
      default:
        return null;
    }
  };

  handleRegistrationCsvExport = () => {
    this.csvLinkRef.current.link.click();
  }

  render() {
    const { productRegistrationsExport, step } = this.props;

    const filenameDate = new Date().toLocaleDateString().replace(/\//g, '_');
    const exportsFilename = `${filenameDate}_product_registrations.csv`;

    return (
      <div className='page-container'>
        <Helmet>
          <title>Clyde | Customers</title>
        </Helmet>

        <div className='page-layout'>
        { productRegistrationsExport.length > 0
          && step !== 'customers'
          && <div className='actions-container'>
            <ClydeTooltip
              id='registrations-export'
              position='right'
              label={
                <div className='actions-container__tooltip__text'>
                  Export Product Registrations
                </div>
              }
              hideIndicator={ true }
            >
              <div
                onClick={ this.handleRegistrationCsvExport }
                className='actions-container__button'
                data-tip
                data-for='registrations-export'
              >
                <img
                  className='actions-container__button__image'
                  alt='Export product registrations'
                  src='/images/download.svg'
                />
              </div>
            </ClydeTooltip>
          </div>
        }
          <CSVLink
            ref={ this.csvLinkRef }
            className='hidden-link'
            filename={ exportsFilename }
            data={ productRegistrationsExport }
          />
          <div className='title-wrapper'>
            { this.modalSwitcher() }
            { this.stepSwitcher() }
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  modal: state.customers.modal,
  step: state.customers.step,
  platform: state.auth.platform,
  shopId: state.auth.shopId,
  productRegistrationsExport: state.productRegistrations.productRegistrationsExport,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  _getProductRegistrationsExport: getProductRegistrationsExport,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Customers);
