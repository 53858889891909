import React from 'react';

import UserManagementTile from './UserManagementTile';

const UserManagementTab = (props) => {
  const { platform } = props;
  return (
    <div className='settings-tab settings-tab--user-management'>
      <div className='settings-tile-container'>
        <UserManagementTile platform={ platform }/>
      </div>
    </div>
  );
};

export default UserManagementTab;
