import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router';

import {
  toggleAutoMargin,
  setCustomMargin,
  setRoundTotal,
  getShopSettings,
  updateShopSettings,
} from '../../../actions/autopilotActions';
import { launchProducts } from '../../../actions/productsActions';

import ContractSettings from './ContractSettings';

const mapStateToProps = (state) => ({
  autopilot: state.autopilot,
  billingAccounts: state.billing.accounts,
  billingInvoice: state.billing.invoice,
  launchProcessing: state.products.launchProcessing,
  platform: state.auth.platform,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  _toggleAutoMargin: toggleAutoMargin,
  _setCustomMargin: setCustomMargin,
  _setRoundTotal: setRoundTotal,
  _getShopSettings: getShopSettings,
  _updateShopSettings: updateShopSettings,
  _launchProducts: launchProducts,
}, dispatch);

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ContractSettings));
