import { connect } from 'react-redux';

import {
  connectClydeControlledIntegration,
  updateSetupModalClydeControlledIntegrationValue,
} from '../../../../../../actions/integrationsActions';

import ClydeControlled from './ClydeControlled';

const mapStateToProps = (state) => ({
  userId: state.auth.userId,
  selectedIntegration: state.integrations.setupModal.selectedIntegration,
});

const mapDispatchToProps = (dispatch) => ({
  _connectIntegration: (integration, data) => {
    dispatch(connectClydeControlledIntegration(integration, data));
  },
  _updateValue: (key, value) => {
    dispatch(updateSetupModalClydeControlledIntegrationValue(key, value));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ClydeControlled);
