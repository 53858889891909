const defaultState = {
  page: 'accounts',
  accounts: [],
  lastStripeBilling: '',
  stripeBillingAttempts: 0,
  stripeIntent: {
    error: null,
    clientSecret: null,
    status: null,
  },
  instructionsDismissed: false,
  getProcessing: false,
  shopCurrency: '',
};

const billingReducer = function products(state = defaultState, action) {
  switch (action.type) {
    case 'SHOW_ACCOUNTS':
      return { ...state, page: 'accounts' };
    case 'ADD_ACCOUNT':
      return { ...state, page: 'add_account' };
    case 'GET_BILLING_PROCESSING':
      return { ...state, getProcessing: true };
    case 'BILLING_VERIFY_PROCESSING':
      return { ...state, stripeBillingAttempts: state.stripeBillingAttempts + 1 };
    case 'GET_BILLING_INFO_RES':
      return {
        ...state,
        accounts: action.payload.accounts,
        invoice: action.payload.invoice,
        getProcessing: false,
        lastStripeBilling: action.payload.lastStripeBilling,
        stripeBillingAttempts: action.payload.stripeBillingAttempts,
        shopCurrency: action.payload.shopCurrency,
      };
    case 'DISMISS_INSTRUCTIONS':
      return { ...state, instructionsDismissed: true };
    case 'BILLING_SUBMIT_PROCESSING':
      return { ...state, getProcessing: true };
    case 'SET_DEFAULT_PROCESSING':
      return { ...state, getProcessing: false };

    case 'VERIFY_STRIPE_MICRODEPOSITS_RES':
    case 'SET_STRIPE_DEFAULT_PAYMENT_METHOD_RES':
      return { ...state, accounts: action.payload.accounts, getProcessing: false };
    case 'CREATE_STRIPE_INTENT_RES':
      return {
        ...state,
        stripeIntent: action.payload.intent,
        accounts: action.payload.accounts,
      };
    case 'RESET_PAYMENT_FORM':
      return {
        ...state,
        stripeIntent: defaultState.stripeIntent,
      };
    case 'LOGOUT':
      return { ...state, ...defaultState };
    case 'ERROR':
      switch (action.payload.reqType) {
        case 'GET_BILLING_INFO_REQ':
          return { ...state, getProcessing: false };
        default:
          return state;
      }
    default:
      return state;
  }
};

export default billingReducer;
