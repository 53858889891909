import { wsSend } from './websocketActions';

export const getProductRegistrationsExport = (shopId) => (dispatch) => {
  dispatch(wsSend({
    type: 'GET_PRODUCT_REGISTRATIONS_EXPORT_REQ',
    payload: { shopId },
  }));
};

export const toggleViewRegistrableProducts = (viewRegistrableProducts) => (dispatch) => {
  dispatch({
    type: 'TOGGLE_REGISTRABLE_PRODUCT_VIEW',
    payload: { viewRegistrableProducts },
  });
};
