import React, { useState } from 'react';
import classnames from 'classnames';
import { ClydeButton } from '@joinclyde/clyde-react-components';

import {
  denialReasonSwitcher,
  isApprovedClaim,
  isDeniedClaim,
  isPendingClaim,
  isResolvedClaim,
} from '../../../Utils/claimHelper';

const AdjudicationDecision = (props) => {
  const {
    isReferral,
    savedClaimState,
    denialReason,
    referralEmailProcessing,
    contractSaleId,
    _toCustomerSearch,
    _closeModal,
    _refreshCop,
    _sendHiClydeReferral,
  } = props;

  const defaultCopyText = 'Copy the link for submission to share directly with customer';
  const [copyButtonText, updateCopyText] = useState(defaultCopyText);

  let buttonRef;

  const copyValue = () => {
    const element = buttonRef;
    element.select();
    document.execCommand('copy');
    updateCopyText('Link copied!');

    setInterval(() => {
      updateCopyText(defaultCopyText);
    }, 1500);
  };

  let headerText;
  let copyText;
  let imgSrc;
  let onClose = _closeModal;

  const onButtonClick = isReferral
    ? () => _sendHiClydeReferral(contractSaleId)
    : _toCustomerSearch;

  // Claim was denied by the adjudication service
  if (isDeniedClaim(savedClaimState)) {
    headerText = 'Claim Not Covered.';
    copyText = denialReasonSwitcher(denialReason, true);
    imgSrc = 'x-circle.svg';
  }

  // Claim was accepted but not approved or denied by the adjudication service
  if (isPendingClaim(savedClaimState)) {
    headerText = 'Claim Needs To Be Reviewed.';
    copyText = 'The claim needs to be reviewed by the Clyde team before a resolution can be provided to the customer.';
    imgSrc = 'alert-circle.svg';
  }

  // Claim was approved by the adjudication service BUT retailer is not vetted to resolve their own claims
  if (isApprovedClaim(savedClaimState)) {
    headerText = 'Claim approved!';
    copyText = 'The customer’s claim has been approved, your customer will be notified ASAP. Click below to return to All Customers.';
    imgSrc = 'checkmark-circle.svg';
  }

  // Claim was resolved by the retailer (from step === 'resolution')
  if (isResolvedClaim(savedClaimState)) {
    headerText = 'Claim resolved!';
    copyText = <>
      The customer’s claim has been approved and resolved!<br />
      There is no need to take any additional action on this resolution.
    </>;
    imgSrc = 'checkmark-circle.svg';
    onClose = _refreshCop;
  }

  // a claim was adh true and within limited warranty - hand off
  if (isReferral) {
    headerText = 'This customer is covered by Clyde!';
    copyText = <>
      This customer’s issue is eligible for coverage by their Clyde product protection plan.<br /><br />
      Please send the customer a direct link so that they can file a claim.<br />
      Clyde, or one of our administrative partners, will collect all the necessary details
      to help solve the customer’s issue.
    </>;
    imgSrc = 'warranty-shield.svg';
  }

  const modalForegroundClass = classnames(
    'cop-modal-foreground',
    'cop-modal-foreground--cop-file-claim',
    { 'cop-modal-foreground--hi-clyde-referral': isReferral },
  );

  return (
    <div className='cop-modal-background' onClick={ onClose }>
      <div
        className={ modalForegroundClass }
        onClick={ (e) => { e.stopPropagation(); } }>
        <button
          className='button-nostyle cop-modal-header__cancel'
          onClick={ onClose }>
          &#x2715;
        </button>
        <div className='claims-feedback'>
            <img
              alt={ headerText }
              className='claims-feedback__img'
              src={ `/images/${imgSrc}` } />
          <h3 className='claims-feedback__header'>{ headerText }</h3>
          <p className='claims-feedback__copy'>{ copyText }</p>
          <ClydeButton
            className='claims-feedback__button'
            onClick={ onButtonClick }
            successText='Sent!'
            processing={ referralEmailProcessing }
          >
            { isReferral ? 'Email Link to Customer' : 'Return to All Customers' }
          </ClydeButton>
          { isReferral
            && <>
              <input
                className='claims-feedback__copy-button__input'
                readOnly
                ref={ (node) => { buttonRef = node; } }
                value={ `${window._env_.REACT_APP_HICLYDE_HOST}/products/${contractSaleId}` }
              />
              <button
                className='button-no-style claims-feedback__copy-button'
                onClick={ copyValue }
              >
                { copyButtonText }
              </button>
            </>
          }
        </div>
      </div>
    </div>
  );
};

export default AdjudicationDecision;
