const defaultState = {
  apiKeys: {
    liveKeys: {},
    testKeys: {},
  },
  getProcessing: false,
  isBasicAuth: true,
  saveProcessing: false,
  showSaveButton: false,
};

const developersReducer = function profile(state = defaultState, action) {
  switch (action.type) {
    case 'SET_IS_BASIC_AUTH':
      return {
        ...state,
        isBasicAuth: action.payload,
        showSaveButton: true,
      };
    case 'SHOP_GET_PROCESSING':
      return {
        ...state,
        getProcessing: true,
      };
    case 'GET_SHOP_DEVELOPER_INFO_RES':
      return {
        ...state,
        apiKeys: action.payload.apiKeys,
        getProcessing: false,
        isBasicAuth: action.payload.isBasicAuth,
      };
    case 'SHOP_SECURITY_SAVE_PROCESSING':
      return {
        ...state,
        saveProcessing: true,
      };
    case 'UPDATE_SHOP_SECURITY_INFO_RES':
      return {
        ...state,
        ...action.payload,
        saveProcessing: false,
        showSaveButton: false,
      };
    case 'ERROR':
      switch (action.payload.reqType) {
        case 'GET_SHOP_DEVELOPER_INFO_REQ':
          return {
            ...state,
            getProcessing: false,
          };
        case 'UPDATE_SHOP_SECURITY_INFO_REQ':
          return {
            ...state,
            saveProcessing: false,
          };
        default:
          return state;
      }
    default:
      return state;
  }
};

export default developersReducer;
