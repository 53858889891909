import React from 'react';
import { ClydeTextInput } from '@joinclyde/clyde-react-components';

import SharedHeader from '../../SharedHeader';
import SharedButtons from '../../SharedButtons';

const AffirmSetup = (props) => {
  const {
    integration: {
      logo: integrationLogo,
      name: integrationName,
      clydeOptions: {
        affirmPublicKey,
      },
    },
    _onSubmit,
    _onTextChange,
  } = props;

  const handleSubmit = (e) => {
    e.preventDefault();
    _onSubmit('affirm', {
      affirmPublicKey,
    });
  };

  const handleTextChange = (e) => {
    const { name, value } = e.target;
    _onTextChange(name, value);
  };

  return (
    <form className='integrations-setup-modal-form' onSubmit={ handleSubmit }>
      <SharedHeader
        logo={ integrationLogo }
        name={ integrationName } />

      <p className='integrations-setup-modal-form__info'>
        Input your information below to complete your integration
      </p>

      <div className='integrations-setup-modal-form__inputs'>
        <div className='integrations-setup-modal-form__inputs__input-group integrations-setup-modal-form__inputs__input-group--margin-right'>
          <label htmlFor='affirmPublicKey'>Public Key</label>
          <ClydeTextInput
            id='affirmPublicKey'
            name='affirmPublicKey'
            onChange={ handleTextChange }
            placeholder='Public Key'
            value={ affirmPublicKey } />
        </div>
      </div>

      <SharedButtons />
    </form>
  );
};

export default AffirmSetup;
