import { connect } from 'react-redux';

import {
  hideChangeOrderCustomer,
  submitChangeOrderCustomer,
} from '../../../../actions/customersActions';

import TransferOrder from './TransferOrder';

const mapStateToProps = (state) => {
  const { auth, customers } = state;
  return {
    finished: customers.changeOrderCustomer.finished,
    orderId: customers.selectedOrder.clydeOrderId,
    processing: customers.changeOrderCustomer.processing,
    userId: auth.userId,
    selectedOrder: customers.selectedOrder,
    shopId: auth.shopId,
  };
};

const mapDispatchToProps = (dispatch) => ({
  _closeModal: () => {
    dispatch(hideChangeOrderCustomer());
  },
  _submitChange: (customerInfo, orderId, userId, shopId, source) => {
    dispatch(submitChangeOrderCustomer(customerInfo, orderId, userId, shopId, source));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(TransferOrder);
