import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import {
  closeProductModal,
  createProduct,
  updateProduct,
  updatePlatformProduct,
  updateProductParts,
} from '../../../actions/productsActions';

import NewProduct from './NewProduct';

const mapStateToProps = (state) => ({
  saveProductProcessing: state.products.saveProductProcessing,
  editProduct: state.products.editProduct,
  productType: state.products.productType,
  platform: state.auth.platform,
  parts: state.products.parts,
  shopCurrency: state.billing.shopCurrency,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  _closeProductModal: closeProductModal,
  _createProduct: createProduct,
  _updateProduct: updateProduct,
  _updatePlatformProduct: updatePlatformProduct,
  _updateProductParts: updateProductParts,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(NewProduct);
