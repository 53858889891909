import React from 'react';
import classnames from 'classnames';
import { ClydeTextInput } from '@joinclyde/clyde-react-components';

const ContactForm = ({
  contactInfo,
  formErrors,
  handleChange,
  updateStep,
  tipStep,
  className,
}) => {
  const {
    firstName,
    lastName,
    phoneNumber,
    email,
    secondaryEmail,
    type,
  } = contactInfo;

  let secondaryEmailLabel = '';
  if (type === 'Primary Contact') {
    secondaryEmailLabel = 'Retailer Support Email';
  }
  if (type === 'Accounting Contact') {
    secondaryEmailLabel = 'Secondary Accounting Email';
  }

  const generateLabelClass = (hasErrors) => classnames('profile-label',
    { 'profile-label--invalid': hasErrors });

  const customClass = className || '';

  return (
    <div className={ `row ${customClass}` } data-test={ type || 'contactForm' }>
      <h5 className='settings-tile__content__subtitle'>{ type }</h5>
      <div className='col-12 col-lg-3 col-md-3 col-sm-6 col-xs-12'>
        <label
            className={ generateLabelClass(formErrors.firstName) }
            htmlFor='first-name'>First Name</label>
        <ClydeTextInput
          name='firstName'
          dataTestValue='firstName'
          onChange={ handleChange }
          value={ firstName || '' }
          id={ `${type}-first-name` }
          className='form-field'
          maxLength='30'
        />
      </div>
      <div className='col-12 col-lg-3 col-md-3 col-sm-6 col-xs-12'>
        <label
            className={ generateLabelClass(formErrors.lastName) }
            htmlFor='last-name'>Last Name</label>
        <ClydeTextInput
          name='lastName'
          dataTestValue='lastName'
          onChange={ handleChange }
          value={ lastName || '' }
          id={ `${type}-last-name` }
          className='form-field'
          maxLength='30'
        />
      </div>
      <div className='col-12 col-lg-3 col-md-3 col-sm-6 col-xs-12'>
        <div
          id='email-instr'
          data-test='popupInstructions'
          className={ `auto-tooltip${tipStep === 2 ? ' show' : ''}` }>
          <div>Double check this – we may need to contact you here.</div>
          <div
            onClick={ updateStep }
            className='auto-tooltip__confirm-text'
            data-test='looksGood'>Looks good.</div>
        </div>
        <label
            className={ generateLabelClass(formErrors.email) }
            htmlFor='email'>Email</label>
        <ClydeTextInput
          name='email'
          dataTestValue='email'
          onChange={ handleChange }
          value={ email || '' }
          id={ `${type}-email` }
          className='form-field'
          maxLength='40'
        />
      </div>
      { secondaryEmailLabel
        && <div className='col-12 col-lg-3 col-md-3 col-sm-6 col-xs-12'>
          <label
              className={ generateLabelClass(formErrors.secondaryEmail) }
            htmlFor='secondaryEmail'
          >
            { secondaryEmailLabel }
          </label>
          <ClydeTextInput
            name='secondaryEmail'
            dataTestValue='retailerSupportEmail'
            onChange={ handleChange }
            value={ secondaryEmail || '' }
            id={ `${type}-secondaryEmail` }
            className='form-field'
            maxLength='40'
          />
        </div>
      }
      <div className='col-12 col-lg-3 col-md-3 col-sm-6 col-xs-12'>
        <div
          id='phone-instr'
          data-test='popupInstructions'
          className={ `auto-tooltip${tipStep === 3 ? ' show' : ''}` }>
          <div>This one, too.</div>
          <div
            onClick={ updateStep }
            className='auto-tooltip__confirm-text' data-test='allSet'>All set.</div>
        </div>
        <label
            className={ generateLabelClass(formErrors.phoneNumber) }
            htmlFor='phoneNumber'>Phone Number</label>
        <ClydeTextInput
          name='phoneNumber'
          dataTestValue='phoneNumber'
          onChange={ handleChange }
          value={ phoneNumber || '' }
          id={ `${type}-phoneNumber` }
          className='form-field'
          maxLength='20'
        />
      </div>
    </div>
  );
};

export default ContactForm;
