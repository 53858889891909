import React, { Component } from 'react';
import { LoadingDots, ClydeButton, ClydeTooltip } from '@joinclyde/clyde-react-components';

import SmallSwitch from '../../../Utils/SmallSwitch';

class DevelopersSettingsTile extends Component {
  state = {
    showLiveKeys: true,
    showSecret: false,
  }

  UNSAFE_componentWillMount = () => {
    this.props._getShopInfo();
  }

  handleSave = () => {
    this.props._updateShopInfo(this.props.developers);
  }

  handleSelectChange = (event) => {
    this.props._setIsBasicAuth(event.target.value);
  }

  showSecret = () => {
    this.setState({ showSecret: true });
  }

  toggleKeys = () => {
    this.setState({
      showLiveKeys: !this.state.showLiveKeys,
      showSecret: false,
    });
  }

  render() {
    let apiLabels = ['Live', 'Test'];
    let applicableClientKey;
    let applicableSecret;

    if (!this.state.showLiveKeys) {
      apiLabels = apiLabels.slice(1).concat(apiLabels.slice(0, 1));
      const { clientKey } = this.props.developers.apiKeys.testKeys;
      const { secret } = this.props.developers.apiKeys.testKeys;
      applicableClientKey = clientKey;
      applicableSecret = secret;
    } else {
      const { clientKey } = this.props.developers.apiKeys.liveKeys;
      const { secret } = this.props.developers.apiKeys.liveKeys;
      applicableClientKey = clientKey;
      applicableSecret = secret;
    }

    return (
      <div>
        <div className='settings-tile'>
          <div className='settings-tile__title settings-tile__title--developers'>Developers</div>
          {
            this.props.developers.getProcessing
              ? <div className='settings-tile__content settings-tile__content--developers'>
                  <div className='tile-loading-container'>
                    <LoadingDots dotColor='clydeBlack' dotSize='large' />
                  </div>
              </div>
              : <div className={ `settings-tile__content settings-tile__content--${this.state.showLiveKeys ? 'live' : 'test'}` }>

                <div className='row settings-tile__content__section'>
                  <div className='col-6 col-xl-3 api-keys-section'>
                    <h5>{ apiLabels[0] } API Keys</h5>
                  </div>
                  <div className='col-6 col-xl-3 api-keys-section__switch-container'>
                    <SmallSwitch
                      toggleFunc={ this.toggleKeys }
                      settingName={ this.state.showLiveKeys }
                      trueLabel='LIVE'
                      falseLabel='TEST' />
                  </div>
                  <div className='col-12 api-keys-section'>
                    <div className='row'>
                      <div className='col-sm-4 col-md-3 col-lg-2 api-keys-section__key-name'>Client Key:</div>
                      <div className='col-sm-7 col-md-5 col-lg-10 api-keys-section__key-value'>
                        <span className='api-keys-section__key-value__text'>{ applicableClientKey }</span>
                      </div>
                    </div>
                    <div className='row'>
                      <div className='col-sm-4 col-md-3 col-lg-2 api-keys-section__key-name'>Secret:</div>
                      <div className='col-sm-7 col-md-5 col-lg-10 api-keys-section__key-value'>
                        {
                          this.state.showSecret
                            ? <span className='api-keys-section__key-value__text'>{ applicableSecret }</span>
                            : <button className='show-secret-button' onClick={ this.showSecret }>Show Secret</button>
                        }
                      </div>
                    </div>
                  </div>
                </div>

                <div className='row'>
                  <div className='col-sm-12 col-xl-6 api-security'>
                    <label
                      className='api-security__label'
                      htmlFor='isBasicAuth'>
                      API security options
                      <div className='api-security__tooltip-container'>
                        <ClydeTooltip
                          position='right'
                          label={
                            <div className='tooltip-text tooltip-text--right'>
                            Stores default to signed requests for enhanced security.
                            API customers have the choice to change from Signed Requests to Basic Auth
                            for situations in which signed requests are not needed. Do not
                            change if you are using a language specific SDK.
                            </div>
                          }
                          hideIndicator>
                          <img alt='info' data-tip data-for='api-security' className='info-icon' src='/images/info-icon.svg'/>
                        </ClydeTooltip>
                      </div>
                    </label>
                    <select
                      className='form-field'
                      id='isBasicAuth'
                      name='isBasicAuth'
                      value={ this.props.developers.isBasicAuth }
                      onChange={ this.handleSelectChange } >
                      <option value='false' key='false'>Request Signing</option>
                      <option value='true' key='true'>Basic Auth</option>
                    </select>
                  </div>
                </div>
              </div>
          }
        </div>
        <div
          id='prof-settings-save-container'
          className='security-settings-save-container'>
          { this.props.developers.showSaveButton && <ClydeButton
            id='developers-save-button'
            className='button--small settings-save-button'
            onClick={ this.handleSave }
            processing={ this.props.developers.saveProcessing }
            successText='Saved'
          >
            Save
          </ClydeButton> }
        </div>
      </div>
    );
  }
}

export default DevelopersSettingsTile;
