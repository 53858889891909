import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getShopifyShopOAuthLink } from '../../../actions/authActions';

import Connect from './Connect';

const mapStateToProps = (state) => state.auth;

const mapDispatchToProps = (dispatch) => bindActionCreators(
  { _getShopifyShopOAuthLink: getShopifyShopOAuthLink },
  dispatch,
);

export default connect(mapStateToProps, mapDispatchToProps)(Connect);
