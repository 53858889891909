import React, { Component } from 'react';
import { LoadingDots, ClydeButton } from '@joinclyde/clyde-react-components';

import ProductImportSummary from './ProductImportSummary';
import SalesImportSummary from './SalesImportSummary';

class ProductUpload extends Component {
  state = {
    finishUpload: false,
    validFile: false,
    fileData: '',
    errorText: null,
    fileName: '',
  }

  downloadTemplate = () => {
    if (this.props.importProcessing === true || this.props.importStep !== 'start') {
      return;
    }
    const link = document.createElement('a');
    link.href = `data:text/csv;charset=utf-8,${this.props.csvTemplateString}`;
    link.download = `clyde-${this.props.isProductCSV ? 'product' : 'sale'}-template.csv`;
    link.click();
  }

  openFileDialog = (e) => {
    e.preventDefault();
    if (this.props.importProcessing === true || this.props.importStep !== 'start') {
      return;
    }
    document.getElementById('upload-file').click();
  }

  selectFile = (e) => {
    const splitFilePath = e.target.value.split('\\');
    const fileName = splitFilePath[splitFilePath.length - 1];
    const maxFileSize = 1024 * 1024 * 25;
    const allowedFileTypes = ['csv', 'vnd.ms-excel'];

    const { files } = e.target;
    const file = files[0];

    if (FileReader && files && files.length) {
      let fileType = file.type.split('/')[1];
      if (!fileType) {
        const splitFileName = fileName.split('.');
        fileType = splitFileName[splitFileName.length - 1];
      }
      if (!allowedFileTypes.includes(fileType) || file.size > maxFileSize) {
        this.setState({ validFile: false, errorText: 'Please upload a CSV no larger than 25mb.' });
        return;
      }
      const fr = new FileReader();
      fr.onload = () => {
        this.enableUpload(fileName, fr.result);
      };
      fr.readAsBinaryString(file);
    }
  }

  enableUpload = (fileName, fileData) => {
    this.setState({
      validFile: true, fileName, errorText: null, fileData,
    });
  }

  handleSubmit = () => {
    if (this.state.validFile === true && !this.props.importProcessing) {
      this.startUpload();
    }
  }

  startUpload = () => {
    this.props._getImportSummary(this.state.fileData);
  }

  finishUpload = (runUpdate) => {
    this.props._submitImport(runUpdate, this.props.importFileKey, this.props.importFileRowCount);
  }

  render() {
    const {
      importPrompts,
      importProcessing,
      importStep,
      importFileKey,
      importSummary,
      importResult,
      _closeUploadModal,
    } = this.props;

    const submittedClass = importProcessing || importStep !== 'start'
      ? 'import-step__button--submitted '
      : '';
    const disabledClass = this.state.validFile
      ? ''
      : 'import-step__button--disabled ';

    return (
      <div className='modal-container'>
        <div className='modal-container__backdrop'></div>
        <div className='clyde-modal-content upload-products'>
          <div id='upload-modal' className='upload-products-modal-content'>
            <div className='upload-steps-container'>
              <div onClick={ () => _closeUploadModal(importFileKey) } className='close-upload-modal clickable'>&#x2715;</div>
              <div className='import-products-prefixed-subtitle'>{ importPrompts.uploadInfo }</div>
              <h1 className='import-products-title'>{ importPrompts.importTitle }:</h1>
              { importStep === 'start'
                && <div className='row import-steps'>
                  <div className='import-step col-lg-4 col-md-4 col-12'>
                    <div className={ `import-step__button ${submittedClass}` } onClick={ this.downloadTemplate }>
                      <div className='import-step__button__content'>
                        <img className='import-step-image' alt='download template' src='/images/download.png'/>
                        <div>Download template</div>
                      </div>
                    </div>
                    <div className='import-step__description'>{ importPrompts.downloadPrompt }</div>
                  </div>
                  <div className='import-step col-lg-4 col-md-4 col-12'>
                    <div onClick={ this.openFileDialog } className={ `import-step__button ${submittedClass}` }>
                      <div className='import-step__button__content'>
                        <img className='import-step-image' alt='upload file' src='/images/upload.png'/>
                        <div>{ this.state.fileName || 'fileName.csv' }</div>
                        <div className='import-csv-error'>{ this.state.errorText }</div>
                      </div>
                    </div>
                    <div className='d-none'>
                      <input id='upload-file' type='file' onChange={ this.selectFile }/>
                    </div>
                    <div className='import-step__description'>
                      Select the completed template.
                      <br /><br />
                      Please note that there is a limit of 1000 rows per CSV.
                    </div>
                  </div>
                  <div className='import-step col-lg-4 col-md-4 col-12'>
                    <div className={ `import-step__button import-step__submit-button ${disabledClass}${submittedClass}` } onClick={ this.handleSubmit }>
                      <div className='import-step__button__content'>
                        <div>Verify file</div>
                      </div>
                    </div>
                    <div className='import-step__description'>{ importPrompts.submitInfo }</div>
                  </div>
                  { importProcessing
                    && <div className='import-progress'>
                      <LoadingDots dotColor='clydeBlack' dotSize='large' className='loading-backdrop--clear' />
                    </div>
                  }
                </div>
              }
              { importStep === 'confirm'
                && <div>
                { this.props.isProductCSV
                  ? <ProductImportSummary
                    step={ importStep }
                    processing={ importProcessing }
                    show={ !importProcessing }
                    importData={ importSummary }/>
                  : <SalesImportSummary
                    step={ importStep }
                    processing={ importProcessing }
                    show={ !importProcessing }
                    importData={ importSummary }/>
                }
                  { importProcessing === false
                    ? <div className='finish-button-row row'>
                      <div className='col-12 finish-buttons'>
                        <ClydeButton
                          id='add-and-update-finish'
                          className='upload-button'
                          onClick={ () => { this.finishUpload(true); } }
                          processing={ importProcessing }
                          disabled={ importProcessing }
                          successText='Done'
                        >
                          { 'Import & update' }
                        </ClydeButton>
                        <ClydeButton
                          id='add-finish'
                          className='upload-button'
                          onClick={ () => { this.finishUpload(false); } }
                          processing={ importProcessing }
                          disabled={ importProcessing }
                          successText='Done'
                        >
                          Import new
                        </ClydeButton>
                        <ClydeButton
                          id='add-cancel'
                          className='upload-button upload-button--red'
                          onClick={ () => _closeUploadModal(importFileKey) }
                          processing={ importProcessing }
                          disabled={ importProcessing }
                          successText='Cancelled'
                        >
                          Cancel
                        </ClydeButton>
                      </div>
                    </div>
                    : <div className='import-progress import-progress--finish'>
                      <LoadingDots dotColor='clydeBlack' dotSize='large' className='loading-backdrop--clear' />
                    </div>
                  }
                </div>
              }
              { importStep === 'finish'
                && <div>
                  { this.props.isProductCSV
                    ? <ProductImportSummary
                      step={ importStep }
                      processing={ importProcessing }
                      show={ !importProcessing }
                      importData={ importResult }
                    />
                    : <SalesImportSummary
                      step={ importStep }
                      processing={ importProcessing }
                      show={ !importProcessing }
                      importData={ importResult }
                    />
                  }
                  <div className='finish-button-row row'>
                    <div className='col-lg-2 offset-lg-10 col-3 offset-9 finish-buttons' >
                      <ClydeButton
                        id='finish-upload'
                        className='upload-button upload-button--red'
                        onClick={ () => _closeUploadModal(importFileKey) }
                      >
                        Finish
                      </ClydeButton>
                    </div>
                  </div>
                </div>
              }
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ProductUpload;
