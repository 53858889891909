import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import {
  getBillingInfo, addAccount, showAccounts,
} from '../../../../actions/billingActions';

import BillingSettingsTile from './BillingSettingsTile';

const mapStateToProps = (state) => ({
  billing: state.billing,
  isOnboarding: state.onboarding.isOnboarding,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  _getBillingInfo: getBillingInfo,
  _addAccount: addAccount,
  _showAccounts: showAccounts,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(BillingSettingsTile);
