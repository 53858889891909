import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import {
  moveToCustomerStep,
  runNewOrderSearch,
  updateCart,
  updateNewOrderCartPrice,
  updateNewOrderCartQuantity,
  updateNewOrderInput,
} from '../../../../../actions/newOrderActions';

import Cart from './Cart';

const mapStateToProps = (state) => ({
  platform: state.auth.platform,
  newOrder: state.newOrder,
  shopId: state.auth.shopId,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  _moveToCustomerStep: moveToCustomerStep,
  _runNewOrderSearch: runNewOrderSearch,
  _updateCart: updateCart,
  _updateNewOrderCartPrice: updateNewOrderCartPrice,
  _updateNewOrderCartQuantity: updateNewOrderCartQuantity,
  _updateNewOrderInput: updateNewOrderInput,
}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Cart);
