import React from 'react';
import classnames from 'classnames';
import { ClydeTextInput } from '@joinclyde/clyde-react-components';
import ProfileCountryInput from '../../Utils/ProfileCountryInput';

const AddressForm = ({ contactInfo, formErrors, handleChange }) => {
  const {
    address1,
    address2,
    city,
    province,
    zip,
    country,
    type,
  } = contactInfo;

  const generateLabelClass = (hasErrors) => classnames('profile-label',
    { 'profile-label--invalid': hasErrors });

  return (
    <>
      <div className='row'>
        <h5 className='settings-tile__content__subtitle'>Billing</h5>
        <div className='col-12 col-lg-6 col-md-6 col-sm-6 col-xs-12'>
          <label
              className={ generateLabelClass(formErrors.address1) }
              htmlFor='address1'>Address 1</label>
          <ClydeTextInput
            name='address1'
            dataTestValue='address1'
            onChange={ handleChange }
            value={ address1 }
            id={ `${type}-address1` }
            className='form-field'
            maxLength='50'
          />
        </div>
        <div className='col-12 col-lg-6 col-md-6 col-sm-6 col-xs-12'>
          <label
            className='profile-label'
            htmlFor='address2'>Address 2</label>
          <ClydeTextInput
            name='address2'
            dataTestValue='address2'
            onChange={ handleChange }
            value={ address2 || '' }
            id={ `${type}-address2` }
            className='form-field'
            maxLength='50'
          />
        </div>
      </div>
      <div className='row'>
        <div className='col-12 col-lg-3 col-md-3 col-sm-6 col-xs-12'>
          <label
              className={ generateLabelClass(formErrors.city) }
              htmlFor='city'>City</label>
          <ClydeTextInput
            name='city'
            dataTestValue='city'
            onChange={ handleChange }
            value={ city }
            id={ `${type}-city` }
            className='form-field'
            maxLength='40'
          />
        </div>
        <div className='col-12 col-lg-3 col-md-3 col-sm-6 col-xs-12'>
          <label
              className={ generateLabelClass(formErrors.province) }
              htmlFor='province'>State/Province</label>
          <ClydeTextInput
            name='province'
            dataTestValue='stateOrProvince'
            onChange={ handleChange }
            value={ province }
            id={ `${type}-province` }
            className='form-field'
            maxLength='6'
          />
        </div>
        <div className='col-12 col-lg-3 col-md-3 col-sm-6 col-xs-12'>
            <label
              className={ generateLabelClass(formErrors.country) }
              htmlFor='country'>Country</label>
            <ProfileCountryInput
              selectId={ `${type}-country` }
              selectName='country'
              selectValue={ country }
              selectChange={ handleChange }
              inputValidation={ () => {} } />
          </div>
        <div className='col-12 col-lg-3 col-md-3 col-sm-6 col-xs-12'>
          <label
              className={ generateLabelClass(formErrors.zip) }
              htmlFor='zip'>Zip</label>
          <ClydeTextInput
            name='zip'
            dataTestValue='zip'
            onChange={ handleChange }
            value={ zip }
            id={ `${type}-zip` }
            className='form-field'
            maxLength='10'
          />
        </div>
      </div>
    </>
  );
};

export default AddressForm;
