import React from 'react';
import { ClydeButton, SuccessAnimation, ClydeTextInput } from '@joinclyde/clyde-react-components';

const ResendPostPurchaseModal = (props) => {
  const { order, resendPpEmail, _cancelResendPpEmail } = props;

  const handleConfirmResend = () => {
    const eligibleLineItemIds = order.eligibleProducts.map((prod) => prod.lineItemId);
    props._confirmResendPpEmail(order.clydeOrderId, eligibleLineItemIds, resendPpEmail.contact);
  };

  return (
    <div className='cop-modal-background' onClick={ _cancelResendPpEmail }>
      <div className='cop-modal-foreground cop-resend-modal' onClick={ (e) => e.stopPropagation() }>
        {
          resendPpEmail.step !== 'finish'
            ? <React.Fragment>
                <div className='cop-modal-header'>
                  <button className='button-nostyle cop-modal-header__cancel' onClick={ _cancelResendPpEmail }>
                    &#x2715;
                  </button>
                  <p className='cop-modal-header__explanation'>
                    This will send an email to the customer with a link to purchase coverage on hiclyde.com
                  </p>
                  <h1 className='cop-modal-header__header'>Send Post Purchase Email</h1>
                </div>

                <div className='cop-modal-content cop-resend-modal'>
                  <div className='cop-resend-modal__contact'>
                    <label htmlFor='resend-contact-input' className='cop-resend-modal__label'>
                      Send to (email taken from order):
                    </label>
                    <ClydeTextInput
                      id='resend-contact-input'
                      className='cop-resend-modal__input'
                      disabled
                      value={ resendPpEmail.contact }
                    />
                  </div>
                </div>

                <div className='cop-modal-actions'>
                  <ClydeButton
                    id='confirm-resend-button'
                    className='cop-modal-actions__confirm'
                    onClick={ handleConfirmResend }
                    processing={ resendPpEmail.processing }
                    successText='Sent'
                  >
                    Confirm and send
                  </ClydeButton>
                </div>
              </React.Fragment>
            : <div className='cop-modal-foreground__action-complete'>
                <SuccessAnimation text='Sent!' onSuccess={ _cancelResendPpEmail } />
              </div>
        }
      </div>
    </div>
  );
};

export default ResendPostPurchaseModal;
