const defaultState = {
  licensingInfo: {},
};

const licenseReducer = function profile(state = defaultState, action) {
  switch (action.type) {
    case 'GET_LICENSING_INFO_RES':
      return {
        ...state,
        licensingInfo: action.payload.licensingInfo,
      };
    default:
      return state;
  }
};

export default licenseReducer;
