import React, { Component } from 'react';
import classnames from 'classnames';
import { ClydeButton, ClydeTextInput } from '@joinclyde/clyde-react-components';
import zxcvbn from 'zxcvbn';

class UserSettingsTile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: {
        email: this.props.user.email,
        password: '',
        newPassword: '',
        confirmPassword: '',
      },
      passwordStrength: 0,
      formErrors: {},
      formValid: false,
      missingFields: {},
    };
  }

  UNSAFE_componentWillMount() {
    this.props._getUserEmail();
  }

  componentWillUnmount() {
    this.props._clearSettingsStatus();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.user.email !== this.props.user.email) {
      this.setState({ user: { ...this.state.user, email: nextProps.user.email }});
    }
    if (this.props.user.updateProcessing === true && nextProps.user.updateProcessing === false) {
      this.setState({
        user: {
          ...this.state.user, email: nextProps.user.email, password: '', newPassword: '', confirmPassword: '',
        },
        passwordStrength: 0,
      });
    }
  }

  handleChange = (e) => {
    const { name } = e.target;
    const { value } = e.target;
    this.setState({ user: { ...this.state.user, [name]: value }}, () => {
      this.validateField(name, value);
      this.props._showSaveButton();
    });
  }

  validateField = (name, value) => {
    const validationErrors = this.state.formErrors;
    switch (name) {
      case 'newPassword':
        const passwordStrength = zxcvbn(value).score;
        passwordStrength < 3
          ? validationErrors.newPassword = 'Password not strong enough'
          : delete (validationErrors.newPassword);
        this.state.user.confirmPassword !== '' && value !== this.state.user.confirmPassword
          ? validationErrors.confirmPassword = 'Passwords do not match.'
          : delete (validationErrors.confirmPassword);
        this.setState({ passwordStrength });
        break;
      case 'confirmPassword':
        value === this.state.user.newPassword
          ? delete (validationErrors.confirmPassword)
          : validationErrors.confirmPassword = 'Passwords do not match.';
        break;
      default:
        break;
    }
    this.setState({
      formErrors: validationErrors,
      formValid: Object.keys(validationErrors).length === 0,
    });
  }

  fieldsComplete = () => {
    const missingFields = {};
    Object.keys(this.state.user).forEach((key) => {
      const emptyField = this.state.user[key].trim().length === 0;
      if (emptyField) {
        missingFields[key] = true;
      }
    });
    if (Object.keys(missingFields).length > 0) {
      this.setState({ missingFields });
      return false;
    }
    return true;
  }

  handleSubmit = () => {
    this.setState({ missingFields: {}});
    if (this.fieldsComplete() && this.state.formValid === true) {
      this.props._submitUserUpdate(this.state.user.password, this.state.user.newPassword);
    }
  }

  render() {
    const errors = this.state.formErrors;
    const { missingFields } = this.state;

    return (
      <div>
        <div className='settings-tile'>
          <div className='settings-tile__title'>
            <span>Account</span>
            { this.props.oAuthUser
              && <span className='settings-tile__title__oauth-disabled-message'>Password management is disabled for OAuth users</span>
            }
            { this.props.user.passwordUpdated
              && <span className='settings-tile__title__updated'>Your password has been updated!</span>
            }
          </div>
          <div className='settings-tile__content'>
            <div className='row'>
              <div className='col-6'>
                <label
                  className='account-label'
                  htmlFor='userEmail'>Email</label>
                <ClydeTextInput
                  className='form-field'
                  id='userEmail'
                  name='email'
                  value={ this.state.user.email }
                  disabled
                />
              </div>
              <div className='col-6'>
                <label
                  className={ classnames('account-label',
                    { 'account-label--invalid': errors.password || missingFields.password }) }
                  htmlFor='userPassword'>Current password</label>
                <input
                  className='form-field'
                  onChange={ this.handleChange }
                  id='userPassword'
                  type='password'
                  name='password'
                  disabled={ this.props.oAuthUser }
                  value={ this.state.user.password }/>
              </div>
              <div className='col-6'>
                <label
                  className={ classnames('account-label account-label--inline-block',
                    { 'account-label--invalid': errors.newPassword || missingFields.newPassword }) }
                  htmlFor='newPassword'>New password</label>
                <div className={ `password-strength strength-${this.state.passwordStrength}` }>
                  <span className='strength-1'></span>
                  <span className='strength-2'></span>
                  <span className='strength-3'></span>
                  <span className='strength-4'></span>
                </div>
                <input
                  className='form-field'
                  onChange={ this.handleChange }
                  id='newPassword'
                  type='password'
                  name='newPassword'
                  disabled={ this.props.oAuthUser }
                  value={ this.state.user.newPassword }/>
              </div>
              <div className='col-6'>
                <label
                  className={ classnames('account-label',
                    { 'account-label--invalid': errors.confirmPassword || missingFields.confirmPassword }) }
                  htmlFor='confirmPassword'>Confirm password</label>
                <input
                  className='form-field'
                  onChange={ this.handleChange }
                  id='confirmPassword'
                  type='password'
                  name='confirmPassword'
                  disabled={ this.props.oAuthUser }
                  value={ this.state.user.confirmPassword }/>
              </div>
            </div>
          </div>
        </div>
        <div className='user-settings-save-container'>
          { this.props.user.error && <div className='invalid-password'>Invalid password</div> }
          { this.props.user.showSaveButton && <ClydeButton
            id='account-save-button'
            className='button--small settings-save-button'
            disabled={ this.props.oAuthUser }
            onClick={ this.handleSubmit }
            processing={ this.props.user.updateProcessing }
            successText='Saved'
          >
            Save
          </ClydeButton> }
        </div>
      </div>
    );
  }
}

export default UserSettingsTile;
