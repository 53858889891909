import React from 'react';
import { LoadingDots } from '@joinclyde/clyde-react-components';
import { Currency } from '../../../Utils/Currency';

const ProductCard = (props) => {
  const {
    product: {
      productManufacturer,
      productName,
      productPurchasePrice,
      productRetailPrice,
      productSku,
    },
    processing,
  } = props;

  return (
    <div className='claims-detail-card claims-detail-card--product'>
      <h3>Product</h3>

      {
        processing
          ? <LoadingDots className='claims-detail-card__loading' dotColor='clydeBlack' dotSize='medium' />
          : <React.Fragment>
              <div className='claims-detail-card__group'>
                <span className='claims-detail-card__group__title'>Product Name:</span>
                <span className='claims-detail-card__group__info'>{ productName }</span>
              </div>
              <div className='claims-detail-card__group'>
                <span className='claims-detail-card__group__title'>Manufacturer:</span>
                <span className='claims-detail-card__group__info'>{ productManufacturer }</span>
              </div>
              <div className='claims-detail-card__group'>
                <span className='claims-detail-card__group__title'>SKU:</span>
                <span className='claims-detail-card__group__info'>{ productSku }</span>
              </div>
              <div className='claims-detail-card__group'>
                <span className='claims-detail-card__group__title'>Current Product Price:</span>
                <span className='claims-detail-card__group__info'><Currency amount={ productRetailPrice } /></span>
              </div>
              <div className='claims-detail-card__group'>
                <span className='claims-detail-card__group__title'>Purchased Product Price:</span>
                <span className='claims-detail-card__group__info'><Currency amount={ productPurchasePrice } /></span>
              </div>
            </React.Fragment>
      }

    </div>
  );
};

export default ProductCard;
