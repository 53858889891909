const defaultState = {
  branded: false,
  cartPrompt: false,
  catalogPrompt: false,
  color: '#000000',
  customLocation: '',
  displayName: '',
  imageData: null,
  imageProcessing: false,
  imageUrl: '',
  modalPrompt: false,
  newImage: false,
  productPagePrompt: false,
  productPageLocation: 'after_form',
  postPurchase: true,
  saveProcessing: false,
  showSaveButton: false,
  validColor: true,
  showRegistrationPage: false,
  usesCheckoutWidget: false,
  onlyIncludeRegistrableProducts: false,
  affirmPublicKey: '',
  affirmEnabled: false,
  selectRegistrable: false,
  type: '',
  cartType: '',
};

const customizeReducer = function customize(state = defaultState, action) {
  switch (action.type) {
    case 'SET_DISPLAY_NAME':
      return {
        ...state,
        displayName: action.payload,
        showSaveButton: true,
      };
    case 'SET_CUSTOM_LOCATION':
      return {
        ...state,
        customLocation: action.payload,
        showSaveButton: true,
      };
    case 'TOGGLE_PROMPT':
      return {
        ...state,
        [action.payload]: !state[action.payload],
        showSaveButton: true,
      };
    case 'TOGGLE_POST_PURCHASE':
      return {
        ...state,
        postPurchaseEmailCampaign: !state.postPurchaseEmailCampaign,
        showSaveButton: true,
      };
    case 'SELECT_COLOR':
      return {
        ...state,
        validColor: true,
        color: action.payload.colorCode,
        showSaveButton: true,
      };
    case 'SELECT_INVALID_COLOR':
      return {
        ...state,
        validColor: false,
        color: action.payload.colorCode,
        showSaveButton: true,
      };
    case 'SET_PRODUCT_PAGE_LOCATION':
      return {
        ...state,
        productPageLocation: action.payload,
        showSaveButton: true,
      };
    case 'SET_SHOP_IMAGE_DATA':
      return {
        ...state,
        imageData: action.payload,
        imageProcessing: false,
        newImage: true,
        showSaveButton: true,
      };
    case 'SELECT_TYPE':
      return {
        ...state,
        type: action.payload,
        showSaveButton: true,
      };
    case 'SELECT_CART_TYPE':
      return {
        ...state,
        cartType: action.payload,
        showSaveButton: true,
      };
    case 'GET_CTA_INFO_PROCESSING':
      return {
        ...state,
        imageProcessing: true,
      };
    case 'GET_CTA_INFO_RES':
      return {
        ...state,
        color: action.payload.color,
        displayName: action.payload.displayName,
        type: action.payload.type,
        cartType: action.payload.cartType,
        imageUrl: action.payload.imageUrl,
        modalPrompt: action.payload.modalPrompt,
        productPagePrompt: action.payload.productPagePrompt,
        cartPrompt: action.payload.cartPrompt,
        catalogPrompt: action.payload.catalogPrompt,
        productPageLocation: action.payload.productPageLocation,
        customLocation: action.payload.customLocation,
        postPurchase: action.payload.postPurchase,
        postPurchaseEmailCampaign: action.payload.postPurchaseEmailCampaign,
        usesCheckoutWidget: action.payload.usesCheckoutWidget,
        showRegistrationPage: action.payload.showRegistrationPage,
        onlyIncludeRegistrableProducts: action.payload.onlyIncludeRegistrableProducts,
        affirmPublicKey: action.payload.affirmPublicKey,
        affirmEnabled: action.payload.showAffirm,
        newImage: false,
        imageProcessing: false,
      };
    case 'CTA_SAVE_PROCESSING':
      return {
        ...state,
        saveProcessing: true,
      };
    case 'DISPLAY_CTA_SAVE_BUTTON':
      return {
        ...state,
        showSaveButton: action.payload,
      };
    case 'UPDATE_CTA_INFO_RES':
      return {
        ...state,
        saveProcessing: false,
        showSaveButton: false, // Hide save button on successful save response
        newImage: false,
        imageUrl: action.payload.imageUrl ? action.payload.imageUrl : state.imageUrl,
      };
    case 'TOGGLE_ONLY_INCLUDE_REGISTRABLE_PRODUCTS_RES':
      return {
        ...state,
        onlyIncludeRegistrableProducts: action.payload.onlyIncludeRegistrableProducts,
      };
    case 'LOGOUT':
      return { ...state, ...defaultState };
    case 'ERROR':
      switch (action.payload.reqType) {
        case 'UPDATE_CTA_INFO_REQ':
          return { ...state, saveProcessing: true };
        default:
          return state;
      }
    default:
      return state;
  }
};

export default customizeReducer;
