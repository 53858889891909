import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import {
  getAllClaimsOnSale,
  cancelDisplayClaimHistory,
} from '../../../../actions/customersActions';

import ShowClaimHistoryModal from './ShowClaimHistory';

const mapStateToProps = (state) => ({
  customerName: state.customers.selectedOrder.fullName,
  displayClaimHistory: state.customers.displayClaimHistory,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  _getAllClaimsOnSale: getAllClaimsOnSale,
  _cancelDisplayClaimHistory: cancelDisplayClaimHistory,
}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ShowClaimHistoryModal);
