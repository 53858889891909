import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { submitSale } from '../../../actions/contractSaleActions';

import EnterCustomer from './EnterCustomer';

const mapStateToProps = (state) => ({
  ...state.contractSale,
  currency: state.billing.currency,
  licensingInfo: state.license.licensingInfo,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({ _submitSale: submitSale }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(EnterCustomer);
