import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import {
  searchCustomers,
  setSearchType,
  selectCustomer,
} from '../../../actions/customersActions';
import { showNewOrderModal } from '../../../actions/newOrderActions';

import CustomerSearch from './CustomerSearch';

const mapStateToProps = (state) => ({
  customers: state.customers,
  shopId: state.auth.shopId,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  _searchCustomers: searchCustomers,
  _setSearchType: setSearchType,
  _selectCustomer: selectCustomer,
  _showNewOrderModal: showNewOrderModal,
}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CustomerSearch);
