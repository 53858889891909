import { connect } from 'react-redux';
import {
  getOrderDetails,
  cancelOEMUpdate,
  updateContractSaleOEMLength,
} from '../../../../actions/customersActions';
import UpdateSaleOEM from './UpdateSaleOEM';

const mapStateToProps = (state) => ({
  updateOEM: state.customers.updateSaleOEM,
  orderId: state.customers.selectedOrder.clydeOrderId,
  shopId: state.customers.selectedOrder.shopId,
});

const mapDispatchToProps = (dispatch) => ({
  _closeModal: () => {
    dispatch(cancelOEMUpdate());
  },
  _updateSaleOEM: (newLength, saleId) => {
    dispatch(updateContractSaleOEMLength(newLength, saleId));
  },
  _refreshOrder: (orderId) => {
    dispatch(cancelOEMUpdate());
    dispatch(getOrderDetails(orderId));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(UpdateSaleOEM);
