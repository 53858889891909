import React, { Component } from 'react';
import classnames from 'classnames';
import { LoadingDots, ClydeTooltip } from '@joinclyde/clyde-react-components';
import moment from 'moment';

import BillingAccountTile from './BillingAccountTile';
import PaymentForm from './PaymentForm';

class BillingSettingsTile extends Component {
  UNSAFE_componentWillMount() {
    this.props._getBillingInfo();
  }

  componentDidMount() {
    if (this.props.billing.page === 'accounts' && this.props.billing.accounts.length === 0 && !this.props.billing.invoice) {
      this.props._addAccount();
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.billing.getProcessing === true
      && nextProps.billing.getProcessing === false
      && nextProps.billing.accounts.length !== 0) {
      this.props._showAccounts();
    }
    if (nextProps.billing.page !== 'accounts' && nextProps.billing.invoice) {
      this.props._showAccounts();
    }
  }

  onPaymentAdded() {
    const { onComplete, _showAccounts } = this.props;
    if (onComplete) {
      onComplete();
    } else {
      _showAccounts();
    }
  }

  render() {
    const isDepositVerificationDisabled = moment().diff(this.props.billing.lastStripeBilling, 'hours') < 24 && process.env.NODE_ENV !== 'development';
    return (
      <div className='settings-tile settings-tile--billing' id='billing-settings-component'>
        <div className='settings-tile__title'>
          <span className='row ml-1'>
            <img alt='' className='billing-lock-icon' src='images/secured.svg'/>
            Billing
          <div className='settings-tile__title__info-icon'>
          <ClydeTooltip
            label={
              <div className='tooltip-text tooltip-text--right'>Clyde charges you each month for contract premiums and our service fee. You keep 100% of the markup in your bank account. That goes straight to your bottom line!</div>
            }
          position='right'/>
          </div>
          </span>
          { this.props.billing.page === 'accounts' && !this.props.billing.invoice
            && <span className='add-billing-button' onClick={ this.props._addAccount }>Add account</span>
          }
        </div>
        { this.props.billing.getProcessing
          ? <div className='settings-tile__content'>
            <div className='settings-tile__content__loading-container'>
              <LoadingDots dotColor='clydeBlack' dotSize='large' />
            </div>
          </div>
          : <div className={ classnames('settings-tile__content',
            { 'settings-tile__content--invoice': this.props.billing.invoice }) }>
            { this.props.billing.page === 'accounts'
              && <div>
                { this.props.billing.invoice
                  ? <span>Invoice billing is enabled for your shop.</span>
                  : <div className='bank-accounts'>
                    <div className='row bank-accounts__header'>
                    { isDepositVerificationDisabled ? (
                    <div className='deposit-locked-warning'>
                      Please wait 24 hours for the microdeposit amounts to be
                      deposited.
                     </div>) : null }
                      <div className='col-lg-4 col-3'>
                        <label className='bank-accounts__header__item'>Type</label>
                      </div>
                      <div className='col-lg-2 col-3'>
                        <label className='bank-accounts__header__item'>Account no.</label>
                      </div>
                    </div>
                    { this.props.billing.accounts.map((account, index) => (
                        <BillingAccountTile
                          key={ `account-${index}` }
                          account={ account }
                          lastUpdate={ this.props.billing.lastStripeBilling }
                          billingAttempts={ this.props.billing.stripeBillingAttempts }
                           />
                    ))
                    }
                  </div>
                }
              </div>
            }
            { (this.props.billing.page === 'add_account' && !this.props.billing.invoice)
              && <div>
                { this.props.billing.accounts.length > 0
                  && <div onClick={ this.props._showAccounts } className='billing-options__back'>
                    <img className='billing-options__back__image' alt='' src='/images/Back-Button.svg'/> Accounts
                  </div>
                }
                <PaymentForm onComplete={ () => this.onPaymentAdded() } />
              </div>
            }
            { this.props.billing.page === 'new_stripe' && <PaymentForm onComplete={ () => this.onPaymentAdded() } /> }
          </div>
        }
      </div>
    );
  }
}

export default BillingSettingsTile;
