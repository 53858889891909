import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { setStripeDefaultPaymentMethod, verifyStripeMicrodeposits } from '../../../../../actions/billingActions';

import BillingAccountTile from './BillingAccountTile';

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    _setStripeDefaultPaymentMethod: setStripeDefaultPaymentMethod,
    _verifyStripeMicrodeposits: verifyStripeMicrodeposits,
  }, dispatch);
}

export default connect(null, mapDispatchToProps)(BillingAccountTile);
