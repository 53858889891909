const defaultState = {
  collectSerialNumber: 'hidden',
  loading: true,
};

const preferencesReducer = (state = defaultState, action) => {
  switch (action.type) {
    case 'GET_SHOP_PREFERENCES_PROCESSING':
      return {
        ...state,
        loading: true,
      };
    case 'SET_SHOP_PREFERENCES_RES':
    case 'GET_SHOP_PREFERENCES_RES':
      return {
        ...state,
        collectSerialNumber: action.payload.preferences.collectSerialNumber || 'hidden',
        loading: false,
      };
    default:
      return state;
  }
};

export default preferencesReducer;
