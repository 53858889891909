import React, { useCallback, useMemo, useState } from 'react';
import { Collapse } from 'react-collapse';
import Img from 'react-image';
import { ClydeCheckbox } from '@joinclyde/clyde-react-components';

import ProductSwitch from './ProductSwitch';
import ProductVariant from './ProductVariant';
import PlatformGate from '../../Utils/PlatformGate';

const PlatformListViewTile = (props) => {
  const {
    product,
    roundTotal,
    margin,
    displayName,
    shopBranded,
    _startRecordSale,
    _editProduct,
    selectRegistrable,
    _createRegistrableProduct,
    _deleteRegistrableProduct,
    onlyIncludeRegistrableProducts,
  } = props;

  const [showVariants, setShowVariants] = useState(false);

  const toggleExpandVariants = () => {
    setShowVariants(!showVariants);
  };

  const startRecordSale = useCallback(() => {
    _startRecordSale(product);
  }, [_startRecordSale, product]);

  let status = product.Reviewed ? 'matched' : 'pending';

  if (status === 'matched') {
    const noVariantContracts = !product.Variants.some((variant) => variant.Contracts?.length > 0);
    if (noVariantContracts) {
      status = 'no-match';
    }
  }

  const activeStatus = useMemo(() => {
    const activeVariants = product.Variants.filter(({ Active }) => (Active === true));
    if (activeVariants.length === 0) {
      return 'off';
    }
    if (activeVariants.length === product.Variants.length) {
      return 'on';
    }
    return 'partial';
  }, [product.Variants]);

  const toggleRegistrable = (productId, currState) => {
    const productIds = [];
    // if the product level checkbox is clicked
    if (!productId) {
      for (const variant of product.Variants) {
        if (variant.RegistrableProduct === product.RegistrableProduct) {
          productIds.push(variant.ProductID);
        }
      }
      if (product.RegistrableProduct) {
        _deleteRegistrableProduct(productIds);
      } else {
        _createRegistrableProduct(productIds);
      }
    } else {
      // for variant level changes
      productIds.push(productId);
      if (currState) {
        _deleteRegistrableProduct(productIds);
      } else {
        _createRegistrableProduct(productIds);
      }
    }
  };

  const editProduct = () => {
    _editProduct(product);
  };

  return (
    <div className='col-12'>
      <div className='row product-tile'>
        <div className='col-5 product-tile__name-section'>
          { selectRegistrable
            ? <ClydeCheckbox
                backgroundColor='clydeBlack'
                className='product-view__registrable-products-checkbox'
                checked={ product.RegistrableProduct || !onlyIncludeRegistrableProducts }
                onChange={ () => toggleRegistrable() }
                disabled={ !onlyIncludeRegistrableProducts }
              />
            : <img onClick={ editProduct } alt='edit product' className='name-section__edit' src='/images/edit.svg'/>
          }
          <Img
            alt='product'
            className='name-section__thumb'
            src={ [product.ImageLink, '/images/default-image.svg'] }
          />
          <div className='name-section__name'>
            { product.Name }
          </div>
        </div>
        <div className='col-2 product-tile__sku-section' data-test='skuNumberCard'>
          <PlatformGate isApiShop>
            SKU: { product.Variants[0]?.VariantSKU }
          </PlatformGate>
        </div>
        <div className='col-lg-3 col-2 product-tile__record-sale-section'>
          { status === 'matched'
            && <div onClick={ startRecordSale } className='record-sale-button'>Record Sale</div>
          }
        </div>
        <div className='col-lg-2 col-md-4 col-5 product-tile__expand-section'>
          <div className='expand-section__container' onClick={ toggleExpandVariants }>
            <img alt=''
              id={ `product-variants-toggle-${product.Index}` }
              className={ `product-tile__variants-caret product-tile__variants-caret--${showVariants ? 'down' : 'right'}` }
              src='/images/caret.svg'/>
          </div>
          <PlatformGate isPlatformShop>
            <ProductSwitch
              productIds={ product.Variants.map((variant) => variant.ProductID) }
              active={ activeStatus }
              reviewed={ product.Reviewed }
              noMatch={ status === 'no-match' }
              index={ product.Index } />
          </PlatformGate>
        </div>
        <Collapse springConfig={ { stiffness: 300, damping: 30 } } hasNestedCollapse className='variants-container col-12' isOpened={ showVariants }>
          <div className='row'><div className='col-12 list-view-variants'>
          { product.Variants.map((variant, index) => (
                <ProductVariant
                  key={ variant.ProductID }
                  productIndex={ product.Index }
                  variantIndex={ index }
                  roundTotal={ roundTotal }
                  margin={ margin }
                  variant={ variant }
                  status={ status }
                  shopName={ displayName }
                  branded={ shopBranded }
                  selectRegistrable={ selectRegistrable }
                  toggleRegistrable={ toggleRegistrable }
                  onlyIncludeRegistrableProducts={ onlyIncludeRegistrableProducts }
                />
          ))
          }
          </div></div>
        </Collapse>
      </div>
    </div>
  );
};

export default PlatformListViewTile;
