import { wsSend } from './websocketActions';

export const runSearch = (shopId, search, pageNumber) => (dispatch) => {
  dispatch({
    type: 'SEARCH_SALES_PROCESSING',
    payload: { pageNumber },
  });
  dispatch(wsSend({
    type: 'SEARCH_SALES_REQ',
    payload: { shopId, search, pageNumber },
  }));
};

export const setSearch = (search) => ({
  type: 'SET_SALE_SEARCH',
  payload: search,
});

export const setPage = (pageNumber) => ({
  type: 'SET_SALES_PAGE_NUMBER',
  payload: { pageNumber },
});

export const showInvoiceExportModal = () => ({
  type: 'SHOW_INVOICE_EXPORT_MODAL',
  payload: {},
});

export const showImportSalesModal = () => ({
  type: 'SHOW_IMPORT_SALES_MODAL',
  payload: {},
});

export const closeUploadSalesModal = (importFileKey) => (dispatch) => {
  if (importFileKey && importFileKey !== '') {
    dispatch(wsSend({
      type: 'CANCEL_SALES_IMPORT',
      payload: { importFileKey },
    }));
  }
  dispatch({
    type: 'CLOSE_IMPORT_SALES_MODAL',
    payload: {},
  });
};

export const getSalesImportSummary = (salesData) => (dispatch) => {
  dispatch(wsSend({
    type: 'IMPORT_SALES_SUMMARY_REQ',
    payload: { salesData },
  }));
  dispatch({
    type: 'IMPORT_SALES_PROCESSING',
    payload: {},
  });
};

export const importSales = (runUpdate, importFileKey, rowCount) => (dispatch) => {
  dispatch(wsSend({
    type: 'IMPORT_SALES_REQ',
    payload: { runUpdate, importFileKey, rowCount },
  }));
  dispatch({
    type: 'IMPORT_SALES_PROCESSING',
    payload: {},
  });
};

export const setInvoiceRange = (range) => ({
  type: 'SET_INVOICE_RANGE',
  payload: range,
});

export const submitInvoiceExport = (range) => (dispatch) => {
  dispatch({
    type: 'INVOICE_EXPORT_PROCESSING',
    payload: {},
  });
  dispatch(wsSend({
    type: 'GET_INVOICE_EXPORT_REQ',
    payload: range,
  }));
};

export const cancelInvoiceExport = () => ({
  type: 'CANCEL_INVOICE_EXPORT',
  payload: {},
});

export const setInvoiceDownloadFalse = () => ({
  type: 'SET_INVOICE_DOWNLOAD_FALSE',
  payload: {},
});

export const showConfirmResendEmail = (saleId) => ({
  type: 'SHOW_CONFIRM_RESEND_EMAIL',
  payload: { saleId },
});

export const getOrderInfo = (saleId) => (dispatch) => {
  dispatch({
    type: 'RESEND_EMAIL_INFO_PROCESSING',
    payload: {},
  });
  dispatch(wsSend({
    type: 'RESEND_EMAIL_INFO_REQ',
    payload: { saleId },
  }));
};

export const confirmResendInfo = (saleId, contact, contactType) => (dispatch) => {
  dispatch({
    type: 'RESEND_EMAIL_SUBMIT_PROCESSING',
    payload: {},
  });
  dispatch(wsSend({
    type: 'RESEND_EMAIL_SUBMIT_REQ',
    payload: { saleId, contact, contactType },
  }));
};

export const cancelResend = () => ({
  type: 'CANCEL_RESEND_EMAIL',
  payload: {},
});

export const exitResend = () => ({
  type: 'EXIT_RESEND_EMAIL',
  payload: {},
});

export default {};
