import React from 'react';
import { connect } from 'react-redux';
import Transition from 'react-transition-group/Transition';

const LaunchProcessing = (props) => {
  const { platform, launchProcessing } = props;
  const duration = 500;
  const defaultStyle = {
    transition: 'opacity 1200ms ease',
  };
  const transitionStyles = {
    entering: { opacity: 1, display: 'block' },
    entered: { opacity: 1, display: 'block' },
    exiting: { opacity: 1, display: 'block' },
    exited: { opacity: 0, display: 'none' },
  };
  const launchingText = {
    entering: 'Contracts updating',
    entered: 'Contracts updating',
    exiting: 'Contracts updated!',
    exited: 'Contracts updating',
  };
  const on = launchProcessing;
  return (
    <div className='launch-processing-container'>
      { (platform === 'Shopify' || platform === 'BigCommerce')
        && <Transition in={ on } timeout={ duration }>
          { (state) => (
            <h5 className='launch-processing-animation' style={ { ...defaultStyle, ...transitionStyles[state] } }>
              { launchingText[state] }
              { state !== 'exiting'
                && <span className='launching-dots-container'>
                  <span className='launching-dots-container__dot'>.</span>
                  <span className='launching-dots-container__dot'>.</span>
                  <span className='launching-dots-container__dot'>.</span>
                </span>
              }
            </h5>
          ) }
        </Transition>
      }
    </div>
  );
};

const mapStateToProps = (state) => ({
  platform: state.auth.platform,
  launchProcessing: state.products.launchProcessing,
});

export default connect(mapStateToProps)(LaunchProcessing);
