import React from 'react';
import { ClydeTextInput } from '@joinclyde/clyde-react-components';

import countriesAndProvincesList from '../../../../lib/countriesAndProvinces';

const ContactForm = (props) => {
  const { contactInfo, _handleTextChange } = props;

  const labelMap = {
    labels: {
      address1: 'Address 1',
      address2: 'Address 2',
      country: 'Country',
      city: 'City',
      email: 'Email',
      firstName: 'First Name',
      lastName: 'Last Name',
      phoneNumber: 'Phone',
      province: 'State/Province',
      zip: 'Zip',
    },
    fields: Object.keys(contactInfo),
  };

  const supportedCountriesList = Object.keys(countriesAndProvincesList);

  const inputSwitcher = (field) => {
    switch (field) {
      case 'province':
        return (
          <select
            id={ `${field}` }
            className='w-input'
            defaultValue={ contactInfo[field] || '' }
            name={ field }
            onChange={ _handleTextChange }>
            {
              countriesAndProvincesList[contactInfo.country || 'US'].map((stateObj) => (
                <option key={ stateObj.value } value={ stateObj.value }>{ stateObj.name }</option>
              ))
            }
          </select>
        );
      case 'country':
        return (
          <select
            id={ `${field}` }
            className='w-input'
            defaultValue={ contactInfo[field] || 'US' }
            name={ field }
            onChange={ _handleTextChange }>
            {
              supportedCountriesList.map((country, countryIdx) => (
                <option
                key={ countryIdx }
                value={ country }
                >
                  { country }
                </option>
              ))
            }
          </select>
        );
      default:
        return (
          <ClydeTextInput
            id={ `${field}` }
            className='w-input'
            onChange={ _handleTextChange }
            name={ field }
            value={ contactInfo[field] || '' }
          />
        );
    }
  };

  return (
    <div className='cop-change-order-customer__contact-form'>
      <div className='row'>

        {
          labelMap.fields.map((field) => (
            <div className='col-6' key={ field }>
              <label htmlFor={ field }>{ labelMap.labels[field] || field }</label>
                { inputSwitcher(field) }
            </div>
          ))
        }

      </div>
    </div>
  );
};

export default ContactForm;
