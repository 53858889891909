import React from 'react';
import { ClydeTextInput } from '@joinclyde/clyde-react-components';

const FriggInputs = (props) => {
  const {
    integrationData,
    jsonSchema,
    uiSchema,
    _handleTextChange,
    _setFormDirty,
    _formDirty,
    errorMap,
  } = props;

  const inputs = Object.keys(jsonSchema.properties);
  return (
    <div className='integrations-setup-modal-form__inputs'>
      {
        inputs.map((inputId) => {
          const inputProperties = jsonSchema.properties[inputId];
          const uiProperties = uiSchema[inputId];
          const inputClasses = errorMap[inputId] && _formDirty ? 'integrations-setup-modal-form__inputs__input-group-error' : '';
          return (
            <div className='integrations-setup-modal-form__inputs__input-group' key={ inputId }>
              <label className={ inputClasses } htmlFor={ inputId }>{ inputProperties.title }</label>
              <ClydeTextInput
                className={ inputClasses }
                id={ inputId }
                name={ inputId }
                onChange={ _handleTextChange }
                onSubmit={ _setFormDirty }
                placeholder={ uiProperties['ui:placeholder'] }
                value={ integrationData[inputId] || '' }/>
            </div>
          );
        })
      }
    </div>
  );
};

export default FriggInputs;
