import React, { Component } from 'react';

class Connect extends Component {
  state = {
    shopName: '',
  }

  componentDidMount() {
    this.shopInput.focus();
  }

  shopNameSubmit = (e) => {
    e.preventDefault();
    this.props._getShopifyShopOAuthLink(this.state);
  }

  shopNameChange = (e) => {
    this.setState({ shopName: e.target.value });
  }

  render() {
    return (
      <div className='gradient'>
        <div className='page-container'>
          <div className='login-layout'>
            <div className='row'>
              <div className='login-tile col-8 offset-2 col-lg-6 offset-lg-3'>
                <img alt='' src='/images/Clyde_Logo_Wordmark.svg'/>
                <div className='login-tile__tagline'>Own the post-purchase experience</div>
                <form onSubmit={ this.shopNameSubmit } >
                  <div className='shopify-login-form row'>
                    <input
                      spellCheck='false'
                      id='enter-shop'
                      placeholder='example.myshopify.com'
                      className='col-12 shopify-login-form__input'
                      type='text'
                      onChange={ this.shopNameChange }
                      ref={ (input) => { this.shopInput = input; } }
                      value={ this.state.shopName } />
                    <button type='submit' className='col-lg-12 shopify-login-form__button'>Install</button>
                  </div>
                </form>
              </div>
            </div>
            <div className='row'>
              <div className='col-10 offset-1 col-lg-6 offset-lg-3 col-md-8 offset-md-2 shopify-permissions'>
                <div className='shopify-permissions__header col-12'>Authorize Clyde to:</div>
                <div className='permission-name col-12'>Manage products</div>
                <div className='col-12'>Clyde adds and updates contracts dynamically and respects your store’s internal structure. We don’t mess with collections or make your product list messy.</div>
                <div className='col-12 shopify-permissions__separator'></div>
                <div className='permission-name col-12'>View orders</div>
                <div className='col-12'>By viewing orders we can track whether or not one of our insurance partners contracts have sold. We only collect information on orders with our contracts in them.</div>
                <div className='col-12 shopify-permissions__separator'></div>
                <div className='permission-name col-12'>View customers</div>
                <div className='col-12'>When a customer makes a purchase with a contract we’ll send them their terms and conditions. We also need this information to report claims to your dashboard.</div>
                <div className='col-12 shopify-permissions__separator'></div>
                <div className='permission-name col-12'>Manage your online store</div>
                <div className='col-12'>This allows us to place your customized call-to-action on your page without you needing to add custom code!</div>
                <div className='col-12 shopify-permissions__separator'></div>
                <div className='permission-name col-12'>Manage orders</div>
                <div className='col-12'>Infrequently, we’ll need to issue a refund to a customer if they cancel their contract. They get refunded a pro-rated amount based on their home state and the underwriter. </div>
                <div className='col-12 shopify-permissions__separator'></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Connect;
