import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Transition from 'react-transition-group/Transition';

import { toggleOnlyIncludeRegistrableProducts } from '../../../actions/customizeActions';

const RegistrableProductSwitch = (props) => {
  const {
    onlyIncludeRegistrableProducts,
    _toggleOnlyIncludeRegistrableProducts,
    title,
  } = props;

  const handleToggle = () => _toggleOnlyIncludeRegistrableProducts(!onlyIncludeRegistrableProducts);

  const duration = 200;
  const defaultStyle = {
    transition: 'left 200ms ease-in-out',
  };
  const transitionStyles = {
    entering: { left: '33px' },
    entered: { left: '33px' },
    exiting: { left: '2px' },
    exited: { left: '2px' },
  };

  return (
    <div className='registrable-product-switch'>
      <div className='registrable-product-switch-title'>{ title }</div>
      <Transition in={ props.on } timeout={ duration }>
        { (state) => (
          <div onClick={ handleToggle } className={ props.on ? 'toggle-onlyRegistrable toggle-onlyRegistrable--on' : 'toggle-onlyRegistrable toggle-onlyRegistrable--off' }>
            <div className='toggle-onlyRegistrable__dot' style={ { ...defaultStyle, ...transitionStyles[state] } }></div>
          </div>
        ) }
      </Transition>
    </div>
  );
};
const mapDispatchToProps = (dispatch) => bindActionCreators({
  _toggleOnlyIncludeRegistrableProducts: toggleOnlyIncludeRegistrableProducts,
}, dispatch);

export default connect(null, mapDispatchToProps)(RegistrableProductSwitch);
