import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import { IntlProvider } from 'react-intl';

import store from './store';
import App from './App';
import ErrorProvider from './providers/ErrorProvider';

ReactDOM.render(
  <IntlProvider locale={ navigator.language } messages={ {} }>
    <Provider store={ store }>
      <ErrorProvider>
        <Router>
          <App/>
        </Router>
      </ErrorProvider>
    </Provider>
  </IntlProvider>,
  document.getElementById('root'),
);
