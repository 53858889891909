import React, { useEffect, useState } from 'react';
import Pagination from 'react-js-pagination';
import {
  ClydeSelect,
  ClydeTextInput,
  LoadingDots,
  ClydeTooltip,
} from '@joinclyde/clyde-react-components';
import { Helmet } from 'react-helmet';

import ClaimsExportModal from './ClaimsExportModal';
import { claimStatusSwitcher } from '../../Utils/claimHelper';
import {
  filters,
  sortOptions,
  searchOptions,
  warrantyTypes,
} from './constants';
import '../../../sass/_modules/claimsList.scss';

const ClaimsList = (props) => {
  const {
    claims,
    claimsCount,
    pageNumber,
    selectedFilter,
    selectedSort,
    searchType,
    searchValue,
    history,
    fetchPending,
    selectedWarrantyFilter,
    manageOEMWarranty,
    _getShopClaims,
    _setClaimPageNumber,
    _setClaimsFilter,
    _setClaimsSort,
    _setSearchType,
    _setSearchValue,
    _setWarrantyFilter,
    _getShopSettings,
  } = props;

  const [showExportModal, setShowExportModal] = useState(false);
  const [exportAllClaims, setExportAllClaims] = useState(false);

  const fetchOptions = {
    pageNumber,
    searchType,
    searchValue,
    filter: selectedFilter,
    sort: selectedSort,
  };

  useEffect(() => {
    _getShopSettings();
  }, []);

  useEffect(() => {
    _getShopSettings();
  }, []);

  useEffect(() => {
    const usefetchOptions = {
      pageNumber,
      searchType,
      searchValue,
      filter: selectedFilter,
      sort: selectedSort,
      warrantyFilter: selectedWarrantyFilter,
    };
    _getShopClaims(usefetchOptions);
  }, [
    _getShopClaims,
    pageNumber,
    searchType,
    searchValue,
    selectedFilter,
    selectedSort,
    selectedWarrantyFilter,
    manageOEMWarranty,
  ]);

  const showAllClaimsExportModal = () => {
    setShowExportModal(true);
    setExportAllClaims(true);
  };

  const cancelClaimsExport = () => {
    setShowExportModal(false);
    setExportAllClaims(false);
  };

  const searchLabelItem = searchOptions.find((type) => type.value === searchType);
  const searchLabel = searchLabelItem ? searchLabelItem.text : 'Email';

  return (
    <div className='page-container'>
      <Helmet>
        <title>Clyde | All Claims</title>
      </Helmet>

      <div className='page-layout'>
        <div className='actions-container'>
          <ClydeTooltip
            id='export-claims'
            position='right'
            label={
              <div className='actions-container__tooltip__text'>Download all claims data</div>
            }
            hideIndicator >
            <div
              onClick={ () => showAllClaimsExportModal() }
              data-tip
              data-for='export-invoice'
              className='actions-container__button'>
              <img
                className='actions-container__button__image actions-container__button__image--export'
                alt='Show claims export modal'
                src='/images/Claims.svg' />
            </div>
          </ClydeTooltip>
          { manageOEMWarranty && (
          <ClydeTooltip
            id='export-claims'
            position='right'
            label={
              <div className='actions-container__tooltip__text'>Download limited warranty claims data</div>
            }
            hideIndicator >
            <div
              onClick={ () => setShowExportModal(true) }
              data-tip
              data-for='export-invoice'
              className='actions-container__button'>
              <img
                className='actions-container__button__image actions-container__button__image--export'
                alt='Export all claims'
                src='/images/download.svg' />
            </div>
          </ClydeTooltip>
          ) }
        </div>
    <div className='claims-summary'>
      <h1>Claims</h1>
      <section className='claims-summary__table-info'>
        <div className='claims-summary__table-info__scopes'>
          <div className='claims-summary__scopes__search'>
            <h4>Search by</h4>
            <ClydeSelect
              className='claims-summary__search__dropdown'
              name='claimsSearch'
              defaultLabel={ 'Email' }
              onChange={ (e) => {
                const searchBy = e.target.value || 'email';
                _setSearchType(searchBy);
              } }
              options={ searchOptions }
            />
          </div>
          <div className='claims-summary__scopes__search'>
            <h4>{ searchLabel }</h4>
            <ClydeTextInput
              className='claims-summary__scopes__search__input'
              name='claimsSearchValue'
              onChange={ (e) => _setSearchValue(e.target.value) }
              value={ searchValue }
              onSubmit={ () => _setClaimPageNumber(1, fetchOptions) }
            />
          </div>
          <div className='claims-summary__scopes__filter'>
            <h4>Filter</h4>
            <ClydeSelect
              className='claims-summary__filter__dropdown'
              name='claimsFilters'
              defaultValue={ selectedFilter }
              defaultLabel='None'
              onChange={ (e) => {
                const filterValue = e.target.value || 'none';
                _setClaimsFilter(filterValue, fetchOptions);
              } }
              options={ filters }
            />
          </div>
          {
            manageOEMWarranty && (
            <div className='claims-summary__scopes__filter'>
              <h4>Coverage Type</h4>
              <ClydeSelect
                className='claims-summary__filter__dropdown'
                name='warrantyFilters'
                defaultValue={ selectedWarrantyFilter }
                defaultLabel='All'
                onChange={ (e) => _setWarrantyFilter(e.target.value, fetchOptions) }
                options={ warrantyTypes }
              />
            </div>
            ) }
          <div className='claims-summary__scopes__sort'>
            <h4>Sort</h4>
            <ClydeSelect
              className='claims-summary__sort__dropdown'
              name='claimsSort'
              defaultValue={ selectedSort }
              defaultLabel={ 'Requires Action' }
              onChange={ (e) => {
                const sortOptionValue = e.target.value || 'requiresAction';
                _setClaimsSort(sortOptionValue, fetchOptions);
              } }
              options={ sortOptions }
                />
              </div>
            </div>
            <Pagination
              itemClass='page-item'
              linkClass='page-link'
              activePage={ pageNumber }
              itemsCountPerPage={ 15 }
              totalItemsCount={ claimsCount }
              pageRangeDisplay={ 5 }
              onChange={ (page) => { _setClaimPageNumber(page, fetchOptions); } }
            />
          </section>
          <table className='claims-summary__table'>
            <thead>
              <tr>
                <th><span className='claim-summary__table__dot'/>Email</th>
                <th>Name</th>
                <th>Updated</th>
                { manageOEMWarranty && <th>Coverage Type</th> }
                <th>Claim Status</th>
                <th>Needs Resolution</th>
              </tr>
            </thead>
            <tbody className='claims-summary__table__body'>
              { claims.map((claim) => <tr
                  key={ claim.claimResolutionId || claim.claimId }
                  onClick={ () => history.push(`/claims/${claim.claimId}`) }
                  className={ `claims-summary__table__row--${claim.claimClass}` }
                >
                  <td><span className='claim-summary__table__dot'/>{ claim.email }</td>
                  <td>{ claim.firstName } { claim.lastName }</td>
                  <td>{ claim.lastUpdated }</td>
                  { manageOEMWarranty && <td>{ claim.warrantyType === 'limitedWarranty' ? 'Limited Warranty' : 'Extended Warranty' }</td> }
                  <td>{ claimStatusSwitcher(claim.resolutionState || claim.claimState) }</td>
                  <td>{ (claim.claimState === 'approved' || claim.claimState === 'repairInitiated') && !claim.claimResolutionId ? 'Yes' : 'No' }</td>
                </tr>) }
              { !claims.length && !fetchPending
                && <tr className='claims-summary__table__row--empty'>
                  <td>No Claims in the Last 60 Days</td>
                </tr>
              }
            </tbody>
          </table>
          { fetchPending && <LoadingDots dotColor='clydeBlack' dotSize='large' /> }
          { showExportModal && <ClaimsExportModal
            allClaims={ exportAllClaims }
            _cancelClaimsExport={ () => cancelClaimsExport(false) } /> }
        </div>
      </div>
    </div>
  );
};

export default ClaimsList;
