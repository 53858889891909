import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import classnames from 'classnames';
import { ClydeTooltip } from '@joinclyde/clyde-react-components';
import UserTypeSwitch from './UserTypeSwitch';
import {
  resetUserPassword,
  toggleUserDisabled,
  updateUserAccountType,
} from '../../../../actions/userManagementActions';

import { formatDate } from '../../../../lib/timeUtils';

class UserManagementUserItem extends Component {
  state = { passwordReset: false };

  handlePasswordReset = () => {
    if (!this.state.passwordReset) {
      this.setState({ passwordReset: true });
      this.props._resetUserPassword(this.props.user.id);
      setTimeout(() => this.setState({ passwordReset: false }), 3000);
    }
  }

  handleToggleUserDisabled = (id, enabled, isOAuth) => {
    if (isOAuth) {
      return;
    }
    this.props._toggleUserDisabled(id, enabled);
  }

  render() {
    const {
      accountType, disabled, email, id, lastLogin, password, link, isOAuth,
    } = this.props.user;
    const lastLoginTime = lastLogin ? formatDate(lastLogin) : 'Never';
    const resetPwClassnames = classnames('button', {
      'user-management__reset_pw_button--reset': this.state.passwordReset,
      'user-management__reset_pw_button--normal': !this.state.passwordReset,
      'user-management__reset_pw_button--disabled': isOAuth,
    });
    return (
      this.props.userId === id
        ? null
        : <div className='user-management__user-item row'>
          <div className='col-12 col-lg-3 user-management__user-item-content user-management__user-item-content--with-pw'>
            <h5 className='user-management__user-item-label'>Email</h5>
            <span className='user-management__email'>{ email }</span>
            { Boolean(password)
              && <p className='user-management__password'>Temp password: { password }(sent in email)<br /><a href={ link }>{ link }</a></p>
            }
          </div>
          <div className='col-12 col-lg-2 user-management__user-item-content'>
            <h5 className='user-management__user-item-label'>Last Login</h5>
            <span className='user-management__last-login-time'>{ lastLoginTime }</span>
          </div>
          <div className='col-12 col-md-6 col-lg-2 user-management__user-item-content'>
            <h5 className='user-management__user-item-label'>Type</h5>
            <select
              type='text'
              name='accountType'
              className='user-management__account-type-select'
              onChange={ (e) => this.props._updateUserAccountType(id, e.target.value) }
              value={ accountType }>
              <option value='admin' >Admin</option>
              <option value='basic'>User</option>
            </select>
          </div>
          <div className='col-12 col-lg-1 user-management__user-item-content'>
            <h5 className='user-management__user-item-label'>Login</h5>
            <span className='user-management__last-login-time'>{
              isOAuth ? 'OAuth' : 'Password'
            }</span>
          </div>
          <div className='col-12 col-lg-2 user-management__user-item-content'>
            <h5 className='user-management__user-item-label'>Status</h5>
            <UserTypeSwitch
              disabled={ isOAuth }
              toggleFunc={ () => this.handleToggleUserDisabled(id, !disabled, isOAuth) }
              truthValue={ disabled }
              trueLabel='Disabled'
              falseLabel='Enabled' />
          </div>
          <div className='col-10 col-lg-2 user-management__reset-pw-section'>
            <ClydeTooltip
              label={
                <div className='tooltip-text'>
                  We'll send this user an email with a new password.
                  They will be able to update their password.
                </div>
              }
              hideIndicator >
              <button
                data-tip data-for='user-reset-password-button'
                className={ resetPwClassnames }
                onClick={ this.handlePasswordReset }
                disabled={ isOAuth }>
                { this.state.passwordReset ? 'Success!' : 'Reset Password' }
              </button>
            </ClydeTooltip>
          </div>
        </div>
    );
  }
}

const mapStateToProps = (state) => ({ userId: state.auth.userId });

const mapDispatchToProps = (dispatch) => bindActionCreators({
  _resetUserPassword: resetUserPassword,
  _toggleUserDisabled: toggleUserDisabled,
  _updateUserAccountType: updateUserAccountType,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(UserManagementUserItem);
