import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import {
  getOrderDetails,
  getReceiptFile,
  resetReceiptFile,
  saveLineItemSerialNumber,
  backToOrderSearch,
  updateDeliveryDate,
  showResendSaleEmail,
  showResendPpEmail,
  showBulkContractSale,
  showChangeOrderCustomer,
} from '../../../actions/customersActions';

import { getShopSettings } from '../../../actions/autopilotActions';

import ProductsView from './ProductsView';

const mapStateToProps = (state) => ({
  customers: state.customers,
  shopSettings: state.autopilot,
  currency: state.billing.currency,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  _getOrderDetails: getOrderDetails,
  _getReceiptFile: getReceiptFile,
  _resetReceiptFile: resetReceiptFile,
  _saveLineItemSerialNumber: saveLineItemSerialNumber,
  _backToOrderSearch: backToOrderSearch,
  _updateDeliveryDate: updateDeliveryDate,
  _showResendSaleEmail: showResendSaleEmail,
  _showResendPpEmail: showResendPpEmail,
  _showBulkContractSale: showBulkContractSale,
  _showChangeOrderCustomer: showChangeOrderCustomer,
  _getShopSettings: getShopSettings,
}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ProductsView);
