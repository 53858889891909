const defaultState = {
  step: 'customers',
  modal: null,
  searchType: 'email',
  selectedCustomer: {
    contact: '',
    dbContactType: '',
    hasHiClydeAccount: false,
    lastPurchase: '',
    orderIdsToHightlight: [],
  },
  selectedOrder: {
    address: {},
    clydeOrderId: '',
    activeContracts: [],
    expiredContracts: [],
    fulfilledContracts: [],
    cancelledContracts: [],
    eligibleProducts: [],
    ineligibleProducts: [],
    activeContractCount: 0,
    expiredContractCount: 0,
    fulfilledContractCount: 0,
    cancelledContractCount: 0,
    eligibleProductCount: 0,
    ineligibleProductCount: 0,
    fullName: '',
    orderClaims: {},
    orderDate: '',
    orderId: '',
    merchantOrderId: '',
    productNames: [],
    saleIds: [],
    transferHistory: [],
  },
  ordersOverview: {
    customerName: '',
    totalActiveContractCount: 0,
    totalEligibleProductCount: 0,
    totalExpiredContractCount: 0,
  },
  claimsOverView: {
    claimsOpen: 0,
    claimsResolved: 0,
    claimsDenied: 0,
  },
  customers: [],
  orders: [],
  productRegistrations: [],
  isProcessing: false,
  actionsProcessing: {
    updateDeliveryDate: false,
    saveNewContractSaleSerialNumber: false,
  },
  resendSaleEmail: {
    contact: '',
    contactType: '',
    processing: false,
    show: false,
    step: 'confirm',
    validContact: false,
  },
  resendPpEmail: {
    contact: '',
    processing: false,
    show: false,
    step: 'confirm',
    validContact: false,
  },
  displayClaimHistory: {
    claimHistory: [],
    processing: false,
    submitProcessing: false,
    sale: {},
  },
  bulkContractSale: {
    actionComplete: false,
    cart: {},
    productContracts: [],
    processing: false,
    submitProcessing: false,
    termsOnOrder: [],
  },
  fileClaim: {
    actionComplete: false,
    claimData: {
      claimType: '',
      claimIssueTypes: [],
      customerEmail: '',
      failureDate: '',
      narrative: '',
      stillFunctional: null,
      supportEmail: '',
      supportName: '',
      usedAsIntended: null,
    },
    claimId: '',
    customerInfo: {},
    processing: false,
    productParts: [],
    remainingLimitOfLiability: 0,
    sale: {},
    savedClaim: {
      claimState: '',
    },
    step: 'claim', // claim, resolution
    submitProcessing: false,
    referralEmailProcessing: false,
  },
  cancelContract: {
    processing: false,
    purchaseSource: '',
    saleId: '',
    step: 'confirm',
  },
  updateSaleOEM: {
    processing: false,
    saleId: '',
    step: 'confirm',
    oemWarrantyLength: 0,
  },
  shopClaimSettings: {
    autoAdjudicate: false,
    retailerResolutions: false,
    repairEnabled: false,
    replaceEnabled: false,
    promoCodeEnabled: false,
    payoutEnabled: false,
  },
  changeOrderCustomer: {
    processing: false,
    finished: false,
  },
  canSerialNumberSearch: false,
  receipt: {
    processing: false,
    url: null,
  },
};

const customersReducer = (state = defaultState, action) => {
  let newState;
  switch (action.type) {
    // Change Customers flow step
    case 'BACK_TO_CUSTOMER_SEARCH':
      return defaultState;
    case 'BACK_TO_ORDER_SEARCH':
      return {
        ...state,
        step: 'orders',
        isProcessing: false,
        selectedOrder: defaultState.selectedOrder,
        receipt: defaultState.receipt,
      };
    // Customer Search listeners
    case 'COP_CAN_SERIAL_NUMBER_SEARCH_RES':
      return {
        ...state,
        canSerialNumberSearch: action.payload,
      };
    case 'LOAD_INITIAL_CUSTOMERS_RES':
      return {
        ...state,
        customers: action.payload,
        isProcessing: false,
      };
    case 'SEARCH_CUSTOMERS_RES':
      return {
        ...state,
        customers: action.payload,
        isProcessing: false,
      };
    case 'SET_SEARCH_TYPE':
      return {
        ...state,
        searchType: action.payload,
      };
    case 'SELECT_CUSTOMER':
      return {
        ...state,
        selectedCustomer: {
          orderIdsToHightlight: action.payload.externalCartIds || [],
          contact: action.payload.contact,
          customerId: action.payload.customerId,
          dbContactType: action.payload.dbContactType,
          phone: action.payload.phone,
          lastPurchase: action.payload.lastPurchase || '',
        },
        step: 'orders',
      };
    // Order Search listeners
    case 'GET_CUSTOMER_ORDERS_RES':
      return {
        ...state,
        orders: action.payload.orders,
        productRegistrations: action.payload.productRegistrations,
        ordersOverview: action.payload.ordersOverview,
        claimsOverView: action.payload.claimsOverView,
        selectedCustomer: {
          ...state.selectedCustomer,
          ...action.payload.customerAccountStatus,
        },
        isProcessing: false,
      };
    case 'SELECT_ORDER':
      return {
        ...state,
        selectedOrder: {
          ...state.selectedOrder,
          ...action.payload,
        },
        step: 'products',
        receipt: defaultState.receipt,
      };
    case 'SELECT_REGISTRATION':
      return {
        ...state,
        selectedOrder: {
          ...state.selectedOrder,
          ...action.payload,
        },
        step: 'registrations',
        receipt: defaultState.receipt,
      };
    // Product View listeners
    case 'GET_ORDER_DETAILS_RES':
      return {
        ...state,
        selectedOrder: {
          ...state.selectedOrder,
          ...action.payload,
        },
        isProcessing: false,
      };
    // Customers processing listeners
    case 'CUSTOMERS_PROCESSING':
      return {
        ...state,
        isProcessing: true,
      };
    // Misc
    case 'LOGOUT':
      return {
        ...state,
        ...defaultState,
      };
    // Actions
    case 'PRODUCT_DELIVERY_DATE_PROCESSING':
      return {
        ...state,
        actionsProcessing: {
          ...state.actionsProcessing,
          updateDeliveryDate: true,
        },
      };
    case 'UPDATE_PRODUCT_DELIVERY_DATE_RES':
      return {
        ...state,
        selectedOrder: {
          ...state.selectedOrder,
          eligibleProducts: action.payload.eligibleProducts,
          ineligibleProducts: action.payload.ineligibleProducts,
          eligibleProductCount: action.payload.eligibleProductCount,
          ineligibleProductCount: action.payload.ineligibleProductCount,
        },
        actionsProcessing: {
          ...state.actionsProcessing,
          updateDeliveryDate: false,
        },
      };
    // Resend sale email actions
    case 'RESEND_SALE_EMAIL_SUBMIT_PROCESSING':
      return {
        ...state,
        resendSaleEmail: {
          ...state.resendSaleEmail,
          processing: true,
        },
      };
    case 'RESEND_SALE_EMAIL_SUBMIT_RES':
      return {
        ...state,
        resendSaleEmail: {
          ...state.resendSaleEmail,
          processing: false,
          step: 'finish',
        },
      };
    case 'SHOW_RESEND_SALE_EMAIL':
      return {
        ...state,
        modal: 'resendSaleEmail',
        resendSaleEmail: {
          ...state.resendSaleEmail,
          contact: state.selectedCustomer.contact,
          show: true,
        },
      };
    case 'CANCEL_RESEND_SALE_EMAIL':
      return {
        ...state,
        modal: null,
        resendSaleEmail: {
          contact: '',
          contactType: '',
          processing: false,
          show: false,
          step: 'confirm',
          validContact: false,
        },
      };
    case 'UPDATE_CONTACT_RESEND_SALE_EMAIL':
      return {
        ...state,
        resendSaleEmail: {
          ...state.resendSaleEmail,
          contact: action.payload.contact,
          validContact: action.payload.isValid,
          contactType: action.payload.contactType,
        },
      };
    // Send pp email actions
    case 'RESEND_PP_EMAIL_SUBMIT_PROCESSING':
      return {
        ...state,
        resendPpEmail: {
          ...state.resendPpEmail,
          processing: true,
        },
      };
    case 'RESEND_PP_EMAIL_SUBMIT_RES':
      return {
        ...state,
        resendPpEmail: {
          ...state.resendPpEmail,
          processing: false,
          step: 'finish',
        },
      };
    case 'SHOW_RESEND_PP_EMAIL':
      return {
        ...state,
        modal: 'resendPpEmail',
        resendPpEmail: {
          ...state.resendPpEmail,
          contact: state.selectedCustomer.contact,
          show: true,
        },
      };
    case 'CANCEL_RESEND_PP_EMAIL':
      return {
        ...state,
        modal: null,
        resendPpEmail: {
          contact: '',
          processing: false,
          show: false,
          step: 'confirm',
          validContact: false,
        },
      };
    // Update claim status actions
    case 'SHOW_DISPLAY_CLAIM_HISTORY':
      return {
        ...state,
        modal: 'displayClaimHistory',
        displayClaimHistory: {
          ...state.displayClaimHistory,
          data: action.payload.data,
          warrantyType: action.payload.warrantyType,
        },
      };
    case 'CANCEL_DISPLAY_CLAIM_HISTORY':
      return {
        ...state,
        modal: null,
        displayClaimHistory: {
          claimHistory: [],
          processing: false,
          submitProcessing: false,
          sale: {},
        },
      };
    case 'GET_ALL_CLAIMS_ON_SALE_PROCESSING':
      return {
        ...state,
        displayClaimHistory: {
          ...state.displayClaimHistory,
          processing: true,
        },
      };
    case 'GET_ALL_CLAIMS_ON_SALE_RES':
      return {
        ...state,
        displayClaimHistory: {
          ...state.displayClaimHistory,
          processing: false,
          claimHistory: action.payload,
        },
      };
    // File claim actions
    case 'SHOW_FILE_COP_CLAIM':
      const claimType = action.payload.type;
      const stateObject = {
        ...state,
        modal: 'fileClaim',
        fileClaim: {
          ...state.fileClaim,
          claimType,
          claimData: {
            ...state.fileClaim.claimData,
            customerEmail:
              state.selectedCustomer.dbContactType === 'email'
                ? state.selectedCustomer.contact
                : '',
          },
          savedClaim: {
            claimState: '',
          },
        },
      };

      if (claimType === 'extendedWarranty') {
        stateObject.fileClaim.sale = action.payload.info;
      } else {
        stateObject.fileClaim.lineItem = action.payload.info;
      }

      return stateObject;
    case 'CANCEL_FILE_COP_CLAIM':
      return {
        ...state,
        modal: null,
        fileClaim: defaultState.fileClaim,
      };
    case 'GET_COP_SALE_INFO_FOR_CLAIM_PROCESSING':
      return {
        ...state,
        fileClaim: {
          ...state.fileClaim,
          processing: true,
        },
      };
    case 'GET_COP_SALE_INFO_FOR_CLAIM_RES':
      return {
        ...state,
        fileClaim: {
          ...state.fileClaim,
          claimData: {
            ...state.fileClaim.claimData,
            supportEmail: action.payload.supportInfo.email,
            supportName: action.payload.supportInfo.firstName,
          },
          processing: false,
          productParts: action.payload.productParts,
          remainingLimitOfLiability: action.payload.remainingLimitOfLiability,
        },
        shopClaimSettings: action.payload.shopClaimSettings,
      };
    case 'UPDATE_FILE_COP_CLAIM_INPUT':
      return {
        ...state,
        fileClaim: {
          ...state.fileClaim,
          claimData: {
            ...state.fileClaim.claimData,
            [action.payload.name]: action.payload.value,
          },
        },
      };
    case 'SHOW_FILE_COP_CLAIM_RESOLUTION_FORM':
      return {
        ...state,
        fileClaim: {
          ...state.fileClaim,
          actionComplete: false,
          step: 'resolution',
        },
      };
    case 'FILE_COP_CLAIM_SUBMIT_PROCESSING':
      return {
        ...state,
        fileClaim: {
          ...state.fileClaim,
          submitProcessing: true,
        },
      };
    case 'FILE_COP_CLAIM_SUBMIT_RES': {
      const { selectedOrder } = state;

      if (
        Array.isArray(action.payload.claimsForLineItem)
        && action.payload.claimsForLineItem.length
      ) {
        // Find if line item is in eligible products or ineligible products
        const { lineItemId } = action.payload.claimsForLineItem[0];
        const isLineItemEligible = action.payload.claimsForLineItem[0].isEligible;

        const eligibleProductWithClaims = selectedOrder.eligibleProducts.map(
          (product) => {
            if (product.lineItemId === lineItemId) {
              product.claims = action.payload.claimsForLineItem;
            }
            return product;
          },
        );

        const ineligibleProductWithClaims = selectedOrder.ineligibleProducts.map((product) => {
          if (product.lineItemId === lineItemId) {
            product.claims = action.payload.claimsForLineItem;
          }
          return product;
        });

        return {
          ...state,
          fileClaim: {
            ...state.fileClaim,
            actionComplete: true,
            claimId: action.payload.dbClaim.claimId,
            savedClaim: {
              ...state.fileClaim.savedClaim,
              claimState: action.payload.dbClaim.claimState,
              denialReason: action.payload.dbClaim.denialReason,
            },
            submitProcessing: false,
          },
          selectedOrder: {
            ...state.selectedOrder,
            eligibleProducts: isLineItemEligible
              ? eligibleProductWithClaims
              : [...selectedOrder.eligibleProducts],
            ineligibleProducts: !isLineItemEligible
              ? ineligibleProductWithClaims
              : [...selectedOrder.ineligibleProducts],
          },
        };
      }

      const oldActiveContracts = [...selectedOrder.activeContracts];
      const updatedActiveContracts = oldActiveContracts.map((contract) => {
        const newContractSales = contract.contractSales.map((sale) => {
          if (sale.saleId === action.payload.dbClaim.saleId) {
            return {
              ...sale,
              claimId: action.payload.dbClaim.claimId,
              claimState: action.payload.dbClaim.claimState,
              claimDate: action.payload.dbClaim.createdAt,
            };
          }
          return sale;
        });
        return {
          ...contract,
          contractSales: newContractSales,
        };
      });

      return {
        ...state,
        fileClaim: {
          ...state.fileClaim,
          actionComplete: true,
          claimId: action.payload.dbClaim.claimId,
          savedClaim: {
            ...state.fileClaim.savedClaim,
            claimState: action.payload.dbClaim.claimState,
            denialReason: action.payload.dbClaim.denialReason,
          },
          submitProcessing: false,
        },
        selectedOrder: {
          ...state.selectedOrder,
          activeContracts: updatedActiveContracts,
        },
      };
    } // end FILE_COP_CLAIM_SUBMIT_RES
    case 'CLOSE_CLAIM_RESOLUTION_RES':
      return {
        ...state,
        fileClaim: {
          ...state.fileClaim,
          savedClaim: {
            ...state.fileClaim.savedClaim,
            claimState: action.payload.claimState,
          },
        },
      };
    // Bulk contract sale actions
    case 'SHOW_BULK_COP_CONTRACT_SALE':
      newState = {};
      for (const prod of state.selectedOrder.eligibleProducts) {
        newState[prod.lineItemId] = [];
      }
      return {
        ...state,
        modal: 'bulkContractSale',
        bulkContractSale: {
          ...state.bulkContractSale,
          cart: newState,
          processing: true,
        },
      };
    case 'CANCEL_BULK_COP_CONTRACT_SALE':
      return {
        ...state,
        modal: null,
        bulkContractSale: {
          ...defaultState.bulkContractSale,
        },
      };
    case 'GET_BULK_COP_CONTRACT_SALE_RES':
      return {
        ...state,
        bulkContractSale: {
          ...state.bulkContractSale,
          processing: false,
          productContracts: action.payload.productContracts,
          termsOnOrder: action.payload.termsOnOrder,
        },
      };
    case 'SELECT_BULK_COP_CONTRACT_SALE_CONTRACT':
      newState = state.bulkContractSale.cart[action.payload.lineItemId];
      newState[action.payload.idx] = action.payload.selectedContract;
      return {
        ...state,
        bulkContractSale: {
          ...state.bulkContractSale,
          cart: {
            ...state.bulkContractSale.cart,
            [action.payload.lineItemId]: newState,
          },
        },
      };
    case 'SELECT_ALL_BULK_COP_CONTRACT_SALE_CONTRACTS':
      return {
        ...state,
        bulkContractSale: {
          ...state.bulkContractSale,
          cart: action.payload,
        },
      };
    case 'BULK_COP_CONTRACT_SALE_SUBMIT_PROCESSING':
      return {
        ...state,
        bulkContractSale: {
          ...state.bulkContractSale,
          submitProcessing: true,
        },
      };
    case 'BULK_COP_CONTRACT_SALE_SUBMIT_RES':
      return {
        ...state,
        bulkContractSale: {
          ...state.bulkContractSale,
          actionComplete: true,
          submitProcessing: false,
        },
      };
    // Cancel contract actions
    case 'SHOW_CANCEL_CONTRACT':
      return {
        ...state,
        modal: 'cancelContract',
        cancelContract: {
          ...state.cancelContract,
          saleId: action.payload.saleId,
          purchaseSource: action.payload.purchaseSource,
        },
      };
    case 'CANCEL_CANCEL_CONTRACT':
      return {
        ...state,
        modal: null,
        cancelContract: {
          ...state.cancelContract,
          processing: false,
          saleId: '',
          step: 'confirm',
        },
      };
    case 'CANCEL_CONTRACT_SUBMIT_PROCESSING':
      return {
        ...state,
        cancelContract: {
          ...state.cancelContract,
          processing: true,
        },
      };
    case 'CANCEL_CONTRACT_SUBMIT_RES':
      return {
        ...state,
        cancelContract: {
          ...state.cancelContract,
          processing: false,
          step: 'finish',
        },
      };
    // change order customer actions
    case 'SHOW_CHANGE_ORDER_CUSTOMER':
      return {
        ...state,
        modal: 'changeOrderCustomer',
      };
    case 'HIDE_CHANGE_ORDER_CUSTOMER':
      if (state.changeOrderCustomer.finished) {
        return {
          ...state,
          step: 'customers',
          selectedOrder: defaultState.selectedOrder,
          changeOrderCustomer: defaultState.changeOrderCustomer,
          modal: null,
        };
      }
      return {
        ...state,
        modal: null,
      };
    case 'CHANGE_ORDER_CUSTOMER_SUBMIT_PROCESSING':
      return {
        ...state,
        changeOrderCustomer: {
          ...state.changeOrderCustomer,
          processing: true,
        },
      };
    case 'UPDATE_ORDER_CUSTOMER_RES':
      return {
        ...state,
        changeOrderCustomer: {
          processing: false,
          finished: true,
        },
      };
    // New order actions (only show/hide -- other actions in newOrderReducer)
    case 'POPULATE_CUSTOMERS_STATE_WITH_NEW_ORDER_INFO':
      return {
        ...defaultState,
        step: 'products',
        ordersOverview: {
          ...defaultState.ordersOverview,
          customerName: `${action.payload.customerInfo.firstName} ${action.payload.customerInfo.lastName}`,
        },
        selectedOrder: {
          ...defaultState.selectedOrder,
          clydeOrderId: action.payload.orderId,
          fullName: `${action.payload.customerInfo.firstName} ${action.payload.customerInfo.lastName}`,
          orderDate: new Date(),
          orderId: action.payload.orderId,
        },
        selectedCustomer: {
          ...defaultState.selectedCustomer,
          contact: action.payload.customerInfo.email,
          dbContactType: 'email',
        },
      };
    case 'SHOW_NEW_ORDER_MODAL':
      return {
        ...state,
        modal: 'newOrder',
      };
    case 'HIDE_NEW_ORDER_MODAL':
      return {
        ...state,
        modal: null,
      };
    // Claims Flow actions
    case 'POPULATE_CUSTOMERS_STATE_WITH_CLAIMS_CUSTOMER_INFO':
      return {
        ...defaultState,
        step: 'orders',
        ordersOverview: {
          ...defaultState.ordersOverview,
          customerName: `${action.payload.firstName} ${action.payload.lastName}`,
        },
        selectedCustomer: {
          ...defaultState.selectedCustomer,
          contact: action.payload.contact,
          dbContactType: action.payload.contactType,
        },
      };
    // Update OEM actions
    case 'UPDATE_CONTRACT_SALE_OEM_PROCESSING':
      return {
        ...state,
        updateSaleOEM: {
          ...state.updateSaleOEM,
          processing: true,
        },
      };
    case 'UPDATE_CONTRACT_SALE_OEM_RES':
      return {
        ...state,
        updateSaleOEM: {
          ...state.updateSaleOEM,
          processing: false,
          step: 'finish',
        },
      };
    case 'SHOW_UPDATE_OEM':
      return {
        ...state,
        modal: 'updateOEM',
        updateSaleOEM: {
          ...state.updateSaleOEM,
          saleId: action.payload.saleId,
          oemWarrantyLength: action.payload.oemWarrantyLength,
        },
      };
    case 'CANCEL_OEM_UPDATE':
      return {
        ...state,
        modal: null,
        updateSaleOEM: {
          processing: false,
          saleId: '',
          step: 'confirm',
        },
      };
    // Serial number actions
    case 'SAVE_LINE_ITEM_SERIAL_NUMBER_RES': {
      const { selectedOrder } = state;
      const oldEligibleProducts = [...selectedOrder.eligibleProducts];
      const updatedEligibleProducts = oldEligibleProducts.map((product) => {
        if (product.lineItemId === action.payload.id) {
          return {
            ...product,
            lineItemSerialNumber: action.payload.serialNumber,
          };
        }
        return product;
      });
      const oldIneligibleProducts = [...selectedOrder.ineligibleProducts];
      const updatedIneligibleProducts = oldIneligibleProducts.map((product) => {
        if (product.lineItemId === action.payload.id) {
          return {
            ...product,
            lineItemSerialNumber: action.payload.serialNumber,
          };
        }
        return product;
      });

      return {
        ...state,
        selectedOrder: {
          ...state.selectedOrder,
          eligibleProducts: updatedEligibleProducts,
          ineligibleProducts: updatedIneligibleProducts,
        },
      };
    }
    case 'SAVE_CONTRACT_SALE_SERIAL_NUMBER':
      return {
        ...state,
        actionsProcessing: {
          ...state.actionsProcessing,
          saveNewContractSaleSerialNumber: true,
        },
      };
    case 'SAVE_CONTRACT_SALE_SERIAL_NUMBER_RES': {
      const { selectedOrder } = state;
      const oldActiveContracts = [...selectedOrder.activeContracts];
      const updatedActiveContracts = oldActiveContracts.map((contract) => {
        const newContractSales = contract.contractSales.map((sale) => {
          if (sale.saleId === action.payload.id) {
            return {
              ...sale,
              serialNumber: action.payload.serialNumber,
            };
          }
          return sale;
        });
        return {
          ...contract,
          contractSales: newContractSales,
        };
      });

      return {
        ...state,
        selectedOrder: {
          ...state.selectedOrder,
          activeContracts: updatedActiveContracts,
        },
        actionsProcessing: {
          ...state.actionsProcessing,
          saveNewContractSaleSerialNumber: false,
        },
      };
    }
    case 'SEND_HI_CLYDE_REFERRAL_PROCESSING':
      return {
        ...state,
        fileClaim: {
          ...state.fileClaim,
          referralEmailProcessing: true,
        },
      };
    case 'SEND_HI_CLYDE_REFERRAL_RES':
      return {
        ...state,
        fileClaim: {
          ...state.fileClaim,
          referralEmailProcessing: false,
        },
      };
    case 'RECEIPT_PROCESSING':
      return {
        ...state,
        receipt: {
          url: null,
          processing: true,
        },
      };
    case 'GET_RECEIPT_FILE_RES':
      return {
        ...state,
        receipt: {
          url: action.payload.receiptLink,
          processing: false,
        },
      };
    case 'RESET_RECEIPT_FILE':
      return {
        ...state,
        receipt: {
          url: null,
          processing: false,
        },
      };
    default:
      return state;
  }
};

export default customersReducer;
