import React from 'react';
import qs from 'querystring';
import { Helmet } from 'react-helmet';

import ShopifyOAuthForm from '../ShopifyOAuthForm';
import ClydeLoginForm from '../ClydeLoginForm';
import ClydeFirstLoginForm from '../ClydeFirstLoginForm';
import ClydePasswordResetForm from '../ClydePasswordResetForm';
import PlatformButton from './PlatformButton';

class LogIn extends React.Component {
  state = {
    platform: this.props.platform || 'Clyde',
    firstTimeUser: false,
    passwordReset: false,
    embeddedBy: '',
  };

  componentDidMount() {
    const query = qs.parse(this.props.location.search.slice(1));

    const ancestorUrl = window
      && window.location.ancestorOrigins
      && window.location.ancestorOrigins.item(0);

    const isBC = typeof ancestorUrl === 'string' && ancestorUrl.indexOf('bigcommerce') > -1;

    if (query.uid) {
      this.props._checkUserFirstLogin(query.uid);
    }

    if (isBC && !this.state.embeddedBy) {
      this.setState({ embeddedBy: 'BigCommerce' });
    }
  }

  handleSetPlatform = (platform) => {
    this.props._setLoginPlatform(platform);
  }

  // Brutal, needs refactor
  handleEnter = (e) => {
    e.preventDefault();
    const inputs = e.target.getElementsByTagName('input');
    const inputData = {};
    for (let i = 0; i < inputs.length; i++) {
      if (inputs[i].type === 'text' || inputs[i].type === 'password') {
        inputData[inputs[i].name] = inputs[i].value;
      }
    }
    this.handleSubmit(inputData);
  }

  handleSubmit = (data) => {
    switch (this.state.platform) {
      case 'Shopify':
        this.props._getShopifyShopOAuthLink(data);
        break;
      case 'Magento':
        this.props._submitUserLogin(data);
        break;
      case 'Clyde':
        if (this.state.firstTimeUser) {
          this.props._submitUserFirstLogin(data);
        } else if (this.state.passwordReset) {
          this.props._resetUserPasswordByEmail(data.email);
        } else {
          this.props._submitUserLogin(data);
        }
        break;
      default:
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.firstTimeUser) {
      this.setState({ firstTimeUser: nextProps.firstTimeUser });
    }
    if (nextProps.platform !== this.props.platform) {
      this.setState({ platform: nextProps.platform });
    }
    // Not a good way at all to do a processing loader
    if (this.props.loginProcessing === false && nextProps.loginProcessing === true) {
      this.props.history.push('/login-processing');
    }
    if (this.props.validSession === false && nextProps.validSession === true) {
      this.props.history.push('/products');
    }
    if (
      this.props.returnToLogin === false
      && nextProps.returnToLogin === true
      && nextProps.expiredToken
      && nextProps.expiredToken.platform === 'Shopify'
    ) {
      this.handleSetPlatform('Shopify');
      this.props._getShopifyShopOAuthLink({ shopName: nextProps.expiredToken.domain });
      this.props._clearExpiredToken();
    }
  }

  togglePasswordReset = (e) => {
    e.preventDefault();
    this.setState({ passwordReset: !this.state.passwordReset });
  }

  render() {
    const {
      passwordResetSent,
      email,
      _getShopifyShopOAuthLink,
      _setEmail,
      _submitUserFirstLogin,
      _submitUserLogin,
      _resetUserPasswordByEmail,
      _setLoginPlatform,
    } = this.props;

    const {
      firstTimeUser,
      platform,
      passwordReset,
      embeddedBy,
    } = this.state;

    const clydeButton = { name: 'Clyde', displayName: 'Email', imageSrc: '/images/api.png' };
    const shopifyButton = { name: 'Shopify', displayName: 'Shopify', imageSrc: '/images/shopify.png' };
    const magentoButton = { name: 'Magento', displayName: 'Magento', imageSrc: '/images/magento.png' };
    const bcButton = { name: 'BigCommerce', displayName: 'BigCommerce', imageSrc: '/images/bigCommerce.png' };
    const sfccButton = { name: 'SFCC', displayName: 'SFCC', imageSrc: '/images/sfcc-logo.svg' };
    let FormComponent; let submitAction; let
      inactivePlatforms;

    switch (platform) {
      case 'Shopify':
        inactivePlatforms = [clydeButton, magentoButton, bcButton, sfccButton];
        FormComponent = ShopifyOAuthForm;
        submitAction = _getShopifyShopOAuthLink;
        break;
      case 'Magento':
        inactivePlatforms = [clydeButton, shopifyButton, bcButton, sfccButton];
        FormComponent = ClydeLoginForm;
        submitAction = _submitUserLogin;
        break;
      case 'BigCommerce':
        inactivePlatforms = [clydeButton, magentoButton, shopifyButton, sfccButton];
        FormComponent = ClydeLoginForm;
        submitAction = _submitUserLogin;
        break;
      case 'SFCC':
        inactivePlatforms = [clydeButton, magentoButton, shopifyButton, bcButton];
        FormComponent = ClydeLoginForm;
        submitAction = _submitUserLogin;
        break;
      default: // Clyde
        inactivePlatforms = [shopifyButton, magentoButton, bcButton, sfccButton];
        if (firstTimeUser) {
          FormComponent = ClydeFirstLoginForm;
          submitAction = _submitUserFirstLogin;
        } else if (passwordReset) {
          FormComponent = ClydePasswordResetForm;
          submitAction = _resetUserPasswordByEmail;
        } else {
          FormComponent = ClydeLoginForm;
          submitAction = _submitUserLogin;
        }
        break;
    }

    return (
      <div className='gradient'>
        <Helmet>
          <title>Clyde | Login</title>
        </Helmet>

        <div className='page-container'>
          <div className='login-layout'>
            <div className='row w-100'>
              <div className='login-tile col-8 offset-2 col-lg-6 offset-lg-3'>
                <img alt='clyde logo' src='/images/Clyde_Logo_Wordmark.svg'/>
                <div className='login-tile__tagline'>Own the post-purchase experience</div>
                <form onSubmit={ this.handleEnter } >
                  <FormComponent
                    submitAction={ submitAction }
                    setEmail={ _setEmail }
                    email={ email }
                    platform={ platform }
                    passwordResetSuccess={ passwordResetSent }
                  />
                  { platform === 'Clyde'
                    && <button
                      onClick={ this.togglePasswordReset }
                      className='login-tile__password-reset-button'
                    >
                      { passwordReset ? 'Back to login' : 'Forgot your password?' }
                    </button>
                  }
                </form>
              </div>
              <div className='select-platform-tile col-8 offset-2 col-lg-6 offset-lg-3'>
                <h3>Log in with:</h3>
                <div className='platform-list row'>
                  { inactivePlatforms.map((inactivePlatform) => (
                    <PlatformButton
                      key={ `${inactivePlatform.name}-login-item` }
                      platform={ inactivePlatform }
                      embeddedBy={ embeddedBy }
                      _setLoginPlatform={ _setLoginPlatform }
                    />
                  )) }
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default LogIn;
