import React,
{ Component } from 'react';
import { ClydeTooltip } from '@joinclyde/clyde-react-components';
import Spinner from './spinner';

class SaveButton extends Component {
  constructor(props) {
    super(props);
    this.state = {
      buttonText: props.text || 'Save', // Default to display 'Save'
      disabled: this.props.disabled || false, // Default to clickable
      onClick: this.props.saveFunc || null, // Default to no action on click
      visible: this.props.visible || false, // Default to hidden
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.processing) {
      this.setState({ onClick: null });
      return;
    }
    if (nextProps.visible) {
      this.setState({ visible: true });
    }

    if (this.props.processing && !nextProps.processing && !nextProps.visible) {
      this.setState({ buttonText: this.props.successText || 'Saved', onClick: null });
      setTimeout(() => {
        this.setState({ visible: false });
      }, 600);
    } else {
      this.setState({ buttonText: this.props.text || 'Save', onClick: this.props.saveFunc });
    }

    if (nextProps.disabled) {
      this.setState({ disabled: true });
    } else {
      this.setState({ disabled: false });
    }
  }

  render() {
    // A default button className if one isn't provided in props
    let className = this.props.className || 'button';
    className += this.state.visible ? '' : ' button--hidden';
    className += this.state.disabled ? ' button--disabled' : '';

    const createButton = () => {
      const buttonClass = this.props.enableTooltip
        ? 'button-nostyle button-internal'
        : `button-nostyle ${className}`;
      return (
        <button
          data-tip
          data-for={ this.props.tooltipId }
          id={ this.props.id }
          onClick={ !this.props.disabled && !this.props.processing ? this.state.onClick : null }
          className={ buttonClass }>
          {
            this.props.processing
              ? <Spinner />
              : <span>{ this.state.buttonText }</span>
          }
          { this.props.children }
        </button>
      );
    };

    return (
      this.props.enableTooltip
        ? <div
          className={ `button-nostyle ${className}` }>
            <ClydeTooltip
              label={
                <div className='tooltip-text'>{ this.props.tooltipText }</div>
              }
              hideIndicator>
              { createButton() }
          </ClydeTooltip>
          </div>
        : createButton()
    );
  }
}

export default SaveButton;
