import React, { useEffect } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import TabHeaderButton from './TabHeaderButton';
import AccountTab from './AccountTab';
import IntegrationsTab from './IntegrationsTab';
import UserManagementTab from './UserManagementTab';
import WawTab from './WawSettingsTab';

import {
  adminTabs,
  basicTabs,
  tabLinkMap,
} from './constants';

const Settings = (props) => {
  const {
    accountType,
    location,
    platform,
    _getCTAInfo,
    waw: {
      // usesCheckoutWidget,
      showRegistrationPage,
    },
  } = props;

  useEffect(() => {
    _getCTAInfo();
  }, []);
  let allowedSettingsTabs = [];
  switch (accountType) {
    case 'admin':
      allowedSettingsTabs = adminTabs;
      break;
    case 'basic':
    default:
      allowedSettingsTabs = basicTabs;
      break;
  }
  let wawTab = '';
  if (showRegistrationPage) {
    wawTab = 'registrations';
  // } else if (usesCheckoutWidget) {
  //   wawTab = 'waw';
  }
  const pathArray = location.pathname.split('/');
  const currentLocation = pathArray[pathArray.length - 1];

  return (
    <div className='page-layout'>
      <div className='settings'>
        <div className='settings__title'>
          <h1>Settings</h1>
          <br/>
        </div>

        <div className='settings__header'>
          {
            allowedSettingsTabs.map((tab) => (
              <TabHeaderButton
                isActive={ tabLinkMap[tab] === currentLocation }
                key={ tab }
                tabType={ tab } />
            ))
          }
          {
            accountType === 'admin' && wawTab && <TabHeaderButton
            isActive={ tabLinkMap[wawTab] === currentLocation }
            key={ wawTab }
            tabType={ wawTab } />
          }
        </div>

        <Switch>
          <Route exact path='/settings' render={ () => <Redirect to='/settings/account' /> } />

          <Route path='/settings/account' render={
            () => <AccountTab accountType={ accountType } platform={ platform } />
          } />
          <Route path='/settings/user-management' render={ () => (accountType === 'admin' ? <UserManagementTab platform={ platform } /> : <Redirect to='/settings/account' />) } />
          <Route path='/settings/integrations' render={ () => (accountType === 'admin' ? <IntegrationsTab platform={ platform } /> : <Redirect to='/settings/account' />) } />
          <Route path='/settings/registrations' render={ () => (accountType === 'admin' ? <WawTab page={ wawTab } /> : <Redirect to='/settings/account' />) } />
        </Switch>
      </div>
    </div>
  );
};

export default Settings;
