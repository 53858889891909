import React from 'react';

const Spinner = (props) => (
    <div className={ `spinner ${props.containerClass}` }>
      <div className='bounce1'></div>
      <div className='bounce2'></div>
      <div className='bounce3'></div>
    </div>
);

export default Spinner;
