import Cookies from 'universal-cookie';

const cookies = new Cookies();
const defaultState = {
  shopId: -1,
  userId: '',
  platform: '',
  accountType: '',
  email: '',
  authed: false,
  loading: true,
  returnToLogin: false,
  expiredToken: null,
  loginProcessing: false,
  loginFailed: false,
  validSession: false,
  firstTimeUser: false,
  shopDomain: '',
  oAuth: false,
  passwordResetSent: false,
};

const authReducer = function user(state = defaultState, action) {
  const sessionDuration = window._env_.REACT_APP_SESSION_DURATION || 12;
  const useSsl = window._env_.REACT_APP_ENV === 'production';
  switch (action.type) {
    case 'VERIFY_SHOPIFY_SHOP_OAUTH_RES':
      cookies.set('clydePlatform', 'Shopify', {
        path: '/',
        secure: useSsl,
        maxAge: 60 * 60 * sessionDuration,
      });
      return {
        ...state,
        loading: false,
        platform: 'Shopify',
        shopId: action.payload.shopId,
        shopDomain: action.payload.shopDomain,
      };
    case 'VERIFY_SHOPIFY_USER_OAUTH_RES':
      cookies.set('clydeShopId', action.payload.shopId, {
        path: '/',
        secure: useSsl,
        maxAge: 60 * 60 * sessionDuration,
      });
      cookies.set('clydeToken', action.payload.token, {
        path: '/',
        secure: useSsl,
        maxAge: 60 * 60 * sessionDuration,
      });
      cookies.set('clydePlatform', 'Shopify', {
        path: '/',
        secure: useSsl,
        maxAge: 60 * 60 * sessionDuration,
      });

      return {
        ...state,
        loading: false,
        platform: 'Shopify',
        authed: true,
        shopId: action.payload.shopId,
        shopDomain: action.payload.shopDomain,
        accountType: action.payload.accountType,
        userId: action.payload.userId,
        oAuth: true,
      };
    case 'BC_LOGIN_RES':
      cookies.set('clydeShopId', action.payload.shopId, {
        path: '/',
        secure: true,
        maxAge: 60 * 60 * sessionDuration,
        sameSite: 'none',
      });
      cookies.set('clydeToken', action.payload.token, {
        path: '/',
        secure: true,
        maxAge: 60 * 60 * sessionDuration,
        sameSite: 'none',
      });
      cookies.set('clydePlatform', 'BigCommerce', {
        path: '/',
        secure: true,
        maxAge: 60 * 60 * sessionDuration,
        sameSite: 'none',
      });

      return {
        ...state,
        loading: false,
        platform: 'BigCommerce',
        authed: true,
        shopId: action.payload.shopId,
        accountType: action.payload.accountType,
        userId: action.payload.userId,
        oAuth: true,
        validSession: true,
      };
    case 'AUTH_FAILED':
      cookies.remove('clydeShopId');
      cookies.remove('clydeToken');
      cookies.remove('clydePlatform');
      cookies.remove('clydeAccountType');
      return {
        ...state,
        returnToLogin: true,
        loading: false,
        authed: false,
        shopId: undefined,
      };
    case 'RETURNED_TO_LOGIN':
      return {
        ...state,
        authed: false,
        returnToLogin: false,
      };
    case 'EXPIRED_TOKEN':
      return {
        ...state,
        authed: false,
        returnToLogin: true,
        loading: false,
        expiredToken: action.payload.auth,
      };
    case 'CLEAR_EXPIRED_TOKEN':
      return {
        ...state,
        expiredToken: null,
      };
    case 'SET_LOGIN_PLATFORM':
      return {
        ...state,
        platform: action.payload.platform,
      };
    case 'LOGIN_PROCESSING':
      return {
        ...state,
        loginFailed: false,
        loginProcessing: true,
        platform: action.payload.platform,
      };
    case 'SUBMIT_LOGIN_RES':
      cookies.set('clydeShopId', action.payload.shopId, {
        path: '/',
        secure: useSsl,
        maxAge: 60 * 60 * sessionDuration,
      });
      cookies.set('clydeToken', action.payload.token, {
        path: '/',
        secure: useSsl,
        maxAge: 60 * 60 * sessionDuration,
      });
      cookies.set('clydePlatform', action.payload.platform || 'Clyde', {
        path: '/',
        secure: useSsl,
        maxAge: 60 * 60 * sessionDuration,
      });
      cookies.set('clydeAccountType', action.payload.accountType, {
        path: '/',
        secure: useSsl,
        maxAge: 60 * 60 * sessionDuration,
      });

      const newState = {
        ...state,
        ...action.payload,
        authed: true,
        firstTimeUser: false,
        loading: false,
        loginProcessing: false,
      };
      delete newState.token;
      return newState;
    case 'AUTH_RES':
      return {
        ...state,
        ...action.payload,
        authed: true,
        firstTimeUser: false,
        loading: true,
        platform: action.payload.platform || state.platform,
        validSession: !!action.payload.shopId,
        oAuth: action.payload.oAuth,
      };
    case 'GET_ONBOARDING_INFO_RES':
      return {
        ...state,
        loading: false,
      };
    case 'SET_LOGIN_EMAIL':
      return {
        ...state,
        email: action.payload,
      };
    case 'CHECK_USERID_PROCESSING':
      return { // In case we want to do a loader
        ...state,
      };
    case 'CHECK_USER_FIRST_LOGIN_RES':
      if (action.payload.email) {
        return {
          ...state,
          firstTimeUser: true,
          email: action.payload.email,
        };
      }
      return {
        ...state,
      };

    case 'RESET_USER_PASSWORD_EMAIL_RES':
      return {
        ...state,
        loginProcessing: false,
        passwordResetSent: true,
      };
    case 'LOGOUT':
      cookies.remove('clydeShopId');
      cookies.remove('clydeToken');
      cookies.remove('clydePlatform');
      cookies.remove('clydeAccountType');
      return {
        ...defaultState,
        platform: state.platform,
      };
    case 'ERROR':
      switch (action.payload.reqType) {
        case 'SUBMIT_FIRST_LOGIN_REQ':
        case 'SUBMIT_LOGIN_REQ':
          return {
            ...state,
            authed: false,
            loginFailed: true,
            loginProcessing: false,
          };
        default:
          return state;
      }
    default:
      return state;
  }
};

export default authReducer;
