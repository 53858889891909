const defaultState = {
  sendsContractSalesOnly: false,
  changeType: 'total',
  graphType: 'contracts',
  averageContractValue: 0,
  averageProductValue: 0,
  attachRate: 0,
  claimRate: 0,
  totalClaimQuantity: 0,
  combinedSales: {
    runSums: {},
    graphData: {
      contractCount: [],
      contractRevenue: [],
      storeRevenue: [],
    },
  },
  posSales: {
    runSums: {},
    graphData: {
      contractCount: [],
      contractRevenue: [],
      storeRevenue: [],
    },
  },
  ppSales: {
    runSums: {},
    graphData: {
      contractCount: [],
      contractRevenue: [],
      storeRevenue: [],
    },
  },
  graphRange: {
    startDate: new Date(),
    endDate: new Date() - 1,
  },
  loading: true,
};

const performanceReducer = function profile(state = defaultState, action) {
  switch (action.type) {
    case 'SELECT_PERFORMANCE_GRAPH_TYPE':
      return {
        ...state,
        graphType: action.payload,
      };
    case 'SELECT_PERFORMANCE_CHANGE_TYPE':
      return {
        ...state,
        changeType: action.payload,
      };
    case 'GET_GRAPH_DATA':
      return {
        ...state,
        graphRange: action.payload,
        loading: true,
      };
    case 'GET_GRAPH_DATA_RES':
      return {
        ...state,
        ...action.payload,
        loading: false,
      };
    case 'LOGOUT':
      return {
        ...state,
        ...defaultState,
      };
    case 'ERROR':
      switch (action.payload.reqType) {
        case 'GET_GRAPH_DATA_REQ':
          return {
            ...state,
            loading: false,
          };
        default:
          return state;
      }
    default:
      return state;
  }
};

export default performanceReducer;
