import React from 'react';
import Transition from 'react-transition-group/Transition';
import classnames from 'classnames';

// This component is currently designed to work only in the Developer's Tile in User
// Settings. To reuse this component please copy and paste the code into a new switch!

const SmallSwitch = (props) => {
  const handleToggle = () => {
    if (!props.disabled) props.toggleFunc(props.settingName);
  };

  const duration = 200;
  const defaultStyle = {
    transition: 'left 200ms ease-in-out',
  };
  const transitionStyles = {
    entering: { left: '34px' },
    entered: { left: '34px' },
    exiting: { left: '4px' },
    exited: { left: '4px' },
  };
  const containerClassNames = classnames('small-switch', {
    'small-switch--disabled': props.disabled,
    'small-switch--true': props.settingName && !props.disabled,
    'small-switch--false': !props.settingName && !props.disabled,
    [props.className]: !!props.className,
  });

  return (
    <Transition in={ props.settingName } timeout={ duration }>
      {
        (state) => (
          <div
            className={ containerClassNames }
            onClick={ handleToggle } >
              <div
                className='small-switch__dot'
                style={ { ...defaultStyle, ...transitionStyles[state] } } >
                <span className='small-switch__dot__text small-switch__dot__text--true'>{ props.trueLabel }</span>
                <span className='small-switch__dot__text small-switch__dot__text--false'>{ props.falseLabel }</span>
              </div>
          </div>
        )
      }
    </Transition>
  );
};

export default SmallSwitch;
