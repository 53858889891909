import React, { Component } from 'react';
import { SuccessAnimation, LoadingDots } from '@joinclyde/clyde-react-components';

import Cart from './Cart';
import ProductCatalogue from './ProductCatalogue';
import NewCustomer from './NewCustomer';

class NewOrder extends Component {
  componentDidMount() {
    const { _countProductCatalgoue, shopId } = this.props;
    _countProductCatalgoue(shopId);
  }

  submitOrder = () => {
    const {
      newOrder: {
        cart,
        customerInfo,
      },
      _submitNewOrder,
    } = this.props;
    _submitNewOrder(cart, customerInfo);
  }

  stepSwitcher = () => {
    const { step } = this.props.newOrder;
    let headerText;
    let contentComponent;
    switch (step) {
      case 'customer':
        headerText = 'Add a customer';
        contentComponent = <NewCustomer />;
        break;
      case 'cart':
      default:
        headerText = 'Create an order';
        contentComponent = <Cart submitOrder={ this.submitOrder } />;
        break;
    }

    return { headerText, contentComponent };
  }

  finishSale = () => {
    const {
      newOrder: {
        orderId,
        customerInfo,
      },
      _finishNewOrder,
      _populateCustomersStateWithNewOrderInfo,
    } = this.props;
    _finishNewOrder();
    _populateCustomersStateWithNewOrderInfo(orderId, customerInfo);
  }

  render() {
    const {
      newOrder: {
        actionComplete,
        productCatalogue,
        requestProcessing,
        showCatalogueModal,
        submitProcessing,
      },
      shopName,
      _hideNewOrderModal,
    } = this.props;

    const { contentComponent, headerText } = this.stepSwitcher();

    return (
      <div className='cop-modal-background' onClick={ _hideNewOrderModal }>
        <div className='cop-modal-foreground cop-modal-foreground--cop-bulk-sale' onClick={ (e) => e.stopPropagation() }>
          {
            showCatalogueModal
              && <ProductCatalogue
                processing={ requestProcessing }
                productCatalogue={ productCatalogue } />
          }
          {
            actionComplete
              ? <div className='cop-modal-foreground__action-complete'>
                  <SuccessAnimation text='Recorded!' onSuccess={ this.finishSale } />
                </div>
              : <React.Fragment>
                  <div className='cop-modal-header'>
                    <button className='button-nostyle cop-modal-header__cancel' onClick={ _hideNewOrderModal }>
                      &#x2715;
                    </button>
                    <p className='cop-modal-header__explanation'>{ shopName }</p>
                    <h1 className='cop-modal-header__header'>{ headerText }</h1>
                  </div>

                  {
                    submitProcessing
                      ? <div className='cop-modal--loading'>
                          <LoadingDots dotSize='large' dotColor='clydeBlack' />

                        </div>
                      : <div className='cop-modal-content'>
                          { contentComponent }
                        </div>
                  }
                </React.Fragment>
          }
        </div>
      </div>
    );
  }
}

export default NewOrder;
