import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import {
  getShopInfo,
  setValidationErrors,
  setText,
  updateShopInfo,
} from '../../../../actions/profileActions';

import ProfileSettingsTile from './ProfileSettingsTile';

const mapStateToProps = (state) => ({
  profile: state.profile,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  _setText: setText,
  _getShopInfo: getShopInfo,
  _updateShopInfo: updateShopInfo,
  _setValidationErrors: setValidationErrors,
}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ProfileSettingsTile);
