import React from 'react';
import { LoadingDots } from '@joinclyde/clyde-react-components';

const ProofOfWorkFiles = ({
  infoProcessing,
  uploadedProofFiles,
  openFileModal,
}) => {
  if (infoProcessing) {
    return (
      <LoadingDots dotColor='clydeBlack' dotSize='small' />
    );
  }

  if (uploadedProofFiles.length > 0) {
    return (
      <>
        {
          uploadedProofFiles.map((file, idx) => (
            <button
              className='button-no-style claims-file-button claims-file-button--small'
              key={ idx }
              onClick={ () => { openFileModal(file); } }>
              File { idx + 1 }
            </button>
          ))
        }
      </>
    );
  }

  return <span>No proof of work files previously uploaded</span>;
};

export default ProofOfWorkFiles;
