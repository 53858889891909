import React from 'react';
import { LoadingDots } from '@joinclyde/clyde-react-components';

import OrderTableRow from './OrderTableRow';

const OrderTable = ({
  orderIdsToHightlight,
  orders,
  isProcessing,
  selectOrderClick,
  selectOrderKey,
  platform,
}) => {
  let orderIdHeader;
  switch (platform) {
    case 'Shopify':
      orderIdHeader = 'Order Name / ID';
      break;
    default:
      orderIdHeader = 'Order ID';
      break;
  }

  return (
    <section className='orders__list'>

      <div className='orders__list__table-header'>
        <h3>Orders:</h3>
        <div className='orders__list__table-header__row' key='olt-header' >
          <div className='orders__list__table-header__row__cell-id'>{ orderIdHeader }</div>
          <div className='orders__list__table-header__row__cell-date'>Order Date</div>
          <div className='orders__list__table-header__row__cell-products'>Products</div>
          <div className='orders__list__table-header__row__cell-eligible'>Eligible Products</div>
          <div className='orders__list__table-header__row__cell-contracts'>Active Contracts</div>
          <div className='orders__list__table-header__row__cell-claims'>Open Claims</div>
        </div>
      </div>

      {
        isProcessing
          ? <div className='orders__list__table-body orders__list__table-body--loading'>
              <LoadingDots dotColor='clydeBlack' dotSize='large' />
            </div>
          : <div className='orders__list__table-body'>
              {
                orders.map((order, idx) => {
                  const highlightRow = orderIdsToHightlight.includes(order.orderId);
                  return (
                    <OrderTableRow
                      order={ order }
                      highlightRow={ highlightRow }
                      idx={ idx }
                      key={ order.clydeOrderId }
                      selectOrderClick={ selectOrderClick }
                      selectOrderKey={ selectOrderKey }
                      platform={ platform } />
                  );
                })
              }
            </div>
      }

    </section>
  );
};

export default OrderTable;
