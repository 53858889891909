import { wsSend } from './websocketActions';

export const verifyStripeMicrodeposits = (key, deposit1, deposit2) => (dispatch) => {
  dispatch({
    type: 'BILLING_VERIFY_PROCESSING',
    payload: {},
  });
  dispatch(wsSend({
    type: 'VERIFY_STRIPE_MICRODEPOSITS_REQ',
    payload: { key, amounts: [deposit1, deposit2] },
  }));
};

export const dismissInstructions = () => ({
  type: 'DISMISS_INSTRUCTIONS',
  payload: {},
});

export const addAccount = () => ({
  type: 'ADD_ACCOUNT',
  payload: {},
});

export const showAccounts = () => ({
  type: 'SHOW_ACCOUNTS',
  payload: {},
});

export const getBillingInfo = () => (dispatch) => {
  dispatch({
    type: 'GET_BILLING_PROCESSING',
    payload: {},
  });
  dispatch(wsSend({
    type: 'GET_BILLING_INFO_REQ',
    payload: {},
  }));
};

export const setStripeDefaultPaymentMethod = (key) => (dispatch) => {
  dispatch({
    type: 'SET_DEFAULT_PROCESSING',
    payload: {},
  });
  dispatch(wsSend({
    type: 'SET_STRIPE_DEFAULT_PAYMENT_METHOD_REQ',
    payload: { key },
  }));
};

export const createStripeIntent = (confirmationToken) => (dispatch) => {
  dispatch(wsSend({
    type: 'CREATE_STRIPE_INTENT_REQ',
    payload: { confirmationToken },
  }));
};

export const resetPaymentForm = () => ({
  type: 'RESET_PAYMENT_FORM',
  payload: {},
});

export const completeBillingStep = () => wsSend({
  type: 'COMPLETE_ONBOARDING_BILLING_REQ',
  payload: {},
});

export const updateBillingAttempts = () => wsSend({
  type: 'UPDATE_STRIPE_BILLING_ATTEMPTS_REQ',
  payload: {},
});

export default {};
