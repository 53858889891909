import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import {
  updateCart,
  hideCatalogueModal,
  updateNewOrderInput,
  runNewOrderSearch,
} from '../../../../../actions/newOrderActions';

import ProductCatalogue from './ProductCatalogue';

const mapStateToProps = (state) => ({
  platform: state.auth.platform,
  newOrder: state.newOrder,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  _updateCart: updateCart,
  _hideCatalogueModal: hideCatalogueModal,
  _updateNewOrderInput: updateNewOrderInput,
  _runNewOrderSearch: runNewOrderSearch,
}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ProductCatalogue);
