const defaultState = {
  autoMargin: true,
  markupPercentage: 50,
  roundTotal: true,
  shopReviewed: false,
  saveProcessing: false,
  showSaveButton: false,
};

const autopilotReducer = function profile(state = defaultState, action) {
  switch (action.type) {
    case 'TOGGLE_AUTO_MARGIN':
      const useMargin = !state.autoMargin === true ? 50 : state.markupPercentage;
      const useRound = !state.autoMargin === true ? true : state.roundTotal;
      return {
        ...state,
        autoMargin: !state.autoMargin,
        markupPercentage: useMargin,
        roundTotal: useRound,
        showSaveButton: true,
      };
    case 'SET_CUSTOM_MARGIN':
      return {
        ...state,
        markupPercentage: action.payload,
        showSaveButton: true,
      };
    case 'SET_ROUND_TOTAL':
      return {
        ...state,
        roundTotal: action.payload,
        showSaveButton: true,
      };
    case 'AUTOPILOT_SAVE_PROCESSING':
      return { ...state, saveProcessing: true };
    case 'UPDATE_SHOP_SETTINGS_RES':
      return {
        ...state,
        saveProcessing: false,
        showSaveButton: false,
      };
    case 'GET_SHOP_SETTINGS_RES':
      return {
        ...state,
        autoMargin: action.payload.autoMargin,
        markupPercentage: action.payload.markupPercentage,
        roundTotal: action.payload.roundTotal,
        shopReviewed: action.payload.shopReviewed,
        manageOEMWarranty: action.payload.manageOEMWarranty,
        shopCurrency: action.payload.currency,
      };
    case 'LOGOUT':
      return { ...state, ...defaultState };
    case 'ERROR':
      switch (action.payload.reqType) {
        case 'AUTOPILOT_SAVE_PROCESSING':
          return { ...state, saveProcessing: false };
        default:
          return state;
      }
    default:
      return state;
  }
};

export default autopilotReducer;
