import React from 'react';
import { connect } from 'react-redux';

const OnboardingDirections = (props) => {
  const directionStrings = {
    'welcome': "Welcome to Clyde! We are reviewing your store to ensure it's a good fit for a product protection program. Service contracts are regulated by various state and federal laws so we want to make sure we're launching a fully compliant program — we'll let you know within 48 hours if your store is a good fit. In the meantime, feel free to learn more about Clyde and get a jump start on onboarding.",
    'store-info': 'Make sure the info below is correct and fill in anything missed. You can edit this info at any time in Settings.',
    'customize': 'This information will be used to preserve your brand across your warranty offering.',
    'bank-connect': 'Link your bank account for easy processing.  We’ll collect contract premiums each month and leave 100% of your profit in your account. That’s risk-free, passive revenue. You must link a US-based bank account — non-US bank accounts are not supported at this time.',
    'licensing': 'California and Florida both have regulations surrounding the sale of service contracts to their residents. Good news though! Clyde handles licensing for you. If you decide to skip, you can start selling immediately to 48 out of 50 states and track your licensing progress in settings.',
    'terms': 'Please review our Terms of Use carefully. Please check the box at the bottom of the page to indicate that you have read and agree to them.',
  };

  return (
    props.isOnboarding || props.pageKey === 'complete'
      ? <p className='onboarding-instructions' data-test='onboardingInstructions'>
      { directionStrings[props.pageKey] }
    </p>
      : null
  );
};

const mapStateToProps = (state) => state.onboarding;

export default connect(mapStateToProps)(OnboardingDirections);
