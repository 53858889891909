const defaultState = {
  sales: [],
  saleCount: 0,
  saleSearch: '',
  pageNumber: 1,

  invoiceExportModal: false,
  invoiceExport: {
    startDate: new Date(),
    endDate: new Date(),
  },
  invoiceExportProcessing: false,
  invoiceExportFile: {
    data: null,
    name: null,
    download: false,
  },

  importSalesModal: false,
  importStep: 'start',
  importSalesProcessing: false,
  importSummary: {
    insert: [], skuDuplicates: [], ignore: [], ignoreCount: 0, insertCount: 0, updateCount: 0,
  },
  importFileKey: '',
  importFileRowCount: 0,
  importResult: {
    inserted: [], updated: [], ignored: [], ignoreCount: 0, insertCount: 0, updateCount: 0,
  },

  showResendEmailModal: false,
  resendContractSaleId: null,
  processingGetOrderInfo: false,
  orderInfo: null,
  resendStep: null,
  processingResend: false,

  searchProcessing: false,
  refresh: false,
  preservePage: false,
};

const salesReducer = (state = defaultState, action) => {
  switch (action.type) {
    case 'SET_SALE_SEARCH':
      return { ...state, saleSearch: action.payload };
    case 'SET_SALES_PAGE_NUMBER':
      return {
        ...state, pageNumber: action.payload.pageNumber, refresh: true, preservePage: true,
      };
    case 'SEARCH_SALES_PROCESSING':
      return { ...state, pageNumber: action.payload.pageNumber, searchProcessing: true };
    case 'SEARCH_SALES_RES':
      return {
        ...state,
        sales: action.payload.sales,
        saleCount: action.payload.count,
        searchProcessing: false,
        refresh: false,
        preservePage: true,
      };
    case 'SHOW_INVOICE_EXPORT_MODAL':
      return {
        ...state,
        invoiceExportModal: true,
      };
    case 'SHOW_IMPORT_SALES_MODAL':
      return {
        ...state,
        importSalesModal: true,
      };
    case 'CLOSE_IMPORT_SALES_MODAL':
      return {
        ...state,
        importSalesModal: false,
        importSalesProcessing: false,
        importFileKey: '',
        importFileRowCount: 0,
        importSummary: defaultState.importSummary,
        importResult: defaultState.importResult,
        importStep: 'start',
      };
    case 'IMPORT_SALES_PROCESSING':
      return {
        ...state,
        importProgressPercent: 0,
        importSalesProcessing: true,
      };
    case 'IMPORT_SALES_SUMMARY_RES':
      return {
        ...state,
        importSalesProcessing: false,
        importSummary: action.payload.importSummary,
        importFileKey: action.payload.importFileKey,
        importFileRowCount: action.payload.rowCount,
        importStep: 'confirm',
      };
    case 'IMPORT_SALES_RES':
      return {
        ...state,
        importSalesProcessing: false,
        importResult: action.payload.importResult,
        importFileKey: '',
        importFileRowCount: 0,
        refresh: true,
        importStep: 'finish',
      };
    case 'SET_INVOICE_RANGE':
      return {
        ...state,
        invoiceExport: {
          ...state.invoiceExport,
          startDate: action.payload.startDate,
          endDate: action.payload.endDate,
        },
      };
    case 'INVOICE_EXPORT_PROCESSING':
      return {
        ...state,
        invoiceExportProcessing: true,
      };
    case 'GET_INVOICE_EXPORT_RES':
      return {
        ...state,
        invoiceExportProcessing: false,
        invoiceExportFile: {
          ...state.invoiceExportFile,
          data: action.payload.data,
          name: action.payload.name,
          download: true,
        },
      };
    case 'SET_INVOICE_DOWNLOAD_FALSE':
      return {
        ...state,
        invoiceExportFile: {
          ...state.invoiceExportFile,
          data: null,
          name: null,
          download: false,
        },
      };
    case 'CANCEL_INVOICE_EXPORT':
      return {
        ...state,
        invoiceExportModal: false,
      };
    case 'SHOW_CONFIRM_RESEND_EMAIL':
      return {
        ...state,
        resendContractSaleId: action.payload.saleId,
        showResendEmailModal: true,
        resendStep: 'confirm',
      };
    case 'RESEND_EMAIL_INFO_PROCESSING':
      return {
        ...state,
        processingGetOrderInfo: true,
      };
    case 'RESEND_EMAIL_INFO_RES':
      return {
        ...state,
        orderInfo: action.payload,
        processingGetOrderInfo: false,
      };
    case 'CANCEL_RESEND_EMAIL':
      return {
        ...state,
        showResendEmailModal: false,
        resendContractSaleId: null,
        processingGetOrderInfo: false,
        orderInfo: null,
        resendStep: null,
      };
    case 'RESEND_EMAIL_SUBMIT_PROCESSING':
      return {
        ...state,
        processingResend: true,
      };
    case 'RESEND_EMAIL_SUBMIT_RES':
      return {
        ...state,
        processingResend: false,
        resendStep: 'finish',
      };
    case 'EXIT_RESEND_EMAIL':
      return {
        ...state,
        showResendEmailModal: false,
        resendContractSaleId: null,
        processingGetOrderInfo: false,
        orderInfo: null,
        resendStep: null,
      };
    case 'ERROR':
      switch (action.payload.reqType) {
        case 'SEARCH_SALES_REQ':
          return { ...state, searchProcessing: false };
        case 'GET_INVOICE_EXPORT_RES':
          return {
            ...state,
            invoiceExportProcessing: false,
            invoiceExportFile: {
              ...state.invoiceExportFile,
              data: null,
              name: null,
              download: false,
            },
          };
        case 'IMPORT_SALES_SUMMARY_REQ':
          return {
            ...state,
            importSalesProcessing: false,
            importStep: 'start',
          };
        default:
          return state;
      }
    default:
      return state;
  }
};

export default salesReducer;
