import React, { useCallback } from 'react';
import { ClydeTextInput, ClydeTooltip, ClydeCheckbox } from '@joinclyde/clyde-react-components';

import ImageUpload from '../../Utils/ImageUpload';
import ClydeColorSelect from '../../Utils/ClydeColorSelect';
import ProductPagePreview from '../ProductPagePreview';
import AffirmIntegration from '../affirmIntegration';

const CustomizeSettings = (props) => {
  const {
    platform,
    cta,
    _setProductPageLocation,
    _togglePrompt,
    _selectType,
    _selectCartType,
    _setDisplayName,
    _setCustomLocation,
    _setShopImageData,
  } = props;

  const handleSetProductPageLocation = useCallback((e) => {
    _setProductPageLocation(e.target.value);
  }, [_setProductPageLocation]);

  const handleTogglePrompt = useCallback((e) => {
    _togglePrompt(e.target.name);
  }, [_togglePrompt]);

  const handleTypeChange = useCallback((e) => {
    _selectType(e.target.value);
  }, [_selectType]);

  const handleCartTypeChange = useCallback((e) => {
    _selectCartType(e.target.value);
  }, [_selectCartType]);

  const isOOTBPlatform = ['shopify', 'bigcommerce'].includes(platform.toLowerCase());

  return (
    <div className='row'>
      <div className='customize-settings col-left col-lg-6 col-12'>
        <div className='customize-tile'>
          <div className='customize-tile__header'>
            <div>Display Name</div>
            <div className='customize-tile__header__info-icon'>
              <ClydeTooltip
                id='displayName'
                position='right'
                label={
                  <div className='tooltip-text tooltip-text--right'>This name will be used in our emails to customers with their contract information after a purchase, helping your customer understand where the email is coming from.</div>
                }
              />
            </div>
          </div>
          <div className='customize-tile__content'>
            <ClydeTextInput
              className='form-field'
              onChange={ (e) => { _setDisplayName(e.target.value); } }
              value={ cta.displayName }
            />
          </div>
        </div>

        <div className='customize-tile'>
          <div className='customize-tile__header'>
            <div>Image</div>
            <div className='customize-tile__header__info-icon'>
              <ClydeTooltip
                  id='image'
                  position='right'
                  label={
                    <div className='tooltip-text tooltip-text--right'>This image will be used to white-label our emails to the customer with their contract information.</div>
                  }
                />
            </div>
          </div>
          <div className='customize-tile__content'>
            <ImageUpload loading={ cta.imageProcessing } imageUrl={ cta.imageUrl } setImage={ _setShopImageData } />
          </div>
        </div>

        <div className='customize-tile'>
          <div className='customize-tile__header'>
            <div>Color</div>
            <div className='customize-tile__header__info-icon'>
              <ClydeTooltip
                    id='color'
                    position='right'
                    label={
                      <div className='tooltip-text tooltip-text--right'>
                        Do you have a go-to text color, or a color in your logo you like?
                        Use it in your CTA so it fits cleanly on your website.
                      </div>
                    }
                  />
            </div>
          </div>
          <div className='customize-tile__content'>
            <div className='customize-option'>
              <ClydeColorSelect useColor={ cta.color } />
            </div>
          </div>
        </div>

        <div className='customize-tile'>
          <div className='customize-tile__header'>
            <div>Location</div>
            <div className='customize-tile__header__info-icon mr-1'>
              <ClydeTooltip
                id='location'
                position='right'
                label={
                  <div className='tooltip-text tooltip-text'>
                    Choose where you want Clyde's protection offering
                    to be shown. You can change this any time in
                    Clyde's Admin portal.
                  </div>
                }
              />
            </div>
          </div>

          { !isOOTBPlatform
            && <div className='customize-option-desc' style={ { marginLeft: 0 } }>
                These options can be used to control the functionality of your storefront integration. You can use these values – accessible in our widget – to determine whether or not to enable Clyde in a particular context. See our <a href='https://docs.joinclyde.com/docs/getting-started-with-widget'>widget docs</a> for more details.
              </div>
          }

          <div className='customize-tile-content'>
            <div className='customize-option'>
              <div className='customize-option__title'>
                <ClydeCheckbox
                  backgroundColor='clydeBlack'
                  onChange={ handleTogglePrompt }
                  id='modalPrompt'
                  name='modalPrompt'
                  checked={ cta.modalPrompt }
                  label='Modal'
                  greyLabel={ false }
                />
              </div>
              { isOOTBPlatform
                && <div className='customize-option-desc'>Your customers will be prompted after they add a product to their cart.</div>
              }
            </div>

            <div className='customize-option'>
              <div className='customize-option__title'>
                <ClydeCheckbox
                  backgroundColor='clydeBlack'
                  onChange={ handleTogglePrompt }
                  id='productPagePrompt'
                  name='productPagePrompt'
                  checked={ cta.productPagePrompt }
                  label='Product page'
                  greyLabel={ false }
                />
              </div>

              { /* Show PDP Options (if prompt is enabled) */ }
              { cta.productPagePrompt
                && <div className='customize-option__settings'>
                  <div className='customize-option customize-option--type'>
                    <div className='customize-option__subtitle'>Type:</div>
                    <select value={ cta.type } onChange={ handleTypeChange } name='customize-style-type' id='customize-type' className='customize-option__select'>
                        <option value='select'>Selector</option>
                        <option value='list'>List</option>
                        <option value='simple'>Simple prompt</option>
                        { platform !== 'BigCommerce'
                          && <option value='radio'>Radio buttons / Checkbox (if 1)</option>
                        }
                    </select>
                  </div>

                { isOOTBPlatform
                  && <>
                    <div className='customize-option__subtitle'>Location:</div>
                    <div className='customize-option__title'>
                      <input type='radio' onChange={ handleSetProductPageLocation } id='productPageLocation1' name='productPageLocation' value='form' checked={ cta.productPageLocation === 'form' }/>
                      <label className='customize-option__title__label customize-option__title__label--small' htmlFor='productPageLocation1'>Above Add To Cart button</label>
                    </div>
                    <div className='customize-option__title'>
                      <input type='radio' onChange={ handleSetProductPageLocation } id='productPageLocation2' name='productPageLocation' value='after_form' checked={ cta.productPageLocation === 'after_form' }/>
                      <label className='customize-option__title__label customize-option__title__label--small' htmlFor='productPageLocation2'>Below Add To Cart button</label>
                    </div>
                    <div className='customize-option__title'>
                      <input type='radio' onChange={ handleSetProductPageLocation } id='productPageLocation3' name='productPageLocation' value='custom' checked={ cta.productPageLocation === 'custom' }/>
                      <label className='customize-option__title__label customize-option__title__label--small' htmlFor='productPageLocation3'>
                        <span>Custom: </span>
                      </label>
                      <ClydeTextInput
                        className='form-field custom-location-input'
                        onChange={ (e) => { _setCustomLocation(e.target.value); } }
                        disabled={ cta.productPageLocation !== 'custom' }
                        name='productPageCustomLocation'
                        value={ cta.customLocation ? cta.customLocation : '' }
                      />
                      <div className='custom-location-link'>
                        <a
                          href='https://www.joinclyde.com/resources/documentation/merchant-operations/customize-your-offering'
                          target='_blank'
                          rel='noopener noreferrer'>
                          <img
                            data-tip data-for='customLocation'
                            alt='link to support article on how to set a custom location for the product page selector'
                            className='custom-location-link__icon'
                            src='/images/info-icon.svg'/>
                        </a>
                      </div>
                    </div>
                  </>
                }
                </div>
              }
              </div>
              <div className='customize-option'>
                <div className='customize-option__title'>
                  <ClydeCheckbox
                    backgroundColor='clydeBlack'
                    onChange={ handleTogglePrompt }
                    id='cartPrompt'
                    name='cartPrompt'
                    checked={ cta.cartPrompt }
                    label='Cart'
                    greyLabel={ false }
                  />
                </div>
                { (platform === 'Shopify' || platform === 'BigCommerce')
                  && <div className='customize-option-desc'>The last chance to up-sell your customers.</div>
                }
              { /* Show Cart Options (if prompt is enabled) */ }
              { cta.cartPrompt
                && <div className='customize-option__settings'>
                  <div className='customize-option customize-option--type'>
                    <div className='customize-option__subtitle'>Type:</div>
                    <select value={ cta.cartType } onChange={ handleCartTypeChange } name='customize-style-type' id='customize-type' className='customize-option__select'>
                      <option value='simple'>Simple Button</option>
                      <option value='dropdown'>Dropdown</option>
                    </select>
                  </div>
                </div>
              }
              </div>
              { platform !== 'Shopify' && platform !== 'BigCommerce'
                && <div className='customize-option'>
                  <div className='customize-option__title'>
                    <ClydeCheckbox
                      backgroundColor='clydeBlack'
                      onChange={ handleTogglePrompt }
                      id='catalogPrompt'
                      name='catalogPrompt'
                      checked={ cta.catalogPrompt }
                      label='Catalog'
                      greyLabel={ false }
                    />
                  </div>
                </div>
              }
            </div>
          </div>

        { /* INTEGRATIONS */ }

        { /* Marker 9/7/22: disabling affirm integration for shopify pp extension */ }
        { platform !== 'Shopify'
          && <div className='customize-tile'>
            <div className='customize-tile__header'>
              <div>Integrations</div>
              <div className='customize-tile__header__info-icon'>
                <ClydeTooltip
                  id='integrations'
                  position='right'
                  label={
                    <div className='tooltip-text tooltip-text--right'>
                      Add additional integrations.
                    </div>
                  }
                />
              </div>
            </div>
          <AffirmIntegration/>
        </div>
        }
        { platform === 'Shopify'
          && <div className='customize-tile mt-4'>
            <div className='customize-tile__header'>
              <div>Shopify Post-purchase CTA</div>
              <div className='customize-tile__header__info-icon'>
                <ClydeTooltip
                  id='post-purchase'
                  position='right'
                  label={
                    <div className='tooltip-text tooltip-text'>
                      Post-purchase checkout extensions allow merchants
                       to add a post-purchase page directly into the
                       Shopify checkout.
                    </div>
                  }
                />
              </div>
            </div>
            Please visit our <a target='_blank' rel='noopener noreferrer' href='https://docs.joinclyde.com/docs/shopify-post-purchase-extension'>documentation page</a> for instructions to activate the Shopify Post-purchase CTA.
          </div>
        }

      </div>

      <div className='customize-settings col-right col-lg-6 col-12'>
        { cta.productPagePrompt && (
          <div>
            <div className='customize-tile__header'>
              <div>Preview:</div>
            </div>
            <div className='customize-option'>
              <ProductPagePreview platform={ platform } color={ cta.color } type={ cta.type } />
            </div>
          </div>
        ) }
      </div>
    </div>
  );
};

export default CustomizeSettings;
