import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import rootReducer from './reducers/rootReducer';
import wsMiddleware from './middleware/websocket';
import handleRedirect from './middleware/handleRedirect';

// Adds Redux Dev Tools in development environment
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ && process.env.NODE_ENV === 'development'
  ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ : compose;

const store = createStore(
  rootReducer,
  composeEnhancers(
    applyMiddleware(wsMiddleware, thunk, handleRedirect),
  ),
);

export default store;
