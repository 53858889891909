import { allClaimStatuses } from '../../Utils/claimHelper';

export const filters = [
  {
    text: 'Requires Action',
    value: 'approved',
  },
  ...allClaimStatuses.map((status) => {
    const { value } = status;
    let text = status.label;

    if (value === 'underClydeReview') {
      text = 'Under Clyde Review';
    } else if (value === 'underAdminReview') {
      text = 'Under Admin Review';
    }

    return { value, text };
  }),
];

export const warrantyTypes = [
  {
    text: 'Limited Warranty',
    value: 'limitedWarranty',
  },
  {
    text: 'Extended Warranty',
    value: 'extendedWarranty',
  },
];

export const sortOptions = [
  {
    text: 'Updated',
    value: 'updated',
  },
];

export const searchOptions = [
  {
    text: 'Name',
    value: 'name',
  },
  {
    text: 'Serial Number',
    value: 'serial',
  },
];
