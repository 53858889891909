export const clearError = () => ({
  type: 'CLEAR_ERROR',
  payload: {},
});

export const showError = (message) => ({
  type: 'ERROR',
  payload: { name: message },
});

export default {};
