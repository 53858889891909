import Cookies from 'universal-cookie';
import { wsSend } from './websocketActions';

const cookies = new Cookies();
const sessionDuration = window._env_.REACT_APP_SESSION_DURATION || 12;
const useSsl = window._env_.REACT_APP_ENV === 'production';

export const getShopifyShopOAuthLink = (formState) => {
  cookies.remove('clydeShopId');
  cookies.remove('clydeToken');
  cookies.remove('clydePlatform');
  cookies.set('clydePlatform', 'Shopify', {
    path: '/',
    secure: useSsl,
    maxAge: 60 * 60 * sessionDuration,
  });
  return wsSend({
    type: 'GET_SHOPIFY_SHOP_OAUTH_LINK_REQ',
    payload: { shopName: formState.shopName, source: 'dashboard', platform: 'Shopify' },
  });
};

export const checkSkeletonKey = (formState) => {
  cookies.remove('clydeShopId');
  cookies.remove('clydeToken');
  cookies.remove('clydePlatform');

  return wsSend({
    type: 'CHECK_SKELETON_KEY',
    payload: { key: formState.sk },
  });
};

export const shopifyInstall = (query) => {
  cookies.remove('clydeShopId');
  cookies.remove('clydeToken');
  cookies.remove('clydePlatform');
  cookies.set('clydePlatform', 'Shopify', {
    path: '/',
    secure: useSsl,
    maxAge: 60 * 60 * sessionDuration,
  });
  return wsSend({
    type: 'SHOPIFY_INSTALL_REQ',
    payload: { query, source: 'dashboard', platform: 'Shopify' },
  });
};

export const bigCommerceInstall = (query) => {
  cookies.remove('clydeShopId');
  cookies.remove('clydeToken');
  cookies.remove('clydePlatform');
  cookies.set('clydePlatform', 'BigCommerce', {
    path: '/',
    secure: useSsl,
    maxAge: 60 * 60 * sessionDuration,
  });
  return wsSend({
    type: 'BC_INSTALL_REQ',
    payload: { query, source: 'dashboard', platform: 'BigCommerce' },
  });
};

export const returnedToLogin = () => ({
  type: 'RETURNED_TO_LOGIN',
  payload: {},
});

export const setLoginPlatform = (platform) => ({
  type: 'SET_LOGIN_PLATFORM',
  payload: { platform },
});

export const submitUserLogin = (formState) => {
  cookies.remove('clydeShopId');
  cookies.remove('clydeToken');
  cookies.remove('clydePlatform');
  return (dispatch) => {
    dispatch(wsSend({
      type: 'SUBMIT_LOGIN_REQ',
      payload: {
        email: formState.email, password: formState.password, source: 'dashboard', platform: 'Clyde',
      },
    }));
    dispatch({
      type: 'LOGIN_PROCESSING',
      payload: { platform: 'Clyde' },
    });
  };
};

export const submitUserFirstLogin = (formState) => {
  cookies.remove('clydeShopId');
  cookies.remove('clydeToken');
  cookies.remove('clydePlatform');
  return (dispatch) => {
    dispatch(wsSend({
      type: 'SUBMIT_FIRST_LOGIN_REQ',
      payload: {
        email: formState.email,
        tempPassword: formState.tempPassword,
        newPassword: formState.newPassword,
        source: 'dashboard',
        platform: 'Clyde',
      },
    }));
    dispatch({
      type: 'LOGIN_PROCESSING',
      payload: { platform: 'Clyde' },
    });
  };
};

export const verifyShopifyShopOAuth = (query) => wsSend({
  type: 'VERIFY_SHOPIFY_SHOP_OAUTH_REQ',
  payload: query,
});

export const verifyShopifyUserOAuth = (query) => wsSend({
  type: 'VERIFY_SHOPIFY_USER_OAUTH_REQ',
  payload: query,
});

export const verifyBCOAuth = (query) => wsSend({
  type: 'VERIFY_BC_OAUTH_REQ',
  payload: {
    ...query,
    source: 'dashboard',
    platform: 'BigCommerce',
  },
});

export const clearExpiredToken = () => ({
  type: 'CLEAR_EXPIRED_TOKEN',
  payload: {},
});

export const setEmail = (email) => ({
  type: 'SET_LOGIN_EMAIL',
  payload: email,
});

export const logout = () => {
  cookies.remove('clydeShopId');
  cookies.remove('clydeToken');
  cookies.remove('clydePlatform');
  return (dispatch) => {
    dispatch({
      type: 'LOGOUT',
      payload: {},
    });
    dispatch(wsSend({
      type: 'LOGOUT_REQ',
      payload: {},
    }));
  };
};

export const checkUserFirstLogin = (userId) => (dispatch) => {
  dispatch(wsSend({
    type: 'CHECK_USER_FIRST_LOGIN_REQ',
    payload: { userId },
  }));
  dispatch({
    type: 'CHECK_USER_FIRST_LOGIN_PROCESSING',
    payload: { },
  });
};

export const resetUserPasswordByEmail = (userEmail) => wsSend({
  type: 'RESET_USER_PASSWORD_EMAIL_REQ',
  payload: { userEmail },
});

export default {};
