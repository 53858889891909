import React, { useMemo } from 'react';
import ReactPhoneInput2 from 'react-phone-input-2';
import examples from 'libphonenumber-js/mobile/examples';
import { getExampleNumber } from 'libphonenumber-js';
import 'react-phone-input-2/lib/high-res.css';

import { useLocale } from '../../providers/LocaleProvider';
import countriesAndProvincesList from '../../lib/countriesAndProvinces';

const SUPPORTED_COUNTRIES = Object.keys(countriesAndProvincesList).map((country) => country.toLowerCase());

export default function PhoneInput({ value, onChange }) {
  const { country } = useLocale();

  const exampleForCountry = useMemo(() => {
    const example = getExampleNumber(country, examples);
    return example.formatInternational();
  }, [country]);

  return (
    <ReactPhoneInput2
      inputClass='clyde-text-input w-input'
      inputStyle={ { width: '100%' } }
      inputProps={ { id: 'customer-phone' } }
      placeholder={ exampleForCountry }
      preferredCountries={ SUPPORTED_COUNTRIES }
      country={ country.toLowerCase() }
      value={ value }
      onChange={ (rawValue) => {
        onChange(rawValue.trim() ? `+${rawValue}` : '');
      } }
    />
  );
}
