import React, { useEffect, useState } from 'react';
import { SmallModal, ClydeCheckbox } from '@joinclyde/clyde-react-components';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { updateAffirmIntegration } from '../../actions/customizeActions';

const AffirmIntegration = (props) => {
  const [affirmPublicKey, setAffirmPublicKey] = useState('');
  const [selected, setSelected] = useState(false);
  const [isModalActive, setModalActive] = useState(false);

  useEffect(() => {
    setAffirmPublicKey(props.affirmPublicKey);
    setSelected(props.affirmEnabled);
  }, [props]);
  const openModal = () => {
    setModalActive(true);
  };

  const closeModal = () => {
    setModalActive(false);
  };

  const handleToggle = () => {
    if (selected) {
      setSelected(false);
      props._updateAffirmIntegration({
        affirmEnabled: false,
      });
    }
    if (!selected) {
      openModal();
    }
  };

  const confirmIntegration = () => {
    props._updateAffirmIntegration({
      affirmPublicKey,
      affirmEnabled: true,
    });
    setSelected(true);
    closeModal();
  };
  return (
    <div className='customize-option'>
      <ClydeCheckbox
        backgroundColor='clydeBlack'
        id='affirmIntegration'
        checked={ selected }
        name='affirmIntegration'
        onChange={ handleToggle }
        label='Affirm Pricing'
        greyLabel={ false }
      />
      <SmallModal
        active={ isModalActive }
        className='affirm-integration-modal'
        closeModal={ closeModal }
      >
        <div className='affirm-integration-modal-body'>
          <div className='logo-integration-section'>
            <img
              alt='clyde-logo'
              className='clyde-integration-logo'
              src='/images/Clyde-Circle-Light.png'
            />
            <img
              alt='connect-integration'
              className='connect-integration-icon'
              src='/images/connect-integration.svg'
            />
            <img
              alt='affirm-logo'
              className='affirm-integration-icon'
              src='images/affirm.svg'
            />
          </div>
          <h2>Connect Affirm to your Clyde Account</h2>
          <p>Input your information below to complete your integration</p>
          <div className='affirm-integration-input-section'>
            <input
              type='text'
              placeholder='Public Key'
              defaultValue={ affirmPublicKey }
              onChange={ (e) => setAffirmPublicKey(e.target.value) }
            />
          </div>
          <div className='affirm-integration-btn-section'>
            <button
              className='connect-integration-btn'
              onClick={ confirmIntegration }
            >
              Connect Integration
            </button>
            <button className='cancel-integration-btn' onClick={ closeModal }>
              Cancel
            </button>
          </div>
        </div>
      </SmallModal>
    </div>
  );
};

const mapStateToProps = (state) => ({
  affirmPublicKey: state.customize.affirmPublicKey,
  affirmEnabled: state.customize.affirmEnabled,
});

const mapDispatchToProps = (dispatch) => bindActionCreators(
  { _updateAffirmIntegration: updateAffirmIntegration },
  dispatch,
);

export default connect(mapStateToProps, mapDispatchToProps)(AffirmIntegration);
