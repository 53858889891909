import React, { useState, useEffect } from 'react';

const DropdownButton = ({ options, customClasses, modalOpen }) => {
  const [isOpen, toggleOpen] = useState(false);

  useEffect(() => {
    toggleOpen(false);
  }, [modalOpen]);

  const handleButtonClick = (e) => {
    e.stopPropagation();
    if (isOpen) {
      e.preventDefault();
      e.currentTarget.blur();
    }
    toggleOpen(!isOpen);
  };

  const handleMenuClick = (e, option) => {
    handleButtonClick(e);
    option.clickHandler(e);
  };

  return (
    options.length > 0
      && <div className='clyde-dropdown-button'>
        <button
          className={ `${customClasses} button-nostyle clyde-dropdown-button__button` }
          onClick={ (e) => handleButtonClick(e) } >
          Actions <div className={ `clyde-dropdown-button__arrow${isOpen ? ' clyde-dropdown-button__arrow--open' : ''}` } />
        </button>
        {
          isOpen
            && <ul className='clyde-dropdown-button__menu'>
              {
                options.map((option) => (
                  <li key={ option.text }>
                    <button
                      className='button-nostyle clyde-dropdown-button__menu__button'
                      onClick={ (e) => handleMenuClick(e, option) }>
                      { option.text }
                    </button>
                  </li>
                ))
              }
            </ul>
        }
      </div>
  );
};

export default DropdownButton;
