import React from 'react';
import { LoadingDots } from '@joinclyde/clyde-react-components';
import classnames from 'classnames';

import { formatDate } from '../../../lib/timeUtils';

const CustomerTable = (props) => {
  const {
    customers,
    isProcessing,
    selectCustomerClick,
    selectCustomerKey,
    search,
    searchType,
    searchSubmitted,
    platform,
  } = props;

  const contactClass = (customerContact) => classnames(
    'customers__list__table-body__row__cell-left',
    {
      'alert-text': !customerContact,
    },
  );

  if (searchSubmitted && customers.length) {
    return (
      <section className='customers__list'>

        <div className='customers__list__table-header'>
          <div className='customers__list__table-header__row' key='clt-header' >
            <div className='customers__list__table-header__row__cell-left'>Contact</div>
            <div className='customers__list__table-header__row__cell-center'>
              { searchType === 'lineItemSerialNumber' ? 'Line Item Serial Number' : 'Date Of Last Purchase' }
            </div>
            { platform === 'Shopify'
              && <div className='customers__list__table-header__row__cell-right'>Order Names</div>
            }
          </div>
        </div>

        {
          isProcessing
            ? <div className='customers__list__table-body customers__list__table-body--loading'>
                <LoadingDots dotColor='clydeBlack' dotSize='large' />
              </div>
            : <div className='customers__list__table-body'>
                {
                  customers.map((customer, idx) => (
                    <div
                      data-test={ `customerRow-${idx}` }
                      className='customers__list__table-body__row'
                      tabIndex='0'
                      onClick={ (e) => selectCustomerClick(e, customer) }
                      onKeyDown={ (e) => selectCustomerKey(e, customer) }
                      key={ `clt-${idx}` } >
                      <div className={ contactClass(customer.contact) }>
                        { customer.contact || 'Missing Customer Information' }
                      </div>
                      <div className='customers__list__table-body__row__cell-center'>
                        {
                          searchType === 'lineItemSerialNumber'
                            ? customer.searchedSerialNumber
                            : formatDate(customer.lastPurchase)
                        }
                      </div>
                      { platform === 'Shopify'
                        && <div className='customers__list__table-body__row__cell-right'>
                          {
                            customer.merchantOrderIds.slice(0, 3).map((id, merchIdx) => (
                                <span key={ merchIdx } style={ id === search && searchType === 'merchantOrderId' ? { fontWeight: 'bold' } : {} }>
                                  { merchIdx === 2
                                    ? id
                                    : `${id}, `
                                  }
                                  { customer.merchantOrderIds.length > 3 && merchIdx === 2 && '...' }
                                </span>
                            ))
                          }
                        </div>
                      }
                    </div>
                  ))
                }
              </div>
        }

      </section>
    );
  }
  return (
      <section className='customers__list'>
        <div className='customers__list__table-body customers__list__search-results-none'>
        { searchSubmitted && !isProcessing
          ? <div className='customers__list__search-results'>
            <svg width='76' height='75' viewBox='0 0 76 75' fill='none' xmlns='http://www.w3.org/2000/svg'>
              <rect x='0.5' width='75' height='75' rx='37.5' fill='#EEEEEE'/>
              <path d='M38 40V29.1667' stroke='black' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round'/>
              <circle cx='38' cy='45.8333' r='1.66667' fill='black'/>
              <path d='M38 54.1667C47.2048 54.1667 54.6667 46.7047 54.6667 37.5C54.6667 28.2952 47.2048 20.8333 38 20.8333C28.7953 20.8333 21.3334 28.2952 21.3334 37.5C21.3334 46.7047 28.7953 54.1667 38 54.1667Z' stroke='black' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round'/>
            </svg>
            <h1>No Customers Found</h1>
            <p>We couldn't locate your customer, try searching something different</p>
          </div>
          : <div className='customers__list__search-results'>
            <svg width='76' height='75' viewBox='0 0 76 75' fill='none' xmlns='http://www.w3.org/2000/svg'>
              <rect x='0.5' width='75' height='75' rx='37.5' fill='#EEEEEE'/>
              <path d='M36.3333 49.1667C43.6971 49.1667 49.6667 43.1971 49.6667 35.8333C49.6667 28.4695 43.6971 22.5 36.3333 22.5C28.9695 22.5 23 28.4695 23 35.8333C23 43.1971 28.9695 49.1667 36.3333 49.1667Z' stroke='black' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round'/>
              <path d='M53 52.5L45.75 45.25' stroke='black' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round'/>
            </svg>
            <h1>Search Customers</h1>
            <p>Use search above to locate your customer</p>
          </div>
        }
        </div>
      </section>
  );
};

export default CustomerTable;
