import React, {
  useCallback, useMemo, useEffect, useState,
} from 'react';
import { PaymentElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { ClydeButton } from '@joinclyde/clyde-react-components';

export default function PaymentForm({
  _createStripeIntent,
  _resetPaymentForm,
  stripeIntent,
  profile,
  onComplete,
}) {
  const stripe = useStripe();
  const elements = useElements();

  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);

  const disabled = useMemo(
    () => (isLoading || !stripe || !elements),
    [isLoading, stripe, elements],
  );

  const onError = useCallback((error) => {
    // eslint-disable-next-line no-console
    console.warn('got stripe error', error);
    if (error.message) {
      setErrorMessage(error.message);
    } else {
      setErrorMessage('Something went wrong. Please try that again later');
    }

    setIsLoading(false);
  }, [setIsLoading, setErrorMessage]);

  const onSubmit = useCallback(async (e) => {
    e.preventDefault();
    setIsLoading(true);

    // Trigger form validation and wallet collection
    const { error: submitError } = await elements.submit();
    if (submitError) {
      onError(submitError);
      return;
    }

    const { error, confirmationToken } = await stripe.createConfirmationToken({
      elements,
    });

    if (error) {
      onError(error);
      return;
    }

    // Create and confirm stripe intent on the backend
    _createStripeIntent(confirmationToken.id);
  }, [setIsLoading, elements, _createStripeIntent, onError]);

  const onVerified = useCallback(() => {
    _resetPaymentForm();
    onComplete();
  }, [_resetPaymentForm, onComplete]);

  // Handle stripe intent (might redirect)
  useEffect(() => {
    const { error, status, clientSecret } = stripeIntent;
    if (error) {
      onError(error);
    } else if (status === 'requires_action') {
      stripe.handleNextAction({ clientSecret })
        .then(onVerified)
        .catch(onError);
    } else if (status) {
      onVerified();
    }
  }, [stripe, stripeIntent, onError, onVerified]);

  const options = {
    defaultValues: {
      billingDetails: {
        email: profile.email,
        name: `${profile.firstName} ${profile.lastName}`,
      },
    },
  };

  return (
    <form onSubmit={ onSubmit } className='payment-form'>
      <h2>Add Bank Account</h2>

      { errorMessage && <p className='error'>{ errorMessage }</p> }

      <PaymentElement options={ options } />

      <div className='button-toolbar'>
        <ClydeButton disabled={ disabled }>
          Connect Account
        </ClydeButton>
      </div>
    </form>
  );
}
