import React from 'react';
import classnames from 'classnames';
import { ClydeTextInput } from '@joinclyde/clyde-react-components';

import PhoneInput from '../../../../Utils/PhoneInput';

import countriesAndProvincesList from '../../../../../lib/countriesAndProvinces';
import { useLocale } from '../../../../../providers/LocaleProvider';

const CustomerForm = ({
  customerInfo, errors, handleTextChange,
}) => {
  const locale = useLocale();

  const labelMap = {
    firstName: 'First Name*',
    lastName: 'Last Name*',
    phone: 'Phone',
    email: 'Email*',
    address1: 'Address 1*',
    address2: 'Address 2',
    city: 'City*',
    country: 'Country*',
    province: 'State/Province*',
    zip: 'Zip Code*',
  };

  const supportedCountriesList = [locale.country];

  const inputSwitcher = (field) => {
    switch (field) {
      case 'province':
        const provinces = countriesAndProvincesList[locale.country];
        if (provinces === false) {
          return <p>N/A</p>;
        }
        return (
          <select
            id={ `${field}` }
            className='w-input'
            defaultValue={ customerInfo[field] || '' }
            name={ field }
            onChange={ (e) => handleTextChange(field, e.target.value) }>
            {
              provinces.map((stateObj) => (
                <option key={ stateObj.value } value={ stateObj.value }>{ stateObj.name }</option>
              ))
            }
          </select>
        );
      case 'country':
        return (
          <select
            id={ `${field}` }
            className='w-input'
            defaultValue={ locale.country }
            name={ field }
            onChange={ (e) => handleTextChange(field, e.target.value) }>
            {
              supportedCountriesList.map((country, idx) => (
                <option value={ country } key={ idx }>
                  { country }
                </option>
              ))
            }
          </select>
        );
      case 'phone':
        return (
          <PhoneInput
            value={ customerInfo.phone || '' }
            onChange={ (value) => handleTextChange('phone', value) }
          />
        );

      default:
        return (
          <ClydeTextInput
            id={ `${field}` }
            className='w-input'
            onChange={ (e) => handleTextChange(field, e.target.value) }
            name={ field }
            value={ customerInfo[field] || '' }
          />
        );
    }
  };

  return (
    <div className='customer-form'>
      <div className='row'>
        {
          Object.keys(labelMap).map((field) => (
            <div className='col-6' key={ `${field}` }>
              <label
                className={ classnames('customer-form-label',
                  { 'customer-form-label--error': errors.indexOf(field) >= 0 }) }
                htmlFor={ field }>
                { labelMap[field] || field }
              </label>
              { inputSwitcher(field) }
            </div>
          ))
        }
      </div>
    </div>
  );
};

export default CustomerForm;
