import React, { Component } from 'react';
import classnames from 'classnames';
import {
  SuccessAnimation, LoadingDots, ClydeButton, ClydeTextInput,
} from '@joinclyde/clyde-react-components';

class ResendSaleEmailModal extends Component {
  state = {
    contact: '',
    contactType: null,
    validContact: false,
  }

  componentDidMount() {
    this.props._getOrderInfo(this.props.saleId);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (JSON.stringify(nextProps.orderInfo) !== JSON.stringify(this.props.orderInfo)) {
      this.setState({
        contact: nextProps.orderInfo.email || nextProps.orderInfo.phone,
        contactType: nextProps.orderInfo.email ? 'email' : 'phone',
        validContact: true,
      });
    }
  }

  handleConfirmResend = () => {
    if (this.state.validContact) {
      let useContact = this.state.contact;
      if (this.state.contactType === 'phone') {
        useContact = useContact.replace(/-/g, '');
      }
      this.props._confirmResendInfo(this.props.saleId, useContact, this.state.contactType);
    }
  }

  handleChangeContact = (e) => {
    const contact = e.target.value;
    this.setState({ contact }, () => {
      this.validateContact(contact);
    });
  }

  validateContact = (value) => {
    const emailRegex = /^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i;
    const phoneRegex = /^\d{10}$/;
    let valid = false;
    let contactType;
    if (emailRegex.test(value)) {
      valid = true;
      contactType = 'email';
    }
    if (phoneRegex.test(value.replace(/-/g, ''))) {
      valid = true;
      contactType = 'phone';
    }
    this.setState({
      validContact: valid,
      contactType,
    });
  }

  render() {
    let text;
    if (this.props.orderInfo) {
      text = `This will send a copy of the contract information from this order to the customer.`;
      if (this.props.orderInfo.contractCount > 1) {
        text += ` The message will contain contract information for all ${this.props.orderInfo.contractCount} contracts on the order.`;
      }
    }
    return (
      <div className='modal-container'>
        <div className='modal-container__backdrop'></div>
        { this.props.resendStep !== 'finish'
          ? <div className='clyde-modal-content clyde-modal-content--resend-email'>
            { this.props.processingGetOrderInfo === false
              ? <div className='resend-modal-content'>
                <div className='cancel-resend' onClick={ this.props._cancelResend }>&#x2715;</div>
                <h1>Re-send contract info</h1>
                <p className='resend-contact-explanation'>{ text }</p>
                <div className='resend-contact'>
                  <label
                    htmlFor='resend-contact-input'
                    className={ classnames('resend-contact__label',
                      { 'resend-contact__label--invalid': !this.state.validContact }) }
                  >
                      Send to (email/phone):
                  </label>
                  <ClydeTextInput
                    className='resend-contact__input'
                    id='resend-contact-input'
                    onChange={ this.handleChangeContact }
                    value={ this.state.contact }
                  />
                </div>
                <div className='resend-actions'>
                  <ClydeButton
                    id='confirm-resend-button'
                    className='resend-actions__confirm'
                    onClick={ this.handleConfirmResend }
                    processing={ this.props.processingResend }
                    disabled={ this.state.validContact === false }
                    successText='Sent'
                  >
                    Confirm
                  </ClydeButton>
                </div>
              </div>
              : <div className='resend-modal-content'>
                <h3>Re-send contract info</h3>
                <LoadingDots dotColor='clydeBlack' dotSize='large' className='resend-loading-container' />
              </div>
            }
          </div>
          : <div className='clyde-modal-content clyde-modal-content--resend-email'>
            <div className='resend-modal-content resend-modal-content--action-complete'>
              <SuccessAnimation text={ 'Sent!' } onSuccess={ this.props._exitResend } />
            </div>
          </div>
        }
      </div>
    );
  }
}

export default ResendSaleEmailModal;
