import React from 'react';

class ClydePasswordResetForm extends React.Component {
  componentDidMount() {
    if (!this.props.passwordResetSuccess) {
      this.emailInput.focus();
    }
  }

  handleEmailChange = (e) => {
    this.props.setEmail(e.target.value);
  }

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.submitAction(this.props.email);
  }

  render() {
    const {
      props: { passwordResetSuccess },
      handleEmailChange,
      handleSubmit,
    } = this;
    return (
      <div className='clyde-login-form clyde-login row'>
        <input
          spellCheck='false'
          id='email'
          placeholder='Email address'
          className='col-12 clyde-login-form__input'
          type='text'
          name='email'
          onChange={ handleEmailChange }
          ref={ (input) => { this.emailInput = input; } }
          value={ this.props.email } />

        { passwordResetSuccess
          ? <span className='col-lg-12'>If you have a Clyde account, you will receive an email with instructions regarding password reset.</span>
          : <button type='submit' onClick={ handleSubmit } className='col-lg-12 clyde-login-form__button'>Send reset email</button>
        }
      </div>
    );
  }
}

export default ClydePasswordResetForm;
