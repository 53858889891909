import { wsSend } from './websocketActions';

export const getShopInfo = () => (dispatch) => {
  dispatch({
    type: 'SHOP_GET_PROCESSING',
    payload: {},
  });
  dispatch(wsSend({
    type: 'GET_SHOP_DEVELOPER_INFO_REQ',
    payload: {},
  }));
};

export const setIsBasicAuth = (isBasicAuth) => ({
  type: 'SET_IS_BASIC_AUTH',
  payload: isBasicAuth,
});

export const updateShopInfo = (securityState) => {
  const sendState = {
    isBasicAuth: securityState.isBasicAuth,
  };
  return (dispatch) => {
    dispatch({
      type: 'SHOP_SECURITY_SAVE_PROCESSING',
      payload: {},
    });
    dispatch(wsSend({
      type: 'UPDATE_SHOP_SECURITY_INFO_REQ',
      payload: sendState,
    }));
  };
};

export default {};
