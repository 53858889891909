import React, { Component } from 'react';
import { SuccessAnimation, ClydeTextInput, ClydeButton } from '@joinclyde/clyde-react-components';

class UpdateOEMModal extends Component {
  state = {
    newLength: '0',
  }

  componentDidMount = () => {
    const { oemWarrantyLength } = this.props.updateOEM;
    if (this.state.newLength !== oemWarrantyLength && typeof oemWarrantyLength !== 'undefined') {
      this.setState({ newLength: oemWarrantyLength });
    }
  }

  handleChange = (e) => {
    const newLength = e.target.value;
    this.setState({ newLength });
  }

  sendUpdate = () => {
    const {
      updateOEM,
      _updateSaleOEM,
    } = this.props;

    const { newLength } = this.state;
    _updateSaleOEM(updateOEM.saleId, newLength);
  }

  render() {
    const {
      props: {
        updateOEM, _closeModal, _refreshOrder, orderId,
      },
      state: { newLength },
      handleChange,
      sendUpdate,
    } = this;

    return (
      <div className='cop-modal-background' onClick={ _closeModal }>
        <div className='cop-modal-foreground' onClick={ (e) => e.stopPropagation() }>
          {
            updateOEM.step !== 'finish'
              ? <React.Fragment>
                  <div className='cop-modal-header'>
                    <button
                      className='button-no-style cop-modal-header__cancel'
                      onClick={ _closeModal }
                    >
                      &#x2715;
                    </button>
                    <h1 className='cop-modal-header__header'>Update OEM WarrantyLength</h1>
                    <p>This sale's OEM is currently { updateOEM.oemWarrantyLength } months</p>
                  </div>

                  <div className='cop-modal-content cop-cancel-modal'>

                    <div className='cop-cancel-modal__contact'>
                      <label htmlFor='cancel-contact-input' className='cop-cancel-modal__label'>
                        Enter a new OEM length (in months)
                      </label>
                      <ClydeTextInput
                        id='cancel-contact-input'
                        className='cop-cancel-modal__input'
                        onChange={ handleChange }
                        value={ newLength }
                        typeNumber
                      />
                    </div>
                  </div>

                  <div className='cop-modal-actions'>
                    <ClydeButton
                      id='confirm-cancel-button'
                      className='cop-modal-actions__confirm'
                      onClick={ sendUpdate }
                      processing={ updateOEM.processing }
                      successText='Updated'
                    >
                      Update
                    </ClydeButton>
                  </div>
                </React.Fragment>
              : <div className='cop-modal-foreground__action-complete'>
                  <SuccessAnimation
                    text='Updated!'
                    onSuccess={ () => _refreshOrder(orderId) } />
                </div>
          }
        </div>
      </div>
    );
  }
}

export default UpdateOEMModal;
