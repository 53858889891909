import React, { useEffect } from 'react';
import { LoadingDots } from '@joinclyde/clyde-react-components';

import { useApi } from '../../providers/ApiProvider';
import { useErrors } from '../../providers/ErrorProvider';

// Basically a permalink for looking up terms by contract ID.
// This consolidates term url resolution logic in api instead of
// spreading it across multiple services.
export default function ContractTermsRedirect({ match }) {
  const { contractId } = match.params;
  const api = useApi();
  const showError = useErrors();

  useEffect(() => {
    api.get(`/contracts/${contractId}`).then((result) => {
      const { data } = result.data;
      if (data) {
        window.location = data.attributes.link;
        return;
      }
      throw new Error('unexpected response from api');
    }).catch(() => {
      showError('ContractNotFound');
    });
  }, [contractId, api, showError]);

  return (
    <div style={ { position: 'fixed', top: '50%', left: '50%' } }>
      <LoadingDots dotColor='clydeBlack' dotSize='large' />
    </div>
  );
}
