import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import {
  closeUploadModal,
  getImportSummary,
  importProducts,
} from '../../actions/productsActions';

import CVSUpload from './CSVUpload';

const mapStateToProps = (state) => {
  const importPrompts = {
    uploadInfo: 'Upload a CSV using our template and we’ll match to contracts',
    importTitle: 'Import Products',
    downloadPrompt: 'Download our template to ensure your products get matched properly.',
    submitInfo: 'Once your file is verified we’ll review the products and finish the import.',
  };

  const csvTemplateString = 'Name,Type,Manufacturer,SKU,Barcode,Price,ImageLink,Description,OEMWarrantyLength,Attributes';

  return {
    importProcessing: state.products.importProductsProcessing,
    importStep: state.products.importStep,
    importSummary: state.products.importSummary,
    importFileKey: state.products.importFileKey,
    importFileRowCount: state.products.importFileRowCount,
    importResult: state.products.importResult,
    isProductCSV: true,
    csvTemplateString,
    importPrompts,
  };
};

const mapDispatchToProps = (dispatch) => bindActionCreators({
  _closeUploadModal: closeUploadModal,
  _getImportSummary: getImportSummary,
  _submitImport: importProducts,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(CVSUpload);
