import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import {
  getClaimsExport,
  setClaimsDownloadFalse,
} from '../../../../actions/claims/claimsListActions';

import ClaimsExportModal from './ClaimsExportModal';

const mapStateToProps = (state) => ({
  claimExportFile: state.claimsList.claimsExportFile,
  claimExportProcessing: state.claimsList.claimsExportProcessing,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  _submitClaimsExport: getClaimsExport,
  _setClaimsDownloadFalse: setClaimsDownloadFalse,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ClaimsExportModal);
