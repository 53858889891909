import { wsSend } from './websocketActions';

export const setText = (profileFieldKey, text, numeric) => {
  let payloadText = text;
  if (numeric && Number.isNaN(+text.replace(/-| /g, ''))) {
    return {
      type: '', payload: {},
    };
  }

  if (numeric) {
    payloadText = text.replace('.', '');
  }

  if (profileFieldKey === 'province') {
    payloadText = text.toUpperCase();
  }

  return {
    type: 'SET_TEXT_FIELD',
    payload: {
      profileField: profileFieldKey,
      text: payloadText,
    },
  };
};

export const setBoolean = (profileFieldKey, value) => ({
  type: 'SET_BOOLEAN_FIELD',
  payload: { profileField: profileFieldKey, value },
});

export const getShopInfo = () => (dispatch) => {
  dispatch({
    type: 'SHOP_GET_PROCESSING',
    payload: {},
  });
  dispatch(wsSend({
    type: 'GET_SHOP_INFO_REQ',
    payload: {},
  }));
};

const formatUpdateInfo = (info) => {
  const topLevel = {
    firstName: info.firstName,
    lastName: info.lastName,
    email: info.email,
    shopName: info.shopName,
    phoneNumber: info.phoneNumber,
    domain: info.domain,
    address1: info.address1,
    address2: info.address2,
    city: info.city,
    province: info.province,
    zip: info.zip,
    country: info.country,
    warrantyType: info.warrantyType,
    oemWarrantyLength: Number(info.oemWarrantyLength),
    warrantyDataAccessible: info.warrantyDataAccessible,
  };

  const contactInfo = {
    ...topLevel,
    type: 'Primary Contact',
    secondaryEmail: info.secondaryEmail,
    hasUpdate: true,
  };

  if (info.contactId) {
    contactInfo.id = info.contactId;
  }

  delete contactInfo.shopName;
  delete contactInfo.domain;
  delete contactInfo.warrantyType;
  delete contactInfo.warrantyDataAccessible;
  delete contactInfo.oemWarrantyLength;

  return {
    ...topLevel,
    contactInfo: [contactInfo],
    language: info.language,
  };
};

export const updateShopInfo = (shopInfoState) => {
  const sendState = formatUpdateInfo(shopInfoState);

  return (dispatch) => {
    dispatch({
      type: 'SHOP_PROFILE_SAVE_PROCESSING',
      payload: {},
    });
    dispatch(wsSend({
      type: 'UPDATE_SHOP_INFO_REQ',
      payload: sendState,
    }));
  };
};

export const completeProfileStep = (shopInfoState, technicalContact, claimsContact) => {
  const sendState = formatUpdateInfo(shopInfoState);
  const filteredTechnicalContact = {};
  const filteredClaimsContact = {};
  const permittedValues = [
    'firstName', 'lastName', 'email', 'phoneNumber',
    'address1', 'address2', 'city', 'province',
    'country', 'zip', 'secondaryEmail',
  ];

  for (const key of permittedValues) {
    filteredTechnicalContact[key] = technicalContact[key] || '';
    filteredClaimsContact[key] = claimsContact[key] || '';
  }

  // an empty string will trigger the uuid validation on app-server
  if (technicalContact.id) {
    filteredTechnicalContact.id = technicalContact.id;
  }

  if (claimsContact.id) {
    filteredClaimsContact.id = claimsContact.id;
  }

  sendState.contactInfo.push({
    ...filteredTechnicalContact,
    hasUpdate: true,
    type: 'Technical Contact',
  });

  sendState.contactInfo.push({
    ...filteredClaimsContact,
    hasUpdate: true,
    type: 'Claims Contact',
  });

  return wsSend({
    type: 'COMPLETE_ONBOARDING_PROFILE_REQ',
    payload: sendState,
  });
};

export const setValidationErrors = (formErrors) => ({
  type: 'SET_VALIDATION_ERRORS',
  payload: { formErrors },
});

export default {};
