import React, { Component } from 'react';
import Moment from 'moment';
import { extendMoment } from 'moment-range';
import { DateRange } from 'react-date-range';
import { Helmet } from 'react-helmet';

import DataTile from './DataTile';
import SalesGraph from './SalesGraph';
import formatMoney from '../../lib/formatMoney';
import { formatDate } from '../../lib/timeUtils';

class Performance extends Component {
  constructor(props) {
    super(props);
    this.moment = extendMoment(Moment);
    this.state = {
      rangeChanged: false,
      showCalendar: false,
    };
  }

  UNSAFE_componentWillMount() {
    const startDate = this.moment().subtract(1, 'months');
    const endDate = this.moment();
    this.updateGraph({
      startDate: startDate.format(),
      endDate: endDate.format(),
    });
  }

  updateGraph(range) {
    const { _getGraphData, platform } = this.props;
    _getGraphData(range, platform);
  }

  handleSelectGraphType = (e) => {
    const { id } = e.currentTarget;
    this.props._selectGraphType(id);
  }

  handleSelectChangeType = (changeType) => {
    this.props._selectChangeType(changeType);
  }

  handleRangeChange = (newRange) => {
    const { startDate, endDate } = newRange;
    this.setState({ showCalendar: false });
    this.updateGraph({
      startDate: startDate.format(),
      endDate: endDate.format(),
    });
  }

  toggleCalendar = (e) => {
    e.stopPropagation();
    if (this.state.showCalendar) {
      e.currentTarget.blur();
    }
    this.setState((prevState) => ({ showCalendar: !prevState.showCalendar }));
  }

  closeCalendar = (e) => {
    e.preventDefault();
    this.setState({ showCalendar: false });
  }

  buildGraphTypeClassNames = () => {
    const { changeType } = this.props;

    let graphTypeTotal = 'button-nostyle button-graph-type button-graph-type--total';
    let graphTypeWeek = 'button-nostyle button-graph-type button-graph-type--week';
    let graphTypeDay = 'button-nostyle button-graph-type button-graph-type--day';
    switch (changeType) {
      case 'week':
        graphTypeWeek += ' button-graph-type--selected';
        break;
      case 'day':
        graphTypeDay += ' button-graph-type--selected';
        break;
      case 'total':
      default:
        graphTypeTotal += ' button-graph-type--selected';
        break;
    }

    return { graphTypeTotal, graphTypeWeek, graphTypeDay };
  }

  buildDefaultRanges = () => ({
    'Today': { startDate: this.moment(), endDate: this.moment() },
    'Past 7 Days': { startDate: this.moment().subtract('7', 'day'), endDate: this.moment() },
    'Past 14 Days': { startDate: this.moment().subtract('14', 'day'), endDate: this.moment() },
    'Past Month': { startDate: this.moment().subtract('1', 'month'), endDate: this.moment() },
    'Past 6 Months': { startDate: this.moment().subtract('6', 'month'), endDate: this.moment() },
    'Past Year': { startDate: this.moment().subtract('1', 'year'), endDate: this.moment() },
  })

  buildSecondaryInfoArrays = () => {
    const {
      averageContractValue,
      averageProductValue,
      claimRate,
      posSales,
      ppSales,
      shopCurrency,
    } = this.props;

    const contractCountSecondaryInfo = [
      {
        title: 'At Time of Sale:',
        value: posSales.runSums.contractCount,
      },
      {
        title: 'Post Purchase:',
        value: ppSales.runSums.contractCount,
      },
    ];
    const contractRevSecondaryInfo = [
      {
        title: 'At Time of Sale:',
        value: formatMoney(posSales.runSums.contractRevenue, shopCurrency),
      },
      {
        title: 'Post Purchase:',
        value: formatMoney(ppSales.runSums.contractRevenue, shopCurrency),
      },
    ];
    const storeRevSecondaryInfo = [
      {
        title: 'At Time of Sale:',
        value: formatMoney(posSales.runSums.storeRevenue, shopCurrency),
      },
      {
        title: 'Post Purchase:',
        value: formatMoney(ppSales.runSums.storeRevenue, shopCurrency),
      },
    ];
    const avgValueSecondaryInfo = [
      {
        title: 'Avg. Product Value:',
        value: formatMoney(averageProductValue, shopCurrency),
      },
      {
        title: 'Avg. Contract Value:',
        value: formatMoney(averageContractValue, shopCurrency),
      },
    ];
    const claimsSecondaryInfo = [
      {
        title: 'Claim Rate:',
        value: `${claimRate}%`,
      },
    ];

    return {
      avgValueSecondaryInfo,
      claimsSecondaryInfo,
      contractCountSecondaryInfo,
      contractRevSecondaryInfo,
      storeRevSecondaryInfo,
    };
  }

  render() {
    const {
      attachRate,
      combinedSales,
      changeType,
      graphRange,
      graphType,
      loading,
      platform,
      posSales,
      ppSales,
      totalClaimQuantity,
      sendsContractSalesOnly,
      shopCurrency,
    } = this.props;

    const {
      graphTypeTotal,
      graphTypeWeek,
      graphTypeDay,
    } = this.buildGraphTypeClassNames();

    const {
      avgValueSecondaryInfo,
      contractCountSecondaryInfo,
      contractRevSecondaryInfo,
      storeRevSecondaryInfo,
      claimsSecondaryInfo,
    } = this.buildSecondaryInfoArrays();

    const displayAttachRate = platform === 'Clyde' || sendsContractSalesOnly;

    let storeAttachRate;
    if (displayAttachRate) {
      // API stores do not have an AR as of 2020/03/18
      storeAttachRate = null;
    } else if (attachRate === null) {
      // Platform stores may or may not have an AR depending on data
      storeAttachRate = 'Not enough data';
    } else {
      storeAttachRate = `${attachRate}%`;
    }

    return (
      <div className='page-container'>
        <Helmet>
          <title>Clyde | Performance</title>
        </Helmet>

        <div className='page-layout'>
          <div className='title-wrapper'>
            <div className='row contract-sales-title-container'>
              <div className='col-12'>
                <h1>Performance</h1>
              </div>
            </div>
          </div>
          <br />
          <div className='page-layout__content d-flex flex-column'>

            <div className='performance-input'>
              <button className='button-nostyle performance-input__date' onClick={ this.toggleCalendar }>
                <div className='performance-input__date__group'>
                  <span className='performance-input__date__group-label'>Start Date</span>
                  <div className='performance-input__date__group-date'>
                    <span>{ formatDate(graphRange.startDate) }</span>
                  </div>
                </div>
                <div className='performance-input__date__group'>
                <span className='performance-input__date__group-label'>End Date</span>
                <div className='performance-input__date__group-date'>
                  <span>{ formatDate(graphRange.endDate) }</span>
                </div>
                </div>
              </button>

              <div className='performance-input__graph'>
                <button
                  className={ graphTypeTotal }
                  onClick={ () => this.handleSelectChangeType('total') }>
                  Running total
                </button>
                <button
                  className={ graphTypeWeek }
                  onClick={ () => this.handleSelectChangeType('week') }>
                  Week over week
                </button>
                <button
                  className={ graphTypeDay }
                  onClick={ () => this.handleSelectChangeType('day') }>
                  Day over day
                </button>
              </div>

            </div>

            <div className='performance-calendar-row' onClick={ (e) => e.stopPropagation() }>
              <div className={ `performance-calendar--${this.state.showCalendar ? 'visible' : 'invisible'}` }>
                <DateRange
                  maxDate={ this.moment() }
                  minDate={ this.moment('2018-06-01') }
                  twoStepChange
                  ranges={ this.buildDefaultRanges() }
                  onChange={ this.handleRangeChange }
                  moveRangeOnFirstSelection={ false }
                  showMonthAndYearPickers
                  showMonthArrow
                  showSelectionPreview />
              </div>
            </div>

            <div className='performance-data-row'>
              <DataTile
                handleSelect={ this.handleSelectGraphType }
                id='contracts'
                selected={ graphType === 'contracts' }
                title='Total Contracts Sold'
                isLoading={ loading }
                mainValue={ combinedSales.runSums.contractCount }
                secondaryInfo={ contractCountSecondaryInfo } />
              <DataTile
                handleSelect={ this.handleSelectGraphType }
                id='contractRevenue'
                selected={ graphType === 'contractRevenue' }
                title='Total Contract Revenue'
                isLoading={ loading }
                mainValue={ formatMoney(combinedSales.runSums.contractRevenue, shopCurrency) }
                secondaryInfo={ contractRevSecondaryInfo } />
              <DataTile
                handleSelect={ this.handleSelectGraphType }
                id='storeRevenue'
                selected={ graphType === 'storeRevenue' }
                title='Gross Profit'
                isLoading={ loading }
                mainValue={ formatMoney(combinedSales.runSums.storeRevenue, shopCurrency) }
                secondaryInfo={ storeRevSecondaryInfo } />
              <DataTile
                handleSelect={ null }
                id='claims'
                selected={ null }
                title='Claims'
                isLoading={ loading }
                mainValue={ totalClaimQuantity }
                secondaryInfo={ claimsSecondaryInfo }
                tooltipLabel={
                  <div className='tooltip-text tooltip-text--performance'>
                    <p>
                      Your claim rate is the percentage of protection plans
                      sold during a given period that had claims filed on them.
                    </p>
                  </div>
                } />
              <DataTile
                handleSelect={ null }
                id='insights'
                selected={ null }
                title={ displayAttachRate ? 'Purchase insights' : 'Attach Rate' }
                isLoading={ loading }
                mainValue={ storeAttachRate }
                secondaryInfo={ avgValueSecondaryInfo }
                tooltipLabel={
                  <div className='tooltip-text tooltip-text--performance'>
                    {
                      !displayAttachRate
                        && <p>
                          Your attach rate is the percentage of sales of eligible products
                          that were purchased with a Clyde Protection Plan.
                          </p>
                    }
                    <p>The average product value is your average cost of a product sold with a protection plan.</p>
                    <p>The average contract value is the average cost paid by your customers for a protection plan.</p>
                  </div>
                } />
            </div>

            <SalesGraph
              changeType={ changeType }
              graphType={ graphType }
              combinedSales={ combinedSales }
              posSales={ posSales }
              ppSales={ ppSales }
              graphRange={ graphRange }
              moment={ this.moment }
              formatMoney={ formatMoney } />

          </div>
        </div>
      </div>
    );
  }
}

export default Performance;
