const defaultContractObj = {
  firstName: '',
  lastName: '',
  email: '',
  secondaryEmail: '',
  phoneNumber: '',
  address1: '',
  city: '',
  province: '',
  zip: '',
};

const defaultState = {
  getProcessing: false,
  saveProcessing: false,
  formErrors: new Array(3).fill({}),
  showSaveButton: false,
  contactList: [
    { ...defaultContractObj },
    { ...defaultContractObj },
    { ...defaultContractObj },
  ],
};

const contactInfoReducer = function profile(state = defaultState, action) {
  switch (action.type) {
    case 'SHOP_GET_PROCESSING':
      return { ...state, getProcessing: true };
    case 'GET_SHOP_INFO_RES':
      return {
        ...state,
        shopID: {},
        contactList: action.payload.contactInfo.slice(1),
        getProcessing: false,
      };
    case 'SET_CONTACT_TEXT_FIELD':
      const { index, name, value } = action.payload;
      const updatedContact = { ...state.contactList[index] };
      updatedContact[name] = value;
      updatedContact.hasUpdate = true;
      return {
        ...state,
        contactList: [
          ...state.contactList.slice(0, index),
          updatedContact,
          ...state.contactList.slice(index + 1),
        ],
        showSaveButton: true,
      };
    case 'SHOP_CONTACTS_SAVE_PROCESSING':
      return { ...state, saveProcessing: true };
    case 'UPDATE_SHOP_CONTACTS_RES':
      const { updatedContacts } = action.payload;
      return {
        ...state,
        contactList: updatedContacts,
        saveProcessing: false,
        showSaveButton: false,
      };
    case 'COMPLETE_ONBOARDING_PROFILE_RES':
      return state;
    case 'GET_ONBOARDING_INFO_RES':
      return { ...state, shopName: action.payload.shopName };
    case 'SET_CONTACT_VALIDATION_ERRORS':
      return {
        ...state,
        formErrors: action.payload.formErrors,
      };
    case 'LOGOUT':
      return defaultState;
    case 'ERROR':
      switch (action.payload.reqType) {
        case 'GET_SHOP_INFO_REQ':
          return { ...state, getProcessing: false };
        case 'UPDATE_SHOP_INFO_REQ':
          return { ...state, getProcessing: false };
        default:
          return state;
      }
    default:
      return state;
  }
};

export default contactInfoReducer;
