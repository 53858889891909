import React, { Component } from 'react';

class ShopifyOAuthForm extends Component {
  state = {
    shopName: '',
    error: false,
    errorMessage: true,
  }

  componentDidMount() {
    this.shopInput.focus();
  }

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
      error: false,
      errorMessage: '',
    });
  }

  handleSubmit = (e) => {
    e.preventDefault();
    if (this.state.shopName.trim().length) {
      this.props.submitAction(this.state);
    } else {
      this.setState({
        error: true,
        errorMessage: "Please provide your Shopify shop's domain",
      });
    }
  }

  render() {
    return (
      <div className='shopify-login-form row'>
        <input
          required
          spellCheck='false'
          id='enter-shop'
          placeholder='example.myshopify.com'
          className='col-12 shopify-login-form__input'
          type='text'
          name='shopName'
          onChange={ this.handleChange }
          ref={ (input) => { this.shopInput = input; } }
          value={ this.state.shopName } />
        <button type='submit' onClick={ this.handleSubmit } className='col-12 shopify-login-form__button'>Log in</button>
        { this.state.error && <span className='login-form__error col-lg-12'>{ this.state.errorMessage }</span> }
      </div>
    );
  }
}

export default ShopifyOAuthForm;
