export const currencies = {
  EUR: 'Euro',
  USD: 'US Dollar',
  CAD: 'Canadian Dollar',
};

export const countries = {
  US: {
    code: 'US',
    name: 'United States',
    currencies: ['USD'],
    active: true,
    defaultLanguage: 'en-CA',
  },
  DE: {
    code: 'DE',
    name: 'Germany',
    currencies: ['EUR'],
    active: true,
    defaultLanguage: 'de-DE',
  },
  CA: {
    code: 'CA',
    name: 'Canada',
    currencies: ['CAD'],
    active: true,
    defaultLanguage: 'fr-CA',
  },
};

export const languages = [
  {
    name: 'Canadian English',
    language: 'en-CA',
  },
  {
    name: 'Canadian French',
    language: 'fr-CA',
  },
  {
    name: 'German German',
    language: 'de-DE',
  },
  {
    name: 'US English',
    language: 'en-US',
  },
];
