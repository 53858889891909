import React from 'react';
import { Route, Redirect, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import Cookies from 'universal-cookie';

const cookies = new Cookies();

const ProtectedRoute = (props) => {
  const { component: Component, path, redirect } = props;
  const accountType = props.accountType || cookies.get('clydeAccountType');
  const linkTo = (nextProps) => {
    switch (accountType) {
      case 'admin':
        return <Component { ...nextProps } />;
      case 'basic':
      default:
        return <Redirect to= { redirect } />;
    }
  };

  return (
    <Route
      path={ path }
      render={ (nextProps) => linkTo(nextProps) }
    />
  );
};

const mapStateToProps = (state) => ({
  accountType: state.auth.accountType,
  platform: state.auth.platform,
});

export default withRouter(connect(mapStateToProps)(ProtectedRoute));
