import { wsSend } from './websocketActions';

export const toggleAutoMargin = () => ({
  type: 'TOGGLE_AUTO_MARGIN',
});

export const setCustomMargin = (marginPercent) => ({
  type: 'SET_CUSTOM_MARGIN',
  payload: parseInt(marginPercent, 10) || '',
});

export const setRoundTotal = (roundTotalBool) => ({
  type: 'SET_ROUND_TOTAL',
  payload: roundTotalBool,
});

export const getShopSettings = (includeShopInfo = false) => wsSend({
  type: 'GET_SHOP_SETTINGS_REQ',
  payload: { includeShopInfo },
});

export const updateShopSettings = (autopilotState, marginChanged) => {
  const data = {
    autoMargin: autopilotState.autoMargin,
    markupPercentage: autopilotState.markupPercentage,
    roundTotal: autopilotState.roundTotal,
    marginChanged,
  };
  return (dispatch) => {
    dispatch({
      type: 'AUTOPILOT_SAVE_PROCESSING',
      payload: {},
    });
    dispatch(wsSend({
      type: 'UPDATE_SHOP_SETTINGS_REQ',
      payload: data,
    }));
  };
};

export default {};
