import React from 'react';
import Img from 'react-image';
import { ClydeTextInput } from '@joinclyde/clyde-react-components';
import ContractTermsLink from '../../../../Utils/ContractTermsLink';

const CartItem = (props) => {
  const {
    product, removeItem, updateQuantity, updatePrice, idx,
  } = props;

  let productLink = (
    <span className='new-order-cart-item-info__link new-order-cart-item-info__link--uneligible'>
      Not eligible
    </span>
  );
  if (product.contractIds && product.contractIds[0]) {
    productLink = (
      <ContractTermsLink
        contractId={ product.contractIds[0] }
        className='new-order-cart-item-info__link'
        onClick={ (e) => e.stopPropagation() }
      >
        View warranty details
      </ContractTermsLink>
    );
  }
  let renderPrice = product.price;
  if (parseFloat(renderPrice)) {
    renderPrice = +(parseFloat(renderPrice).toFixed(2));
  }

  return (
    <div className='new-order-cart-item'>
      <div className='new-order-cart-item__left'>
        <Img
          className='new-order-cart-item-img'
          alt={ product.name }
          src={ [product.imageLink, '/images/default-image.svg'] } />
        <div className='new-order-cart-item-info'>
          <span className='new-order-cart-item-info__name'>{ product.name }</span>
            { product.variantName && <span className='new-order-cart-item-info__variant-name'>{ product.variantName }</span> }
            { productLink }
        </div>
      </div>

      <div className='new-order-cart-item__right'>
        <div className='new-order-cart-item-price'>
          <ClydeTextInput
            className='new-order-cart-item-price__input'
            min='0'
            value={ renderPrice }
            onChange={ (e) => updatePrice(e, idx) }
            step='0.01'
            typeNumber
          />
        </div>
        <ClydeTextInput
          className='new-order-cart-item-quantity'
          min='0'
          value={ product.quantity }
          onChange={ (e) => updateQuantity(e, idx) }
          typeNumber
        />
      </div>

      <button
        className='button-nostyle new-order-cart-item-remove'
        onClick={ (e) => removeItem(e, idx) }>
        &#x2715;
      </button>
    </div>
  );
};

export default CartItem;
