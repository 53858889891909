import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import {
  getOrderDetails,
  cancelCancelContract,
  confirmCancelContract,
  confirmCancelPpContract,
} from '../../../../actions/customersActions';

import CancelContractModal from './CancelContract';

const mapStateToProps = (state) => ({
  cancelContract: state.customers.cancelContract,
  orderId: state.customers.selectedOrder.clydeOrderId,
  shopId: state.customers.selectedOrder.shopId,
  platform: state.auth.platform,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  _getOrderDetails: getOrderDetails,
  _cancelCancelContract: cancelCancelContract,
  _confirmCancelContract: confirmCancelContract,
  _confirmCancelPpContract: confirmCancelPpContract,
}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CancelContractModal);
