const defaultState = {
  errors: [],
};

const errorReducer = function user(state = defaultState, action) {
  switch (action.type) {
    case 'ERROR':
      return { errors: [action.payload.name, ...state.errors] };
    case 'CLEAR_ERROR':
      return { errors: state.errors.slice(1) };
    default:
      return state;
  }
};

export default errorReducer;
